import usFlag from "../assets/images/flags/us.jpg";
import russia from "../assets/images/flags/russia.jpg";
import turkish from "../assets/images/flags/turkish.jpg";

const languages: Object = {
  tr: {
    label: "Turkish",
    flag: turkish,
  },
  ru: {
    label: "Russian",
    flag: russia,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
