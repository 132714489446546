import { createAsyncThunk } from "@reduxjs/toolkit";
import { merchantTableAPI } from "src/api/api_merchantTable/api_merchantTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setMerchantTable,
  setStatusMerchantTable,
  setTotalCountPageMerchantTable,
} from "src/features/MerchantTable/reducer";
import {
  setMerchantTableBrands,
  setStatusMerchantTableBrands,
  setTotalCountPageMerchantTableBrands,
} from "src/features/MerchantTableBrands/reducer";

import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchMerchantTableBrands = createAsyncThunk<any, any>(
  "merchantTableBrands/fetchMerchantTableBrands",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTableBrands({
        status: "merchantTableBrands-loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchMerchantTableBrands(
        param.sellerId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setMerchantTableBrands({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusMerchantTableBrands({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setTotalCountPageMerchantTableBrands({
            totalCountPage: res.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusMerchantTableBrands({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelMerchantTableBrands = createAsyncThunk<any, any>(
  "excelMerchantTableBrands/fetchExcelMerchantTableBrands",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTableBrands({
        status: "merchantTableBrands-loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchExcelMerchantTableBrands(
        param.sellerId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusMerchantTableBrands({ status: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusMerchantTableBrands({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
