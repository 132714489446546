import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { TableSoldType } from "src/features/product/miniProductComponent/table/tableSold/type";

export const slice = createSlice({
  name: "tableSoldProduct",
  initialState: {
    table: [] as Array<TableSoldType>,
    statusTableSold: "idle" as RequestStatusType,
  },
  reducers: {
    setTableSold(
      state,
      action: PayloadAction<{ table: Array<TableSoldType> }>,
    ) {
      state.table = [...action.payload.table];
    },
    setStatusTableSold(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusTableSold = action.payload.status;
    },
    clearTableSoldData(state, action) {
      return {
        ...state,
        table: [] as Array<TableSoldType>,
      };
    },
  },
});

export const tableSoldProduct = slice.reducer;

export const { setTableSold, setStatusTableSold, clearTableSoldData } =
  slice.actions;
