import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  passwordRecoveryMessage: "",
  status: "idle",
};
const slice = createSlice({
  name: "forgotPassword",
  initialState: initialState,
  reducers: {
    setForgotPasswordAc(state, action: PayloadAction<{ value: string }>) {
      state.passwordRecoveryMessage = action.payload.value;
    },
    setStatusForgotPassword(state, action: PayloadAction<{ status: string }>) {
      state.status = action.payload.status;
    },
  },
});
export const forgotPasswordReducer = slice.reducer;
export const { setForgotPasswordAc, setStatusForgotPassword } = slice.actions;
type ActionsType = ReturnType<typeof setForgotPasswordAc>;
