import React, { useEffect, useState } from "react";
import Select from "react-select";
import { onChangePhraseProductResearch } from "../reducer";
import { useAppDispatch } from "../../../utilitsFunction/redux-utils";
import { fetchCategoryTC } from "../thunk";
import {
  selectDataProductResearch,
  selectStatusFetchCategoryProductResearch,
  selectStatusProductResearch,
  selectValueProductResearch,
} from "../selectors";
import { useAppSelector } from "../../../store/types";
import { useTranslation } from "react-i18next";
import useSearchParams from "../../../features/CategoryTable/hooks/useSearchParams";
import { setUserStatus } from "../../../features/App/reducer";
import { selectUserStatus } from "../../../features/App/selectors";

export const AutoCompleteSelect = ({
  selectedOption,
  setSelectedOption,
  params,
  cats,
  formik,
}) => {
  const { urlParamNameCategory } = useSearchParams();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [timerId, setTimerId] = useState(undefined);
  const dispatch = useAppDispatch();
  const value = useAppSelector(selectValueProductResearch);
  const data = useAppSelector(selectDataProductResearch);
  const status = useAppSelector(selectStatusProductResearch);
  const statusFetchCategory = useAppSelector(
    selectStatusFetchCategoryProductResearch,
  );
  const userStatus = useAppSelector(selectUserStatus);

  // console.log(statusFetchCategory, "statusFetchCategory fetchCategory");
  useEffect(() => {
    dispatch(fetchCategoryTC(value));
  }, [value]);
  // const params = new URLSearchParams();
  useEffect(() => {
    if (urlParamNameCategory !== null && urlParamNameCategory !== "") {
      const categoryArray = urlParamNameCategory.split(",").map((category) => ({
        // value: category,
        label: category,
      }));
      setSelectedOption(categoryArray); // Установка выбранных категорий в состояние
    } else {
      setSelectedOption([]); // Если параметр URL отсутствует или пуст, сбросить выбранные категории
    }
  }, [urlParamNameCategory]);

  const handleChange = (selectedOption) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    setSelectedOption(selectedOption);
    // formik.setFieldValue(fieldName, selectedOption);
    const selectedValues = selectedOption.map((option) => option.value);
    const selectedLabel = selectedOption.map((option) => option.label);

    // Обновляем значение в формике, удаляя удаленные значения
    formik.setFieldValue(
      "cats",
      selectedValues.join(","), // Объединяем выбранные значения через запятую
    );
    formik.setFieldValue(
      "urlParamNameCategory",
      selectedLabel.join(","), // Объединяем выбранные значения через запятую
    );

    // for (const selectedOptionElement of selectedOption) {
    //   formik.setFieldValue(
    //     "cats",
    //     cats === ""
    //       ? selectedOptionElement.value
    //       : cats + "," + selectedOptionElement.value,
    //   );
    //   formik.setFieldValue("urlParamNameCategory", selectedOption);
    // }
  };

  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  //   let newUrl = window.location.pathname;
  //
  //   // Обновляем URL в зависимости от выбранных значений
  //   if (selectedOption && selectedOption.length > 0) {
  //     const params = new URLSearchParams();
  //     selectedOption.forEach((option) => {
  //       params.append("urlParamNameCategory", option.label);
  //     });
  //     newUrl += `?${params.toString()}`;
  //   }
  //
  //   window.history.pushState({}, "", newUrl);
  // };
  const handleInputChange = (value, actionMeta) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    setInputValue(value);
    // formik.setFieldValue(fieldName, value);
    timerId && clearTimeout(timerId);

    const id = +setTimeout(() => {
      dispatch(onChangePhraseProductResearch({ value }));
      setTimerId(undefined);
    }, 1500);
    setTimerId(id);
  };
  const handleMenuClose = (e) => {
    console.log(e, "Меню закрыто, возможно, значение было очищено");
    // Ваши дополнительные действия при закрытии меню (очистке)
  };
  return (
    <div>
      <Select
        style={{ zIndex: "100" }}
        isLoading={statusFetchCategory === "fetchCategory-loading"}
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange} // Обработчик изменений ввода
        isClearable={true}
        isMulti={true}
        // options={optionGroup}
        onMenuClose={handleMenuClose}
        options={data}
        closeMenuOnSelect={false}
        isSearchable={true} // Включаем возможность поиска
        placeholder={t("Start typing the name of the category")}
      />
    </div>
  );
};
