import React, { Suspense } from "react";
import { Spinner } from "reactstrap";

export const withSuspense = (Component: any) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: "fixed",
            top: "30%",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      }
    >
      <Component />
    </Suspense>
  );
};
