import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { SelectDropdown } from "src/components/Widgets/SelectDropdown/SelectDropdown";
import { useAppSelector } from "src/store/types";

const WidgetBlockCategoryTwo = ({
  title,
  selector,
  dataSelector,
  selectDropdown,
  id,
  selectChoiceBrandAndSellers,
  fetchDataBrands,
  fetchDataSellers,
}: any) => {
  const status = useAppSelector(selector);
  const data: any = useAppSelector(dataSelector);
  const { t, i18n } = useTranslation();

  // const formatValue = (val: any) => {
  //   if (val === undefined) {
  //     return <span className="placeholder" style={{ width: "25%" }}></span>; // Handle undefined case as needed
  //   }
  //   if (val >= 1000000) {
  //     return (val / 1000000).toFixed(2) + "M";
  //   } else if (val >= 1000) {
  //     return (val / 1000).toFixed(1) + "k";
  //   }
  //   return val.toLocaleString("ru-RU"); // если значение меньше 1000, просто форматируем с разделителями тысяч
  // };
  function formatValue(revenue: any) {
    const numericValue = Number(revenue);
    if (!numericValue) {
      return <span className="placeholder" style={{ width: "25%" }}></span>;
    }

    if (numericValue >= 1000000) {
      return (
        (numericValue / 1000000).toLocaleString("ru-RU", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "M"
      ); // Например, 3 082.62M
    } else if (numericValue >= 10000) {
      return (
        (numericValue / 1000).toLocaleString("ru-RU", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + "k"
      ); // Например, 10.8k
    }

    return numericValue.toLocaleString("ru-RU"); // Для значений меньше 10 000
  }
  return (
    <Col xl={3} sm={6} className="px-1">
      <Card
        style={{
          margin: "5px",
          position: "relative",
          opacity: status === "loading" ? 0.5 : "",
          pointerEvents: status === "loading" ? "none" : undefined,
        }}
      >
        <CardBody
          style={{
            minHeight: "208px", // Минимальная высота
            display: "block",
            marginTop: "2px",
          }}
        >
          {status === "loading" && (
            <CustomSpinner absolute={true} top={"50%"} />
          )}
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="font-size-xs text-uppercase">{title}</h6>
            {selectDropdown && (
              <SelectDropdown
                id={id}
                select={selectChoiceBrandAndSellers}
                defaultOption={[t("Brands"), t("Sellers")]}
                fetchOne={fetchDataBrands}
                fetchTwo={fetchDataSellers}
              />
            )}
          </div>
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="row mb-3">
                <div className="col-6">
                  <p className="text-muted mb-2">{t("with sold")}</p>
                  <h5>
                    {formatValue(data.with_sold)}
                    <p style={{ marginTop: "5px" }}>
                      <small
                        style={{
                          marginRight: "3px",
                          color:
                            data.with_sold_increment < 0
                              ? "#f34e4e"
                              : "#39cb5b",
                          fontSize: "14.4px",
                        }}
                      >
                        {/*{data.with_sold_increment !== undefined &&*/}
                        {/*data.with_sold_increment < 0 ? (*/}
                        {/*  <>*/}
                        {/*    /!*<small className="badge badge-soft-success font-13">*!/*/}
                        {/*    -{Math.abs(data.with_sold_increment) + "%"}*/}
                        {/*    /!*</small>*!/*/}
                        {/*  </>*/}
                        {/*) : (*/}
                        {/*  <>*/}
                        {/*    /!*<small className="badge badge-soft-success font-13">*!/*/}
                        {/*    +*/}
                        {/*    {data.with_sold_increment !== undefined &&*/}
                        {/*      data.with_sold_increment + "%"}*/}
                        {/*    /!*</small>*!/*/}
                        {/*  </>*/}
                        {/*)}*/}
                        {data.with_sold_increment === undefined ? (
                          <span
                            className="placeholder"
                            style={{ width: "10%" }}
                          ></span>
                        ) : data.with_sold_increment < 0 ? (
                          <>
                            {Math.abs(data.with_sold_increment) + "%"}
                            <i
                              className="uil uil-arrow-down text-danger"
                              style={{ verticalAlign: "middle" }}
                            ></i>
                          </>
                        ) : (
                          <>
                            {data.with_sold_increment + "% "}
                            <>
                              <i
                                className="uil uil-arrow-up text-success"
                                style={{ verticalAlign: "middle" }}
                              ></i>
                            </>
                          </>
                        )}
                      </small>
                    </p>
                  </h5>
                </div>
                <div className="col-6">
                  <p className="text-muted mb-2">{t("total")}</p>
                  <h5>
                    {formatValue(data.total)}
                    <p style={{ marginTop: "5px" }}>
                      <small
                        style={{
                          marginRight: "3px",
                          color:
                            data.total_increment < 0 ? "#f34e4e" : "#39cb5b",
                          fontSize: "14.4px",
                        }}
                      >
                        {data.total_increment === undefined ? (
                          <span
                            className="placeholder"
                            style={{ width: "10%" }}
                          ></span>
                        ) : data.total_increment < 0 ? (
                          <>
                            {/*/!*<small className="badge badge-soft-success font-13">*!/*/}
                            {/*-{Math.abs(data.total_increment) + "%"}*/}
                            {/*/!*</small>*!/*/}
                            {Math.abs(data.total_increment) + "%"}
                            <i
                              className="uil uil-arrow-down text-danger"
                              style={{ verticalAlign: "middle" }}
                            ></i>
                          </>
                        ) : (
                          <>
                            {/*/!*<small className="badge badge-soft-success font-13">*!/*/}
                            {/*+{data.total_increment + "%"}*/}
                            {/*/!*</small>*!/*/}
                            {data.total_increment + "%"}
                            <i
                              className="uil uil-arrow-up text-success"
                              style={{ verticalAlign: "middle" }}
                            ></i>
                          </>
                        )}
                      </small>
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WidgetBlockCategoryTwo;
