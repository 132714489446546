import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Col, Modal, Table } from "reactstrap";

const TableDescription = ({
  tog_standard,
  modal_standard,
  setmodal_standard,
}: any) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("All-data-are-for-the-last-30-days")}</p>
          <Col className="col-xxl-12" xl={12}>
            {/*<Card>*/}
            <CardHeader className="justify-content-between d-flex align-items-center">
              <CardTitle style={{ marginBottom: "7px" }} className="h4">
                {t("Description-of-columns-of-the-report-table")}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>{t("Column")}</th>
                      <th>{t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("SoldTableBrandList")}</td>
                      <td>{t("Quantity-sold")}</td>
                    </tr>
                    <tr>
                      <td>{t("Revenue")}</td>
                      <td>
                        {t(
                          "Quantity sold multiplied by the price on the day of sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*4*/}
                      <td>{t("Goods, pcs")}</td>
                      <td>{t("Quantity of goods found in the brand")}</td>
                    </tr>
                    <tr>
                      {/*5*/}
                      <td>{t("Goods with sales, pcs")}</td>
                      <td>
                        {t(
                          "Number of items in the brand that have at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*6*/}
                      <td>{t("Share of goods with sales")}</td>
                      <td>
                        {t(
                          "The ratio of items with sales to the total number of items in the brand",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*7*/}
                      <td>{t("Goods with stocks")}</td>
                      <td>{t("Number of items in the brand with stock")}</td>
                    </tr>
                    <tr>
                      {/*8*/}
                      <td>{t("Share of goods with stocks")}</td>
                      <td>
                        {t(
                          "Ratio of items with stock to total number of items in the brand",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*9*/}
                      <td>{t("Number of sellers")}</td>
                      <td>{t("Number of salespeople selling the brand")}</td>
                    </tr>
                    <tr>
                      {/*10*/}
                      <td>{t("Sellers with sales")}</td>
                      <td>
                        {t(
                          "Number of sellers selling the brand who have had at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*11*/}
                      <td>{t("Share of sellers with sales")}</td>
                      <td>
                        {t(
                          "The ratio of the number of salespeople with sales to the total number of salespeople selling the brand",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*12*/}
                      <td>{t("Sellers with stocks")}</td>
                      <td>
                        {t(
                          "Number of branded sellers who had stock at the time of our last data capture",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*13*/}
                      <td>{t("Share of sellers with stocks")}</td>
                      <td>
                        {t(
                          "The ratio of the number of sellers with stock to the total number of sellers",
                        )}
                      </td>
                    </tr>
                    <tr>
                      {/*14*/}
                      <td>{t("Min price of goods with sales")}</td>
                      <td>
                        {t("Minimum price of an item with sales in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*15*/}
                      <td>{t("Max price of goods with sales")}</td>
                      <td>
                        {t("Maximum price of an item with sales in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*16*/}
                      <td>{t("Median price of goods with sales")}</td>
                      <td>
                        {t("Median price of goods with sales in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*17*/}
                      <td>{t("Min price of goods with stocks")}</td>
                      <td>
                        {t("Minimum price of goods with stock in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*18*/}
                      <td>{t("Max price of goods with stocks")}</td>
                      <td>
                        {t("Maximum price of goods with stock in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*19*/}
                      <td>{t("Median price of goods with stocks")}</td>
                      <td>
                        {t("Median price of goods with stock in the brand")}
                      </td>
                    </tr>
                    <tr>
                      {/*20*/}
                      <td>{t("Min. price")}</td>
                      <td>{t("Minimum price of goods in a brand")}</td>
                    </tr>
                    <tr>
                      {/*// 21*/}
                      <td>{t("Max. price")}</td>
                      <td>{t("Maximum price of goods in a brand")}</td>
                    </tr>

                    <tr>
                      {/*22*/}
                      <td>{t("Median price")}</td>
                      <td>
                        {t(
                          "Median price in a brand (similar to the average, but slightly more accurate)",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </CardBody>
  );
};

export default TableDescription;
