import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { FetchResponseAxios } from "src/api/types";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setStatusVariant,
  setVariants,
} from "src/features/product/miniProductComponent/variants/reducer";
import { VariantsResponseType } from "src/features/product/miniProductComponent/variants/type";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchVariantProduct = createAsyncThunk<
  any,
  { id: string; merchantId: string }
>(
  "variantProduct/fetchVariantProduct",
  async ({ id, merchantId }, thunkAPI) => {
    thunkAPI.dispatch(setStatusVariant({ status: "variant-loading" }));
    try {
      const res = await productAPI.fetchVariantProduct(id, merchantId);
      if (res.data.success === true) {
        thunkAPI.dispatch(setVariants({ variants: res.data.message }));
        thunkAPI.dispatch(setStatusVariant({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusVariant({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
