import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setData,
  setStatusProduct,
} from "src/features/product/product-reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchProduct = createAsyncThunk<
  any,
  { id: string; itemNumberUrl: string; merchantId: string }
>(
  "product/fetchProduct",
  async ({ id, itemNumberUrl, merchantId }, thunkAPI) => {
    thunkAPI.dispatch(setStatusProduct({ status: "product-loading" }));
    try {
      const res = await productAPI.fetchProduct(id, itemNumberUrl, merchantId);

      if (res.data.success) {
        thunkAPI.dispatch(setData({ dataProduct: res.data.message }));
        thunkAPI.dispatch(setStatusProduct({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusProduct({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      thunkAPI.dispatch(setStatusProduct({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
