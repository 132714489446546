import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "productSearch",
  initialState:
    ({
      productId: null as null | number,
      status: "idle" as RequestStatusType,
    } as any) || {},
  reducers: {
    setSearchProduct(state, action: PayloadAction<{ productId: number }>) {
      state.productId = action.payload.productId;
    },
    setStatusSearchProduct(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetSearchProduct(state, action) {
      return {
        ...state,
        productId: null as null | number,
      };
    },
  },
});

export const productSearchReducer = slice.reducer;
export const { setSearchProduct, setStatusSearchProduct, resetSearchProduct } =
  slice.actions;
