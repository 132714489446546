import React from "react";

export const Header = ({ number, title, text }: any) => {
  return (
    <div className="card-header d-flex align-items-center">
      <div className="flex-shrink-0 me-3">
        <div className="avatar-sm">
          <div className="avatar-title rounded-circle bg-soft-primary text-primary">
            {number}
          </div>
        </div>
      </div>
      <div className="flex-grow-1">
        <h5 className="card-title">
          {title}
          {/*{t("Integration")}*/}
        </h5>
        <div className="mb-0">
          <span className="font-13 text-muted">
            {text}

            {/*{t("Integration")}*/}
          </span>
        </div>
      </div>
    </div>
  );
};
