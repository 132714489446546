import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaChartLine,
  FaRegChartBar,
  FaShoppingCart,
  FaUserTie,
  FaWarehouse,
} from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { Badge, Button, Spinner, Tooltip } from "reactstrap";
import { ScrollWrapper } from "src/components/ScrollWrapper/ScrollWrapper";
import { selectUserStatus } from "src/features/App/selectors";
import {
  clearSellerData,
  setMerchantId,
} from "src/features/product/miniProductComponent/seller/reducer";
import {
  selectMerchantId,
  selectSellerData,
  selectStatusProduct,
} from "src/features/product/miniProductComponent/seller/selectors";
import { fetchSellerProduct } from "src/features/product/miniProductComponent/seller/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import "./Seller.module.css";

const SellerCard = ({
  merchantId,
  dispatch,
  imageUrl,
  sellerName,
  hasStock,
  hasSold,
  disabled,
  rating,
  active,
  sold,
  revenue,
  share,
}: any) => {
  const userStatus = useAppSelector(selectUserStatus);

  const [salesTooltipOpen, setSalesTooltipOpen] = useState(false);
  const [stockTooltipOpen, setStockTooltipOpen] = useState(false);
  const [salesSoldTooltipOpen, setSalesSoldTooltipOpen] = useState(false);
  const [shareTooltipOpen, setShareTooltipOpen] = useState(false);
  const [revenueTooltipOpen, setRevenueTooltipOpen] = useState(false);
  const [ratingTooltipOpen, setRatingTooltipOpen] = useState(false);
  const [faUserTie, setFaUserTie] = useState(false);
  const [sellerNameTooltip, setSellerName] = useState(false);

  const toggleSalesTooltip = () => setSalesTooltipOpen(!salesTooltipOpen);

  const toggleStockTooltip = () => setStockTooltipOpen(!stockTooltipOpen);

  const { t } = useTranslation();

  const handleClick = () => {
    if (!disabled) {
      dispatch(setMerchantId({ merchantId: merchantId }));
    }
  };

  const formatRevenue = (revenue: any) => {
    const revenueNumber = parseFloat(revenue);
    if (isNaN(revenueNumber)) {
      // Если значение не является числом, возвращаем исходное значение
      return revenue;
    }

    if (revenueNumber >= 1000000) {
      return `${(revenueNumber / 1000000).toFixed(1)} ${t("mln.")}`;
    } else if (revenueNumber >= 1000) {
      return `${(revenueNumber / 1000).toFixed(1)} ${t("k")}`;
    } else {
      return revenue.toString();
    }
  };
  const formattedRevenue = formatRevenue(revenue);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Обработчик изменения размера экрана
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Эффект для добавления слушателя события изменения размера экрана
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Чистка слушателя при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(screenWidth, "screenWidth");
  const navigate = useNavigate();

  const handleIconClick = (merchantId: any) => {
    navigate(`/trendyol/seller/${merchantId}/products`);
  };

  return (
    // <SimpleBar className="chat-message-list widget-chat-list">
    <li>
      <a
        onClick={handleClick}
        style={{
          color: "skyblue",
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: disabled
            ? "rgb(8 29 41 / 8%)"
            : active
            ? "#4ca6c2"
            : "",
          // color: disabled ? "#fff" : active ? "#fff" : "skyblue",
        }}
      >
        <div className="d-flex align-items-start">
          <div className="flex-shrink-0 user-img online align-self-center me-3">
            <img src={imageUrl} className="rounded-circle avatar-sm" alt="" />
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-14 mb-1">
              <Tooltip
                placement="top"
                isOpen={sellerNameTooltip}
                target={`sellerName${merchantId}`}
                toggle={() => setSellerName(!sellerNameTooltip)}
              >
                {sellerName}
              </Tooltip>
              {sellerName.length > 12 ? (
                <span id={`sellerName${merchantId}`}>
                  {`${sellerName.slice(0, 12)}... `}
                </span>
              ) : (
                <span id={`sellerName${merchantId}`}>{sellerName + " "}</span>
              )}
              <Tooltip
                placement="top"
                isOpen={ratingTooltipOpen}
                target={`rating${merchantId}`}
                toggle={() => setRatingTooltipOpen(!ratingTooltipOpen)}
              >
                {t("rating")}
              </Tooltip>
              <Badge
                id={`rating${merchantId}`}
                color="link"
                pill
                className="badge-soft-primary"
              >
                {rating}
              </Badge>{" "}
            </h5>

            <>
              <Tooltip
                placement="top"
                isOpen={stockTooltipOpen}
                target={`stockIcon${merchantId}`}
                toggle={toggleStockTooltip}
              >
                {hasStock ? t("Stock available") : t("No stock")}
              </Tooltip>
              <FaWarehouse
                size={14}
                id={`stockIcon${merchantId}`}
                onMouseEnter={toggleStockTooltip}
                onMouseLeave={toggleStockTooltip}
                style={{ marginLeft: "3px" }}
                color={hasStock ? "green" : "red"}
              />{" "}
              <Tooltip
                placement="top"
                isOpen={revenueTooltipOpen}
                toggle={() => setRevenueTooltipOpen(!revenueTooltipOpen)}
                target={`revenue${merchantId}`}
              >
                {/*{hasSold ? t("Sales have been made") : t("No sales")}*/}
                {t("Revenue")}
              </Tooltip>
              <Badge id={`revenue${merchantId}`} className="bg-success">
                {" " + formattedRevenue}
              </Badge>{" "}
              <Tooltip
                placement="top"
                isOpen={salesSoldTooltipOpen}
                toggle={() => setSalesSoldTooltipOpen(!salesSoldTooltipOpen)}
                target={`shoppingCartIconSold${merchantId}`}
              >
                {t("Number of sold pcs")}
              </Tooltip>
              <Badge
                id={`shoppingCartIconSold${merchantId}`}
                className="bg-warning"
              >
                {" " + sold + " "}
              </Badge>{" "}
              <Tooltip
                placement="top"
                isOpen={shareTooltipOpen}
                toggle={() => setShareTooltipOpen(!shareTooltipOpen)}
                target={`share${merchantId}`}
              >
                {/*{hasSold ? t("Sales have been made") : t("No sales")}*/}
                {t("Seller's share of total sales")}
              </Tooltip>
              <Badge id={`share${merchantId}`} className="bg-info">
                {share + "%"}
              </Badge>{" "}
            </>
          </div>
          <FaChartLine
            onClick={() => handleIconClick(merchantId)}
            size={12}
            id={`faUserTie${merchantId}`}
            onMouseEnter={() => setFaUserTie(true)}
            onMouseLeave={() => setFaUserTie(false)}
            style={{ marginTop: "5px" }}
            color={faUserTie ? "#126985" : "skyBlue"} // Цвет в зависимости от состояния
          />
        </div>
      </a>
    </li>
    // </SimpleBar>
  );
};

type PropsType = {
  id: string | null;
  itemNumberUrl: string | null;
};

export const Seller = (props: PropsType) => {
  const { t } = useTranslation();

  const [ttop, setttop] = useState(false);

  const dateSelect = useAppSelector(selectDateSelect);
  const data = useAppSelector(selectSellerData);
  const status = useAppSelector(selectStatusProduct);
  const merchantId = useAppSelector(selectMerchantId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchSellerProduct({
        id: props.id,
        itemNumberUrl: props.itemNumberUrl,
        startDate: dateSelect[0],
        endDate: dateSelect[1],
      }),
    );
  }, [props.id, props.itemNumberUrl, dateSelect]);

  useEffect(() => {
    return () => {
      dispatch(clearSellerData({}));
    };
  }, []);

  return (
    <>
      {status === "seller-loading" ? (
        <div
          className="card-h-90 card"
          style={{
            backgroundColor: "white",
            alignItems: "center",
            padding: "50px",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      ) : (
        <div className="card">
          <div className="d-flex card-header justify-content-between">
            <div className="h4 card-title " style={{ cursor: "pointer" }}>
              {t("sellers")}{" "}
              <Tooltip
                placement="top"
                isOpen={ttop}
                target="TooltipTop"
                toggle={() => {
                  setttop(!ttop);
                }}
              >
                {t("sellersTooltip")}
              </Tooltip>
              <i className="uil-question-circle" id={"TooltipTop"}></i>
            </div>
            {/*{data.length > 1 && (*/}
            <Button
              style={{
                marginLeft: "auto",
                marginTop: "-5px",
                marginBottom: "-5px",
              }}
              color="info"
              size="sm"
              onClick={() => {
                dispatch(setMerchantId({ merchantId: null }));
              }}
              active={merchantId === null}
            >
              {t("all")}{" "}
            </Button>
            {/*)}*/}
          </div>
          <div
          // className="pb-4"
          >
            <ScrollWrapper>
              <div className="card-body">
                <ul className="list-unstyled chat-list">
                  {data.map((seller, index) => (
                    <SellerCard
                      t={t}
                      dispatch={dispatch}
                      key={index}
                      hasSold={seller.hasSold}
                      hasStock={seller.hasStock}
                      imageUrl={seller.logo_url}
                      sellerName={seller.name}
                      merchantId={seller.merchant_id}
                      active={seller.merchant_id === merchantId}
                      rating={seller.seller_score}
                      sold={seller.sold}
                      revenue={seller.revenue}
                      share={seller.share}
                      disabled={seller.enable === false || data.length < 2}
                    />
                  ))}
                </ul>
              </div>
            </ScrollWrapper>
          </div>
        </div>
      )}
    </>
  );
};
