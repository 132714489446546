import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VariablesType } from "src/features/App/type";
import { AppRootStateType } from "src/store/types";
// import { InputsType } from "src/features/CategoryTable/type";

const initialState: any = {
  categoryId: null,
  categoryName: null,
  status: "idle",
  url: "",
  error: null as any,
  success: null,
  isInitialized: false,
  name: null,
  tax: null,
  phone: null,
  tokenGoogle: null,
  email: null,
  toggle: false,
  paidTo: null,
  tariffName: null,
  userStatus: false,
  variables: null,
  nameUrl: "",
  api: "",
  secret: "",
  supplierId: "",
  tokenIntegration: "",
};

export const slice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setAppErrorAC(state, action: PayloadAction<{ error: string | null }>) {
      state.error = action.payload.error;
    },
    setURLAC(state, action: PayloadAction<{ url: string | null }>) {
      state.url = action.payload.url;
    },
    setPaidToAC(state, action: PayloadAction<{ paidTo: any }>) {
      state.paidTo = action.payload.paidTo;
    },
    setTariffNameAC(state, action: PayloadAction<{ tariffName: any }>) {
      state.tariffName = action.payload.tariffName;
    },
    setUserStatus(state, action: PayloadAction<{ userStatus: string }>) {
      let status = action.payload.userStatus;
      // state.userStatus = status === "unpaid";
      state.userStatus = action.payload.userStatus;
    },
    setAppSuccessAC(state, action: PayloadAction<{ success: string | null }>) {
      state.success = action.payload.success;
    },
    setAppStatusAC(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    setAppInitializedAC(
      state,
      action: PayloadAction<{ isInitialized: boolean }>,
    ) {
      state.isInitialized = action.payload.isInitialized;
    },
    changeNameAC(state, action: PayloadAction<{ name: string | null }>) {
      state.name = action.payload.name;
    },
    changeTexAC(state, action: PayloadAction<{ tax: string | null }>) {
      state.tax = action.payload.tax;
    },
    changePhoneAC(state, action: PayloadAction<{ phone: string | null }>) {
      state.phone = action.payload.phone;
    },
    setUserVariables(
      state,
      action: PayloadAction<{ variables: VariablesType }>,
    ) {
      state.variables = action.payload.variables;
    },
    fetchTokenAC(state, action: PayloadAction<{ tokenGoogle: string | null }>) {
      state.tokenGoogle = action.payload.tokenGoogle;
    },
    changeEmailAC(state, action: PayloadAction<{ email: string | null }>) {
      state.email = action.payload.email;
    },
    changeToggleAC(state, action: PayloadAction<{ toggle: boolean }>) {
      state.toggle = action.payload.toggle;
    },
    setCategoryId(state, action: PayloadAction<{ categoryId: any }>) {
      state.categoryId = action.payload.categoryId;
    },
    setNameCategory(state, action: PayloadAction<{ categoryName: any }>) {
      state.categoryName = action.payload.categoryName;
    },
    setNameUrl(state, action: PayloadAction<{ nameUrl: string }>) {
      state.nameUrl = action.payload.nameUrl;
    },
    //
    setKey(state, action: PayloadAction<{ api: string }>) {
      state.api = action.payload.api;
    },

    setSecret(state, action: PayloadAction<{ secret: string }>) {
      state.secret = action.payload.secret;
    },
    setSupplierId(state, action: PayloadAction<{ supplierId: string }>) {
      state.supplierId = action.payload.supplierId;
    },
    setTokenIntegration(state, action: PayloadAction<{ token: string }>) {
      state.tokenIntegration = action.payload.token;
    },
    // resetAppState(state, action) {
    //   return {
    //     ...state,
    //     categoryId: null,
    //     categoryName: null,
    //     status: "idle",
    //     url: "",
    //     error: null as any,
    //     success: null,
    //     isInitialized: false,
    //     name: null,
    //     tex: null,
    //     phone: null,
    //     tokenGoogle: null,
    //     email: null,
    //     toggle: false,
    //     paidTo: null,
    //     tariffName: null,
    //     userStatus: false,
    //     variables: null,
    //     nameUrl: "",
    //   };
    // },
  },
});
export const appReducer = slice.reducer;
export const {
  // resetAppState,
  setPaidToAC,
  setURLAC,
  setUserVariables,
  setTariffNameAC,
  setUserStatus,
  setCategoryId,
  setNameCategory,
  changeToggleAC,
  changeEmailAC,
  fetchTokenAC,
  setAppErrorAC,
  setAppStatusAC,
  changePhoneAC,
  changeTexAC,
  setAppSuccessAC,
  changeNameAC,
  setAppInitializedAC,
  setNameUrl,
} = slice.actions;

export type RequestStatusType =
  | "idle"
  | "loading"
  | "succeeded"
  | "failed"
  | "graph-loading"
  | "product-loading"
  | "image-loading"
  | "variant-loading"
  | "seller-loading"
  | "tableCategoryPosition-loading"
  | "tableSearchPosition-loading"
  | "tableSold-loading"
  | "descriptionEdit-loading"
  | "category-loading"
  | "categoryTable-loading"
  | "sellers-loading"
  | "brands-loading"
  | "merchantTable-loading"
  | "merchantTableCategory-loading"
  | "merchantTableBrands-loading"
  | "brandsTableProduct-loading"
  | "brandsTableSellers-loading"
  | "brandsTableCategory-loading"
  | "tableCategoryList-loading"
  | "listSellersTable-loading"
  | "listBrandTable-loading"
  | "searchProduct-loading"
  | "productAdvert-loading"
  | "subscribe-loading"
  | "tableFavoriteGoods-loading"
  | "forgot-password-loading"
  | "sign-loading"
  | "logout-loading"
  | "priceSegmentsTable-loading"
  | "productResearch-loading"
  | "fetchCategory-loading";

export type InitialStateType = {
  categoryId: null;
  categoryName: null;
  status: RequestStatusType;
  error: string | null;
  success: string | null;
  isInitialized: boolean;
  name: string | null;
  tex: string | null;
  phone: string | null;
  tokenGoogle: string | null;
  email: string | null;
  toggle: boolean;
};
