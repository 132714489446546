import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "merchantTableCategory",
  initialState: {
    table: [] as any,
    filteredTable: "" as any,
    orderName: "" as string,
    ascDesc: "" as string,
    page: 1 as number,
    totalCountPage: null as number | null,
    columnName: [] as Array<InputsType>,
    showVideo: true,
    restart: false,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setTableCategoryList(state, action: PayloadAction<{ table: Array<any> }>) {
      state.table = [...action.payload.table];
    },
    setFilterTableCategoryList(
      state,
      action: PayloadAction<{ value: string }>,
    ) {
      state.filteredTable = action.payload.value;
    },
    onClickPageTableCategoryList(
      state,
      action: PayloadAction<{ page: number }>,
    ) {
      state.page = action.payload.page;
    },
    setTotalCountPageTableCategoryList(
      state,
      action: PayloadAction<{
        totalCountPage: number | null;
      }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameTableCategoryList(
      state,
      action: PayloadAction<{ orderName: string }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescTableCategoryList(
      state,
      action: PayloadAction<{ ascDesc: string }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    onChangePhraseTableCategoryList(
      state,
      action: PayloadAction<{
        columnName: Array<// InputsType
        any>;
      }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setShowVideoCategoryList(
      state,
      action: PayloadAction<{ showVideo: boolean }>,
    ) {
      state.showVideo = action.payload.showVideo;
    },
    setStatusTableCategoryList(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    setRestartCategoryList(state, action: PayloadAction<{ restart: boolean }>) {
      state.restart = action.payload.restart;
    },
    resetTableCategoryList(state, action) {
      return {
        ...state,
        table: [] as any,
        filteredTable: "" as any,
        orderName: "" as string,
        ascDesc: "" as string,
        columnName: [] as Array<InputsType>,
        page: 1 as number,
        restart: false,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const tableCategoryListReducer = slice.reducer;

export const {
  setTableCategoryList,
  setFilterTableCategoryList,
  onClickPageTableCategoryList,
  setTotalCountPageTableCategoryList,
  clickOrderNameTableCategoryList,
  clickAscDescTableCategoryList,
  setShowVideoCategoryList,
  onChangePhraseTableCategoryList,
  resetTableCategoryList,
  setRestartCategoryList,
  setStatusTableCategoryList,
} = slice.actions;
