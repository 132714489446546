import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetData from "src/components/Widgets/Widget";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import s from "src/features/CategoryTable/Category.module.css";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";
import {
  selectCategoryId,
  selectNameCategory,
  selectNameUrl,
} from "src/features/CategoryTable/selectors";
import { InputsType } from "src/features/CategoryTable/type";
import { resetMerchantTable } from "src/features/MerchantTable/reducer";
import {
  selectChoiceCompetitors,
  selectChoiceProductInTheTops,
  selectDataCompetitors,
  selectDataProductInTheTops,
  selectDataRating,
  selectDataRevenue,
  selectStatusCompetitors,
  selectStatusDataRating,
  selectStatusDataRevenue,
  selectStatusProductInTheTops,
} from "src/features/MerchantTable/selectors";
import {
  fetchDataCategory,
  fetchDataCompetitorsSold,
  fetchDataRating,
  fetchDataRevenue,
  fetchDataSearch,
  fetchExcelMerchantTable,
  fetchItemsInTheCategoryTop,
} from "src/features/MerchantTable/thunk";
import {
  clickAscDescMerchantTableBrands,
  clickOrderNameMerchantTableBrands,
  onClickPageMerchantTableBrands,
  resetMerchantTableBrands,
  setFilterMerchantTableBrands,
} from "src/features/MerchantTableBrands/reducer";
import {
  selectAscDescMerchantTableBrands,
  selectDataMerchantTableBrands,
  selectOrderNameMerchantTableBrands,
  selectPageMerchantTableBrands,
  selectStatusMerchantTableBrands,
  selectTotalCountPageMerchantTableBrands,
} from "src/features/MerchantTableBrands/selectors";
import {
  fetchExcelMerchantTableBrands,
  fetchMerchantTableBrands,
} from "src/features/MerchantTableBrands/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { AppRootStateType, useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const MerchantTableBrands = () => {
  const [tooltipName, setTooltipName] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: "" },
  ]);
  const [image, setImage] = useState<string>("");
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const { sellerId } = useParams();
  const data = useAppSelector(selectDataMerchantTableBrands);
  const orderName = useAppSelector(selectOrderNameMerchantTableBrands);
  const ascDesc = useAppSelector(selectAscDescMerchantTableBrands);
  const page = useAppSelector(selectPageMerchantTableBrands);
  const totalCountPage = useAppSelector(
    selectTotalCountPageMerchantTableBrands,
  );
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const statusMerchantTable = useAppSelector(selectStatusMerchantTableBrands);
  const nameUrl = useAppSelector(selectNameUrl);
  const userStatus = useAppSelector(selectUserStatus);

  document.title = `Restatos | ${
    categoryId === sellerId && currentLanguage === "tr"
      ? t("SellerAnalyticalReportOne") +
        nameCategory +
        t("SellerAnalyticalReportTwo")
      : categoryId === sellerId && currentLanguage !== "tr"
      ? t("SellerAnalyticalReport") + " " + nameCategory
      : ""
  }`;

  async function fetchData() {
    const resName = await instance.get<
      FetchResponseTable<Array<{ message: string; path: string }>>
    >(`trendyol/seller/name/${sellerId}`);
    dispatch(setCategoryId({ categoryId: sellerId }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== sellerId) {
    fetchData();
  }
  const dateSelect = useAppSelector(selectDateSelect);

  const param: any = {
    sellerId,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
    // page,
  };
  useEffect(() => {
    dispatch(fetchMerchantTableBrands(param));
    dispatch(fetchDataCompetitorsSold(param));
    dispatch(fetchDataRating(param));
    dispatch(fetchDataRevenue(param));
    // dispatch(fetchDataCompetitorsSold(sellerId));
  }, [sellerId, page, dateSelect[0], dateSelect[1]]);

  useEffect(() => {
    return () => {
      dispatch(resetMerchantTableBrands({}));
      dispatch(resetMerchantTable({}));
      dispatch(clearDate({}));

      setImage("");
    };
  }, []);

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPageMerchantTableBrands({ page: page }));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    dispatch(onClickPageMerchantTableBrands({ page: newPage }));
    setCount(newCount);
  };
  const handleSort = useCallback((column, sortDirection) => {
    dispatch(
      clickOrderNameMerchantTableBrands({
        orderName: column.selector,
      }),
    );
    dispatch(clickAscDescMerchantTableBrands({ ascDesc: sortDirection }));
  }, []);

  const filtered = useSelector(
    (state: AppRootStateType) => state.merchantTableBrands.filteredTable,
  );
  let filteredData = data.filter((el: any) =>
    el.name.toLowerCase().includes(filtered.toLowerCase()),
  );
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    dispatch(setFilterMerchantTableBrands({ value: value }));

    setInputs(updatedInputs);
  };
  const columns = [
    {
      name: t("name"),
      selector: "name",
      sortable: true,
      title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/brand/${row.id}/products`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          <Tooltip />
        </>
      ),
    },

    {
      name: t("revenue"),
      selector: "revenueInSeller",
      sortable: true,
      format: (row: { revenueInSeller: any }) =>
        (row.revenueInSeller / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("revenueTotal"),
      selector: "revenueTotal",
      sortable: true,

      format: (row: { revenueTotal: number }) =>
        (row.revenueTotal / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },

    {
      name: t("revenueSharePercent"),
      selector: "revenueSharePercent",
      sortable: true,

      format: (row: { revenueSharePercent: number }) =>
        row.revenueSharePercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("soldInSeller"),
      selector: "soldInSeller",
      format: (row: { soldInSeller: number }) =>
        row.soldInSeller.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("soldTotal"),
      selector: "soldTotal",
      format: (row: { soldTotal: number }) =>
        row.soldTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareSoldInSellerFromAllSalesPercent"),
      selector: "shareSoldInSellerFromAllSalesPercent",
      format: (row: { shareSoldInSellerFromAllSalesPercent: number }) =>
        row.shareSoldInSellerFromAllSalesPercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("countProdInSeller"),
      selector: "countProdInSeller",
      format: (row: { countProdInSeller: number }) =>
        row.countProdInSeller.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSold"),
      selector: "prodWithSold",
      format: (row: { prodWithSold: number }) =>
        row.prodWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSoldPercent"),
      selector: "prodWithSoldPercent",
      format: (row: { prodWithSoldPercent: number }) =>
        row.prodWithSoldPercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("countProdTotal"),
      selector: "countProdTotal",
      format: (row: { countProdTotal: number }) =>
        row.countProdTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("countProdSharePercent"),
      selector: "countProdSharePercent",
      format: (row: { countProdSharePercent: number }) =>
        row.countProdSharePercent
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("avgSoldPerProdTotal"),
      selector: "avgSoldPerProdTotal",
      format: (row: { avgSoldPerProdTotal: any }) =>
        row.avgSoldPerProdTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgSoldPerProdWithSold"),
      selector: "avgSoldPerProdWithSold",
      format: (row: { avgSoldPerProdWithSold: any }) =>
        row.avgSoldPerProdWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgPrice"),
      // selector: (row) => row.price,
      selector: "avgPrice",
      format: (row: { avgPrice: any }) =>
        (row.avgPrice / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("sellersCount"),
      selector: "sellersCount",
      format: (row: { sellersCount: any }) =>
        row.sellersCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: <div style={{ marginLeft: "3px" }}>{t("chart")}</div>,

      cell: (row: { chart: Array<number> }) => (
        <div style={{ marginLeft: "-27px" }}>
          <D3BarChart data={row.chart} />
        </div>
      ),
    },
  ] as any;

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      {statusMerchantTable === "merchantTableBrands-loading" && (
        <CustomSpinner absolute={true} top={"600px"} />
      )}
      <div
      // className={
      //   statusMerchantTable === "merchantTableBrands-loading" ? s.opasity : ""
      // }
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/seller-list">Sellers</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === sellerId && currentLanguage === "tr" ? (
                      <>
                        {t("SellerAnalyticalReportOne")}
                        {nameCategory}&nbsp;{t("SellerAnalyticalReportTwo")}
                      </>
                    ) : null}
                    {categoryId === sellerId && currentLanguage !== "tr" ? (
                      <>
                        {t("SellerAnalyticalReport")}&nbsp;{nameCategory}
                      </>
                    ) : null}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{ background: "white", fontSize: "20px", paddingLeft: "10px" }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === sellerId && currentLanguage === "tr" ? (
                <>
                  {t("SellerAnalyticalReportOne")}
                  {nameCategory}&nbsp;{t("SellerAnalyticalReportTwo")}
                </>
              ) : null}
              {categoryId === sellerId && currentLanguage !== "tr" ? (
                <>
                  {t("SellerAnalyticalReport")}&nbsp;{nameCategory}
                </>
              ) : null}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelMerchantTableBrands(param))}
              />
            </span>

            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <WidgetData
          id={sellerId}
          //
          dataRevenue={selectDataRevenue}
          selectDataRevenue={selectDataRevenue}
          selectStatusDataRevenue={selectStatusDataRevenue}
          //
          dataRating={selectDataRating}
          selectStatusDataRating={selectStatusDataRating}
          //
          dataProductInTheTops={selectDataProductInTheTops}
          selectChoiceProductInTheTops={selectChoiceProductInTheTops}
          fetchDataSearch={fetchDataSearch}
          fetchDataCategory={fetchDataCategory}
          selectStatusProductInTheTops={selectStatusProductInTheTops}
          //
          select={false}
          dataCompetitors={selectDataCompetitors}
          selectChoiceCompetitors={selectChoiceCompetitors}
          selectStatusCompetitors={selectStatusCompetitors}
          fetchDataCompetitorsSold={fetchDataCompetitorsSold}
          fetchItemsInTheCategoryTop={fetchItemsInTheCategoryTop}
        />
        <div
          className={
            statusMerchantTable === "merchantTableBrands-loading"
              ? s.opasity
              : ""
          }
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <NavLink
              to={`/trendyol/seller/${sellerId}/products`}
              type="button"
              className="btn-sm btn btn-light "
              style={{ marginTop: "10px" }}
            >
              {t("products")}
            </NavLink>
            <NavLink
              to={`/trendyol/seller/categories/${sellerId}`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("category")}
            </NavLink>
            <a
              href={`/trendyol/seller/brands/${sellerId}`}
              className="btn-sm btn btn-primary"
              style={{ marginTop: "10px" }}
            >
              {t("brands")}
            </a>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {inputs.map((input, index) => (
                // TODO
                <CustomInput
                  id={input.id}
                  // disabled={loading}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  // onKeyPress={onKeyPress}
                />
              ))}
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav tableList ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"sold"}
            title={t("")}
            columns={columns}
            data={filteredData}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            // sortIcon={null}
            responsive={true}
            // onChangePage={handlePageChange}
            sortServer={false}
            defaultSortAsc={true}
            // noDataComponent={<div>{t("dataTable")}</div>}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          <TooltipInTable tooltipName={tooltipName} />
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
