import React, { useState, useEffect, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Spinner, Tooltip } from "reactstrap";
import { ScrollWrapper } from "src/components/ScrollWrapper/ScrollWrapper";
import { selectStatus } from "src/features/App/selectors";
import {
  clearPhotoState,
  setTimeCreatePhoto,
} from "src/features/product/miniProductComponent/photo/reducer";
import {
  selectDataPhoto,
  selectKeyTimeCreate,
} from "src/features/product/miniProductComponent/photo/selectors";
import { fetchProductImage } from "src/features/product/miniProductComponent/photo/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { format } from "date-fns";

type TypeProps = {
  photo?: any;
  id: string;
  itemNumberUrl: string;
};

export const Photo = (props: TypeProps) => {
  const { t } = useTranslation();
  const data = useAppSelector(selectDataPhoto);
  const keyTimeCreate = useAppSelector(selectKeyTimeCreate);
  const status = useAppSelector(selectStatus);

  const dispatch = useAppDispatch();
  const [ttop, setttop] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const onClickImage = (image: string) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  useEffect(() => {
    setImageLoaded(false);
    dispatch(
      fetchProductImage({ id: props.id, itemNumberUrl: props.itemNumberUrl }),
    );
  }, [props.id]);

  useEffect(() => {
    return () => {
      dispatch(clearPhotoState({}));
    };
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event: globalThis.KeyboardEvent): void => {
      if (event.key === "Escape") {
        setModalOpen(false);
        setSelectedImage("");
      }
    };
    window.addEventListener("keydown", handleEscapeKey);
    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);
  const onClickListItem = (time: any) => {
    if (time !== keyTimeCreate) {
      dispatch(setTimeCreatePhoto({ timeCreatePhoto: time }));
    } else {
      dispatch(setTimeCreatePhoto({ timeCreatePhoto: time }));
    }
  };
  const myFunc = (el: any) => {
    if (el.time_create !== keyTimeCreate) {
      setImageLoaded(false);
      onClickListItem(el.time_create);
    }
  };

  return (
    <div>
      <>
        {status === "image-loading" ? (
          <div
            className="card-h-90 card"
            style={{
              backgroundColor: "white",
              alignItems: "center",
              padding: "50px",
              width: "100%",
            }}
          >
            <Spinner color="info">Loading...</Spinner>
          </div>
        ) : (
          <>
            <div className="card-h-90 card">
              <div className="d-flex card-header justify-content-between">
                <div className="h4 card-title " style={{ cursor: "pointer" }}>
                  {t("photo")}{" "}
                  <Tooltip
                    placement="top"
                    isOpen={ttop}
                    target="photo"
                    toggle={() => {
                      setttop(!ttop);
                    }}
                  >
                    {t("photoTooltip")}
                  </Tooltip>
                  <i className="uil-question-circle" id="photo" style={{}}></i>
                </div>
              </div>
              {/*<ScrollWrapper>*/}
              <div
                data-simplebar="init"
                className="chat-message-list widget-chat-list"
              >
                <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                  <div className="simplebar-mask">
                    <div
                      className="simplebar-offset"
                      style={{ right: "0px", bottom: "0px", top: "0px" }}
                    >
                      <div
                        className="simplebar-content-wrapper"
                        role="region"
                        aria-label="scrollable content"
                        style={{
                          overflowX: "auto",
                          overflowY: "hidden",
                          top: "0px",
                        }}
                      >
                        <div className="card-body">
                          <div
                            style={{
                              display: "flex",
                              overflowX: "auto",
                              overflowY: "hidden",
                              whiteSpace: "nowrap",
                              marginBottom: "10px",
                            }}
                          >
                            {data.length > 1 &&
                              data.map((el: any, i: any) => {
                                const formattedDate = format(
                                  new Date(el.time_create),
                                  "dd.MM.yyyy",
                                );
                                return (
                                  <Button
                                    key={i}
                                    size={"sm"}
                                    style={{
                                      fontSize: "10px",
                                      width: "auto",
                                      maxWidth: "120px",
                                      margin: "3px",
                                    }}
                                    onClick={() => myFunc(el)}
                                    active={keyTimeCreate === el.time_create}
                                    color="info"
                                  >
                                    {formattedDate}
                                  </Button>
                                );
                              })}
                          </div>

                          <div>
                            {data.map((el: any, i: any) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                  key={i}
                                >
                                  {keyTimeCreate === el.time_create && (
                                    <div>
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "3%",
                                          marginTop: "5px ",
                                          marginLeft: "3px ",
                                          maxWidth: "150px",
                                          // height: "50%",
                                        }}
                                        onClick={() => onClickImage(el.image)}
                                        src={el.image}
                                        alt=""
                                        onLoad={() => setImageLoaded(true)}
                                      />
                                      {!imageLoaded && (
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <span
                                            className="placeholder"
                                            style={{ width: "25%" }}
                                          ></span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*</ScrollWrapper>*/}
            </div>
          </>
        )}
      </>
      {modalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          // onKeyDown={(e) => handlePressEsc(e)}
          onClick={() => setModalOpen(false)}
        >
          <img
            style={{
              borderRadius: "12px",
              maxHeight: "600px",
              objectFit: "contain",
            }}
            src={selectedImage}
            alt=""
          />
        </div>
      )}
    </div>
  );
};
