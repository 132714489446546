import React from "react";
import Chart from "react-apexcharts";
import { format } from "date-fns";

type PriceGraphProps = {
  data: any[];
  xAxisLabels: string[];
};

export const PriceGraph: React.FC<PriceGraphProps> = ({
  data,
  xAxisLabels,
}) => {
  const options = {
    series: [
      {
        name: "Price",
        data: data.map((el) => el.price),
      },
    ],
    chart: {
      id: "price-chart",
      type: "line",
      height: 230,
      dropShadow: {
        enabled: false,
        enabledOnSeries: [1],
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
      zoom: {
        type: "x",
        enabled: false,
      },
    },
    colors: ["#f8e402"],
    stroke: {
      width: [2],
      curve: ["straight"],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: [1],
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: xAxisLabels,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#f8e402",
        },
        formatter: function (value: any) {
          return Math.round(value);
        },
      },
    },
  };

  return (
    <Chart options={options} series={options.series} type="line" height={230} />
  );
};
