import {
  CaseReducer,
  PayloadAction,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { FieldErrorType } from "src/api/types";
import { rootReducer, store } from "src/slices";

export type RootReducerType = typeof rootReducer;

// определить автоматически тип всего объекта состояния
export type AppRootStateType = ReturnType<RootReducerType>;
export type AppDispatchType = typeof store.dispatch;
export type AppSelectorType = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<AppRootStateType> =
  useSelector;
export type ThunkError = {
  rejectValue: { errors: Array<string>; fieldsErrors?: Array<FieldErrorType> };
};
export type AppThunkType<ReturnType = void> = ThunkAction<
  ReturnType,
  AppRootStateType,
  unknown,
  Action<string>
>;
export type ReducerType<S, A = any> = CaseReducer<S, PayloadAction<A>>;
