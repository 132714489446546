// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import detector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";
// import translationENG from "./locales/eng/translation.json";
// import translationRS from "./locales/rs/translation.json";
//
// // the translations
// const resources : any = {
//   rs: {
//     translation: translationRS,
//   },
//
//   eng: {
//     translation: translationENG,
//   },
// };
//
// const language : any = localStorage.getItem("I18N_LANGUAGE");
// if (!language) {
//   localStorage.setItem("I18N_LANGUAGE", "translation");
// }
//
// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .use(LanguageDetector)
//   .use(detector)
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: localStorage.getItem("I18N_LANGUAGE") || "translation",
//     // fallbackLng: "translation", // use translation if detected lng is not available
//
//     keySeparator: false, // we do not use keys in form messages.welcome
//
//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//     supportedLngs: ['tr','translation','ru'],
//     fallbackLng: "tr",
//     detection: {
//       order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
//       caches: ['cookie']
//     },
//     backend: {
//       loadPath: '/src/locales/translation.json',
//     },
//   });
// i18n
//
//   // .use(HttpApi)
//   .init({
//
//
//
//   });
// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// import { DateTime } from 'luxon';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    debug: true,
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// i18n.services.formatter.add('DATE_LONG', (value, lng, _options) => {
//   return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
// });

export default i18n;
