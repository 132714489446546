import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setStatusTableSold,
  setTableSold,
} from "src/features/product/miniProductComponent/table/tableSold/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchTablesSoldProduct = createAsyncThunk<
  any,
  {
    id: string;
    itemNumberUrl: string;
    merchantId: string;
    dateOne: string;
    dateTwo: string;
  }
>(
  "tablesSoldProduct/fetchTablesSoldProduct",
  async ({ id, itemNumberUrl, merchantId, dateOne, dateTwo }, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableSold({
        status: "tableSold-loading",
      }),
    );
    try {
      const res = await productAPI.fetchTableSoldProduct(
        id,
        itemNumberUrl,
        merchantId,
        dateOne,
        dateTwo,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTableSold({ table: res.data.data }));

        thunkAPI.dispatch(setStatusTableSold({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableSold({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
