import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, Col, Row } from "reactstrap";
import { InputFormik } from "src/components/InputFormik/InputFormik";
import {
  selectAPIKey,
  selectAPISecret,
  selectSatıcıID,
  selectToken,
} from "src/features/App/selectors";
import { Header } from "src/pages/Contacts/Header/Header";
import { integrationDataTC } from "src/pages/Contacts/Integration-Data/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const IntegrationData = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const api = useAppSelector(selectAPIKey);
  const secret = useAppSelector(selectAPISecret);
  const supplierId = useAppSelector(selectSatıcıID);
  const token = useAppSelector(selectToken);

  const formik = useFormik({
    // validationSchema: integrationYup,
    initialValues: {
      api: api,
      secret: secret,
      supplierId: supplierId,
      token: token,
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        await dispatch(integrationDataTC(values));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="card border shadow-none mb-5">
      <Header number={2} title={t("Integration")} text={t("")} />
      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <InputFormik
                formik={formik}
                fieldName={"api"}
                type={"password"}
              />
            </Col>
            <Col lg={6}>
              <InputFormik formik={formik} fieldName={"supplierId"} type={""} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <InputFormik
                formik={formik}
                fieldName={"secret"}
                type={"password"}
              />
            </Col>
            <Col lg={6}>
              <InputFormik
                formik={formik}
                fieldName={"token"}
                type={"password"}
              />
            </Col>
          </Row>
          <div className="mt-3">
            <button
              disabled={!formik.isValid || formik.isSubmitting}
              className="btn btn-info w-40"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </CardBody>
    </div>
  );
};
