import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Tooltip } from "reactstrap";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { clearDescriptionEdit } from "src/features/product/miniProductComponent/descriptionEdit/reducer";
import {
  selectDescriptionEditData,
  selectKeyTimeCreate,
  selectStatusDescriptionEdit,
} from "src/features/product/miniProductComponent/descriptionEdit/selectors";
import { fetchProductDescriptionEdit } from "src/features/product/miniProductComponent/descriptionEdit/thunk";
import { setTimeCreate } from "src/features/product/miniProductComponent/photo/reducer";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

import "./DescriptionEdit.module.css";
import { format } from "date-fns";

type PropsType = {
  id: string;
};

export const DescriptionEdit = (props: PropsType) => {
  const { t } = useTranslation();
  const [top, setTop] = useState(false);
  const sortRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const descriptionEditData = useAppSelector(selectDescriptionEditData);
  const status = useAppSelector(selectStatusDescriptionEdit);
  const keyTimeCreate = useAppSelector(selectKeyTimeCreate);

  useEffect(() => {
    if (keyTimeCreate !== null) {
      setOpen(true);
    }
  }, [keyTimeCreate]);

  const onClickListItem = (time: any) => {
    if (time !== keyTimeCreate) {
      dispatch(setTimeCreate({ timeCreate: time }));
      setOpen(true);
    } else {
      dispatch(setTimeCreate({ timeCreate: time }));
      setOpen(!open);
    }
  };

  useEffect(() => {
    dispatch(
      fetchProductDescriptionEdit({
        id: props.id,
      }),
    );
  }, [props.id]);

  useEffect(() => {
    return () => {
      dispatch(clearDescriptionEdit({}));
    };
  }, []);

  return (
    <div ref={sortRef}>
      <Card>
        <div className="d-flex card-header justify-content-between">
          <div className="h4 card-title">
            {t("historyDescriptionProduct")}{" "}
            <Tooltip
              placement="top"
              isOpen={top}
              target="historyDescriptionProduct"
              toggle={() => {
                setTop(!top);
              }}
            >
              {t("editDescriptionTooltip")}
            </Tooltip>
            <i
              style={{ cursor: "pointer" }}
              id={"historyDescriptionProduct"}
              className="uil-question-circle"
            ></i>
          </div>
        </div>
        {/*<h4 className="card-title mb-3"></h4>*/}

        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "14px",
            }}
          >
            {status === "descriptionEdit-loading" ? (
              <div
                style={{
                  background: "white",
                  width: "300px",
                  height: "300px",
                }}
              >
                <CustomSpinner absolute={true} top={"200px"} />
              </div>
            ) : (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {descriptionEditData.map((el: any) => (
                  <div
                    key={el.time_create}
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  >
                    <Button
                      size={"sm"}
                      style={{
                        fontSize: "10px",
                        width: "100%",
                        maxWidth: "120px",
                      }}
                      onClick={() => onClickListItem(el.time_create)}
                      active={keyTimeCreate === el.time_create && open}
                      color="info"
                    >
                      {format(new Date(el.time_create), "dd.MM.yyyy")}
                    </Button>
                  </div>
                ))}
              </div>
            )}

            {descriptionEditData.map((el: any) => (
              <div
                key={el.time_create}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {keyTimeCreate === el.time_create && (
                  <div>
                    {el.description.contentDescriptions.map((desc: any) => (
                      <p key={desc.description}>
                        {desc.bold ? (
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {desc.description}
                          </span>
                        ) : (
                          desc.description
                        )}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
