import { AppRootStateType } from "src/store/types";

export const selectDataListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.table;
export const selectFilteredDataListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.filteredTable;
export const selectOrderNameListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.orderName;
export const selectAscDescListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.ascDesc;
export const selectPageListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.page;
export const selectTotalCountPageListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.totalCountPage;
export const selectStatusListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.status;
export const selectColumnNameListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.columnName;
export const selectShowVideoListSellersTable = (state: AppRootStateType) =>
  state.listSellersTable.showVideo;
export const selectRestart = (state: AppRootStateType) =>
  state.listSellersTable.restart;
