import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import { ClearButton } from "src/components/InputClearButton/InputClearButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetData from "src/components/Widgets/Widget";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import {
  fetchDataCompetitorsSold,
  fetchDataRating,
  fetchDataRevenue,
  fetchItemsInTheCategoryTop,
} from "src/features/MerchantTable/thunk";
import {
  selectStatusCompetitors,
  selectStatusDataRevenue,
} from "src/features/MerchantTable/selectors";
import {
  selectChoiceCompetitors,
  selectChoiceProductInTheTops,
  selectDataCompetitors,
  selectDataProductInTheTops,
  selectDataRating,
  selectDataRevenue,
  selectStatusDataRating,
  selectStatusProductInTheTops,
} from "src/features/MerchantTable/selectors";
import {
  fetchDataCategory,
  fetchDataSearch,
} from "src/features/MerchantTable/thunk";
import s from "src/features/CategoryTable/Category.module.css";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";

import {
  selectCategoryId,
  selectNameCategory,
  selectNameUrl,
} from "src/features/CategoryTable/selectors";
import { InputsType } from "src/features/CategoryTable/type";
import TableDescription from "src/features/MerchantTable/table-description";
import {
  clickAscDescMerchantTable,
  clickOrderNameMerchantTable,
  deleteSubscribeMerchantTable,
  onChangePhraseMerchantTable,
  onClickPageMerchantTable,
  resetMerchantTable,
  setStatusMerchantTable,
  setSubscribeMerchantTable,
} from "src/features/MerchantTable/reducer";

import {
  selectAscDescMerchantTable,
  selectColumnNameMerchantTable,
  selectDataMerchantTable,
  selectOrderNameMerchantTable,
  selectPageMerchantTable,
  selectStatusMerchantTable,
  selectSubscribeMerchantTable,
  selectTotalCountPageMerchantTable,
} from "src/features/MerchantTable/selectors";
import {
  fetchExcelMerchantTable,
  fetchMerchantTable,
} from "src/features/MerchantTable/thunk";
import {
  fetchFollow,
  fetchUnFollow,
} from "src/features/product/miniProductComponent/subscribeButton/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const MerchantTable = () => {
  const { price_from, price_to, name } = useSearchParams();
  const [tooltipName, setTooltipName] = useState<any>(null);

  const { category_url } = useParams();
  const { sellerId } = useParams();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [loadStar, setLoadStar] = useState(false);
  const [inputs, setInputs] = useState<InputsType[]>([
    {
      id: 0,
      label: "Price, from",
      name: "price_from",
      value: price_from ?? "",
    },
    {
      id: 1,
      label: "Price, up to",
      name: "price_to",
      value: price_to ?? "",
    },
    { id: 2, label: "Name", name: "name", value: name ?? "" },
  ]);
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [image, setImage] = useState<string>("");
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const [tooltipData, setTooltipData] = useState<any>(null);

  const data = useAppSelector(selectDataMerchantTable);
  const columnName = useAppSelector(selectColumnNameMerchantTable);
  const orderName = useAppSelector(selectOrderNameMerchantTable);
  const ascDesc = useAppSelector(selectAscDescMerchantTable);
  const page = useAppSelector(selectPageMerchantTable);
  const totalCountPage = useAppSelector(selectTotalCountPageMerchantTable);
  const statusMerchantTable = useAppSelector(selectStatusMerchantTable);
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const subscribe = useAppSelector(selectSubscribeMerchantTable);
  const nameUrl = useAppSelector(selectNameUrl);
  const userStatus = useAppSelector(selectUserStatus);

  document.title = `Restatos | ${
    categoryId === sellerId && currentLanguage === "tr"
      ? t("SellerAnalyticalReportOne") +
        nameCategory +
        t("SellerAnalyticalReportTwo")
      : categoryId === sellerId && currentLanguage !== "tr"
      ? t("SellerAnalyticalReport") + " " + nameCategory
      : ""
  }`;

  async function fetchData() {
    const resName = await instance.get<
      FetchResponseTable<Array<{ message: string; path: string }>>
    >(`trendyol/seller/name/${sellerId}`);
    dispatch(setCategoryId({ categoryId: sellerId }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== sellerId) {
    fetchData();
  }

  const dateSelect = useAppSelector(selectDateSelect);

  const param: any = {
    sellerId,
    columnName: inputs,
    orderName,
    ascDesc,
    page,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };

  useEffect(() => {
    // Комментирую не факт что прийдеться удалять урл и так всё вроде работает
    //всё равно не работало
    // if (price_from || price_to) {
    //   const newUrl = window.location.origin + window.location.pathname;
    //   window.history.pushState({}, document.title, newUrl);
    // }

    return () => {
      dispatch(resetMerchantTable({}));
      dispatch(clearDate({}));
      setImage("");
    };
  }, []);
  useEffect(() => {
    dispatch(fetchMerchantTable(param));
    //
    dispatch(fetchDataCompetitorsSold(param));
    dispatch(fetchDataRating(param));
    dispatch(fetchDataRevenue(param));
    // dispatch(fetchDataCompetitorsSold(param));
  }, [
    category_url,
    columnName,
    ascDesc,
    orderName,
    page,
    dateSelect[0],
    dateSelect[1],
  ]);

  useEffect(() => {
    dispatch(onClickPageMerchantTable({ page: 1 }));
  }, [ascDesc, orderName, columnName]);

  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    const filteredValue =
      inputs[index].name === "price_from" || inputs[index].name === "price_to"
        ? value.replace(/\D/g, "")
        : value;

    if (value === "") {
      dispatch(
        setStatusMerchantTable({
          status: "merchantTable-loading",
        }),
      );
      dispatch(onChangePhraseMerchantTable({ columnName: [] }));
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? filteredValue : input.value,
    }));
    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);

    const params = new URLSearchParams();
    updatedInputs.forEach((input) => {
      if (input.value !== "") {
        params.append(input.name, input.value);
      }
    });

    let url = window.location.pathname;
    if (params.toString() !== "") {
      url += `?${params.toString()}`;
    }
    window.history.pushState({}, "", url);

    const id = +setTimeout(() => {
      dispatch(onChangePhraseMerchantTable({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhraseMerchantTable({ columnName: inputs }));
    }
  };

  const mouseClick = useCallback(() => {
    setMouse(false);
  }, []);

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPageMerchantTable({ page: page }));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPageMerchantTable({ page: newPage }));
    setCount(newCount);
  };
  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderNameMerchantTable({ orderName: column.selector }));
    dispatch(clickAscDescMerchantTable({ ascDesc: sortDirection }));
  }, []);
  const follow = async (id: number) => {
    setLoadStar(true);
    try {
      const res = await dispatch(fetchFollow(id));
      if (res.payload.data.success) {
        setLoadStar(false);
        dispatch(setSubscribeMerchantTable({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };
  const unFollow = async (id: number) => {
    setLoadStar(true);

    try {
      const res = await dispatch(fetchUnFollow(id));
      if (res.payload.data.success) {
        setLoadStar(false);
        dispatch(deleteSubscribeMerchantTable({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };

  const columns = [
    {
      // name: t("followed"),
      selector: "followed",
      width: "20px",

      cell: (row: any) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={
            subscribe[row.id] ? () => unFollow(row.id) : () => follow(row.id)
          }
        >
          {subscribe[row.id] ? (
            <i className="mdi mdi-star"></i>
          ) : (
            <i className="mdi mdi-star-outline"></i>
          )}
        </div>
      ),
    },
    {
      // selector: (row) => row.image,
      // name: t("image"),
      selector: "image",
      width: "55px",

      cell: (row: any) => (
        <div>
          {" "}
          <a
            target="_blank"
            href={`https://www.trendyol.com/p/p-p-${row.id}`}
            rel="noreferrer"
          >
            <img
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.image, setTooltipData);
              }}
              onMouseOut={() => setTooltipData(null)}
              style={{ borderRadius: "15%", cursor: "pointer" }}
              // width={"35px"}
              height={"35px"}
              src={row.image}
              data-tip="Image"
            />
          </a>
          {/*<img*/}
          {/*  width={"30px"}*/}
          {/*  height={"30px"}*/}
          {/*  style={{*/}
          {/*    marginTop: "5px",*/}
          {/*    // marginRight: "30px",*/}
          {/*    // transform: "rotate(-50deg)",*/}
          {/*  }}*/}
          {/*  src="/favicon.ico"*/}
          {/*  alt=""*/}
          {/*/>*/}
        </div>
      ),
    },
    {
      // name: t("name"),
      name: <div>{t("name")}</div>,

      selector: "name",
      sortable: true,
      title: "Image Column Tooltip",
      cell: (row: { id: number; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/product/${row.id}`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("brand"),
      // selector: (row) => row.brand,
      selector: "brand",
      sortable: true,
      cell: (row: { brand: string; id: number; brand_id: number }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.brand, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/brand/${row.brand_id}/products`}
          >
            {row.brand.length > 22
              ? row.brand.substr(0, 22) + "..."
              : row.brand}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("barcode"),
      selector: "barcode",
    },
    {
      name: t("bestPositionInCat"),
      selector: "bestPositionInCat",
      format: (row: { bestPositionInCat: any }) =>
        row.bestPositionInCat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("revenue"),
      selector: "revenue",
      sortable: true,
      id: "revenue",
      format: (row: { revenue: number }) =>
        (row.revenue / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("sold"),
      selector: "sold",
      sortable: true,
      format: (row: { sold: any }) =>
        row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("stock"),
      selector: "stock",
      sortable: true,
      format: (row: { stock: any }) =>
        row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("price"),
      selector: "price",
      format: (row: { price: any }) =>
        (row.price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("minPrice"),
      selector: "minPrice",
      format: (row: { minPrice: any }) =>
        (row.minPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " " +
        " TL",
    },
    {
      name: t("maxPrice"),
      selector: "maxPrice",
      format: (row: { maxPrice: any }) =>
        (row.maxPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("merchantCount"),
      selector: "merchantCount",
      format: (row: { merchantCount: any }) =>
        row.merchantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("merchantsWithSold"),
      selector: "merchantsWithSold",
      format: (row: { merchantsWithSold: number }) =>
        row.merchantsWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      // sortable: true,
    },
    {
      name: t("shareMerchants"),
      // selector: (row) => row.catCount,
      selector: "shareMerchants",
      format: (row: { shareMerchants: number }) =>
        row.shareMerchants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        "%",
      // sortable: true,
    },
    {
      name: t("favourites"),
      // selector: (row) => row.favorites,
      selector: "favorites",
      sortable: true,
      format: (row: { favorites: any }) =>
        row.favorites.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },

    {
      name: t("avgStock"),
      selector: "avgStock",
      format: (row: { avgStock: any }) =>
        row.avgStock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("Average stocks per 1 seller"),
      selector: "avgStockPerMerchant",
      format: (row: { avgStockPerMerchant: any }) =>
        row.avgStockPerMerchant
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("Average stocks per 1 seller with sold"),
      selector: "avgStockPerMerchantWithSold",
      format: (row: { avgStockPerMerchantWithSold: any }) =>
        row.avgStockPerMerchantWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("tax"),
      selector: "tax",
      format: (row: { tax: any }) =>
        row.tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("minRating"),
      selector: "minRating",
      format: (row: { minRating: any }) =>
        row.minRating
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("maxRating"),
      selector: "maxRating",
      format: (row: { maxRating: any }) =>
        row.maxRating
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("minRatingCount"),
      selector: "minRatingCount",
      format: (row: { minRatingCount: any }) =>
        row.minRatingCount
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("maxRatingCount"),
      selector: "maxRatingCount",
      format: (row: { maxRatingCount: any }) =>
        row.maxRatingCount
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("Rating increment"),
      selector: "ratingIncrement",
      format: (row: { ratingIncrement: any }) =>
        row.ratingIncrement
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("Rating increment, pcs"),
      selector: "ratingIncrementCount",
      cell: (row: { ratingIncrementCount: any }) => (
        <div
          style={{
            marginRight: "-90px",
            marginLeft: "10px",
          }}
        >
          {row.ratingIncrementCount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </div>
      ),
    },
    {
      name: t("Count of comments at the beginning of the period"),
      selector: "minComments",
      cell: (row: { minComments: any }) => (
        <div
          style={{
            marginRight: "-90px",
            marginLeft: "10px",
          }}
        >
          {row.minComments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </div>
      ),
    },
    {
      name: t("Count of comments at the end of the period"),
      selector: "maxComments",
      cell: (row: { maxComments: any }) => (
        <div
          style={{
            marginRight: "-90px",
            marginLeft: "10px",
          }}
        >
          {row.maxComments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </div>
      ),
    },
    {
      name: t("Comment increment"),
      selector: "incrementComments",
      cell: (row: { incrementComments: any }) => (
        <div
          style={{
            marginRight: "-90px",
            marginLeft: "10px",
          }}
        >
          {row.incrementComments
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%"}
        </div>
      ),
    },
    {
      name: t("Comment increment, pcs"),
      selector: "incrementCommentsCount",
      cell: (row: { incrementCommentsCount: any }) => (
        <div
          style={{
            marginRight: "-90px",
            marginLeft: "10px",
          }}
        >
          {row.incrementCommentsCount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </div>
      ),
    },
    {
      name: t("catCount"),
      // selector: (row) => row.catCount,
      selector: "catCount",
      // sortable: true,
    },
    {
      name: <div>{t("chart")}</div>,
      cell: (row: { chart: Array<number> }) => (
        <div style={{ marginLeft: "-61px" }}>
          {" "}
          <D3BarChart data={row.chart} />
        </div>
      ),
    },
  ] as any;

  const [modal_standard, setmodal_standard] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  // const conditionalRowStylesOpasity: any = useMemo(
  //   () => [
  //     {
  //       when: (row: any, index: any) => {
  //         return row.index >= 0;
  //       },
  //       style: {
  //         opacity:
  //           statusMerchantTable === "merchantTable-loading" || loadStar
  //             ? 0.1
  //             : "",
  //         pointerEvents:
  //           statusMerchantTable === "merchantTable-loading" || loadStar
  //             ? "none"
  //             : "",
  //       },
  //     },
  //   ],
  //   [],
  // );

  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />

      {mouse && <img className={s.bigImage} src={image} alt="" />}
      {statusMerchantTable === "merchantTable-loading" &&
        (<CustomSpinner absolute={true} top={"600px"} /> ||
          (loadStar && <CustomSpinner absolute={true} top={"600px"} />))}
      <div
      // className={
      //   statusMerchantTable === "merchantTable-loading" || loadStar
      //     ? s.opasity
      //     : ""
      // }
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/seller-list">Sellers</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === sellerId && currentLanguage === "tr" ? (
                      <>
                        {t("SellerAnalyticalReportOne")}
                        {nameCategory} {t("SellerAnalyticalReportTwo")}
                      </>
                    ) : null}
                    {categoryId === sellerId && currentLanguage !== "tr" ? (
                      <>
                        {t("SellerAnalyticalReport")}&nbsp;{nameCategory}
                      </>
                    ) : null}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
          }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === sellerId && currentLanguage === "tr" ? (
                <>
                  {t("SellerAnalyticalReportOne")}
                  {nameCategory} {t("SellerAnalyticalReportTwo")}
                </>
              ) : null}
              {categoryId === sellerId && currentLanguage !== "tr" ? (
                <>
                  {t("SellerAnalyticalReport")}&nbsp;{nameCategory}
                </>
              ) : null}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelMerchantTable(param))}
              />
            </span>
            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <WidgetData
          id={sellerId}
          //
          dataRevenue={selectDataRevenue}
          selectDataRevenue={selectDataRevenue}
          selectStatusDataRevenue={selectStatusDataRevenue}
          //
          dataRating={selectDataRating}
          selectStatusDataRating={selectStatusDataRating}
          //
          dataProductInTheTops={selectDataProductInTheTops}
          selectChoiceProductInTheTops={selectChoiceProductInTheTops}
          fetchDataSearch={fetchDataSearch}
          fetchDataCategory={fetchDataCategory}
          selectStatusProductInTheTops={selectStatusProductInTheTops}
          //
          select={false}
          dataCompetitors={selectDataCompetitors}
          selectChoiceCompetitors={selectChoiceCompetitors}
          selectStatusCompetitors={selectStatusCompetitors}
          fetchDataCompetitorsSold={fetchDataCompetitorsSold}
          fetchItemsInTheCategoryTop={fetchItemsInTheCategoryTop}
        />

        <div
          className={
            statusMerchantTable === "merchantTable-loading" || loadStar
              ? s.opasity
              : ""
          }
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <a
              href={`/trendyol/seller/${sellerId}/products`}
              // to={"#"}
              // type="button"
              className="btn-sm btn btn-primary"
              style={{ marginTop: "10px" }}
            >
              {t("products")}
            </a>
            <NavLink
              to={`/trendyol/seller/categories/${sellerId}`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("category")}
            </NavLink>
            <NavLink
              to={`/trendyol/seller/brands/${sellerId}`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("brands")}
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <i
                onClick={() => setmodal_standard(true)}
                className="uil-question-circle "
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
              {inputs.map((input, index) => (
                // TODO
                <CustomInput
                  id={input.id}
                  disabled={statusMerchantTable === "merchantTable-loading"}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  onKeyPress={onKeyPress}
                />
              ))}
              <ClearButton
                inputs={inputs}
                setInputs={setInputs}
                setIsCleared={() =>
                  dispatch(onChangePhraseMerchantTable({ columnName: inputs }))
                }
              />
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav stickyCategory ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"revenue"}
            title={t("")}
            columns={columns}
            data={data}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            sortServer={true}
            // sortIcon={null}
            responsive={true}
            // onChangePage={handlePageChange}
            onSort={handleSort}
            // noDataComponent={<div>{t("dataTable")}</div>}
            defaultSortAsc={false}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          {tooltipData && (
            <img
              style={{
                zIndex: 100,
                position: "absolute",
                top: tooltipData.y - 27,
                left: tooltipData.x + 30,
                background: "white",
                padding: "4px",
                height: "250px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              src={tooltipData.value}
              alt=""
            />
          )}{" "}
          <TooltipInTable tooltipName={tooltipName} />
          <SuperPagination
            page={page}
            itemsCountForPage={count}
            totalCount={totalCountPage}
            onChange={onChangePagination}
          />
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
