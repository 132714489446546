import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Dropdown, Offcanvas } from "reactstrap";
import { useDynamicStore } from "src/components/VerticalLayout/hooks/useDynamicStore";
import YouTubeIcon from "src/components/VerticalLayout/YouTubeIcon";
import logoDark from "../../assets/images/restatos-full.svg";
import logoLight from "../../assets/images/logo-light.png";
import logoSm from "../../assets/images/logo-sm.png";
import { RightSidebar } from "../CommonForBoth/RightSidebar";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

const Header = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { selectFunc, actionFunc } = useDynamicStore(currentUrl);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [position, setPosition] = useState<string>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [megaMenu, setmegaMenu] = useState(false);

  const toggleTopDrawer = () => {
    setPosition("right");
    setOpenDrawer(!openDrawer);
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  function tToggle() {
    var body = document.body;
    body.classList.toggle("sidebar-enable");
    // if (window.screen.width <= 998) {
    //   body.classList.toggle("sidebar-enable");
    // }
    document.body.setAttribute("data-sidebar-size", "lg");
  }
  const isMobile = window.innerWidth > 581;

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/trendyol/category-list" className="logo logo-dark">
                {/*<span className="logo-sm">*/}
                {/*  <img src={logoSm} alt="" height="22" />*/}
                {/*</span>*/}
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="36" />
                </span>
              </Link>
              <Link to="/sales" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            <Dropdown
              className="d-none d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            ></Dropdown>
          </div>
          <div className="d-flex">
            {isMobile && (
              <div style={{ marginTop: "22.5px", marginRight: "1.5px" }}>
                <NavLink
                  target={"_blank"}
                  className={"btn-primary btn-sm btn"}
                  to={
                    "https://chromewebstore.google.com/detail/restatos-trendyol-analiz/dmkoohpcbkdibfinebjiopmnnmgpffli"
                  }
                >
                  {t("Use the Google extension.")}
                </NavLink>
              </div>
            )}
            {selectFunc && actionFunc && (
              <YouTubeIcon select={selectFunc} actionStore={actionFunc} />
            )}

            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
      <Offcanvas isOpen={openDrawer} onClosed={onDrawerClose} direction={"end"}>
        <RightSidebar onClosed={onDrawerClose} />
      </Offcanvas>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
