import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAdvertAPI } from "src/api/api_productAdvert/api_productAdvert";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setProductAdvert,
  setStatusProductAdvert,
} from "src/features/product/miniProductComponent/advertisingCompany/reducer";
import { FetchGraphProductArgs } from "src/features/product/miniProductComponent/graphWrapper/type";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchProductAdvert = createAsyncThunk<any, FetchGraphProductArgs>(
  "productAdvert/fetchProductAdvert",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductAdvert({ status: "productAdvert-loading" }),
    );
    try {
      const res = await productAdvertAPI.fetchProductAdvert(
        param.id,
        param.itemNumberUrl,
        param.merchantId,
        param.resultDate1,
        param.resultDate2,
      );

      if (res.data.success === true) {
        thunkAPI.dispatch(setProductAdvert({ data: res.data.data }));
        thunkAPI.dispatch(setStatusProductAdvert({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusProductAdvert({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
