// import React, { useEffect } from "react";
// import Chart from "react-apexcharts";
// import { useTranslation } from "react-i18next";
// import { Card, CardBody, Spinner } from "reactstrap";
// import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
// import { selectUserStatus } from "src/features/App/selectors";
// import { clearGraphStock } from "src/features/product/miniProductComponent/graphWrapper/reducer";
// import {
//   selectGraphSelectDate1,
//   selectGraphStockSales,
//   selectStatusGraphProduct,
// } from "src/features/product/miniProductComponent/graphWrapper/selectors";
// import { fetchGraphProduct } from "src/features/product/miniProductComponent/graphWrapper/thunk";
// import { useAppSelector } from "src/store/types";
// import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// import "./GraphStock.module.css";
// import { format } from "date-fns";
// import graph from "src/assets/images/graph.png";
// type TypeProps = {
//   data: any;
//   id: string;
//   itemNumberUrl: string;
//   merchantId: string;
// };
//
// export function GraphWrapper(props: TypeProps) {
//   const { t } = useTranslation();
//   const dispatch = useAppDispatch();
//   const status = useAppSelector(selectStatusGraphProduct);
//   const graphStockSales = useAppSelector(selectGraphStockSales);
//   const graphSelectDate1 = useAppSelector(selectGraphSelectDate1);
//   const userStatus = useAppSelector(selectUserStatus);
//
//   const resultDate1 = graphSelectDate1[0]
//     ? "?date_from=" + graphSelectDate1[0]
//     : "";
//   const resultDate2 = graphSelectDate1[1]
//     ? "&date_to=" + graphSelectDate1[1]
//     : "";
//
//   useEffect(() => {
//     dispatch(
//       fetchGraphProduct({
//         id: props.id,
//         itemNumberUrl: props.itemNumberUrl,
//         merchantId: props.merchantId,
//         resultDate1,
//         resultDate2,
//       }),
//     );
//   }, [
//     props.id,
//     props.itemNumberUrl,
//     props.merchantId,
//     resultDate1,
//     resultDate2,
//   ]);
//
//   useEffect(() => {
//     dispatch(clearGraphStock({}));
//   }, []);
//
//   const product = [
//     // {
//     //   name: t("stock"),
//     //   data: graphStockSales.map((el: any) => el.stock),
//     // },
//     {
//       name: t("sales"),
//       data: graphStockSales.map((el: any) => el.sold),
//     },
//     {
//       name: t("comments"),
//       data: graphStockSales.map((el: any) => el.comments),
//     },
//     //
//     {
//       name: t("price"),
//       data: graphStockSales.map((el: any) => el.price),
//     },
//     // {
//     //   name: t("favorites"),
//     //   data: graphStockSales.map((el: any) => el.favorites),
//     // },
//     // {
//     //   name: t("rating"),
//     //   data: graphStockSales.map((el: any) => el.rating_count),
//     // },
//   ];
//   const options = {
//     series: product,
//     chart: {
//       id: "chart2",
//       type: "line",
//       height: 230,
//       dropShadow: {
//         enabled: false,
//         enabledOnSeries: [1],
//       },
//       toolbar: {
//         autoSelected: "pan",
//         show: false,
//       },
//       zoom: {
//         type: "x",
//         enabled: false,
//         // autoScaleYaxis: true,
//       },
//     },
//     colors: ["#00E396", "#ff00ff", "#f8e402"],
//     stroke: {
//       width: [2, 2, 2],
//       curve: ["smooth", "smooth", "smooth"],
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     fill: {
//       opacity: [1, 0.75, 0.75],
//     },
//     markers: {
//       size: 0,
//     },
//     yaxis: [
//       // {
//       //   seriesName: t("stock"),
//       //   axisTicks: {
//       //     show: true,
//       //     color: "#008FFB",
//       //   },
//       //   axisBorder: {
//       //     show: true,
//       //     color: "#008FFB",
//       //   },
//       //   labels: {
//       //     style: {
//       //       colors: "#008FFB",
//       //     },
//       //     formatter: function (value: any) {
//       //       return Math.round(value);
//       //     },
//       //   },
//       //   title: {
//       //     text: t("stock"),
//       //     style: {
//       //       color: "#008FFB",
//       //     },
//       //   },
//       // },
//       {
//         seriesName: t("sales"),
//         // opposite: true,
//         axisTicks: {
//           show: true,
//           color: "#00E396",
//         },
//         axisBorder: {
//           show: true,
//           color: "#00E396",
//         },
//         labels: {
//           style: {
//             colors: "#00E396",
//           },
//           formatter: function (value: any) {
//             return Math.round(value);
//           },
//         },
//         title: {
//           text: t("sales"),
//           style: {
//             color: "#00E396",
//           },
//         },
//       },
//       {
//         seriesName: t("comments"),
//         opposite: true,
//         axisTicks: {
//           show: true,
//           color: "#a5a4a4",
//         },
//         axisBorder: {
//           show: true,
//           color: "#a5a4a4",
//         },
//         labels: {
//           style: {
//             colors: "#a5a4a4",
//           },
//           formatter: function (value: any) {
//             return Math.round(value);
//           },
//         },
//         title: {
//           text: t("comments"),
//           style: {
//             color: "#a5a4a4",
//           },
//         },
//       },
//       //
//       //
//       //
//       // Номер 4
//       {
//         seriesName: t("price"),
//         opposite: true,
//         axisTicks: {
//           show: true,
//           color: "#f8e402",
//         },
//         axisBorder: {
//           show: true,
//           color: "#f8e402",
//         },
//         labels: {
//           style: {
//             colors: "#f8e402",
//           },
//           formatter: function (value: any) {
//             return Math.round(value);
//           },
//         },
//         title: {
//           text: t("price"),
//           style: {
//             color: "#f8e402",
//           },
//         },
//       },
//       // НОМЕР  6
//       /*{
//         seriesName: t("favorites"),
//         opposite: true,
//         axisTicks: {
//           show: true,
//           color: "#0ee3d2",
//         },
//         axisBorder: {
//           show: true,
//           color: "#0ee3d2",
//         },
//         labels: {
//           style: {
//             colors: "#0ee3d2",
//           },
//           formatter: function (value: any) {
//             return Math.round(value);
//           },
//         },
//         title: {
//           text: t("favorites"),
//           style: {
//             color: "#0ee3d2",
//           },
//         },
//       },
//       // Номер 7
//       {
//         seriesName: t("rating"),
//         opposite: true,
//         axisTicks: {
//           show: true,
//           color: "#cf19e3",
//         },
//         axisBorder: {
//           show: true,
//           color: "#cf19e3",
//         },
//         labels: {
//           style: {
//             colors: "#cf19e3",
//           },
//           formatter: function (value: any) {
//             return Math.round(value);
//           },
//         },
//         title: {
//           text: t("rating"),
//           style: {
//             color: "#cf19e3",
//           },
//         },
//       },*/
//     ],
//     xaxis: {
//       // title: { text: t("stockAndSales") },
//       categories: graphStockSales.map((el: any) =>
//         format(new Date(el.date), "dd.MM.yyyy"),
//       ),
//     },
//   };
//
//   const optionsLine = {
//     series: product,
//     chart: {
//       id: "chart1",
//       height: 130,
//       type: "area",
//       // brush: {
//       //   target: "chart2",
//       //   enabled: true,
//       // },
//       selection: {
//         enabled: true,
//         xaxis: {
//           min: new Date("24 April 2017").getTime(),
//           max: new Date("29 May 2017").getTime(),
//         },
//       },
//     },
//     colors: ["#008FFB", "#00E396", "#a5a4a4"],
//     stroke: {
//       width: [1, 2, 3],
//       curve: ["straight", "monotoneCubic"],
//     },
//     fill: {
//       type: "gradient",
//       gradient: {
//         opacityFrom: 0.91,
//         opacityTo: 0.1,
//       },
//     },
//     // xaxis: {
//     //   type: "datetime",
//     //   tooltip: {
//     //     enabled: false,
//     //   },
//     // },
//     yaxis: {
//       max: 100,
//       tickAmount: 2,
//     },
//   };
//
//   const option = {
//     xaxis: {
//       title: { text: t("stockAndSales") },
//       categories: graphStockSales.map((el: any) =>
//         format(new Date(el.date), "dd.MM.yyyy"),
//       ),
//     },
//     yaxis: {
//       labels: {
//         formatter: function (value: number) {
//           return value;
//         },
//       },
//     },
//     chart: {
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         type: "x",
//         enabled: false,
//         // autoScaleYaxis: true,
//       },
//     },
//   };
//
//   return (
//     <React.Fragment>
//       <Card className="card-h-100">
//         <div className="container-fluid mt-3 mb-3">
//           <div className="card-title card-header">
//             <h4 className=" mb-2">{t("graphSoldAndSales")}</h4>
//             <p
//               style={{
//                 color: " #9e9e9e",
//                 lineHeight: "22px",
//                 fontWeight: "300",
//               }}
//             >
//               {t("graphsConditional")}
//             </p>
//           </div>
//
//           {userStatus === "trial" || userStatus === "unpaid" ? (
//             <div style={{ height: "500px" }}>
//               <img
//                 src={graph}
//                 alt="Graph"
//                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
//               />
//               <LimitedAccessComponent />
//             </div>
//           ) : status === "graph-loading" ? (
//             <div style={{ textAlign: "center", padding: "200px" }}>
//               <Spinner color="info">Loading...</Spinner>
//             </div>
//           ) : (
//             <CardBody>
//               <Chart
//                 options={options}
//                 series={options.series}
//                 type="area"
//                 height={550}
//               />
//             </CardBody>
//           )}
//         </div>
//       </Card>
//     </React.Fragment>
//   );
// }

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Spinner } from "reactstrap";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { selectUserStatus } from "src/features/App/selectors";
import { CommentsGraph } from "src/features/product/miniProductComponent/graphWrapper/graphs/Graph-Comments/Graph-Comments";
import { PriceGraph } from "src/features/product/miniProductComponent/graphWrapper/graphs/Graph-Price/Graph-Price";
import { SalesGraph } from "src/features/product/miniProductComponent/graphWrapper/graphs/Graph-Sales/Graphs-Sales";
import { clearGraphStock } from "src/features/product/miniProductComponent/graphWrapper/reducer";
import {
  selectGraphSelectDate1,
  selectGraphStockSales,
  selectStatusGraphProduct,
} from "src/features/product/miniProductComponent/graphWrapper/selectors";
import { fetchGraphProduct } from "src/features/product/miniProductComponent/graphWrapper/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import "./GraphStock.module.css";
import { format } from "date-fns";
import graph from "src/assets/images/graph.png";

type TypeProps = {
  data: any;
  id: string;
  itemNumberUrl: string;
  merchantId: string;
};

export function GraphWrapper(props: TypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatusGraphProduct);
  const graphStockSales = useAppSelector(selectGraphStockSales);
  const graphSelectDate1 = useAppSelector(selectGraphSelectDate1);
  const userStatus = useAppSelector(selectUserStatus);

  const resultDate1 = graphSelectDate1[0]
    ? "?date_from=" + graphSelectDate1[0]
    : "";
  const resultDate2 = graphSelectDate1[1]
    ? "&date_to=" + graphSelectDate1[1]
    : "";

  useEffect(() => {
    dispatch(
      fetchGraphProduct({
        id: props.id,
        itemNumberUrl: props.itemNumberUrl,
        merchantId: props.merchantId,
        resultDate1,
        resultDate2,
      }),
    );
  }, [
    props.id,
    props.itemNumberUrl,
    props.merchantId,
    resultDate1,
    resultDate2,
  ]);

  useEffect(() => {
    dispatch(clearGraphStock({}));
  }, []);

  const xAxisLabels = graphStockSales.map((el: any) =>
    format(new Date(el.date), "dd.MM.yyyy"),
  );

  return (
    <React.Fragment>
      <Card className="card-h-100">
        <div className="container-fluid mt-3 mb-3">
          <div className="card-title card-header">
            <h4 className=" mb-2">{t("graphSoldAndSales")}</h4>
            <p
              style={{
                color: " #9e9e9e",
                lineHeight: "22px",
                fontWeight: "300",
              }}
            >
              {t("graphsConditional")}
            </p>
          </div>

          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div style={{ height: "500px" }}>
              <img
                src={graph}
                alt="Graph"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <LimitedAccessComponent />
            </div>
          ) : status === "graph-loading" ? (
            <div style={{ textAlign: "center", padding: "200px" }}>
              <span className="placeholder" style={{ width: "25%" }}></span>
            </div>
          ) : (
            <CardBody>
              {t("sales")}
              <SalesGraph data={graphStockSales} xAxisLabels={xAxisLabels} />
              {t("comments")}
              <CommentsGraph data={graphStockSales} xAxisLabels={xAxisLabels} />
              {t("price")}
              <PriceGraph data={graphStockSales} xAxisLabels={xAxisLabels} />
            </CardBody>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
}
