import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { FetchProductType } from "src/features/product/type";

export const slice = createSlice({
  name: "product",
  initialState: {
    dataProduct: {} as FetchProductType,
    itemNumber: null as null | number,
    dateSelect1: [] as string[],
    endDateOne: "" as string | null,
    statusProduct: "idle" as RequestStatusType,
  },
  reducers: {
    setData(state, action: PayloadAction<{ dataProduct: FetchProductType }>) {
      state.dataProduct = action.payload.dataProduct;
    },
    setItemNumber(state, action: PayloadAction<{ itemNumber: number | null }>) {
      state.itemNumber = action.payload.itemNumber;
    },
    setDateSelect1(state, action: PayloadAction<{ dateSelect: string[] }>) {
      state.dateSelect1 = [...action.payload.dateSelect];
    },
    clearDate(state, action: PayloadAction<{}>) {
      state.dateSelect1 = [];
    },

    setStatusProduct(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusProduct = action.payload.status;
    },
    clearProductData(state, action) {
      return {
        ...state,
        dataProduct: {} as FetchProductType,
        itemNumber: null as number | null,
        dateSelect1: [] as string[],
        endDateOne: "" as string | null,
      };
    },
  },
});
export const productReducer = slice.reducer;
export const {
  setData,
  setItemNumber,
  setDateSelect1,
  clearDate,
  setStatusProduct,
  clearProductData,
} = slice.actions;
