import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAPI } from "src/api/api_helper";
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from "src/features/App/reducer";
import { clearData } from "src/features/trendOil/reducer";
import { RegisterRequestType } from "src/pages/Authentication/Register";
import { LoginRequestType } from "src/pages/AuthenticationInner/Login1";
import { PasswordChangeRequestType } from "src/pages/Contacts/UserSetting";
import { setIsLoggedInAc, setStatusSign } from "src/slices/Auth/Login/reducer";
import {
  setCanUseTrial,
  setCheckPromo,
  setSignUpData,
  setStatusSignUp,
} from "src/slices/Auth/Register/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const signUpTC = createAsyncThunk<any, RegisterRequestType>(
  "signUp/signUpTC",
  async (data, thunkAPI) => {
    // thunkAPI.dispatch(setAppStatusAC({ status: "loading" }));
    thunkAPI.dispatch(setStatusSignUp({ status: "sign-loading" }));

    try {
      const res = await authAPI.signUp(data);
      if (res.data.success === true) {
        if (res.data.message.url) {
          console.log(res, "res.data.message.url");
          window.location.href = res.data.message.url;
        }
        // thunkAPI.dispatch(setIsLoggedInAc({ value: true }));  КОММЕНТИМ С ВАДИМОМ 10.03.24
        // localStorage.setItem("token", res.data.token!);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        document.cookie = `temp-confirm-email=${
          res.data.token
        }; expires=${expirationDate.toUTCString()}; domain=.restatos.com; path=/;`; // C Вадимом  сделали 15.03.24

        // thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));КОММЕНТИМ С ВАДИМОМ 10.03.24
        // thunkAPI.dispatch(setStatusSign({ status: "succeeded" }));  КОММЕНТИМ С ВАДИМОМ 10.03.24

        thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));

        // window.location.reload();
        // КОММЕНТИМ С ВАДИМОМ 10.03.24
      } else {
        console.log(res, "exam else sign");
        // return handleAsyncServerNetworkError(res.data.error, thunkAPI);
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
        thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));

        // thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" })); КОММЕНТИМ С ВАДИМОМ 10.03.24
      }
    } catch (error: any) {
      thunkAPI.dispatch(setAppErrorAC({ error: error }));

      thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));

      // thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" })); КОММЕНТИМ С ВАДИМОМ 10.03.24
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const loginTC = createAsyncThunk<any, LoginRequestType>(
  "login/loginTC",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(setStatusSign({ status: "sign-loading" }));
    try {
      const res = await authAPI.login(data);
      if (res.data.success === true) {
        localStorage.setItem("token", res.data.token!);
        // thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
        // window.location.reload(); Закоментил 20.03.24
        thunkAPI.dispatch(setIsLoggedInAc({ value: true }));
        thunkAPI.dispatch(setStatusSign({ status: "succeeded" }));
      } else {
        // thunkAPI.dispatch(setAppErrorAC({ error: "ERROR " }));
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
        thunkAPI.dispatch(setStatusSign({ status: "succeeded" }));
      }
    } catch (error: any) {
      thunkAPI.dispatch(setStatusSign({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const logoutTC = createAsyncThunk<any, void>(
  "login/logoutTC",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setAppStatusAC({ status: "loading" }));
    try {
      const res = await authAPI.logout();
      if (res.data.success === true) {
        localStorage.removeItem("token");
        document.cookie = `token=1; expires=Fri, 31 Dec 2000 23:59:59 GMT; domain=.restatos.com; path=/;`;
        // document.cookie = `token=1; expires=Fri, 31 Dec 2000 23:59:59 GMT; domain=localhost; path=/;`;
        thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
        thunkAPI.dispatch(setIsLoggedInAc({ value: false }));
        thunkAPI.dispatch(clearData({}));
      } else {
        thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
        thunkAPI.dispatch(setAppErrorAC({ error: "ERROR in the Logout" }));
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
      }
    } catch (error: any) {
      thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
      // thunkAPI.dispatch(setStatusSign({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const signUpDataTC = createAsyncThunk<any, any>(
  "signUpData/signUpDataTC",
  async ({}, thunkAPI) => {
    thunkAPI.dispatch(setStatusSignUp({ status: "sign-loading" }));

    try {
      const res = await authAPI.signUpData();
      if (res.data.success === true) {
        thunkAPI.dispatch(setSignUpData({ tariffs: res.data.message.tariffs }));
        thunkAPI.dispatch(
          setCanUseTrial({ canUseTrial: res.data.message.can_use_trial }),
        );
        thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
        thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      }
    } catch (error: any) {
      thunkAPI.dispatch(setAppErrorAC({ error: error }));

      thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const checkPromoTc = createAsyncThunk<any, any>(
  "checkPromo/checkPromoTc",
  async (promo, thunkAPI) => {
    thunkAPI.dispatch(setStatusSignUp({ status: "sign-loading" }));

    try {
      const res = await authAPI.checkPromo(promo);
      console.log(res, "res");

      if (res.data.success === true) {
        console.log(res, "res");
        // thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));

        thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
        thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      }
    } catch (error: any) {
      thunkAPI.dispatch(setAppErrorAC({ error: error }));

      thunkAPI.dispatch(setStatusSignUp({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
