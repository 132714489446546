import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { TableCategoryPositionType } from "src/features/product/miniProductComponent/table/tableCategoryPosition/type";

export const slice = createSlice({
  name: "tableCategoryPositionProduct",
  initialState: {
    tableCategoryPosition: [] as Array<TableCategoryPositionType>,
    subscribe: {} as any,
    statusTableCategoryPosition: "idle" as RequestStatusType,
  },
  reducers: {
    setTableCategoryPosition(
      state,
      action: PayloadAction<{
        table: Array<TableCategoryPositionType>;
      }>,
    ) {
      state.tableCategoryPosition = [...action.payload.table];
    },
    setStatusTableCategoryPosition(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusTableCategoryPosition = action.payload.status;
    },
    clearCategoryPositionTableData(state, action) {
      return {
        ...state,
        tableCategoryPosition: [] as Array<TableCategoryPositionType>,
      };
    },
  },
});
export const tableCategoryPositionProduct = slice.reducer;
export const {
  setTableCategoryPosition,
  setStatusTableCategoryPosition,
  clearCategoryPositionTableData,
} = slice.actions;
