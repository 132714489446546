import { useFormik } from "formik";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbItem, Col, Row } from "reactstrap";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import { ClearButton } from "src/components/InputClearButton/InputClearButton";
import { LimitedAccessComponent } from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import VideoModal from "src/components/VideoModal/VideoModal";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import {
  clickAscDescTableCategoryList,
  clickOrderNameTableCategoryList,
  onChangePhraseTableCategoryList,
  onClickPageTableCategoryList,
  resetTableCategoryList,
  setRestartCategoryList,
  setShowVideoCategoryList,
  setStatusTableCategoryList,
} from "src/features/CategoryList/reducer";
import TableDescription from "src/features/CategoryList/table-description";
import {
  fetchExcelTableCategoryList,
  fetchTableCategoryList,
} from "src/features/CategoryList/thunk";
import s from "src/features/CategoryTable/Category.module.css";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { InputsType } from "src/features/CategoryTable/type";
import {
  selectAscDescTableCategoryList,
  selectColumnNameTableCategoryList,
  selectDataTableCategoryList,
  selectOrderNameTableCategoryList,
  selectPageTableCategoryList,
  selectRestart,
  selectShowVideoCategoryList,
  selectStatusTableCategoryList,
  selectTotalCountPageTableCategoryList,
} from "src/features/CategoryList/selectors";
import { AutoCompleteSelect } from "src/pages/Product-Research/InputGroup/InputGroup";
import { fetchProductResearchTC } from "src/pages/Product-Research/thunk";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeightListTable } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { productResearchYup } from "src/utilitsFunction/schemas";

export const CategoryList = () => {
  const {
    name,
    share_goods_with_sold_from,
    share_goods_with_sold_to,
    share_merch_with_sold_from,
    share_merch_with_sold_to,
    cats,
  } = useSearchParams();

  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [tooltipName, setTooltipName] = useState<any>(null);

  document.title = "Restatos | Category List";
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const h = findHeightListTable(ref);
  const [count, setCount] = useState<number>(100);
  const data = useAppSelector(selectDataTableCategoryList);
  const orderName = useAppSelector(selectOrderNameTableCategoryList);
  const page = useAppSelector(selectPageTableCategoryList);
  const ascDesc = useAppSelector(selectAscDescTableCategoryList);
  const totalCountPage = useAppSelector(selectTotalCountPageTableCategoryList);
  const status = useAppSelector(selectStatusTableCategoryList);
  const columnName = useAppSelector(selectColumnNameTableCategoryList);
  const userStatus = useAppSelector(selectUserStatus);
  const restart = useAppSelector(selectRestart);

  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: name ?? "" },
    {
      id: 1,
      label: "Share of goods in sales, from",
      name: "share_goods_with_sold_from",
      value: share_goods_with_sold_from ?? "",
    },
    {
      id: 2,
      label: "Share of goods with sales, up to",
      name: "share_goods_with_sold_to",
      value: share_goods_with_sold_to ?? "",
    },
    {
      id: 3,
      label: "Share of salespeople with sales, from",
      name: "share_merch_with_sold_from",
      value: share_merch_with_sold_from ?? "",
    },
    {
      id: 4,
      label: "Share of salespeople with sales, up to",
      name: "share_merch_with_sold_to",
      value: share_merch_with_sold_to ?? "",
    },
    // { id: 1, label: t("Brand"), name: "brand", value: "" },
    // { id: 2, label: "Barcode", name: "barcode", value: "" },
  ]);
  const param = {
    // category_url,
    columnName: inputs,
    orderName,
    ascDesc,
    page,
  };

  if (restart) {
    const hasNonEmptyValue = inputs.some((input: any) => input.value !== "");

    if (hasNonEmptyValue) {
      const newInputs = inputs.map((input: any) => ({ ...input, value: "" }));

      const params = new URLSearchParams();
      newInputs.forEach((input: any) => {
        if (input.value === "") {
          params.append(input.name, input.value);
        }
      });
      setInputs(newInputs);

      const newUrl = window.location.origin + window.location.pathname;

      window.history.pushState({}, document.title, newUrl);
      dispatch(onChangePhraseTableCategoryList({ columnName: inputs }));
    }
    dispatch(setRestartCategoryList({ restart: false }));
  }

  useEffect(() => {
    dispatch(fetchTableCategoryList(param));
  }, [ascDesc, orderName, page, columnName]);

  useEffect(() => {
    dispatch(onClickPageTableCategoryList({ page: 1 }));
  }, [ascDesc, orderName, columnName]);

  useEffect(() => {
    return () => {
      dispatch(resetTableCategoryList({}));
    };
  }, []);
  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderNameTableCategoryList({ orderName: column.selector }));
    dispatch(clickAscDescTableCategoryList({ ascDesc: sortDirection }));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPageTableCategoryList({ page: newPage }));
    setCount(newCount);
  };

  const columns = [
    {
      name: t("name"),
      selector: "name",
      sortable: true,
      // title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/category/${row.id}/products`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          {/*<Tooltip />*/}
        </>
      ),
    },
    {
      name: t("sold"),
      selector: "sold",
      sortable: true,
      format: (row: { sold: any }) =>
        row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("revenue"),
      selector: "revenue",
      sortable: true,
      id: "revenue",
      format: (row: { revenue: number }) =>
        (row.revenue / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    // {
    //   name: t("stock"),
    //   selector: "stock",
    //   sortable: true,
    //   format: (row: { stock: any }) =>
    //     row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    // },

    {
      name: t("prodCount"),
      selector: "prodCount",
      sortable: true,
      format: (row: { prodCount: any }) =>
        row.prodCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodCountWithSold"),
      selector: "prodCountWithSold",
      sortable: true,
      format: (row: { prodCountWithSold: any }) =>
        row.prodCountWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareProdWithSold"),
      selector: "shareProdWithSold",
      sortable: true,
      format: (row: { shareProdWithSold: any }) =>
        row.shareProdWithSold
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    //ВАДИМ ПОПРОСИЛ СКРЫТЬ товаров с остатками и доля торова с остатками
    // {
    //   name: t("prodCountWithStock"),
    //   // selector: (row) => row.position,
    //   selector: "prodCountWithStock",
    //   format: (row: { prodCountWithStock: any }) =>
    //     row.prodCountWithStock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    //   sortable: true,
    // },
    // // 7
    // {
    //   name: t("shareProdWithStock"),
    //   selector: "shareProdWithStock",
    //   sortable: true,
    //   format: (row: { shareProdWithStock: any }) =>
    //     row.shareProdWithStock
    //       .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    // },
    // 8

    {
      name: t("merchCount"),
      selector: "merchCount",
      sortable: true,
      format: (row: { merchCount: any }) =>
        row.merchCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 9
    {
      name: t("merchCountWithSold"),
      selector: "merchCountWithSold",
      sortable: true,
      format: (row: { merchCountWithSold: any }) =>
        row.merchCountWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 10

    {
      name: t("shareMerchWithSold"),
      selector: "shareMerchWithSold",
      sortable: true,
      format: (row: { shareMerchWithSold: any }) =>
        row.shareMerchWithSold
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    // 11
    {
      name: t("merchCountWithStock"),
      selector: "merchCountWithStock",
      sortable: true,
      format: (row: { merchCountWithStock: any }) =>
        row.merchCountWithStock
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 12
    // {
    //   name: t("shareMerchWithStock"),
    //   selector: "shareMerchWithStock",
    //   sortable: true,
    //   format: (row: { shareMerchWithStock: any }) =>
    //     row.shareMerchWithStock
    //       .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    // },
    // 13
    {
      name: t("brandCount"),
      selector: "brandCount",
      sortable: true,
      format: (row: { brandCount: any }) =>
        row.brandCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 14
    {
      name: t("brandCountWithSold"),
      selector: "brandCountWithSold",
      sortable: true,
      format: (row: { brandCountWithSold: any }) =>
        row.brandCountWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 15
    {
      name: t("shareBrandWithSold"),
      selector: "shareBrandWithSold",
      sortable: true,
      format: (row: { shareBrandWithSold: any }) =>
        row.shareBrandWithSold
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    // 16

    {
      name: t("brandCountWithStock"),
      selector: "brandCountWithStock",
      sortable: true,
      format: (row: { brandCountWithStock: any }) =>
        row.brandCountWithStock
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 17
    // {
    //   name: t("shareBrandWithStock"),
    //   selector: "shareBrandWithStock",
    //   sortable: true,
    //   format: (row: { shareBrandWithStock: any }) =>
    //     row.shareBrandWithStock
    //       .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    // },
    // 18
    {
      name: t("minPrice"),
      selector: "minPrice",
      format: (row: { minPrice: any }) =>
        (row.minPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("maxPrice"),
      selector: "maxPrice",
      format: (row: { maxPrice: any }) =>
        (row.maxPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("avgPrice"),
      // selector: (row) => row.price,
      selector: "avgPrice",
      format: (row: { avgPrice: any }) =>
        (row.avgPrice / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    // {
    //   name: <div style={{ marginLeft: "70px" }}>{t("chart")}</div>,
    //   cell: (row: { chart: Array<number> }) => <D3BarChart data={row.chart} />,
    // },
  ] as any;
  // share_goods_with_sold_from,
  //   share_goods_with_sold_to,
  //   share_merch_with_sold_from,
  //   share_merch_with_sold_to,

  // const handleInputChange = (index: number, value: string) => {
  //   dispatch(onClickPageTableCategoryList({ page: 1 }));
  //   const updatedInputs = inputs.map((input, i) => ({
  //     ...input,
  //     value: i === index ? value : input.value,
  //   }));
  //   dispatch(setFilterTableCategoryList({ value: value }));
  //
  //   setInputs(updatedInputs);
  // };
  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhraseTableCategoryList({ columnName: inputs }));
    }
  };
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }

    const filteredValue =
      inputs[index].name === "share_goods_with_sold_from" ||
      inputs[index].name === "share_goods_with_sold_to" ||
      inputs[index].name === "share_merch_with_sold_from" ||
      inputs[index].name === "share_merch_with_sold_to"
        ? value.replace(/\D/g, "")
        : value;

    if (value === "") {
      dispatch(
        setStatusTableCategoryList({
          status: "tableCategoryList-loading",
        }),
      );
      dispatch(onChangePhraseTableCategoryList({ columnName: [] }));
    }

    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? filteredValue : input.value,
    }));

    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);

    const params = new URLSearchParams();

    updatedInputs.forEach((input) => {
      if (input.value !== "") {
        params.append(input.name, input.value);
      }
    });

    let url = window.location.pathname;
    if (params.toString() !== "") {
      url += `?${params.toString()}`;
    }
    window.history.pushState({}, "", url);

    const id = +setTimeout(() => {
      // dispatch(onClickPageTableCategoryList({ page: 1 }));
      dispatch(onChangePhraseTableCategoryList({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  const [modal_standard, setmodal_standard] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const params = new URLSearchParams();
  const formik = useFormik({
    validationSchema: productResearchYup,
    initialValues: {
      cats: cats ?? "",
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        if (userStatus === "trial") {
          dispatch(setUserStatus({ userStatus: "unpaid" }));
          return;
        }
        await dispatch(fetchTableCategoryList(param));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />
      {status === "tableCategoryList-loading" && (
        <CustomSpinner absolute={true} top={"300px"} />
      )}
      <div
        className={
          status === "tableCategoryList-loading"
            ? s.opasity
            : // : userStatus === "trial" || userStatus === "unpaid"
              // ? s.blur
              ""
        }
        // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/category-list">
                      {t("categoryList")}
                    </Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        {/*<div*/}
        {/*  style={{ background: "white", fontSize: "20px", paddingLeft: "10px" }}*/}
        {/*>*/}
        {/*  <CardHeader>{t("SellerAnalyticalReport")}</CardHeader>*/}
        {/*</div>*/}

        <div
          style={{ height: "100%", background: "white", paddingLeft: "10px" }}
          className="d-flex flex-wrap gap-2 align-items-center"
        >
          <div style={{ pointerEvents: "none", opacity: 0.6 }}>
            <MyDatePickerComponent />
          </div>
          {/*  <NavLink*/}
          {/*    to={`#`}*/}
          {/*    type="button"*/}
          {/*    className="btn-sm btn btn-light"*/}
          {/*    style={{ marginTop: "10px" }}*/}
          {/*  >*/}
          {/*    {t("products")}*/}
          {/*  </NavLink>*/}
          {/*  <NavLink*/}
          {/*    // to={`/trendyol/seller//${sellerId}/products`}*/}
          {/*    to={"#"}*/}
          {/*    type="button"*/}
          {/*    className="btn-sm btn btn-primary"*/}
          {/*    style={{ marginTop: "10px" }}*/}
          {/*  >*/}
          {/*    {t("category")}*/}
          {/*  </NavLink>*/}
          {/*  <NavLink*/}
          {/*    to={`#`}*/}
          {/*    type="button"*/}
          {/*    className="btn-sm btn btn-light"*/}
          {/*    style={{ marginTop: "10px" }}*/}
          {/*  >*/}
          {/*    {t("brands")}*/}
          {/*  </NavLink>*/}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <ExcelButton
              action={() => dispatch(fetchExcelTableCategoryList(param))}
            />
            <i
              onClick={() => setmodal_standard(true)}
              className="uil-question-circle "
              style={{ fontSize: "24px", cursor: "pointer" }}
            ></i>
            {/*<AutoCompleteSelect*/}
            {/*  selectedOption={selectedOption}*/}
            {/*  setSelectedOption={setSelectedOption}*/}
            {/*  params={params}*/}
            {/*  cats={formik.values.cats}*/}
            {/*  formik={formik}*/}
            {/*/>*/}
            {inputs.map((input, index) => (
              // TODO
              <>
                <CustomInput
                  id={input.id}
                  disabled={status === "tableCategoryList-loading"}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  onKeyPress={onKeyPress}
                />
              </>
            ))}
            <ClearButton
              inputs={inputs}
              setInputs={setInputs}
              setIsCleared={() =>
                dispatch(
                  onChangePhraseTableCategoryList({ columnName: inputs }),
                )
              }
            />
          </div>
        </div>
        <VideoModal
          turkishURL={"https://www.youtube.com/embed/hyksv6fnHXM"}
          englishURL={"https://www.youtube.com/embed/cpIj0l7TaXM"}
          actionStore={setShowVideoCategoryList}
          selectFunc={selectShowVideoCategoryList}
        />
        <DataTable
          conditionalRowStyles={
            userStatus === "trial" || userStatus === "unpaid"
              ? conditionalRowStyles
              : ""
          }
          className={`customTableYaroslav tableList ${
            userStatus === "trial" || userStatus === "unpaid" ? "no-scroll" : ""
          }`}
          // className={"customTableYaroslav"}
          defaultSortFieldId={"revenue"}
          title={t("")}
          columns={columns}
          data={data}
          subHeader
          fixedHeaderScrollHeight={h}
          fixedHeader
          // sortIcon={null}
          responsive={true}
          // sortServer={false}
          sortServer={true}
          defaultSortAsc={false}
          onSort={handleSort}
          // onChangePage={handlePageChange}
          // sortIcon
          // defaultSortAsc={true}
          noDataComponent={<div>{""}</div>}
        />
        {userStatus === "trial" || userStatus === "unpaid" ? (
          <div className={s.blurLists}></div>
        ) : null}

        <TooltipInTable tooltipName={tooltipName} />

        <SuperPagination
          page={page}
          itemsCountForPage={count}
          totalCount={totalCountPage}
          onChange={onChangePagination}
        />
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
