import { createAsyncThunk } from "@reduxjs/toolkit";
import { merchantTableAPI } from "src/api/api_merchantTable/api_merchantTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setDataCompetitors,
  setDataProductInTheTops,
  setDataRating,
  setDataRevenue,
  setSelectCompetitors,
  setSelectProductInTheTops,
  setStatusCompetitors,
  setStatusDataRating,
  setStatusDataRevenue,
  setStatusProductInTheTops,
} from "src/features/MerchantTable/reducer";
import {
  setMerchantTable,
  setStatusMerchantTable,
  setTotalCountPageMerchantTable,
} from "src/features/MerchantTable/reducer";

import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchMerchantTable = createAsyncThunk<any, any>(
  "merchantTable/fetchMerchantTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTable({ status: "merchantTable-loading" }),
    );
    try {
      const { res, resInfo } = await merchantTableAPI.fetchMerchantTable(
        param.sellerId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setMerchantTable({ table: res.data.data }));
        thunkAPI.dispatch(setStatusMerchantTable({ status: "succeeded" }));
        thunkAPI.dispatch(
          setTotalCountPageMerchantTable({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(setStatusMerchantTable({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchExcelMerchantTable = createAsyncThunk<any, any>(
  "excelMerchantTable/fetchExcelMerchantTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTable({ status: "merchantTable-loading" }),
    );
    try {
      const res = await merchantTableAPI.fetchExcelMerchantTable(
        param.sellerId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(setStatusMerchantTable({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusMerchantTable({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchDataCompetitorsSold = createAsyncThunk<any, any>(
  "dataCompetitorsSold/fetchDataCompetitorsSold",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCompetitors({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchDataCompetitorsSold(
        param.sellerId,
        param.startDate,
        param.endDate,
      );

      if (res.data.success === true) {
        // thunkAPI.dispatch(
        //   setSelectCompetitors({ select: "Revenue in this category" }),
        // );
        thunkAPI.dispatch(
          setDataCompetitors({ data: res.data.message.data.competitors }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
      } else {
        // thunkAPI.dispatch(
        //   setSelectCompetitors({
        //     select: "Number of products in the top of category",
        //   }),
        // );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Competitors - Category Revenue

// export const fetchCategoryRevenue = createAsyncThunk<any, any>(
//   "categoryRevenue/fetchCategoryRevenue",
//   async (id, thunkAPI) => {
//     thunkAPI.dispatch(
//       setStatusCompetitors({
//         status: "loading",
//       }),
//     );
//     try {
//       const res = await brandsTableAPI.fetchCategoryRevenue(id);
//       if (res.data.success === true) {
//         thunkAPI.dispatch(
//           setSelectCompetitors({ select: "Выручка в категории" }),
//         );
//         thunkAPI.dispatch(
//           setDataCompetitors({ data: res.data.message.data.competitors }),
//         );
//         thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
//       } else {
//         thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
//         thunkAPI.dispatch(
//           setSelectCompetitors({ select: "Товаров в топе категории, шт" }),
//         );
//         thunkAPI.dispatch(
//           setAppErrorAC({
//             error:
//               "Something went wrong. We're already looking into the problem",
//           }),
//         );
//         // return handleAsyncServerAppError(res.data, thunkAPI);
//       }
//     } catch (error) {
//       return handleAsyncServerNetworkError(error, thunkAPI);
//     }
//   },
// );

// Получаем данные для Competitors - items in the category top
export const fetchItemsInTheCategoryTop = createAsyncThunk<any, any>(
  "itemsInTheCategoryTop/fetchItemsInTheCategoryTop",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCompetitors({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchItemsInTheCategoryTop(id);
      if (res.data.success === true) {
        // thunkAPI.dispatch(
        //   setSelectCompetitors({
        //     select: "Number of products in the top of category",
        //   }),
        // );
        thunkAPI.dispatch(
          setDataCompetitors({ data: res.data.message.data.competitors }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
        // thunkAPI.dispatch(
        //   setSelectCompetitors({ select: "revenueInCategory" }),
        // );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchDataRating = createAsyncThunk<any, any>(
  "dataRating/fetchDataRating",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusDataRating({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchDataRating(
        param.sellerId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataRating({ rating: res.data }));
        thunkAPI.dispatch(setStatusDataRating({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusDataRating({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Селекта - Search
export const fetchDataSearch = createAsyncThunk<any, any>(
  "dataSearch/fetchDataSearch",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductInTheTops({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchDataSearch(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Search" }));

        thunkAPI.dispatch(setDataProductInTheTops({ data: res.data }));
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "categories" }));
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Category" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      thunkAPI.dispatch(setSelectProductInTheTops({ select: "categories" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
// Получаем данные для Селекта - Category
export const fetchDataCategory = createAsyncThunk<any, any>(
  "dataCategory/fetchDataCategory",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductInTheTops({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchDataCategory(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "categories" }));
        thunkAPI.dispatch(setDataProductInTheTops({ data: res.data }));
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Search" }));

        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      thunkAPI.dispatch(setSelectProductInTheTops({ select: "Search" }));

      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchDataRevenue = createAsyncThunk<any, any>(
  "dataRevenue/fetchDataRevenue",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusDataRevenue({
        status: "loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchDataRevenue(
        param.sellerId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataRevenue({ revenue: res.data }));
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
