import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

const initialState = {
  isLoggedIn: false,
  status: "idle" as RequestStatusType,
};

export const slice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setIsLoggedInAc(state, action: PayloadAction<{ value: boolean }>) {
      state.isLoggedIn = action.payload.value;
    },
    setStatusSign(state, action: PayloadAction<{ status: RequestStatusType }>) {
      state.status = action.payload.status;
    },
  },
});

export const loginReducer = slice.reducer;
export const { setIsLoggedInAc, setStatusSign } = slice.actions;
type ActionsType = ReturnType<typeof setIsLoggedInAc>;
