import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setIsSubscribe,
  setStatusSubscribeButton,
  setToggleSubscription,
} from "src/features/product/miniProductComponent/subscribeButton/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchIsSubscribe = createAsyncThunk<any, any>(
  "isSubscribe/fetchIsSubscribe",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusSubscribeButton({ status: "subscribe-loading" }),
    );
    try {
      const res = await productAPI.isSubscribe(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(
          setIsSubscribe({ isFollowing: res.data.message.exists }),
        );
        // thunkAPI.dispatch(setToggleSubscription({ toggle: null }));
        // thunkAPI.dispatch(setToggleSubscription({ toggle: false }));
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchFollow = createAsyncThunk<any, any>(
  "follow/fetchFollow",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusSubscribeButton({ status: "subscribe-loading" }),
    );
    thunkAPI.dispatch(
      setStatusSubscribeButton({ status: "categoryTable-loading" }),
    );
    try {
      const res = await productAPI.follow(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setToggleSubscription({ toggle: true }));
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
        return res;
      } else {
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchUnFollow = createAsyncThunk<any, any>(
  "unFollow/fetchUnFollow",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusSubscribeButton({ status: "subscribe-loading" }),
    );
    thunkAPI.dispatch(
      setStatusSubscribeButton({ status: "categoryTable-loading" }),
    );
    try {
      const res = await productAPI.unFollow(id);
      if (res.data.success === true) {
        // thunkAPI.dispatch(setIsSubscribe({ isFollowing: res.data.message }));
        thunkAPI.dispatch(setToggleSubscription({ toggle: false }));
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
        return res;
      } else {
        thunkAPI.dispatch(setStatusSubscribeButton({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
