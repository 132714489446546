export const handlesMouseOver = (
  event: any,
  image: any,
  setTooltipData: any,
) => {
  const xPosition = event.clientX;
  const yPosition = event.clientY;

  setTooltipData({ x: xPosition, y: yPosition, value: image });
};
export const handlesMouseOut = (setTooltipData: any) => {};
