import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Col, Modal, Table } from "reactstrap";

const TableDescription = ({
  tog_standard,
  modal_standard,
  setmodal_standard,
}: any) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("All-data-are-for-the-last-30-days")}</p>
          <Col className="col-xxl-12" xl={12}>
            {/*<Card>*/}
            <CardHeader className="justify-content-between d-flex align-items-center">
              <CardTitle style={{ marginBottom: "7px" }} className="h4">
                {t("Description-of-columns-of-the-report-table")}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>{t("Column")}</th>
                      <th>{t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("SoldTable")}</td>
                      <td>{t("Quantity-sold")}</td>
                    </tr>
                    <tr>
                      <td>{t("Revenue")}</td>
                      <td>
                        {t(
                          "Quantity sold multiplied by the price on the day of sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods")}</td>
                      <td>{t("Number of items found in a category")}</td>
                    </tr>
                    <tr>
                      <td>{t("Goods with sales")}</td>
                      <td>
                        {t(
                          "Number of items in the category that have at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of goods with sales")}</td>
                      <td>
                        {t(
                          "The ratio of items with sales to the total number of items in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods with stock")}</td>
                      <td>
                        {t(
                          "Number of items in the category that have stocks at the time of our last data collection",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of goods with stock")}</td>
                      <td>
                        {t(
                          "Ratio of items with stocks to the total number of items in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sellers")}</td>
                      <td>{t("Number of sellers in the category")}</td>
                    </tr>
                    <tr>
                      <td>{t("Sellers with sales")}</td>
                      <td>
                        {t(
                          "Number of sellers in the category with at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of sellers with sales")}</td>
                      <td>
                        {t(
                          "Ratio of the number of sellers with sales to the total number of sellers in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sellers with stock")}</td>
                      <td>
                        {t(
                          "The number of sellers in the category who have a stock item",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of sellers with stock")}</td>
                      <td>
                        {t(
                          "The ratio of the number of sellers with stocks to the total number of sellers in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Brands")}</td>
                      <td>{t("Number of brands in the category")}</td>
                    </tr>
                    <tr>
                      <td>{t("Brands with sales")}</td>
                      <td>
                        {t(
                          "Number of brands in the category that have had at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of brands with sales")}</td>
                      <td>
                        {t(
                          "The ratio of the number of brands with sales to the total number of brands in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Brands with stock")}</td>
                      <td>
                        {t(
                          "The number of brands in the category that had items in stock at the time of our last data retrieval",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of brands with stock")}</td>
                      <td>
                        {t(
                          "The ratio of the number of brands with stocks to the total number of brands",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Min. price")}</td>
                      <td>
                        {t("Minimum price of the product in the category")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Max. price")}</td>
                      <td>
                        {t("Maximum price of the product in the category")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Median price")}</td>
                      <td>
                        {t(
                          "Median price in the category (roughly like the average, but slightly more accurate)",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </CardBody>
  );
};

export default TableDescription;
