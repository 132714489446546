import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoriesAPI } from "src/api/api_helper";
import { setAppStatusAC } from "src/features/App/reducer";
import {
  setCategoryData,
  setStatusCategory,
} from "src/features/trendOil/reducer";
// import { ThunkError } from "src/store/types";
export const getCategoriesVadim = createAsyncThunk<any, void>(
  "categories/getCategoriesVadim",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setStatusCategory({ status: "category-loading" }));
    thunkAPI.dispatch(setAppStatusAC({ status: "loading" }));
    try {
      const res = await categoriesAPI.fetchCategories();
      thunkAPI.dispatch(setStatusCategory({ status: "succeeded" }));
      thunkAPI.dispatch(setCategoryData({ categories: res.data.message }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
// export const fetchMiniCategories = createAsyncThunk<any, any>(
//   "categories/fetchMiniCategories",
//   async (id, thunkAPI) => {
//     try {
//       const res = await categoriesAPI.fetchMiniSelectedCat(id);
//       return { miniCategories: res.data.message };
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   },
// );
