import { instance } from "src/api/api_helper";
import { FetchResponseAxios, FetchResponseTable } from "src/api/types";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";

export const integrationProductsAPI = {
  async fetchIntegrationProducts(
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
  ) {
    const url = new URL(`https://web-api.restatos.com/trendyolAPIProducts/get`);

    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyolAPIProducts/get/info`,
    );
    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });

    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }

    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get(`${url}`, {
        params: params,
      }),
      instance.get(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  async fetchTrendyolAPICredsSave(data: any) {
    const url = new URL(
      `https://web-api.restatos.com/account/trendyolAPICredsSave`,
    );
    return await instance.post<FetchResponseAxios<string>>(`${url}`, data);
  },
  async fetchTrendyolAPIPriceUpdater(data: any) {
    const url = new URL(
      `https://web-api.restatos.com/trendyolAPIPriceUpdater/get`,
    );

    return await instance.post<FetchResponseAxios<string>>(`${url}`, data);
  },
  async fetchAutoPriceSet(data: any) {
    const url = new URL(
      `https://web-api.restatos.com/trendyolAPIPriceUpdater/set`,
    );

    return await instance.post<FetchResponseAxios<string>>(`${url}`, data);
  },
};
