import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountUnSubscribeAPI } from "src/api/api-account-unsubscribe/api-account-un-subscribe";
import { authAPI } from "src/api/api_helper";
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from "src/features/App/reducer";
import { PasswordChangeRequestType } from "src/pages/Contacts/UserSetting";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const passwordChangeTC = createAsyncThunk<
  any,
  PasswordChangeRequestType
>("passwordChange/passwordChangeTC", async (data, thunkAPI) => {
  thunkAPI.dispatch(setAppStatusAC({ status: "loading" }));
  try {
    const res = await authAPI.passwordChange(data);
    if (res.data.success === true) {
      thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
      thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
    } else {
      thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));

      thunkAPI.dispatch(
        setAppErrorAC({ error: "ERROR in the Password Change" }),
      );
    }
  } catch (error: any) {
    thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
    return handleAsyncServerNetworkError(error, thunkAPI);
  }
});

export const accountUnSubscribeTC = createAsyncThunk(
  "accountUnSubscribe/accountUnSubscribeTC",
  async (value: { message: string }, thunkAPI) => {
    try {
      const res = await accountUnSubscribeAPI.accountUnSubscribe(value);
      if (res.data.success === true) {
        thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
      } else {
        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
      }
    } catch (error: any) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
