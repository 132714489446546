// import image from "src/assets/images/my/internet_website_name_web_hosting_domain_icon_220417.svg";
// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { Card, CardBody, Spinner, Tooltip } from "reactstrap";
//
// import { selectStatusProduct } from "src/features/product/selectors";
// import { useAppSelector } from "src/store/types";
//
// type TypeProps = {
//   data: any;
// };
//
// export const Description = (props: TypeProps) => {
//   const [top, setTop] = useState(false);
//
//   const { t } = useTranslation();
//   const statusProduct = useAppSelector(selectStatusProduct);
//
//   const dataRows = [
//     {
//       label: t("title"),
//       value: props.data.name,
//     },
//     {
//       label: t("barcode"),
//       value: props.data.barcode,
//     },
//     {
//       label: t("rating"),
//       value: `${props.data.rating_score} (${props.data.rating_count})`,
//     },
//     {
//       label: t("numOfCat"),
//       value: props.data.category_count,
//     },
//
//     {
//       label: t("favourites"),
//       value: props.data.favorite_count,
//     },
//     {
//       label: t("tax"),
//       value: `${props.data.tax} % `,
//     },
//     {
//       label: t("price"),
//       value: `${props.data.price / 100} TL`,
//     },
//   ];
//
//   return (
//     <Card className="card-h-90">
//       {/*<CardBody>*/}
//       {statusProduct === "product-loading" ? (
//         <div
//           className="card-h-90 card"
//           style={{
//             backgroundColor: "white",
//             alignItems: "center",
//             padding: "50px",
//             width: "100%",
//           }}
//         >
//           <Spinner color="info">Loading...</Spinner>
//         </div>
//       ) : (
//         <>
//           <div
//             className="h4 card-title card-header"
//             style={{ cursor: "pointer" }}
//           >
//             {t("description")}{" "}
//           </div>
//           <ul className="list-unstyled chat-list">
//             {dataRows.map((description: any) => (
//               <li>
//                 <a
//                   style={{
//                     color: "skyblue",
//                   }}
//                 >
//                   <div className="d-flex align-items-start">
//                     <div className="flex-shrink-0 user-img online align-self-center me-3">
//                       <img
//                         src={image}
//                         className="rounded-circle avatar-sm"
//                         alt=""
//                       />
//                     </div>
//                     <div className="flex-grow-1 overflow-hidden">
//                       <h5 className="text-truncate font-size-14 mb-1">
//                         {description.label}
//                         {description.label === t("title") && (
//                           <span
//                             className="h4 card-title "
//                             style={{ cursor: "pointer" }}
//                             id="Tooltip"
//                           >
//                             {" "}
//                             <i
//                               className="uil-question-circle"
//                               style={{ margin: "3px" }}
//                             ></i>
//                             <Tooltip
//                               placement="top"
//                               isOpen={top}
//                               target="Tooltip"
//                               toggle={() => {
//                                 setTop(!top);
//                               }}
//                             >
//                               {description.label === t("title") &&
//                                 description.value}
//                             </Tooltip>
//                           </span>
//                         )}
//                       </h5>
//                       {description.value && (
//                         <p className={`text-truncate  mb-0`}>
//                           {description.value}
//                         </p>
//                       )}
//                     </div>
//                   </div>
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </>
//       )}
//       {/*</CardBody>*/}
//     </Card>
//   );
// };
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Card, CardBody, Spinner, Tooltip } from "reactstrap";
import { ScrollWrapper } from "src/components/ScrollWrapper/ScrollWrapper";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import { SubscribeButton } from "src/features/product/miniProductComponent/subscribeButton/SubscribeButton";

import { selectStatusProduct } from "src/features/product/selectors";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

type TypeProps = {
  data: any;
  id: string;
  merchantId: string;
  itemNumberUrl: string;
};

export const Description = (props: TypeProps) => {
  const userStatus = useAppSelector(selectUserStatus);

  const dispatch = useAppDispatch();

  const [top, setTop] = useState(false);
  const { t } = useTranslation();

  let urlForShow = props.data.url
    ? props.data.url.substring(0, 30) + "..."
    : "";

  const statusProduct = useAppSelector(selectStatusProduct);

  const dataRows = [
    {
      id: 0,
      label: "Trendyol URL",
      value: urlForShow,
    },
    {
      id: 1,
      label: t("title"),
      value: props.data.name,
    },
    {
      id: 2,
      label: t("brand"),
      value: (
        <NavLink
          to={`https://app.restatos.com/trendyol/brand/${props.data.brand_id}/products`}
          // to={`https://www.trendyol.com/sr?rb=${props.data.brand_id}`}
        >
          {props.data.brand_name}
        </NavLink>
      ),
    },
    {
      id: 3,
      label: t("rating"),
      value: `${props.data.rating_score} (${props.data.rating_count})`,
    },
    {
      id: 4,
      label: t("NumberOfMerchants"),
      value:
        userStatus === "trial" || userStatus === "unpaid" ? (
          <i
            onClick={() => dispatch(setUserStatus({ userStatus: "unpaid" }))}
            className="uil uil-question-circle"
            style={{ fontSize: "24px", cursor: "pointer" }}
          ></i>
        ) : (
          props.data.merchant_count
        ),
    },
    {
      id: 5,
      label: t("Sellers with stocks"),
      value:
        userStatus === "trial" || userStatus === "unpaid" ? (
          <i
            onClick={() => dispatch(setUserStatus({ userStatus: "unpaid" }))}
            className="uil uil-question-circle"
            style={{ fontSize: "24px", cursor: "pointer" }}
          ></i>
        ) : (
          props.data.merchants_with_stock_count
        ),
    },
    {
      id: 6,
      label: t("Sellers with sales"),
      value:
        userStatus === "trial" || userStatus === "unpaid" ? (
          <i
            onClick={() => dispatch(setUserStatus({ userStatus: "unpaid" }))}
            className="uil uil-question-circle"
            style={{ fontSize: "24px", cursor: "pointer" }}
          ></i>
        ) : (
          props.data.merchants_with_sold_count
        ),
    },
    {
      id: 7,
      label: t("numOfCat"),
      value: props.data.category_count,
    },

    {
      id: 8,
      label: t("favourites"),
      value: props.data.favorite_count,
    },
    {
      id: 9,
      label: t("tax"),
      value: `${props.data.tax}% `,
    },
    {
      id: 10,
      label: t("price"),
      value: `${props.data.price / 100}TL`,
    },
    {
      id: 11,
      label: t("barcodeProductDescription"),
      value: props.data.barcode,
    },
  ];
  return (
    <Card className="card-h-90">
      {statusProduct === "product-loading" ? (
        <div
          className="card-h-90 card"
          style={{
            backgroundColor: "white",
            alignItems: "center",
            padding: "50px",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      ) : (
        <>
          <div className="d-flex card-header justify-content-between">
            <div className="h4 card-title" style={{ cursor: "pointer" }}>
              {t("Description")}
            </div>
            <SubscribeButton
              id={props.id}
              merchantId={props.merchantId}
              itemNumberUrl={props.itemNumberUrl}
            />
          </div>
          <ScrollWrapper>
            <div className="card-body">
              {dataRows.map((el, index) => {
                return index === 0 ? (
                  <p key={el.id}>
                    <strong>{el.label}</strong>:{" "}
                    <a target={"_blank"} href={props.data.url}>
                      {el.value}
                    </a>
                  </p>
                ) : (
                  <p key={el.id}>
                    <strong>{el.label}</strong>: {el.value}
                  </p>
                );
              })}
            </div>
          </ScrollWrapper>
        </>
      )}
    </Card>
  );
};
