import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { TooltipInTable } from "src/components/tooltipInTable";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import s from "src/features/CategoryTable/Category.module.css";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";

import { selectCategotyImage } from "src/features/CategoryTable/selectors";
import {
  fetchFollow,
  fetchUnFollow,
} from "src/features/product/miniProductComponent/subscribeButton/thunk";
import {
  clickAscDescProductResearch,
  clickOrderNameProductResearch,
  deleteSubscribeProductResearch,
  onClickPageProductResearch,
  setSubscribeProductResearch,
} from "src/pages/Product-Research/reducer";
import {
  selectAscDescProductResearch,
  selectOrderNameProductResearch,
  selectPageProductResearch,
  selectSubscribeProductResearch,
  selectTableProductResearch,
  selectTotalCountPageProductResearch,
} from "src/pages/Product-Research/selectors";

import { useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const TableProductResearch = ({ setLoadStar }: any) => {
  const userStatus = useAppSelector(selectUserStatus);
  const image = useAppSelector(selectCategotyImage);
  const [mouse, setMouse] = useState<boolean>(false);
  const [tooltipData, setTooltipData] = useState<any>(null);
  const [tooltipName, setTooltipName] = useState<any>(null);
  const [count, setCount] = useState<number>(100);
  const orderName = useAppSelector(selectOrderNameProductResearch);
  const ascDesk = useAppSelector(selectAscDescProductResearch);
  const page = useAppSelector(selectPageProductResearch);
  const totalCountPage = useAppSelector(selectTotalCountPageProductResearch);
  const subscribe = useAppSelector(selectSubscribeProductResearch);
  const { category_url } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const table = useAppSelector(selectTableProductResearch);

  // const param: any = {
  //   // category_url,
  //   orderName: orderName,
  //   ascDesk: ascDesk,
  //   page: page,
  // };

  useEffect(() => {
    dispatch(onClickPageProductResearch({ page: 1 }));
  }, [ascDesk, orderName]);

  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderNameProductResearch({ orderName: column.selector }));
    dispatch(clickAscDescProductResearch({ ascDesk: sortDirection }));
  }, []);

  const follow = async (id: number) => {
    setLoadStar(true);
    try {
      const res = await dispatch(fetchFollow(id));

      if (res.payload.data.success) {
        setLoadStar(false);
        dispatch(setSubscribeProductResearch({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };
  const unFollow = async (id: number) => {
    setLoadStar(true);

    try {
      const res = await dispatch(fetchUnFollow(id));

      if (res.payload.data.success) {
        setLoadStar(false);
        dispatch(deleteSubscribeProductResearch({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPageProductResearch({ page: newPage }));
    setCount(newCount);
  };

  const columns =
    ([
      {
        // name: t("followed"),
        selector: "followed",
        width: "20px",

        cell: (row: any) => (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={
              subscribe[row.id] ? () => unFollow(row.id) : () => follow(row.id)
            }
          >
            {subscribe[row.id] ? (
              <i className="mdi mdi-star"></i>
            ) : (
              <i className="mdi mdi-star-outline"></i>
            )}
          </div>
        ),
      },
      {
        // name: t("image"),
        selector: "image",
        width: "55px",

        cell: (row) => (
          <div
          // style={{ display: "block", flexDirection: "row" }}
          >
            {
              <a
                target="_blank"
                href={`https://www.trendyol.com/p/p-p-${row.id}`}
                rel="noreferrer"
              >
                <img
                  onMouseOver={(event: any) => {
                    handlesMouseOver(event, row.image, setTooltipData);
                  }}
                  onMouseOut={() => setTooltipData(null)}
                  style={{ borderRadius: "15%", cursor: "pointer" }}
                  height={"35px"}
                  src={row.image}
                  data-tip="Image"
                />
              </a>
            }
          </div>
        ),
      },
      {
        // name: t("name"),
        name: <div>{t("name")}</div>,
        // selector: (row) => row.name,
        selector: "name",
        title: "Image Column Tooltip",
        cell: (row: { id: string; name: string }) => (
          <>
            <NavLink
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.name, setTooltipName);
              }}
              onMouseOut={() => setTooltipName(null)}
              // onMouseEnter={() => handleMouseEnter(row.id)}
              // onMouseLeave={handleMouseLeave}
              to={`/trendyol/product/${row.id}`}
              // target="_blank"
              id={`nameCategory${row.id}`}
            >
              {/*{row.name.substr(0, 22)}*/}
              {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
            </NavLink>
          </>
        ),
      },

      {
        name: t("brand"),
        // selector: (row) => row.brand,
        selector: "brand",
        sortable: true,
        cell: (row: { brand_id: string; brand: string; id: any }) => (
          <>
            <NavLink
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.brand, setTooltipName);
              }}
              onMouseOut={() => setTooltipName(null)}
              // onMouseEnter={() => handleMouseEnter(row.id)}
              // onMouseLeave={handleMouseLeave}
              to={`/trendyol/brand/${row.brand_id}/products`}
              target="_blank"
            >
              {row.brand.length > 22
                ? row.brand.substr(0, 22) + "..."
                : row.brand}
            </NavLink>
          </>
        ),
      },
      {
        name: t("revenue"),
        selector: "revenue",
        id: "revenue",
        format: (row: { revenue: number }) =>
          (row.revenue / 100)
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
        sortable: true,
      },

      {
        name: t("sold"),
        selector: "sold",
        format: (row: { sold: any }) =>
          row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        sortable: true,
      },

      {
        name: t("price"),
        // selector: (row) => row.price,
        selector: "price",
        format: (row: { price: any }) =>
          (row.price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
          " TL",
      },
      {
        name: t("minPrice"),
        selector: "minPrice",
        format: (row: { minPrice: any }) =>
          (row.minPrice / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      },
      {
        name: t("maxPrice"),
        selector: "maxPrice",
        format: (row: { maxPrice: any }) =>
          (row.maxPrice / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      },

      {
        name: t("merchantCount"),
        selector: "merchantCount",
        format: (row: { merchantCount: any }) =>
          row.merchantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("merchantsWithSold"),
        selector: "merchantsWithSold",
        format: (row: { merchantsWithSold: number }) =>
          row.merchantsWithSold
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        // sortable: true,
      },

      {
        name: t("shareMerchants"),
        // selector: (row) => row.catCount,
        selector: "shareMerchants",
        format: (row: { shareMerchants: number }) =>
          row.shareMerchants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
          "%",
        // sortable: true,
      },

      {
        name: t("favorites"),
        // selector: (row) => row.favorites,
        selector: "favorites",
        format: (row: { favorites: any }) =>
          row.favorites.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        sortable: true,
      },

      {
        name: t("tax"),
        selector: "tax",
        format: (row: { tax: any }) =>
          row.tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
      },

      {
        name: t("minRating"),
        selector: "minRating",
        format: (row: { minRating: any }) =>
          row.minRating
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("maxRating"),
        selector: "maxRating",
        format: (row: { maxRating: any }) =>
          row.maxRating
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },

      {
        name: t("minRatingCount"),
        selector: "minRatingCount",
        format: (row: { minRatingCount: any }) =>
          row.minRatingCount
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },

      {
        name: t("maxRatingCount"),
        selector: "maxRatingCount",
        format: (row: { maxRatingCount: any }) =>
          row.maxRatingCount
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      //
      {
        name: t("Rating increment"),
        selector: "ratingIncrement",
        format: (row: { ratingIncrement: any }) =>
          row.ratingIncrement
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
      },
      //

      {
        name: t("catCount"),
        // selector: (row) => row.catCount,
        selector: "catCount",
        cell: (row: { catCount: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.catCount}
          </div>
        ),
        // sortable: true,
      },
      {
        name: t("chart"),
        cell: (row: { chart: Array<number> }) => (
          <div style={{ marginLeft: "-61px" }}>
            <D3BarChart data={row.chart} />
          </div>
        ),
      },
    ] as React.JSX.IntrinsicAttributes & TableColumn<any>[]) || undefined;

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      <div>
        {mouse && <img className={s.bigImage} src={image} alt="" />}
        <DataTable
          conditionalRowStyles={
            userStatus === "trial" || userStatus === "unpaid"
              ? conditionalRowStyles
              : ""
          }
          className={"customTableYaroslav stickyCategory"}
          defaultSortFieldId={"revenue"}
          striped
          defaultSortAsc={false}
          columns={columns}
          data={table}
          subHeader
          fixedHeaderScrollHeight={newHeight}
          fixedHeader
          sortServer={true}
          sortIcon={null}
          // onChangePage={handlePageChange}
          onSort={handleSort}
          responsive={true}
          // noDataComponent={<div>{t("dataTable")}</div>}
          noDataComponent={<div>{""}</div>}
        />
        {tooltipData && (
          <img
            style={{
              position: "absolute",
              top: tooltipData.y - 27,
              left: tooltipData.x + 30,
              background: "white",
              padding: "4px",
              height: "250px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              zIndex: 10,
            }}
            src={tooltipData.value}
            alt=""
          />
        )}
        <TooltipInTable tooltipName={tooltipName} />
        <SuperPagination
          page={page}
          itemsCountForPage={count}
          totalCount={totalCountPage}
          onChange={onChangePagination}
        />
      </div>
    </>
  );
};
