import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
  Tooltip,
} from "reactstrap";
import { ModalWindow } from "src/components/ModalWindow/ModalWindow";
import {
  selectPaidTo,
  selectTariffName,
  selectUserStatus,
} from "src/features/App/selectors";
import { RegisterRequestType } from "src/pages/Authentication/Register";
import { Header } from "src/pages/Contacts/Header/Header";
import { IntegrationData } from "src/pages/Contacts/Integration-Data/Integration-Data";
import {
  selectEmail,
  selectPhone,
  selectTex,
  selectTokenGoogle,
  selectUserName,
} from "src/pages/Contacts/selectors";
import { passwordChangeTC } from "src/pages/Contacts/thunk";
import { useAppSelector } from "src/store/types";
import {
  changePasswordYpu,
  optionalInformation,
} from "src/utilitsFunction/schemas";
import { fetchTokenTC, initializeAppTC } from "src/features/App/thunk";
import { changeInformationTC } from "src/slices/Auth/Profile/reducer";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import "./UserSetting.module.css";
import avatar1 from "../../assets/images/profile.jpg";
import update from "../../assets/images/my/update.svg";
export type PasswordChangeRequestType = Omit<
  RegisterRequestType,
  "name" | "email"
>;

export type InformationDataRequestType = {
  name: string | null;
  tax: string | null;
  phone: string | null;
};

export const UserSetting = () => {
  document.title = "Restatos | User Settings";
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const userName = useAppSelector(selectUserName);
  const phone = useAppSelector(selectPhone);
  const tax = useAppSelector(selectTex);
  const tokenGoogle = useAppSelector(selectTokenGoogle);
  const email = useAppSelector(selectEmail);
  const tarifName = useAppSelector(selectTariffName);
  const userStatus = useAppSelector(selectUserStatus);
  const paidTo = useAppSelector(selectPaidTo);

  const formikInformation = useFormik({
    validationSchema: optionalInformation,
    initialValues: {
      name: userName,
      tax: tax,
      phone: phone,
    },
    onSubmit: async (values: InformationDataRequestType, { setSubmitting }) => {
      try {
        await dispatch(changeInformationTC(values));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
      // dispatch(changeInformationTC(values));
      //
    },
  });
  const formik = useFormik({
    validationSchema: changePasswordYpu,
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    onSubmit: async (values: PasswordChangeRequestType, { setSubmitting }) => {
      try {
        await dispatch(passwordChangeTC(values));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
      // await dispatch(passwordChangeTC(values));
      // setSubmitting(false);
    },
  });

  const getToken = () => {
    dispatch(fetchTokenTC());
  };
  const formattedDate = format(new Date(paidTo), "dd.MM.yyyy");
  return (
    <div
    // className="page-content"
    >
      <Container fluid>
        <Row>
          <Col xs="12">
            <ModalWindow
              open={open}
              setOpen={setOpen}
              placeholder={t("Enter the reason for unsubscribing")}
              title={t("Are you sure you want to unsubscribe?")}
              body={t(
                "Please specify the reason for canceling your subscription. It is important to us.",
              )}
              button={t("Unsubscribe")}
            />
            <div
              className="
            d-flex align-items-center justify-content-between"
            >
              {/*<h4 className="mb-0">{breadcrumbItem}</h4>*/}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  {/*<BreadcrumbItem*/}
                  {/*//   style={{*/}
                  {/*//     marginTop: "10px",*/}
                  {/*//     marginBottom: "10px",*/}
                  {/*//     marginLeft: "15px",*/}
                  {/*//   }}*/}
                  {/*//   active*/}
                  {/*// >*/}
                  {/*//   <Link to="/trendyol/categories">Trendyol</Link>*/}
                  {/*// </BreadcrumbItem>*/}
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    {/*<Link to="#">User Settings</Link>*/}
                    <span>User Settings</span>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Row className="col-xxl-3 col-lg-4">
            <Card>
              <CardBody>
                <div
                  className="profile-bg position-relative overflow-hidden rounded-top"
                  style={{ height: "150px" }}
                >
                  <div className="bg-overlay bg-dark bg-gradient"></div>
                </div>
                <div className="text-center">
                  <div className="position-relative d-inline-block mx-auto mt-n5 mb-4">
                    <div className="avatar-lg">
                      <img
                        src={avatar1}
                        alt=""
                        className="rounded-circle img-thumbnail"
                      />
                    </div>
                  </div>
                  <h5 className="font-size-16 mb-1">{userName}</h5>
                </div>
                <hr className="my-4" />
                <div>
                  <div className="mt-4">
                    <p className="text-muted mb-1">
                      {t("rate")}
                      {/*:*/}
                    </p>
                    <h5 className="font-size-14 text-truncate">
                      {userStatus === "unpaid" || userStatus === "trial" ? (
                        <>
                          {t("userStatus")}{" "}
                          <NavLink to={"https://restatos.com/price"}>
                            {t("Choose")}
                          </NavLink>
                        </>
                      ) : (
                        <>
                          {tarifName}{" "}
                          <NavLink onClick={() => setOpen(true)} to={"#"}>
                            {t("Unsubscribe")}
                          </NavLink>
                        </>
                      )}
                    </h5>
                  </div>
                  <div className="mt-4">
                    <p className="text-muted mb-1">
                      {t("ValidUntil")}
                      {/*:*/}
                    </p>
                    <h5 className="font-size-14 text-truncate">
                      {paidTo === null ? "-" : formattedDate}
                    </h5>
                  </div>
                  <div className="mt-4">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <p className="text-muted mb-1">{t("key")} </p>
                      <img
                        onClick={getToken}
                        id="TooltipExample"
                        style={{
                          cursor: "pointer",
                          width: "14px",
                          height: "14px",
                          marginBottom: "3px",
                        }}
                        src={update}
                        alt=""
                      />

                      <Tooltip
                        // onClick={getToken}
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggle}
                      >
                        {t("Get a new token")}
                      </Tooltip>
                    </div>
                    <h5 className="font-size-14 text-truncate">
                      {tokenGoogle}
                    </h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>

          <div className="col-xxl-9 col-lg-8">
            <Card>
              <CardBody>
                <h5 className="card-title mb-4">{t("setting")}</h5>
                <div className="card border shadow-none mb-5">
                  <Header number={1} title={t("general")} text={t("")} />

                  <CardBody>
                    <div>
                      <form onSubmit={formikInformation.handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="gen-info-name-input"
                              >
                                {t("name")}
                              </label>
                              <Input
                                disabled={formikInformation.isSubmitting}
                                className="form-control"
                                id="gen-info-name-input"
                                {...formikInformation.getFieldProps("name")}
                                invalid={
                                  // formikInformation.touched.name
                                  // &&
                                  formikInformation.errors.name ? true : false
                                }
                              />
                              {
                                // formikInformation.touched.name &&
                                formikInformation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {formikInformation.errors.name}
                                  </FormFeedback>
                                ) : null
                              }
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="gen-info-designation-input"
                              >
                                {t("taxNumber")}
                              </label>
                              <Input
                                disabled={formikInformation.isSubmitting}
                                type="text"
                                className="form-control"
                                // id="gen-info-designation-input"
                                placeholder={t("taxNumber")}
                                {...formikInformation.getFieldProps("tax")}
                                invalid={
                                  // formikInformation.touched.tex &&
                                  formikInformation.errors.tax ? true : false
                                }
                              />
                              {
                                // formikInformation.touched.tex &&
                                formikInformation.errors.tax ? (
                                  <FormFeedback type="invalid">
                                    {formikInformation.errors.tax}
                                  </FormFeedback>
                                ) : null
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="gen-info-designation-input"
                              >
                                {t("phone")}
                              </label>
                              <Input
                                disabled={formikInformation.isSubmitting}
                                className="form-control"
                                placeholder={t("phone")}
                                {...formikInformation.getFieldProps("phone")}
                                invalid={
                                  // formikInformation.touched.phone &&
                                  formikInformation.errors.phone ? true : false
                                }
                              />
                              {
                                // formikInformation.touched.phone &&
                                formikInformation.errors.phone ? (
                                  <FormFeedback type="invalid">
                                    {formikInformation.errors.phone}
                                  </FormFeedback>
                                ) : null
                              }
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="gen-info-designation-input"
                              >
                                {t("email")}
                              </label>
                              <Input
                                disabled
                                className="form-control"
                                id="gen-info-designation-input"
                                placeholder={email as string | undefined}
                                name="username"
                              />
                            </div>
                          </Col>
                          {/*<Col lg={6}>*/}
                          {/*  <div className="mb-3">*/}
                          {/*    /!*{t("key")}*!/*/}
                          {/*    /!*<div className="mt-4">*!/*/}
                          {/*    <div*/}
                          {/*      style={{*/}
                          {/*        display: "flex",*/}
                          {/*        flexDirection: "row",*/}
                          {/*        alignItems: "center",*/}
                          {/*        gap: "0px",*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      <label*/}
                          {/*        className="form-label"*/}
                          {/*        htmlFor="gen-info-designation-input"*/}
                          {/*      >*/}
                          {/*        {t("key")}*/}
                          {/*      </label>*/}
                          {/*      <img*/}
                          {/*        onClick={getToken}*/}
                          {/*        id="TooltipExample"*/}
                          {/*        style={{*/}
                          {/*          cursor: "pointer",*/}
                          {/*          width: "14px",*/}
                          {/*          height: "14px",*/}
                          {/*          marginBottom: "7px",*/}
                          {/*          // marginRight: "-5px",*/}
                          {/*        }}*/}
                          {/*        src={update}*/}
                          {/*        alt=""*/}
                          {/*      />*/}

                          {/*      <Tooltip*/}
                          {/*        // onClick={getToken}*/}
                          {/*        isOpen={tooltipOpen}*/}
                          {/*        target="TooltipExample"*/}
                          {/*        toggle={toggle}*/}
                          {/*      >*/}
                          {/*        {t("token")}*/}
                          {/*      </Tooltip>*/}
                          {/*      /!*</div>*!/*/}
                          {/*    </div>*/}
                          {/*    <Input*/}
                          {/*      disabled*/}
                          {/*      className="form-control"*/}
                          {/*      id="gen-info-designation-input"*/}
                          {/*      placeholder={tokenGoogle as string | undefined}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*</Col>*/}
                        </Row>
                        <div className="mt-3">
                          <button
                            disabled={
                              !formikInformation.isValid ||
                              formikInformation.isSubmitting
                            }
                            className="btn btn-info w-40"
                            type="submit"
                          >
                            {t("save")}
                          </button>
                        </div>
                      </form>

                      <div className="collapse" id="collapseChangePassword">
                        <div className="card border shadow-none card-body">
                          <Row>
                            <div className="col-lg-4">
                              <div className="mb-lg-0">
                                <label
                                  htmlFor="current-password-input"
                                  className="form-label"
                                >
                                  Current password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter Current password"
                                  id="current-password-input"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mb-lg-0">
                                <label
                                  htmlFor="new-password-input"
                                  className="form-label"
                                >
                                  New password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter New password"
                                  id="new-password-input"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mb-lg-0">
                                <label
                                  htmlFor="confirm-password-input"
                                  className="form-label"
                                >
                                  Confirm password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter Confirm password"
                                  id="confirm-password-input"
                                />
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
                <IntegrationData />

                <div className="card border shadow-none mb-5">
                  <Header
                    number={3}
                    title={t("security")}
                    text={t("recomendateValidation")}
                  />

                  <CardBody>
                    <form onSubmit={formik.handleSubmit}>
                      <input
                        type="hidden"
                        disabled
                        className="form-control"
                        id="email-invisible"
                        name="username"
                        value={email}
                      />

                      <div className="mb-3">
                        <label htmlFor="contact-info-email-input">
                          {t("newPassword")}
                        </label>
                        {/*<Input*/}
                        {/*  type="password"*/}
                        {/*  className="form-control"*/}
                        {/*  id="contact-info-email-input"*/}
                        {/*  placeholder={t("newPassword")}*/}
                        {/*  {...formik.getFieldProps("password")}*/}
                        {/*  onBlur={() => {}}*/}
                        {/*  invalid={*/}
                        {/*    formik.touched.password && !!formik.errors.password*/}
                        {/*  }*/}
                        {/*/>*/}

                        {/*{*/}
                        {/*  // formik.touched.password*/}
                        {/*  // &&*/}
                        {/*  formik.errors.password ? (*/}
                        {/*    <FormFeedback type="invalid">*/}
                        {/*      {formik.errors.password}*/}
                        {/*    </FormFeedback>*/}
                        {/*  ) : null*/}
                        {/*}*/}
                        <Input
                          autoComplete="on"
                          disabled={formik.isSubmitting}
                          placeholder={t("newPassword")}
                          id="Password1"
                          type="password"
                          required
                          {...formik.getFieldProps("password")}
                          invalid={
                            !!(
                              formik.touched.password && formik.errors.password
                            )
                          }
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <FormFeedback type="invalid">
                            {formik.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row>
                        <div className="mb-3">
                          <div className="mb-md-0">
                            <label
                              htmlFor="contact-info-website-input"
                              className="form-label"
                            >
                              {t("confirmPassword")}
                            </label>
                            {/*<Input*/}
                            {/*  type="password"*/}
                            {/*  className="form-control"*/}
                            {/*  placeholder={t("confirmPassword")}*/}
                            {/*  id="contact-info-website-input"*/}
                            {/*  {...formik.getFieldProps("passwordConfirm")}*/}
                            {/*  onBlur={() => {}}*/}
                            {/*  invalid={*/}
                            {/*    formik.touched.passwordConfirm &&*/}
                            {/*    !!formik.errors.passwordConfirm*/}
                            {/*  }*/}
                            {/*/>*/}

                            {/*{*/}
                            {/*  // formik.touched.passwordConfirm*/}
                            {/*  // &&*/}
                            {/*  formik.errors.passwordConfirm ? (*/}
                            {/*    <FormFeedback type="invalid">*/}
                            {/*      {formik.errors.passwordConfirm}*/}
                            {/*    </FormFeedback>*/}
                            {/*  ) : null*/}
                            {/*}*/}
                            <Input
                              autoComplete="on"
                              disabled={formik.isSubmitting}
                              placeholder={t("confirmPassword")}
                              id="passwordConfirm"
                              type="password"
                              required
                              {...formik.getFieldProps("passwordConfirm")}
                              invalid={
                                !!(
                                  formik.touched.passwordConfirm &&
                                  formik.errors.passwordConfirm
                                )
                              }
                            />
                            {formik.touched.passwordConfirm &&
                            formik.errors.passwordConfirm ? (
                              <FormFeedback type="invalid">
                                {formik.errors.passwordConfirm}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3">
                            <button
                              disabled={!formik.isValid || formik.isSubmitting}
                              className="btn btn-info w-40"
                              type="submit"
                            >
                              {t("save")}
                            </button>
                          </div>
                        </div>
                      </Row>
                    </form>
                  </CardBody>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
