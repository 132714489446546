import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { useAppSelector } from "src/store/types";

const WidgetBlockCategory = ({ selector, dataSelector }: any) => {
  const status = useAppSelector(selector);
  const data: any = useAppSelector(dataSelector);
  const { t, i18n } = useTranslation();

  // const formatValue = (val: any) => {
  //   if (val === undefined) {
  //     return <span className="placeholder" style={{ width: "25%" }}></span>; // Handle undefined case as needed
  //   }
  //   // if (val >= 1000000) {
  //   //   return (val / 1000000).toFixed(2).replace(".", ",") + "M";
  //   // } else if (val >= 1000) {
  //   //   return (val / 1000).toFixed(1).replace(".", ",") + "k";
  //   // }
  //
  //   return val.toLocaleString("ru-RU"); // если значение меньше 1000, просто форматируем с разделителями тысяч
  // };
  const formatValue = (val: any) => {
    if (val === undefined || val === null) {
      return <span className="placeholder" style={{ width: "25%" }}></span>; // Handle undefined or null case as needed
    }

    if (typeof val !== "number") {
      return val; // Return val as is if it's not a number (optional, depending on your use case)
    }

    return val.toLocaleString("ru-RU"); // Format number with Russian locale
  };
  return (
    <Col xl={3} sm={6} className="px-1">
      <Card
        style={{
          margin: "5px",
          position: "relative",
          opacity: status === "loading" ? 0.5 : "",
          pointerEvents: status === "loading" ? "none" : undefined,
        }}
      >
        <CardBody
          style={{
            minHeight: "207px", // Минимальная высота
            display: "block",
            marginTop: "3px",
          }}
        >
          {status === "loading" && (
            <CustomSpinner absolute={true} top={"50%"} />
          )}
          <h6 className="font-size-xs text-uppercase">{t("median price")}</h6>
          <div className="row align-items-center">
            <div className="col-sm-12">
              {/*{data.now !== undefined && data.prev !== undefined && (*/}
              <div className="row mb-3">
                <div className="col-6">
                  <p className="text-muted mb-2">{t("This Period")}</p>
                  <h5>{formatValue(data.now)}</h5>
                  {/*<h5>{data.now}</h5>*/}
                </div>
                <div className="col-6">
                  <p className="text-muted mb-2">{t("Last Period")}</p>
                  <h5>
                    {formatValue(data.prev)}
                    {/*{data.prev}*/}
                    <p></p>
                  </h5>
                </div>
              </div>
              <p style={{ marginTop: "-25px" }}>
                <span
                  style={{
                    marginRight: "3px",
                    color: data.increment < 0 ? "#f34e4e" : "#39cb5b",
                  }}
                >
                  {data.increment === undefined ? (
                    <span
                      className="placeholder"
                      style={{ width: "10%" }}
                    ></span>
                  ) : data.increment < 0 ? (
                    <>
                      {Math.abs(data.increment) + "%"}
                      <i
                        className="uil uil-arrow-down text-danger"
                        style={{ verticalAlign: "middle" }}
                      ></i>
                    </>
                  ) : (
                    <>
                      {data.increment + "%"}
                      <i
                        className="uil uil-arrow-up text-success"
                        style={{ verticalAlign: "middle" }}
                      ></i>
                    </>
                  )}
                </span>
                <span style={{ fontSize: "12px" }}>
                  {data.increment !== undefined
                    ? " " + t("From previous period")
                    : " "}
                </span>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WidgetBlockCategory;
