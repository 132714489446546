import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetCard from "src/components/Widgets/WidgetCard/WidgetCard";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import WidgetBlockCategory from "src/features/CategoryTable/miniCategory/WidgetBlockCategory/WidgetBlockCategory";
import WidgetBlockCategoryTwo from "src/features/CategoryTable/miniCategory/WidgetBlockCategoryTwo/WidgetBlockCategoryTwo";
import { resetCategoryTableState } from "src/features/CategoryTable/reducer";
import {
  fetchDataBrands,
  fetchDataPriceAvg,
  fetchDataProducts,
  fetchDataRevenue,
  fetchDataSellers,
  fetchExcelDataCategoryTable,
} from "src/features/CategoryTable/thunk";
import {
  clearTableCategoryBrands,
  onChangePhraseCategoryBrandsTable,
  setAscDescCategoryBrandsTable,
  setOrderNameCategoryBrandsTable,
  setPageCategoryBrandsTable,
  setStatusCategoryBrandsTable,
} from "src/features/CategoryTableBrands/reducer";
import {
  selectAscDescBrandsTable,
  selectColumnNameBrands,
  selectDataBrandsTable,
  selectNameCategoryBrandsTable,
  selectOrderNameBrandsTable,
  selectPageBrandsTable,
  selectStatusCategoryTableBrandsTable,
  selectTotalCountPageBrandsTable,
} from "src/features/CategoryTableBrands/selectors";
import s from "src/features/CategoryTable/Category.module.css";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";
import {
  selectCategoryId,
  selectChoiceBrandAndSellers,
  selectDataBrandAndSellers,
  selectDataPriceAvg,
  selectDataProducts,
  selectDataRevenue,
  selectNameUrl,
  selectStatusBrandAndSellers,
  selectStatusDataRevenue,
  selectStatusPriceAvg,
  selectStatusProducts,
} from "src/features/CategoryTable/selectors";
import { InputsType } from "src/features/CategoryTable/type";
import {
  fetchCategoryBrandsTable,
  fetchExcelCategoryBrandsTable,
} from "src/features/CategoryTableBrands/thunk";
import TableDescription from "src/features/CategoryTableBrands/table-description";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const CategoryTableBrands = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { category_url } = useParams();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: "" },
  ]);
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectDataBrandsTable);
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [count, setCount] = useState<number>(100);
  const columnNameBrands = useAppSelector(selectColumnNameBrands);
  const nameCategory = useAppSelector(selectNameCategoryBrandsTable);
  const orderColumnName = useAppSelector(selectOrderNameBrandsTable);
  const ascDesc = useAppSelector(selectAscDescBrandsTable);
  const page = useAppSelector(selectPageBrandsTable);
  const totalCountPage = useAppSelector(selectTotalCountPageBrandsTable);
  const statusBrandsTable = useAppSelector(
    selectStatusCategoryTableBrandsTable,
  );
  const nameUrl = useAppSelector(selectNameUrl);
  const dateSelect = useAppSelector(selectDateSelect);
  const userStatus = useAppSelector(selectUserStatus);

  const param: any = {
    category_url,
    columnNameBrands,
    orderColumnName,
    ascDesc,
    page,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };
  const categoryId = useAppSelector(selectCategoryId);
  document.title = `Restatos |  ${
    categoryId === category_url && currentLanguage === "tr" ? nameCategory : ""
  }
                    ${t("AnalyticalReportByCategory")}
                    ${
                      categoryId === category_url && currentLanguage !== "tr"
                        ? nameCategory
                        : ""
                    }`;

  async function fetchData() {
    const resName = await instance.get<
      FetchResponseTable<Array<{ message: string; path: string }>>
    >(`trendyol/category/name/${category_url}`);
    dispatch(setCategoryId({ categoryId: category_url }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== category_url) {
    fetchData();
  }

  useEffect(() => {
    dispatch(fetchCategoryBrandsTable(param));
    dispatch(fetchDataRevenue(param));
    dispatch(fetchDataPriceAvg(param));
    dispatch(fetchDataProducts(param));
  }, [
    category_url,
    columnNameBrands,
    orderColumnName,
    ascDesc,
    page,
    dateSelect[0],
    dateSelect[1],
  ]);

  useEffect(() => {
    dispatch(setPageCategoryBrandsTable({ page: 1 }));
  }, [ascDesc, orderColumnName, columnNameBrands]);

  useEffect(() => {
    return () => {
      dispatch(clearTableCategoryBrands({}));
      dispatch(resetCategoryTableState({}));

      dispatch(clearDate({}));
    };
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(setPageCategoryBrandsTable({ page: newPage }));
    setCount(newCount);
  };
  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(
      setOrderNameCategoryBrandsTable({
        orderName: column.selector,
      }),
    );
    dispatch(setAscDescCategoryBrandsTable({ ascDesc: sortDirection }));
  }, []);
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    if (value === "") {
      dispatch(
        setStatusCategoryBrandsTable({
          status: "brands-loading",
        }),
      );
      dispatch(onChangePhraseCategoryBrandsTable({ columnName: [] }));
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    setInputs(updatedInputs);

    timerId && clearTimeout(timerId);

    const id = +setTimeout(() => {
      dispatch(
        onChangePhraseCategoryBrandsTable({ columnName: updatedInputs }),
      );
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };
  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhraseCategoryBrandsTable({ columnName: inputs }));
    }
  };
  const [tooltipName, setTooltipName] = useState<any>(null);

  const columns = [
    {
      name: t("name"),
      // selector: (row) => row.name,
      selector: "name",
      title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/brand/${row.id}/products`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("revenueSharePercent"),
      selector: "revenueSharePercent",
      format: (row: { revenueSharePercent: any }) =>
        row.revenueSharePercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("revenueInCategory"),
      selector: "revenueInCategory",
      id: "revenueInCategory",
      sortable: true,
      format: (row: { revenueInCategory: any }) =>
        (row.revenueInCategory / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("revenueTotal"),
      // selector: (row) => row.price,
      selector: "revenueTotal",

      format: (row: { revenueTotal: any }) =>
        (row.revenueTotal / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },

    {
      name: t("soldInCategory"),
      selector: "soldInCategory",
      format: (row: { soldInCategory: any }) =>
        row.soldInCategory.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("soldTotal"),
      selector: "soldTotal",
      format: (row: { soldTotal: any }) =>
        row.soldTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareSoldInCategoryFromAllSalesPercent"),
      selector: "shareSoldInCategoryFromAllSalesPercent",
      format: (row: { shareSoldInCategoryFromAllSalesPercent: any }) =>
        row.shareSoldInCategoryFromAllSalesPercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },

    {
      name: t("countProdInCategory"),
      selector: "countProdInCategory",
      format: (row: { countProdInCategory: any }) =>
        row.countProdInCategory
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSold"),
      selector: "prodWithSold",
      format: (row: { prodWithSold: any }) =>
        row.prodWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSoldPercent"),
      selector: "prodWithSoldPercent",
      format: (row: { prodWithSoldPercent: any }) =>
        row.prodWithSoldPercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("countProdTotal"),
      selector: "countProdTotal",
      format: (row: { countProdTotal: any }) =>
        row.countProdTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // countProdSharePercent сделать float
    {
      name: t("countProdSharePercent"),
      selector: "countProdSharePercent",
      format: (row: { countProdSharePercent: any }) =>
        row.countProdSharePercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("avgSoldPerProdTotal"),
      selector: "avgSoldPerProdTotal",
      format: (row: { avgSoldPerProdTotal: any }) =>
        row.avgSoldPerProdTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgSoldPerProdWithSold"),
      selector: "avgSoldPerProdWithSold",
      format: (row: { avgSoldPerProdWithSold: any }) =>
        row.avgSoldPerProdWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgPrice"),
      // selector: (row) => row.price,
      selector: "avgPrice",
      format: (row: { avgPrice: any }) =>
        (row.avgPrice / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("categoryCount"),
      selector: "categoryCount",
      format: (row: { categoryCount: any }) =>
        row.categoryCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: <div style={{ marginLeft: "9px" }}>{t("chart")}</div>,
      cell: (row: { chart: Array<number> }) => (
        <div style={{ marginLeft: "-16px" }}>
          <D3BarChart data={row.chart} />
        </div>
      ),
    },
  ] as any;
  const [modal_standard, setmodal_standard] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );
  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />
      {statusBrandsTable === "brands-loading" && (
        <CustomSpinner absolute={true} top={"600px"} />
      )}
      <div
      // className={statusBrandsTable === "brands-loading" ? s.opasity : ""}
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    <Link
                      // target="_blank"
                      to="/trendyol/category-list"
                    >
                      Categories
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === category_url && currentLanguage === "tr" ? (
                      <> {nameCategory}&nbsp;</>
                    ) : null}
                    {t("AnalyticalReportByCategory")}&nbsp;
                    {categoryId === category_url && currentLanguage !== "tr"
                      ? nameCategory
                      : null}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{ background: "white", fontSize: "20px", paddingLeft: "10px" }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === category_url && currentLanguage === "tr" ? (
                <> {nameCategory}&nbsp;</>
              ) : null}
              {t("AnalyticalReportByCategory")}&nbsp;
              {categoryId === category_url && currentLanguage !== "tr"
                ? nameCategory
                : null}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelCategoryBrandsTable(param))}
              />
            </span>
            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <div className="row" style={{ marginBottom: "2px", marginTop: "5px" }}>
          <WidgetCard
            bar={true}
            selector={selectStatusDataRevenue}
            dataSelector={selectDataRevenue}
            id={category_url}
            title={t("revenue")}
            // subtitle={"Бабло"}
            select={false}
          />
          <WidgetBlockCategory
            id={category_url}
            selector={selectStatusPriceAvg}
            dataSelector={selectDataPriceAvg}
          />
          <WidgetBlockCategoryTwo
            title={t("brands and sellers")}
            id={category_url}
            selector={selectStatusBrandAndSellers}
            dataSelector={selectDataBrandAndSellers}
            selectDropdown={true}
            selectChoiceBrandAndSellers={selectChoiceBrandAndSellers}
            fetchDataBrands={fetchDataBrands}
            fetchDataSellers={fetchDataSellers}
          />
          <WidgetBlockCategoryTwo
            title={t("products")}
            id={category_url}
            selector={selectStatusProducts}
            dataSelector={selectDataProducts}
            selectDropdown={false}
          />
        </div>
        <div
          className={statusBrandsTable === "brands-loading" ? s.opasity : ""}
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <NavLink
              to={`/trendyol/category/${category_url}/products`}
              type="button"
              className="btn-sm btn
          btn-light
            "
              style={{ marginTop: "10px" }}
            >
              {t("products")}
            </NavLink>
            <NavLink
              to={`/trendyol/category/${category_url}/sellers`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("sellers")}
            </NavLink>

            <a
              href={`/trendyol/category/${category_url}/brands`}
              className="btn-sm btn btn-primary"
              style={{ marginTop: "10px" }}
            >
              {t("brands")}
            </a>
            <NavLink
              to={`/trendyol/price/${category_url}`}
              type="button"
              className="btn-sm btn btn-light"
              style={{
                marginTop: "10px",
              }}
            >
              {t("Price segments")}
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <i
                onClick={() => setmodal_standard(true)}
                className="uil-question-circle "
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
              {inputs.map((input, index) => (
                // TODO
                <CustomInput
                  id={input.id}
                  disabled={statusBrandsTable === "brands-loading"}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  onKeyPress={onKeyPress}
                />
              ))}
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav tableList ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"revenueInCategory"}
            defaultSortAsc={false}
            title={t("")}
            columns={columns}
            data={data}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            sortServer={true}
            sortIcon={null}
            responsive={true}
            onSort={handleSort}
            // noDataComponent={<div>{t("dataTable")}</div>}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          <TooltipInTable tooltipName={tooltipName} />
          <SuperPagination
            page={page}
            itemsCountForPage={count}
            totalCount={totalCountPage}
            onChange={onChangePagination}
          />
          {/*<Row>*/}
          {/*  <Col lg={6}>*/}
          {/*    <Card>*/}
          {/*      <CardHeader className="justify-content-between d-flex align-items-center">*/}
          {/*        /!*<h4 className="card-title">Simple Pie Chart</h4>*!/*/}
          {/*      </CardHeader>*/}
          {/*      <CardBody>*/}
          {/*        <div id="simple_pie_chart" className="apex-charts" dir="ltr">*/}
          {/*          <Simple />*/}
          {/*        </div>*/}
          {/*      </CardBody>*/}
          {/*    </Card>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
