import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";

import { Card, CardBody, Col, Row, Container } from "reactstrap";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import {
  clearFeedback,
  setPage,
} from "src/features/product/miniProductComponent/feedback/reducer";
import {
  selectFeedback,
  selectPage,
  selectStatus,
  selectTotalCountPage,
} from "src/features/product/miniProductComponent/feedback/selectors";
import {
  fetchExcelFeedback,
  fetchFeedback,
} from "src/features/product/miniProductComponent/feedback/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const Feedback = ({ id, merchantId }: any) => {
  const [count, setCount] = useState<number>(100);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateSelect = useAppSelector(selectDateSelect);
  const data = useAppSelector(selectFeedback);
  const status = useAppSelector(selectStatus);
  const page = useAppSelector(selectPage);
  const totalCountPage = useAppSelector(selectTotalCountPage);
  const userStatus = useAppSelector(selectUserStatus);

  let param = {
    id: id,
    merchantId: merchantId,
    page: page,
    // startDate: dateSelect[0],
    // endDate: dateSelect[1],
  };

  useEffect(() => {
    dispatch(fetchFeedback(param));
  }, [
    id,
    merchantId,
    // dateSelect[0], dateSelect[1]
  ]);

  useEffect(() => {
    dispatch(clearFeedback({}));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(setPage({ page: newPage }));
    setCount(newCount);
  };
  return (
    <React.Fragment>
      <ExcelButton action={() => dispatch(fetchExcelFeedback(param))} />
      <div className="page-content">
        {status === "merchantTable-loading" && (
          <CustomSpinner absolute={true} top={"600px"} />
        )}
        {/*<CardHeader className="d-flex justify-content-between align-items-center  ">*/}
        {/*  <div className="h4 card-title">*/}
        {/*    <ExcelButton*/}
        {/*      action={() => dispatch(fetchExcelDataBrandsTableProduct(param))}*/}
        {/*    />*/}
        {/*    /!*{t("Adverts")}*!/*/}
        {/*    /!*<Tooltip*!/*/}
        {/*    /!*  placement="top"*!/*/}
        {/*    /!*  isOpen={top}*!/*/}
        {/*    /!*  target="Adverts"*!/*/}
        {/*    /!*  toggle={() => {*!/*/}
        {/*    /!*    setTop(!top);*!/*/}
        {/*    /!*  }}*!/*/}
        {/*    /!*>*!/*/}
        {/*    /!*  {t("AdvertsTooltip")}*!/*/}
        {/*    /!*</Tooltip>*!/*/}
        {/*    /!*<i*!/*/}
        {/*    /!*  className="uil-question-circle"*!/*/}
        {/*    /!*  style={{ marginLeft: "3px", cursor: "pointer" }}*!/*/}
        {/*    /!*  id={"Adverts"}*!/*/}
        {/*    /!*></i>*!/*/}
        {/*  </div>*/}
        {/*</CardHeader>*/}
        <Container fluid>
          {/*<ExcelButton*/}
          {/*  action={() => dispatch(fetchExcelDataBrandsTableProduct(param))}*/}
          {/*/>*/}
          {/* Render Breadcrumbs */}
          {/*<Breadcrumbs title="Timeline" breadcrumbItem="Center Timeline" />*/}
          <Row className="justify-content-center">
            <Col xl={10}>
              <div className="verti-timeline">
                {Array.isArray(data) &&
                  (data as any).map((el: any) => {
                    return (
                      <div
                        className={
                          el.rating > 3 ? `timeline-item left` : "timeline-item"
                        }
                      >
                        <div className="timeline-block">
                          <Card
                            className={
                              el.rating <= 3
                                ? "timeline-box timeline-red-point"
                                : "timeline-box"
                            }
                          >
                            <CardBody>
                              <div className="timeline-date">
                                {/*{el.created_date}*/}
                                {el.datetime}
                              </div>
                              <h5 className="mt-3 font-size-16">
                                {" "}
                                {el.user_full_name}
                                {/*Timeline Event One*/}
                              </h5>
                              <div className="text-muted">
                                <p className="mb-0">
                                  {el.comment}
                                  {/*It will be as simple as occidental in fact. To an*/}
                                  {/*english person, it will seem like simplified*/}
                                  {/*English, as a skeptical friend*/}
                                </p>
                              </div>
                              <div className="text-muted">
                                <p className="mb-0">
                                  {/*<Rating*/}
                                  {/*  stop={5}*/}
                                  {/*  initialRating={el.rating}*/}
                                  {/*  emptySymbol="mdi mdi-star-outline text-muted"*/}
                                  {/*  fullSymbol="mdi mdi-star"*/}
                                  {/*  className="rating-symbol-background color-text-restatos"*/}
                                  {/*/>{" "}*/}
                                  {/*<Rating*/}
                                  {/*  stop={5}*/}
                                  {/*  initialRating={el.rating}*/}
                                  {/*  emptySymbol={*/}
                                  {/*    <span*/}
                                  {/*      className="mdi mdi-star-outline text-muted"*/}
                                  {/*      style={{ pointerEvents: "none" }}*/}
                                  {/*    />*/}
                                  {/*  }*/}
                                  {/*  fullSymbol={*/}
                                  {/*    <span*/}
                                  {/*      className="mdi mdi-star"*/}
                                  {/*      style={{ pointerEvents: "none" }}*/}
                                  {/*    />*/}
                                  {/*  }*/}
                                  {/*  className="rating-symbol-background color-text-restatos"*/}
                                  {/*/>*/}

                                  <Rating
                                    stop={5}
                                    initialRating={el.rating}
                                    emptySymbol="mdi mdi-star-outline text-muted"
                                    fullSymbol="mdi mdi-star"
                                    className={
                                      el.rating > 3
                                        ? "rating-symbol-background color-text-restatos"
                                        : "rating-symbol-background text-danger"
                                    }
                                    readonly
                                  />
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
                {/*                {Array.isArray(data) &&*/}
                {/*                  (data as any).map((el: any) => {*/}
                {/*                    return (*/}
                {/*<<<<<<< HEAD*/}
                {/*                      <div className={`timeline-item left`}>*/}
                {/*                        <div className="timeline-block">*/}
                {/*                          <Card className="timeline-box">*/}
                {/*                            <CardBody>*/}
                {/*<<<<<<< HEAD*/}
                {/*                    */}
                {/*                    */}
                {/*                              <div className="timeline-date">*/}
                {/*                                /!*{el.created_date}*!/*/}
                {/*                                {el.datetime}*/}
                {/*                              </div>*/}
                {/*=======*/}
                {/*                              <div className="timeline-date">{el.datetime}</div>*/}
                {/*>>>>>>> d59621d955674f0ac22bb25b0c79f0625b9c6c28*/}
                {/*                              <h5 className="mt-3 font-size-16">*/}
                {/*                                {" "}*/}
                {/*                                {el.user_full_name}*/}
                {/*                                /!*Timeline Event One*!/*/}
                {/*                              </h5>*/}
                {/*                              <div className="text-muted">*/}
                {/*                                <p className="mb-0">*/}
                {/*                                  {el.comment}*/}
                {/*                                  /!*It will be as simple as occidental in fact. To an*!/*/}
                {/*                                  /!*english person, it will seem like simplified*!/*/}
                {/*                                  /!*English, as a skeptical friend*!/*/}
                {/*                                </p>*/}
                {/*                              </div>*/}
                {/*                              <div className="text-muted">*/}
                {/*                                <p className="mb-0">*/}
                {/*                                  <Rating*/}
                {/*                                    stop={5}*/}
                {/*                                    initialRating={el.rating}*/}
                {/*                                    emptySymbol="mdi mdi-star-outline text-muted"*/}
                {/*                                    fullSymbol="mdi mdi-star"*/}
                {/*                                    className="rating-symbol-background color-text-restatos"*/}
                {/*                                  />{" "}*/}
                {/*                                </p>*/}
                {/*                              </div>*/}
                {/*                            </CardBody>*/}
                {/*                          </Card>*/}
                {/*                        </div>*/}
                {/*                      </div>*/}
                {/*                    );*/}
                {/*                  })}*/}
                {/*                {(data as any)["negative"] &&*/}
                {/*                  (data as any)["negative"].map((el: any) => {*/}
                {/*                    return (*/}
                {/*                      <div className={`timeline-item`}>*/}
                {/*=======*/}
                {/*                      <div*/}
                {/*                        className={*/}
                {/*                          el.rating > 3 ? `timeline-item left` : "timeline-item"*/}
                {/*                        }*/}
                {/*                      >*/}
                {/*>>>>>>> b9f0c63bdee87bb6515783fd141abfb7cd5da4fa*/}
                {/*                        <div className="timeline-block">*/}
                {/*                          <Card className="timeline-box">*/}
                {/*                            <CardBody>*/}
                {/*                              <div className="timeline-date">{el.datetime}</div>*/}
                {/*                              <h5 className="mt-3 font-size-16">*/}
                {/*                                {" "}*/}
                {/*                                {el.user_full_name}*/}
                {/*                                /!*Timeline Event One*!/*/}
                {/*                              </h5>*/}
                {/*                              <div className="text-muted">*/}
                {/*                                <p className="mb-0">*/}
                {/*                                  {el.comment}*/}
                {/*                                  /!*It will be as simple as occidental in fact. To an*!/*/}
                {/*                                  /!*english person, it will seem like simplified*!/*/}
                {/*                                  /!*English, as a skeptical friend*!/*/}
                {/*                                </p>*/}
                {/*                              </div>*/}
                {/*                              <div className="text-muted">*/}
                {/*                                <p className="mb-0">*/}
                {/*                                  <Rating*/}
                {/*                                    stop={5}*/}
                {/*                                    initialRating={el.rating}*/}
                {/*                                    emptySymbol="mdi mdi-star-outline text-muted"*/}
                {/*                                    fullSymbol="mdi mdi-star text-danger"*/}
                {/*                                    className="rating-symbol-background"*/}
                {/*                                  />{" "}*/}
                {/*                                </p>*/}
                {/*                              </div>*/}
                {/*                            </CardBody>*/}
                {/*                          </Card>*/}
                {/*                        </div>*/}
                {/*                      </div>*/}
                {/*                    );*/}
                {/*                  })}*/}

                {/*{(data as any)["negative"] &&*/}
                {/*  (data as any)["negative"].map((el: any) => {*/}
                {/*    return (*/}
                {/*      <div className={`timeline-item`}>*/}
                {/*        <div className="timeline-block">*/}
                {/*          <Card className="timeline-box">*/}
                {/*            <CardBody>*/}
                {/*              <div className="timeline-date">*/}
                {/*                {el.created_date}*/}
                {/*              </div>*/}
                {/*              <h5 className="mt-3 font-size-16">*/}
                {/*                {" "}*/}
                {/*                {el.user_full_name}*/}
                {/*                /!*Timeline Event One*!/*/}
                {/*              </h5>*/}
                {/*              <div className="text-muted">*/}
                {/*                <p className="mb-0">*/}
                {/*                  {el.comment}*/}
                {/*                  /!*It will be as simple as occidental in fact. To an*!/*/}
                {/*                  /!*english person, it will seem like simplified*!/*/}
                {/*                  /!*English, as a skeptical friend*!/*/}
                {/*                </p>*/}
                {/*              </div>*/}
                {/*            </CardBody>*/}
                {/*          </Card>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*//*/}
                {/*<div className="timeline-item left">*/}
                {/*  <div className="timeline-block">*/}
                {/*    <div className="time-show-btn mt-0">*/}
                {/*      <Link to="#" className="btn btn-danger btn-rounded w-lg">*/}
                {/*        2021*/}
                {/*      </Link>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*{arr.map((el) => {*/}
                {/*  return (*/}
                {/*    <div*/}
                {/*      className={`timeline-item ${*/}
                {/*        el.rating > "4" ? "left" : ""*/}
                {/*      }`}*/}
                {/*    >*/}
                {/*      <div className="timeline-block">*/}
                {/*        <Card className="timeline-box">*/}
                {/*          <CardBody>*/}
                {/*            <div className="timeline-date">{el.date}</div>*/}
                {/*            <h5 className="mt-3 font-size-16">*/}
                {/*              {" "}*/}
                {/*              {el.title}*/}
                {/*              /!*Timeline Event One*!/*/}
                {/*            </h5>*/}
                {/*            <div className="text-muted">*/}
                {/*              <p className="mb-0">*/}
                {/*                {el.subTitle}*/}
                {/*                /!*It will be as simple as occidental in fact. To an*!/*/}
                {/*                /!*english person, it will seem like simplified*!/*/}
                {/*                /!*English, as a skeptical friend*!/*/}
                {/*              </p>*/}
                {/*            </div>*/}
                {/*          </CardBody>*/}
                {/*        </Card>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  );*/}
                {/*})}*/}
                {/*<div className="timeline-item left">*/}
                {/*  <div className="timeline-block">*/}
                {/*    <Card className="timeline-box">*/}
                {/*      <CardBody>*/}
                {/*        <div className="timeline-date">21 April</div>*/}
                {/*        <h5 className="mt-3 font-size-16">*/}
                {/*          {" "}*/}
                {/*          Timeline Event Two*/}
                {/*        </h5>*/}
                {/*        <div className="text-muted">*/}
                {/*          <p className="mb-0">*/}
                {/*            To achieve this, it would be necessary to have more*/}
                {/*            common words.*/}
                {/*          </p>*/}
                {/*        </div>*/}
                {/*      </CardBody>*/}
                {/*    </Card>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="timeline-item">*/}
                {/*  <div className="timeline-block">*/}
                {/*    <Card className="timeline-box">*/}
                {/*      <CardBody>*/}
                {/*        <div className="timeline-date">15 April</div>*/}
                {/*        <h5 className="mt-3 font-size-16">*/}
                {/*          {" "}*/}
                {/*          Timeline Event Three*/}
                {/*        </h5>*/}
                {/*        <div className="text-muted">*/}
                {/*          <p>*/}
                {/*            The new common language will be more simple and*/}
                {/*            regular than the existing European languages be as*/}
                {/*            simple as occidental*/}
                {/*          </p>*/}
                {/*        </div>*/}
                {/*        <div className="timeline-album">*/}
                {/*          <Link to="#">*/}
                {/*            <img src={img2} alt="" />*/}
                {/*          </Link>*/}
                {/*          <Link to="#">*/}
                {/*            <img src={img3} alt="" />*/}
                {/*          </Link>*/}
                {/*          <Link to="#">*/}
                {/*            <img src={img4} alt="" />*/}
                {/*          </Link>*/}
                {/*        </div>*/}
                {/*      </CardBody>*/}
                {/*    </Card>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="timeline-item left">*/}
                {/*  <div className="timeline-block">*/}
                {/*    <Card className="timeline-box">*/}
                {/*      <CardBody>*/}
                {/*        <div className="timeline-date">09 April</div>*/}
                {/*        <h5 className="mt-3 font-size-16">*/}
                {/*          {" "}*/}
                {/*          Timeline Event Four*/}
                {/*        </h5>*/}
                {/*        <div className="text-muted">*/}
                {/*          <p className="mb-0">*/}
                {/*            Sed ut perspiciatis unde omnis iste natus error sit*/}
                {/*            voluptatem accusantium doloremque laudantium, ab*/}
                {/*            illo inventore veritatis et*/}
                {/*          </p>*/}
                {/*        </div>*/}
                {/*      </CardBody>*/}
                {/*    </Card>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="timeline-item">*/}
                {/*  <div className="timeline-block">*/}
                {/*    <Card className="timeline-box">*/}
                {/*      <CardBody>*/}
                {/*        <div className="timeline-date">02 April</div>*/}
                {/*        <h5 className="mt-3 font-size-16">*/}
                {/*          {" "}*/}
                {/*          Timeline Event Five*/}
                {/*        </h5>*/}
                {/*        <div className="text-muted">*/}
                {/*          <p className="mb-0">*/}
                {/*            Itaque earum rerum hic tenetur a sapiente delectus,*/}
                {/*            ut aut doloribus asperiores.*/}
                {/*          </p>*/}
                {/*        </div>*/}
                {/*      </CardBody>*/}
                {/*    </Card>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              <SuperPagination
                page={page}
                itemsCountForPage={count}
                totalCount={totalCountPage}
                onChange={onChangePagination}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
