import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setStatusTableSearchPosition,
  setTableSearchPosition,
} from "src/features/product/miniProductComponent/table/tableSearchPosition/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchSearchPositionTableProduct = createAsyncThunk<
  any,
  { id: string; dateOne: string; dateTwo: string }
>(
  "searchTableProduct/fetchSearchPositionTableProduct",
  async ({ id, dateOne, dateTwo }, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableSearchPosition({
        status: "tableSearchPosition-loading",
      }),
    );
    try {
      const res = await productAPI.fetchSearchPositionTableProduct(
        id,
        dateOne,
        dateTwo,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTableSearchPosition({ table: res.data.data }));

        thunkAPI.dispatch(
          setStatusTableSearchPosition({ status: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusTableSearchPosition({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
