import { createAsyncThunk } from "@reduxjs/toolkit";
import { priceSegmentsTableAPI } from "src/api/api-price-segments-table/api-price-segments-table";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setPriceSegmentsTable,
  setStatusPriceSegmentsTable,
} from "src/features/Price-Segments-Table/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchPriceSegmentsTable = createAsyncThunk<any, any>(
  "priceSegmentsTable/fetchPriceSegmentsTable",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusPriceSegmentsTable({
        status: "priceSegmentsTable-loading",
      }),
    );
    try {
      const res = await priceSegmentsTableAPI.priceSegmentsTable(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setPriceSegmentsTable({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusPriceSegmentsTable({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusPriceSegmentsTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
