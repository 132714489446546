import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setSellerData,
  setStatusSeller,
} from "src/features/product/miniProductComponent/seller/reducer";
import { setStatusVariant } from "src/features/product/miniProductComponent/variants/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchSellerProduct = createAsyncThunk<
  any,
  {
    id: string | null;
    itemNumberUrl: string | null;
    startDate: string | null;
    endDate: string | null;
  }
>(
  "sellerProduct/fetchSellerProduct",
  async ({ id, itemNumberUrl, startDate, endDate }, thunkAPI) => {
    thunkAPI.dispatch(setStatusSeller({ status: "seller-loading" }));
    try {
      const res = await productAPI.fetchSellerProduct(
        id,
        itemNumberUrl,
        startDate,
        endDate,
      );

      if (res.data.success === true) {
        thunkAPI.dispatch(setSellerData({ sellerData: res.data.message }));
        thunkAPI.dispatch(setStatusSeller({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusVariant({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      // dispatch(setStatusSeller({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
