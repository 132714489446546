import React from "react";
import { Pagination } from "@mui/material";
import s from "./SuperPagination.module.css";

export type SuperPaginationPropsType = {
  id?: string;
  page: number;
  itemsCountForPage: number;
  totalCount: number | null;
  onChange: (page: number, count: number) => void;
};

export const SuperPagination: React.FC<SuperPaginationPropsType> = ({
  page,
  itemsCountForPage,
  totalCount,
  onChange,
}) => {
  const lastPage = Math.ceil(totalCount! / itemsCountForPage);

  const onChangeCallback = (event: any, page: number) => {
    onChange(page, itemsCountForPage);
  };

  return (
    <div className={s.pagination}>
      <Pagination
        color="primary"
        sx={
          {
            // mt: 1,
          }
        }
        page={page}
        count={lastPage}
        onChange={onChangeCallback}
        // hideNextButton
        // hidePrevButton
      />
    </div>
  );
};
