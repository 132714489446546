import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorSnackbar } from "src/components/ErrorSnackbar/ErrorSnackbar";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { App } from "src/features/App/App";
import { rootReducer } from "./slices";
import "./i18n";

import { withSuspense } from "./utilitsFunction/withSuspense";
const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ErrorSnackbar />
    <React.Fragment>
      <BrowserRouter>{withSuspense(App)}</BrowserRouter>
    </React.Fragment>
  </Provider>,
);
