import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Card, CardBody, Button } from "reactstrap";

export const LimitedAccessComponent: React.FC = () => {
  const [open, setOpen] = useState(true);
  const { t, i18n } = useTranslation();

  const containerStyle: React.CSSProperties = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
  };
  const handleClose = () => {
    setOpen(false); // Закрываем компонент при клике на крестик
  };

  return (
    <>
      {open && (
        <div style={containerStyle}>
          <Card style={{ maxWidth: "400px", padding: "20px" }}>
            <button
              className="btn btn-link"
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={handleClose}
            >
              <FaTimes /> {/* Иконка крестика */}
            </button>
            <CardBody className="text-center">
              {" "}
              {/* Центрирование содержимого по центру */}
              <h5 style={{ marginBottom: "5px" }} className="card-title">
                {t("Limited Access")}
              </h5>
              <p className="card-text">
                {t("You need to buy to access full features.")}
              </p>
              <NavLink
                className="btn btn-primary btn-lg"
                to="https://restatos.com/price"
              >
                {t("Buy Now")}
              </NavLink>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default LimitedAccessComponent;
