import { Row, Col, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classname from "classnames";
import React, { useState, useEffect } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//import withRouter
import withRouter from "src/components/Common/withRouter";

//i18n
import { useTranslation, withTranslation } from "react-i18next";
import { selectUserStatus } from "src/features/App/selectors";
import { setRestartCategoryList } from "src/features/CategoryList/reducer";
import { setRestartListBrandTable } from "src/features/ListBrandTable/reducer";
import { setRestartListSellersTable } from "src/features/ListSellersTable/reducer";
import { setRestartProductResearch } from "src/pages/Product-Research/reducer";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

const Navbar = (props: any) => {
  const userStatus = useAppSelector(selectUserStatus);

  const path = useLocation();
  const { t } = useTranslation();
  // const [pricing, setpricing] = useState<boolean>(false);
  // const [app, setapp] = useState<boolean>(false);
  // const [email, setemail] = useState<boolean>(false);
  // const [contact, setcontact] = useState<boolean>(false);
  // const [component, setcomponent] = useState<boolean>(false);
  // const [form, setform] = useState<boolean>(false);
  // const [table, settable] = useState<boolean>(false);
  // const [chart, setchart] = useState<boolean>(false);
  // const [icon, seticon] = useState<boolean>(false);
  // const [map, setmap] = useState<boolean>(false);
  // const [extra, setextra] = useState<boolean>(false);
  // const [invoice, setinvoice] = useState<boolean>(false);
  // const [timeline, settimeline] = useState<boolean>(false);
  // const [auth, setauth] = useState<boolean>(false);
  // const [authbasic, setauthbasic] = useState<boolean>(false);
  // const [authcover, setauthcover] = useState<boolean>(false);
  // const [error, seterror] = useState<boolean>(false);
  // const [error1, seterror1] = useState<boolean>(false);
  // const [error2, seterror2] = useState<boolean>(false);
  // const [utility, setutility] = useState<boolean>(false);
  // const [project, setproject] = useState<boolean>(false);
  // const [dashoboard, setdashoboard] = useState<boolean>(false);
  // const [elements, setelements] = useState<boolean>(false);

  // const pathName = path.pathname;
  // useEffect(() => {
  //   var matchingMenuItem = null;
  //   var ul: any = document.getElementById("navigation");
  //   var items: any = ul.getElementsByTagName("a");
  //   for (var i = 0; i < items.length; ++i) {
  //     if (pathName === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem);
  //   }
  // });

  // function activateParentDropdown(item: any) {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   if (parent) {
  //     parent.classList.add("active"); // li
  //     const parent2 = parent.parentElement;
  //     parent2.classList.add("active"); // li
  //     const parent3 = parent2.parentElement;
  //     if (parent3) {
  //       parent3.classList.add("active"); // li
  //       const parent4 = parent3.parentElement;
  //       if (parent4) {
  //         parent4.classList.add("active"); // li
  //         const parent5 = parent4.parentElement;
  //         if (parent5) {
  //           parent5.classList.add("active"); // li
  //           const parent6 = parent5.parentElement;
  //           if (parent6) {
  //             parent6.classList.add("active"); // li
  //           }
  //         }
  //       }9
  //     }
  //   }
  //   return false;
  // }
  const dispatch = useAppDispatch();

  let previewConst =
    "https://app.restatos.com/trendyol/product-research?minPrice=1000&maxPrice=1500&cats=330%2C1316519%2C17&urlParamNameCategory=Erkek+Sneaker%2C%C3%87ocuk+Sneaker%2CKad%C4%B1n+Sneaker";
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      // setdashoboard(!dashoboard);
                    }}
                  >
                    <Icon name="monitor" />{" "}
                    {/*<span>{props.t("Dashboards")}</span>{" "}*/}
                    <span>Trendyol</span> <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu")}>
                    <Link
                      onClick={() =>
                        dispatch(setRestartCategoryList({ restart: true }))
                      }
                      to="/trendyol/category-list"
                      className="dropdown-item"
                    >
                      {t("categories")}
                    </Link>
                    {/*<Link to="/trendyol/categories" className="dropdown-item">*/}
                    {/*  {t("niche")}*/}
                    {/*</Link>*/}
                    <Link
                      onClick={() =>
                        dispatch(setRestartListSellersTable({ restart: true }))
                      }
                      to="/trendyol/seller-list"
                      className="dropdown-item"
                    >
                      {t("sellers")}
                    </Link>
                    <Link
                      onClick={() =>
                        dispatch(setRestartListBrandTable({ restart: true }))
                      }
                      to="/trendyol/brand-list"
                      className="dropdown-item"
                    >
                      {t("brands")}
                    </Link>
                    <Link
                      to="/trendyol/product-search"
                      className="dropdown-item"
                    >
                      {t("searchProduct")}
                    </Link>
                    <Link
                      onClick={() =>
                        dispatch(setRestartProductResearch({ restart: true }))
                      }
                      to={
                        userStatus === "trial" || userStatus === "unpaid"
                          ? previewConst
                          : "/trendyol/product-research"
                      }
                      className="dropdown-item"
                    >
                      {t("Market research")}
                    </Link>
                    <Link
                      to={
                        userStatus === "trial" || userStatus === "unpaid"
                          ? previewConst
                          : "/trendyol/integration"
                      }
                      className="dropdown-item"
                    >
                      {t("Integration")}
                    </Link>

                    {/*<Link to="/dashboards-analytics" className="dropdown-item">*/}
                    {/*  {t("similar")}*/}
                    {/*</Link>*/}
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Navbar));
