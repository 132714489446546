import { createAsyncThunk } from "@reduxjs/toolkit";
import { productResearchAPI } from "src/api/api-product-research/api-product-research";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setCategoryList,
  setStatusFetchCategory,
  setStatusProductResearch,
  setTable,
  setTotalCountPageProductResearch,
} from "src/pages/Product-Research/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchProductResearchTC = createAsyncThunk<
  any,
  { value: any; paramsTable: any }
>(
  "ProductResearch/fetchProductResearch",
  async ({ value, paramsTable }, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductResearch({
        status: "productResearch-loading",
      }),
    );
    try {
      const { res, resInfo } = await productResearchAPI.fetchProductResearchAPI(
        value,
        paramsTable.orderName ?? "",
        paramsTable.ascDesk ?? "",
        paramsTable.page ?? "",
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTable({ data: res.data.data }));
        thunkAPI.dispatch(
          setTotalCountPageProductResearch({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
        thunkAPI.dispatch(
          setStatusProductResearch({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusProductResearch({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchCategoryTC = createAsyncThunk<any, any>(
  "ProductResearch/fetchProductResearch",
  async (value, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusFetchCategory({
        status: "fetchCategory-loading",
      }),
    );
    try {
      const res = await productResearchAPI.fetchCategoryAPI(value);
      if (res.data.success === true) {
        thunkAPI.dispatch(setCategoryList({ data: res.data.message }));
        thunkAPI.dispatch(
          setStatusFetchCategory({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusFetchCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
