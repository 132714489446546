import { da } from "date-fns/locale";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetData from "src/components/Widgets/Widget";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import { resetBrandsTableState } from "src/features/BrandsTable/reducer";
import {
  selectChoiceCompetitors,
  selectChoiceProductInTheTops,
  selectDataCompetitors,
  selectDataProductInTheTops,
  selectDataRating,
  selectDataRevenue,
  selectStatusCompetitors,
  selectStatusDataRating,
  selectStatusDataRevenue,
  selectStatusProductInTheTops,
} from "src/features/BrandsTable/selectors";
import {
  fetchDataCategory,
  fetchDataCompetitorsSold,
  fetchDataRating,
  fetchDataRevenue,
  fetchDataSearch,
  fetchExcelDataBrandsTableProduct,
  fetchItemsInTheCategoryTop,
} from "src/features/BrandsTable/thunk";
import {
  fetchDataBrandsSellersTable,
  fetchExcelDataBrandsSellersTable,
} from "src/features/BrandsTableSellers/thunk";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";
import {
  selectCategoryId,
  selectNameCategory,
  selectNameUrl,
} from "src/features/CategoryTable/selectors";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import DataTable, { TableColumn } from "react-data-table-component";
import s from "../CategoryTable/Category.module.css";
import "../CategoryTable/Category.module.css";
import { Tooltip } from "react-tooltip";
import {
  clickAscDescBrandsSellers,
  clickOrderNameBrandsSellers,
  onChangePhraseBrandsSellers,
  onClickPageBrandsSellers,
  resetBrandsTableStateSellers,
  setStatusTableBrandsSellers,
} from "./reducer";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { useAppSelector } from "src/store/types";
import {
  selectAscDescBrandsSellers,
  selectColumnNameBrandsTableSellers,
  selectDataBrandsSellers,
  selectOrderNameBrandsSellers,
  selectPageBrandsSellers,
  selectStatusTableBrandsSellers,
  selectTotalCountPageBrandsSellers,
} from "./selectors";

import { useTranslation } from "react-i18next";

export const BrandsTableSellers = () => {
  const [tooltipName, setTooltipName] = useState<any>(null);
  const { brandId } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);

  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: "" },
    // { id: 1, label: t("Brand"), name: "brand", value: "" },
    // { id: 2, label: "Barcode", name: "barcode", value: "" },
  ]);

  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [image, setImage] = useState<string>("");
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const [tooltipData, setTooltipData] = useState<any>(null);

  const data = useAppSelector(selectDataBrandsSellers);
  // const categoryId = useAppSelector(selectBrandsId);
  // const columnName = useAppSelector(selectColumnNameBrands);
  const columnName = useAppSelector(selectColumnNameBrandsTableSellers);
  const orderName = useAppSelector(selectOrderNameBrandsSellers);
  const ascDesc = useAppSelector(selectAscDescBrandsSellers);
  const page = useAppSelector(selectPageBrandsSellers);
  const totalCountPage = useAppSelector(selectTotalCountPageBrandsSellers);
  const statusCategoryTable = useAppSelector(selectStatusTableBrandsSellers);
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const nameUrl = useAppSelector(selectNameUrl);
  const dateSelect = useAppSelector(selectDateSelect);
  const userStatus = useAppSelector(selectUserStatus);

  let loading = statusCategoryTable === "brandsTableSellers-loading";
  document.title = `Restatos | ${
    categoryId === brandId && currentLanguage === "tr" ? nameCategory : ""
  }
                    ${t("AnalyticalReportByBrand")}
                    ${
                      categoryId === brandId && currentLanguage !== "tr"
                        ? nameCategory
                        : ""
                    }`;

  async function fetchData() {
    const resName = await instance.get<
      FetchResponseTable<Array<{ message: string; path: string }>>
    >(`trendyol/brand/name/${brandId}`);
    dispatch(setCategoryId({ categoryId: brandId }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== brandId) {
    fetchData();
  }
  useEffect(() => {
    dispatch(fetchDataBrandsSellersTable(param));
    dispatch(fetchDataRating(param));
    dispatch(fetchDataRevenue(param));
  }, [
    brandId,
    columnName,
    ascDesc,
    orderName,
    page,
    dateSelect[0],
    dateSelect[1],
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetBrandsTableStateSellers({}));
      dispatch(resetBrandsTableState({}));
      dispatch(clearDate({}));
      setImage("");
    };
  }, []);

  const param: any = {
    brandId,
    columnName,
    orderName,
    ascDesc,
    page,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };

  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    if (value === "") {
      dispatch(
        setStatusTableBrandsSellers({
          status: "brandsTableSellers-loading",
        }),
      );
      dispatch(onChangePhraseBrandsSellers({ columnName: [] }));
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);

    const id = +setTimeout(() => {
      dispatch(onChangePhraseBrandsSellers({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhraseBrandsSellers({ columnName: inputs }));
    }
  };

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPageBrandsSellers({ page: page }));
  }, []);
  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderNameBrandsSellers({ orderName: column.selector }));
    dispatch(clickAscDescBrandsSellers({ ascDesc: sortDirection }));
  }, []);

  const columns = [
    {
      // name: t("image"),
      // name: t("image"),

      selector: "logo",
      cell: (row: any) => (
        <div
        // style={{ display: "block", flexDirection: "row" }}
        >
          {" "}
          {/*https://www.trendyol.com/magaza/m-m-{merchantId}*/}
          <a
            target="_blank"
            href={`https://www.trendyol.com/magaza/m-m-${row.id}`}
            rel="noreferrer"
          >
            <img
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.logo, setTooltipData);
              }}
              onMouseOut={() => setTooltipData(null)}
              style={{ borderRadius: "15%", cursor: "pointer" }}
              // width={"35px"}
              height={"35px"}
              src={row.logo}
              data-tip="Image"
            />
          </a>
          {/*<img*/}
          {/*  width={"30px"}*/}
          {/*  height={"30px"}*/}
          {/*  style={{*/}
          {/*    marginTop: "5px",*/}
          {/*    // marginRight: "30px",*/}
          {/*    // transform: "rotate(-50deg)",*/}
          {/*  }}*/}
          {/*  src="/favicon.ico"*/}
          {/*  alt=""*/}
          {/*/>*/}
        </div>
      ),
    },
    {
      // name: t("name"),
      name: <div>{t("name")}</div>,
      // selector: (row) => row.name,
      selector: "name",
      title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            to={`/trendyol/seller/${row.id}/products`}
            // target="_blank"
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("revenue"),
      selector: "revenueInBrand",
      format: (row: { revenueInBrand: number }) =>
        (row.revenueInBrand / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      sortable: true,
    },
    {
      name: t("revenueTotal"),
      // selector: (row) => row.price,
      selector: "revenueTotal",
      sort: true,
      sortable: true,
      format: (row: { revenueTotal: any }) =>
        (row.revenueTotal / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("revenueSharePercent"),
      selector: "revenueSharePercent",
      format: (row: { revenueSharePercent: any }) =>
        row.revenueSharePercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("soldInBrand"),
      selector: "soldInBrand",
      format: (row: { soldInBrand: any }) =>
        row.soldInBrand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("soldTotal"),
      selector: "soldTotal",
      format: (row: { soldTotal: any }) =>
        row.soldTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareSoldInBrandFromAllSalesPercent"),
      selector: "shareSoldInBrandFromAllSalesPercent",
      format: (row: { shareSoldInBrandFromAllSalesPercent: any }) =>
        row.shareSoldInBrandFromAllSalesPercent
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("countProdInBrand"),
      selector: "countProdInBrand",
      format: (row: { countProdInBrand: any }) =>
        row.countProdInBrand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("countProdTotal"),
      selector: "countProdTotal",
      format: (row: { countProdTotal: any }) =>
        row.countProdTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("countProdSharePercent"),
      selector: "countProdSharePercent",
      format: (row: { countProdSharePercent: any }) =>
        row.countProdSharePercent + "%",
    },
    {
      name: t("avgSoldPerProdTotal"),
      selector: "avgSoldPerProdTotal",
      format: (row: { avgSoldPerProdTotal: any }) =>
        row.avgSoldPerProdTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgSoldPerProdWithSold"),
      selector: "avgSoldPerProdWithSold",
      format: (row: { avgSoldPerProdWithSold: any }) =>
        row.avgSoldPerProdWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSold"),
      selector: "prodWithSold",
      format: (row: { prodWithSold: any }) =>
        row.prodWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSoldPercent"),
      selector: "prodWithSoldPercent",
      format: (row: { prodWithSoldPercent: any }) =>
        row.prodWithSoldPercent
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("avgPrice"),
      // selector: (row) => row.price,
      selector: "avgPrice",
      format: (row: { avgPrice: any }) =>
        (row.avgPrice / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("sumStock"),
      selector: "sumStock",
      format: (row: { sumStock: any }) =>
        row.sumStock
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("rating"),
      selector: "rating",
      format: (row: { rating: any }) =>
        row.rating
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: <div style={{ marginLeft: "3px" }}>{t("chart")}</div>,
      cell: (row: { chart: Array<number> }) => (
        <div style={{ marginLeft: "-31px" }}>
          <D3BarChart data={row.chart} />
        </div>
      ),
    },
  ] as any;
  // as React.JSX.IntrinsicAttributes & TableColumn<CategoryMessage>[];
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPageBrandsSellers({ page: newPage }));
    setCount(newCount);
  };

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );
  return (
    <>
      {mouse && <img className={s.bigImage} src={image} alt="" />}
      {loading && <CustomSpinner absolute={true} top={"600px"} />}
      <div
      // className={loading ? s.opasity : ""}
      //    style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div
              className="
               d-flex align-items-center justify-content-between"
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/brand-list">Brands</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === brandId && currentLanguage === "tr" ? (
                      <> {nameCategory}&nbsp;</>
                    ) : null}
                    {t("AnalyticalReportByBrand")}&nbsp;
                    {categoryId === brandId && currentLanguage !== "tr"
                      ? nameCategory
                      : null}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
          }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === brandId && currentLanguage === "tr" ? (
                <> {nameCategory}&nbsp;</>
              ) : null}
              {t("AnalyticalReportByBrand")}&nbsp;
              {categoryId === brandId && currentLanguage !== "tr"
                ? nameCategory
                : null}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelDataBrandsSellersTable(param))}
              />
            </span>
            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <WidgetData
          id={brandId}
          //
          dataRevenue={selectDataRevenue}
          selectDataRevenue={selectDataRevenue}
          selectStatusDataRevenue={selectStatusDataRevenue}
          //
          dataRating={selectDataRating}
          selectStatusDataRating={selectStatusDataRating}
          //
          dataProductInTheTops={selectDataProductInTheTops}
          selectChoiceProductInTheTops={selectChoiceProductInTheTops}
          fetchDataSearch={fetchDataSearch}
          fetchDataCategory={fetchDataCategory}
          selectStatusProductInTheTops={selectStatusProductInTheTops}
          //
          nameOne={"Revenue in this category"}
          nameTwo={"Number of products in the top of category"}
          select={true}
          dataCompetitors={selectDataCompetitors}
          selectChoiceCompetitors={selectChoiceCompetitors}
          selectStatusCompetitors={selectStatusCompetitors}
          fetchDataCompetitorsSold={fetchDataCompetitorsSold}
          fetchItemsInTheCategoryTop={fetchItemsInTheCategoryTop}
        />
        <div className={loading ? s.opasity : ""} style={{ height: "100%" }}>
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <NavLink
              to={`/trendyol/brand/${brandId}/products`}
              type="button"
              className={"btn-light btn-sm btn"}
              style={{ marginTop: "10px" }}
            >
              {t("products")}
            </NavLink>
            <a
              href={`/trendyol/brand/${brandId}/sellers`}
              className="btn-sm btn btn-primary"
              style={{ marginTop: "10px" }}
            >
              {t("sellers")}
            </a>
            <NavLink
              to={`/trendyol/brand/${brandId}/categories`}
              type="button"
              className="btn-sm btn btn-light"
              style={{
                marginTop: "10px",
              }}
            >
              {t("category")}
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {inputs.map((input, index) => (
                // TODO
                <CustomInput
                  id={input.id}
                  disabled={loading}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  onKeyPress={onKeyPress}
                />
              ))}
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav stickyCategoryTableSellers ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"position"}
            striped
            defaultSortAsc={true}
            columns={columns}
            data={data}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            sortServer={true}
            sortIcon={null}
            // onChangePage={handlePageChange}
            onSort={handleSort}
            responsive={true}
            // noDataComponent={<div>{t("dataTable")}</div>}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          {tooltipData && (
            <img
              style={{
                zIndex: 100,
                position: "absolute",
                top: tooltipData.y - 27,
                left: tooltipData.x + 30,
                background: "white",
                padding: "4px",
                height: "250px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              src={tooltipData.value}
              alt=""
            />
          )}
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          <TooltipInTable tooltipName={tooltipName} />

          <SuperPagination
            page={page}
            itemsCountForPage={count}
            totalCount={totalCountPage}
            onChange={onChangePagination}
          />
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
