import axios from "axios";
import {
  AuthParamsType,
  FetchResponseAxios,
  FetchResponseTable,
} from "src/api/types";
import { DescriptionEditType } from "src/features/product/miniProductComponent/descriptionEdit/type";
import { GraphsMessageType } from "src/features/product/miniProductComponent/graphWrapper/type";
import { ImageDataType } from "src/features/product/miniProductComponent/photo/type";
import { SellerDataType } from "src/features/product/miniProductComponent/seller/type";
import { TableCategoryPositionType } from "src/features/product/miniProductComponent/table/tableCategoryPosition/type";
import { TableSearchPositionType } from "src/features/product/miniProductComponent/table/tableSearchPosition/type";
import { TableSoldType } from "src/features/product/miniProductComponent/table/tableSold/type";
import { VariantsResponseType } from "src/features/product/miniProductComponent/variants/type";
import { FetchProductType } from "src/features/product/type";
import { TrendOilType } from "src/features/trendOil/type";
import { RegisterRequestType } from "src/pages/Authentication/Register";
import { LoginRequestType } from "src/pages/AuthenticationInner/Login1";
import {
  InformationDataRequestType,
  PasswordChangeRequestType,
} from "src/pages/Contacts/UserSetting";
import { checkPromoTc, signUpDataTC } from "src/slices/Auth/Login/thunk";
import { cookiesFind } from "src/utilitsFunction/cookiesFind";

export const instance = axios.create({
  // baseURL: "https://web-api.restatos.com/",
  baseURL: "https://web-api.restatos.com/",
  // "https://web-api-dev.restatos.com/",
  // ...settings,
});

instance.interceptors.request.use(
  (config) => {
    // Получаем токен из локального хранилища
    const token = cookiesFind(localStorage.getItem("token"), "token");

    // Если токен существует, добавляем его в заголовок Authorization
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    //
    const tkey = cookiesFind(localStorage.getItem("tkey"), "tkey");

    if (typeof tkey != "undefined" && tkey != "undefined" && tkey) {
      config.headers["SecKey"] = tkey;
    }
    // Получаем текущий язык из локального хранилища
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

    // Если язык существует, добавляем его в заголовок Accept-Language
    if (currentLanguage) {
      config.headers["Accept-Language"] = currentLanguage;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
export const authAPI = {
  me() {
    return instance.get<FetchResponseAxios<AuthParamsType>>("account/me");
  },
  signUp(data: RegisterRequestType) {
    return instance.post<FetchResponseAxios<any>>("auth/signup", data);
  },
  signUpData() {
    return instance.get<
      FetchResponseAxios<{ tariffs: any; can_use_trial: any }>
    >("account/signUpData");
  },
  checkPromo(promo: any) {
    return instance.post<FetchResponseAxios<any>>("account/checkPromo", promo);
  },
  forgotPassword(data: { email: string }) {
    return instance.post<FetchResponseAxios<string>>(
      "/auth/forgotPassword",
      data,
    );
  },
  login(data: LoginRequestType) {
    return instance.post<FetchResponseAxios<string>>("auth/signin", data);
  },
  logout() {
    return instance.post<FetchResponseAxios>("auth/logout");
  },
  informationChange(data: InformationDataRequestType) {
    return instance.post<FetchResponseAxios<string>>(
      "account/dataUpdate",
      data,
    );
  },

  passwordChange(data: PasswordChangeRequestType) {
    return instance.post<FetchResponseAxios<string>>(
      "account/passwordUpdate",
      data,
    );
  },
};
export const categoriesAPI = {
  fetchCategories() {
    return instance.get<FetchResponseAxios<TrendOilType>>(
      "trendyol/categories",
    );
  },
};

const urlProduct = "trendyol/product/";

export const productAPI = {
  fetchProduct(id: string, itemNumberUrl: string, merchantId: string) {
    const itemNumberAll = itemNumberUrl ? itemNumberUrl : "/all";

    return instance.get<FetchResponseAxios<FetchProductType>>(
      `${urlProduct}main/${id}${itemNumberAll}${merchantId}`,
    );
  },
  isSubscribe(id: string) {
    // const itemNumberAll = itemNumber ? itemNumber : "/all";
    // const merchantIdUrl = merchantId ? "/all" + merchantId : "";
    return instance.get<FetchResponseAxios<any>>(`follow/trendyol/check/${id}`);
  },
  follow(id: string) {
    // GET /follow/trendyol/check/{id}
    // const itemNumberAll = itemNumber ? itemNumber : "/all";
    // const merchantIdUrl = merchantId ? "/all" + merchantId : "";
    return instance.get<FetchResponseAxios<any>>(
      // ${itemNumberAll}${merchantIdUrl}
      `follow/trendyol/add/${id}`,
    );
  },
  unFollow(id: string) {
    // const itemNumberAll = itemNumber ? itemNumber : "/all";
    // const merchantIdUrl = merchantId ? "/all" + merchantId : "";
    return instance.get<FetchResponseAxios<any>>(
      // ${itemNumberAll}${merchantIdUrl}
      `follow/trendyol/delete/${id}`,
    );
  },
  fetchProductImage(id: string, itemNumber: string) {
    return instance.get<FetchResponseAxios<Array<ImageDataType>>>(
      `${urlProduct}image/${id}${itemNumber}`,
    );
  },
  fetchVariantProduct(id: string, merchantId: string) {
    const merchantIdUrl = merchantId ? "/all" + merchantId : "";
    return instance.get<FetchResponseAxios<Array<VariantsResponseType>>>(
      `${urlProduct}variants/${id}${merchantIdUrl}`,
    );
  },
  fetchSellerProduct(
    id: string | null,
    itemNumberUrl: string | null,
    startDate: any,
    endDate: any,
  ) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get<FetchResponseAxios<Array<SellerDataType>>>(
      `${urlProduct}merchant/${id}${itemNumberUrl}`,
      {
        params: params,
      },
    );
  },
  fetchGraphProduct(
    id: string,
    itemNumberUrl: string,
    merchantId: string,
    resultDate1: string,
    resultDate2: string,
  ) {
    const itemNumberAll = itemNumberUrl ? itemNumberUrl : "/all";
    return instance.get<FetchResponseAxios<Array<GraphsMessageType>>>(
      `${urlProduct}sold-chart/${id}${itemNumberAll}${merchantId}${resultDate1}${resultDate2}`,
    );
  },
  fetchCategoryPositionTableProduct(
    id: string,
    resultDate1: string,
    resultDate2: string,
  ) {
    return instance.get<FetchResponseTable<Array<TableCategoryPositionType>>>(
      `${urlProduct}category-position-table/${id}${resultDate1}${resultDate2}`,
    );
  },
  fetchSearchPositionTableProduct(
    id: string,
    resultDate1: string,
    resultDate2: string,
  ) {
    return instance.get<FetchResponseTable<Array<TableSearchPositionType>>>(
      `${urlProduct}search-position-table/${id}${resultDate1}${resultDate2}`,
    );
  },
  fetchTableSoldProduct(
    id: string,
    itemNumberUrl: string,
    merchantId: string,
    resultDate1: string,
    resultDate2: string,
  ) {
    const itemNumber = itemNumberUrl !== "" ? itemNumberUrl : "/all";

    return instance.get<FetchResponseTable<Array<TableSoldType>>>(
      `${urlProduct}sold-table/${id}${itemNumber}${merchantId}${resultDate1}${resultDate2}`,
    );
  },
  fetchProductDescriptionEdit(id: string) {
    return instance.get<FetchResponseAxios<Array<DescriptionEditType>>>(
      `${urlProduct}description/${id}`,
    );
  },
  fetchFeedback(
    id: string,
    merchantId: any,
    page: any,
    // startDate: any,
    // endDate: any,
  ) {
    const merchantIdUrl = merchantId ? merchantId : "";
    const params: { [key: string]: string } = {};

    return instance.get<FetchResponseAxios<any>>(
      `/trendyol/product/reviews/${id}/all${merchantIdUrl}`,
      {
        params: params,
      },
    );
  },
  fetchExcelFeedback(id: string, merchantId: any, page: any) {
    const merchantIdUrl = merchantId ? merchantId : "";
    const params: { [key: string]: string } = {};

    return instance.get<FetchResponseAxios<any>>(
      `/trendyol/product/reviews/${id}/all${merchantIdUrl}?excel=true`,
      {
        params: params,
      },
    );
  },
};
export const tokenAPI = {
  fetchToken() {
    return instance.get<FetchResponseAxios<{ token: string }>>(
      "account/updatePluginToken",
    );
  },
};
