import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbItem, Card, CardBody, Col, Input, Row } from "reactstrap";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import s from "src/features/CategoryTable/Category.module.css";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
// import { RegisterRequestType } from "src/pages/Authentication/Register";
import { AutoCompleteSelect } from "src/pages/Product-Research/InputGroup/InputGroup";
import {
  resetProductResearch,
  setCategoryList,
} from "src/pages/Product-Research/reducer";
import {
  selectAscDescProductResearch,
  selectOrderNameProductResearch,
  selectPageProductResearch,
  selectStatusFetchCategoryProductResearch,
  selectStatusProductResearch,
  selectTotalCountPageProductResearch,
} from "src/pages/Product-Research/selectors";
import { TableProductResearch } from "src/pages/Product-Research/Table/TableProductResearch";
import { fetchProductResearchTC } from "src/pages/Product-Research/thunk";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { productResearchYup } from "src/utilitsFunction/schemas";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

// export type PasswordChangeRequestType = Omit<
//   RegisterRequestType,
//   "name" | "email"
// >;
//
// export type InformationDataRequestType = {
//   name: string | null;
//   tax: string | null;
//   phone: string | null;
// };

export const ProductResearch = () => {
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const {
    minPrice,
    maxPrice,
    merchCountFrom,
    merchCountTo,
    soldFrom,
    soldTo,
    revenueFrom,
    revenueTo,
    ratingFrom,
    ratingTo,
    favoritesFrom,
    favoritesTo,
    minRatingCount,
    maxRatingCount,
    shareMerchantsFrom,
    shareMerchantsTo,
    taxFrom,
    taxTo,
    brand,
    name,
    cats,
    urlParamNameCategory,
  } = useSearchParams();

  useEffect(() => {
    // Получаем строку из URL-адреса

    // Разделяем строку по запятой и создаем массив объектовш
    if (urlParamNameCategory !== null) {
      const categoryArray = urlParamNameCategory.split(",").map((category) => ({
        value: category, // Значение
        label: category, // Метка
      }));
      dispatch(setCategoryList({ data: categoryArray }));
    }
  }, [urlParamNameCategory]);

  const { category_url } = useParams();
  const [loadStar, setLoadStar] = useState(false);
  const status = useAppSelector(selectStatusProductResearch);
  const page = useAppSelector(selectPageProductResearch);
  const orderName = useAppSelector(selectOrderNameProductResearch);
  const ascDesk = useAppSelector(selectAscDescProductResearch);
  const totalCountPage = useAppSelector(selectTotalCountPageProductResearch);
  const statusFetchCategory = useAppSelector(
    selectStatusFetchCategoryProductResearch,
  );
  const userStatus = useAppSelector(selectUserStatus);

  const loading =
    status === "productResearch-loading" ||
    statusFetchCategory === "fetchCategory-loading";
  document.title = "Restatos | Product Research";

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams();

  const formik = useFormik({
    validationSchema: productResearchYup,
    initialValues: {
      minPrice: minPrice ?? "",
      maxPrice: maxPrice ?? "",
      merchCountFrom: merchCountFrom ?? "",
      merchCountTo: merchCountTo ?? "",
      soldFrom: soldFrom ?? "",
      soldTo: soldTo ?? "",
      revenueFrom: revenueFrom ?? "",
      revenueTo: revenueTo ?? "",
      ratingFrom: ratingFrom ?? "",
      ratingTo: ratingTo ?? "",
      favoritesFrom: favoritesFrom ?? "",
      favoritesTo: favoritesTo ?? "",
      minRatingCount: minRatingCount ?? "",
      maxRatingCount: maxRatingCount ?? "",
      shareMerchantsFrom: shareMerchantsFrom ?? "",
      shareMerchantsTo: shareMerchantsTo ?? "",
      taxFrom: taxFrom ?? "",
      taxTo: taxTo ?? "",
      brand: brand ?? "",
      name: name ?? "",
      // selectedCategory: [],
      cats: cats ?? "",
      urlParamNameCategory: urlParamNameCategory ?? "",
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        if (userStatus === "trial") {
          dispatch(setUserStatus({ userStatus: "unpaid" }));
          return;
        }

        await dispatch(
          fetchProductResearchTC({
            value: values,
            paramsTable: {
              orderName: null,
              ascDesk: null,
              page: 1,
            },
          }),
        );
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  for (const key in formik.values) {
    if (formik.values[key] !== "" && formik.values[key].length !== 0) {
      if (Array.isArray(formik.values[key])) {
        for (const keyElement of formik.values[key]) {
          // params.append("cats", keyElement.value);
          params.append("urlParamNameCategory", keyElement.label);
        }
      } else {
        params.append(key, formik.values[key]);
      }
    }
  }

  let url = window.location.pathname;
  if (params.toString() !== "") {
    url += `?${params.toString()}`;
  }
  window.history.pushState({}, "", url);
  useEffect(() => {
    dispatch(
      fetchProductResearchTC({
        value: formik.values,
        paramsTable: { orderName, ascDesk, page },
      }),
    );
  }, [category_url, ascDesk, orderName, page]);
  useEffect(() => {
    return () => {
      dispatch(resetProductResearch({}));
    };
  }, []);

  const handleReset = () => {
    formik.resetForm();
    setSelectedOption([]);
    for (const key in formik.values) {
      if (
        formik.values[key] === ""
        // || formik.values[key].length === 0
      ) {
        params.append(key, formik.values[key]);
      }
      const newUrl = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, newUrl);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        {(loading && <CustomSpinner absolute={true} top={"300px"} />) ||
          (loadStar && <CustomSpinner absolute={true} top={"300px"} />)}
        <div
          className={loading || loadStar ? s.opasity : ""}
          style={{ height: "100%" }}
        >
          <Row>
            <Col xs="12">
              <div
                className="
            d-flex align-items-center justify-content-between"
              >
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "15px",
                      }}
                      active
                    >
                      <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        // marginLeft: "15px",
                      }}
                      active
                    >
                      <span>{t("Market research")}</span>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <TableProductResearch setLoadStar={setLoadStar} />
          <Row>
            <>
              <Card>
                <CardBody>
                  {/*<h5 className="card-title mb-4">*/}
                  {/*  /!*{t("setting")}*!/*/}
                  {/*  Product Research*/}
                  {/*</h5>*/}
                  {/*<div className="card border shadow-none mb-5">*/}
                  {/*<div className="card-header d-flex align-items-center">*/}
                  {/*  <div className="flex-shrink-0 me-3">*/}
                  {/*    <div className="avatar-sm">*/}
                  {/*      <div className="avatar-title rounded-circle bg-soft-primary text-primary">*/}
                  {/*        01*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="flex-grow-1">*/}
                  {/*    <h5 className="card-title">{t("general")}</h5>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<CardBody>*/}
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      {/*<Row>*/}
                      <div
                        style={{
                          display: "flex",
                          flexDirection:
                            window.innerWidth < 1200 ? "column" : "row",
                          justifyContent:
                            window.innerWidth < 1200
                              ? "center"
                              : "space-between",
                          width: window.innerWidth < 1200 ? "100%" : "auto",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: window.innerWidth < 1200 ? "100%" : "50%",
                          }}
                        >
                          <label
                            // style={{ marginTop: "10px" }}
                            htmlFor="choices-multiple-skill-input"
                            className="form-label"
                          >
                            {t("Categories")}
                          </label>
                          <AutoCompleteSelect
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            params={params}
                            cats={formik.values.cats}
                            formik={formik}
                          />
                        </div>

                        <div
                          style={{
                            width: window.innerWidth < 1200 ? "100%" : "50%",
                          }}
                          // style={{ width: "50%" }}
                        >
                          <label
                            htmlFor="choices-multiple-skill-input"
                            className="form-label"
                          >
                            {t("Filters")}
                          </label>
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3">*/}
                            {/*  {t("Price")}*/}
                            {/*</label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Price")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("minPrice")}
                              invalid={formik.errors.minPrice ? true : false}
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("maxPrice")}
                              invalid={formik.errors.maxPrice ? true : false}
                            />
                          </div>
                          {formik.errors.minPrice && (
                            <div className="invalid-feedback">
                              {formik.errors.minPrice}
                            </div>
                          )}
                          {formik.errors.maxPrice && (
                            <div className="invalid-feedback">
                              {formik.errors.maxPrice}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3">*/}
                            {/*  {t("Number of selles")}*/}
                            {/*</label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Number of sellers")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("merchCountFrom")}
                              invalid={
                                formik.errors.merchCountFrom ? true : false
                              }
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("merchCountTo")}
                              invalid={
                                formik.errors.merchCountTo ? true : false
                              }
                            />
                          </div>
                          {formik.errors.merchCountFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.merchCountFrom}
                            </div>
                          )}
                          {formik.errors.merchCountTo && (
                            <div className="invalid-feedback">
                              {formik.errors.merchCountTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3">*/}
                            {/*  Ежемесячные продажи*/}
                            {/*</label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Number of sales")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("soldFrom")}
                              invalid={formik.errors.soldFrom ? true : false}
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("soldTo")}
                              invalid={formik.errors.soldTo ? true : false}
                            />
                          </div>
                          {formik.errors.soldFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.soldFrom}
                            </div>
                          )}
                          {formik.errors.soldTo && (
                            <div className="invalid-feedback">
                              {formik.errors.soldTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3">*/}
                            {/*  Ежемесячные оборот*/}
                            {/*</label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Revenue")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("revenueFrom")}
                              invalid={formik.errors.revenueFrom ? true : false}
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("revenueTo")}
                              invalid={formik.errors.revenueTo ? true : false}
                            />
                          </div>
                          {formik.errors.revenueFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.revenueFrom}
                            </div>
                          )}
                          {formik.errors.revenueTo && (
                            <div className="invalid-feedback">
                              {formik.errors.revenueTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3">*/}
                            {/*  {" "}*/}
                            {/*  Оценка продукта*/}
                            {/*</label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Rating")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("ratingFrom")}
                              invalid={formik.errors.ratingFrom ? true : false}
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("ratingTo")}
                              invalid={formik.errors.ratingTo ? true : false}
                            />
                          </div>
                          {formik.errors.ratingFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.ratingFrom}
                            </div>
                          )}
                          {formik.errors.ratingTo && (
                            <div className="invalid-feedback">
                              {formik.errors.ratingTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            {/*<label className="form-label me-3"></label>*/}
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Rating count")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("minRatingCount")}
                              invalid={
                                formik.errors.minRatingCount ? true : false
                              }
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("maxRatingCount")}
                              invalid={
                                formik.errors.maxRatingCount ? true : false
                              }
                            />
                          </div>
                          {formik.errors.minRatingCount && (
                            <div className="invalid-feedback">
                              {formik.errors.minRatingCount}
                            </div>
                          )}
                          {formik.errors.maxRatingCount && (
                            <div className="invalid-feedback">
                              {formik.errors.maxRatingCount}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("FavoritesProductResearch")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("favoritesFrom")}
                              invalid={
                                formik.errors.favoritesFrom ? true : false
                              }
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("favoritesTo")}
                              invalid={formik.errors.favoritesTo ? true : false}
                            />
                          </div>
                          {formik.errors.favoritesFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.favoritesFrom}
                            </div>
                          )}
                          {formik.errors.favoritesTo && (
                            <div className="invalid-feedback">
                              {formik.errors.favoritesTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Share of salespeople with sales")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("shareMerchantsFrom")}
                              invalid={
                                formik.errors.shareMerchantsFrom ? true : false
                              }
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("shareMerchantsTo")}
                              invalid={
                                formik.errors.shareMerchantsTo ? true : false
                              }
                            />
                          </div>
                          {formik.errors.shareMerchantsFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.shareMerchantsFrom}
                            </div>
                          )}
                          {formik.errors.shareMerchantsTo && (
                            <div className="invalid-feedback">
                              {formik.errors.shareMerchantsTo}
                            </div>
                          )}
                          <div className="mb-3 d-flex align-items-center">
                            <Input
                              type="number"
                              className="form-control me-3"
                              placeholder={t("tax")}
                              disabled
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control me-3"
                              placeholder={t("Min")}
                              {...formik.getFieldProps("taxFrom")}
                              invalid={formik.errors.taxFrom ? true : false}
                            />
                            <Input
                              disabled={
                                userStatus === "trial" ||
                                userStatus === "unpaid"
                              }
                              type="number"
                              className="form-control"
                              placeholder={t("Max")}
                              {...formik.getFieldProps("taxTo")}
                              invalid={formik.errors.taxTo ? true : false}
                            />
                          </div>
                          {formik.errors.taxFrom && (
                            <div className="invalid-feedback">
                              {formik.errors.taxFrom}
                            </div>
                          )}
                          {formik.errors.taxTo && (
                            <div className="invalid-feedback">
                              {formik.errors.taxTo}
                            </div>
                          )}
                          <Input
                            disabled={
                              userStatus === "trial" || userStatus === "unpaid"
                            }
                            style={{ marginBottom: "5px" }}
                            type="text"
                            placeholder={t("Brand")}
                            {...formik.getFieldProps("brand")}
                            invalid={formik.errors.name ? true : false}
                          />
                          <Input
                            disabled={
                              userStatus === "trial" || userStatus === "unpaid"
                            }
                            type="text"
                            placeholder={t("ProductName")}
                            {...formik.getFieldProps("name")}
                            invalid={formik.errors.name ? true : false}
                          />
                          {/*<div className="mb-3 d-flex align-items-center">*/}
                          {/*  <Input*/}
                          {/*    style={{ width: "48%" }}*/}
                          {/*    type="number"*/}
                          {/*    className="form-control me-3"*/}
                          {/*    placeholder={t("Brand")}*/}
                          {/*    disabled*/}
                          {/*  />*/}
                          {/*  <Input*/}
                          {/*    style={{ width: "100.5%" }}*/}
                          {/*    type="number"*/}
                          {/*    className="form-control "*/}
                          {/*    placeholder={t("")}*/}
                          {/*    {...formik.getFieldProps("brand")}*/}
                          {/*    invalid={formik.errors.brand ? true : false}*/}
                          {/*  />*/}
                          {/*</div>*/}
                          {/*{formik.errors.brand && (*/}
                          {/*  <div className="invalid-feedback">*/}
                          {/*    {formik.errors.brand}*/}
                          {/*  </div>*/}
                          {/*)}*/}
                          {/*<div className="mb-3 d-flex align-items-center">*/}
                          {/*  <Input*/}
                          {/*    style={{ width: "48%" }}*/}
                          {/*    type="number"*/}
                          {/*    className="form-control me-3"*/}
                          {/*    placeholder={t("ProductName")}*/}
                          {/*    disabled*/}
                          {/*  />*/}
                          {/*  <Input*/}
                          {/*    style={{ width: "100.5%" }}*/}
                          {/*    type="number"*/}
                          {/*    className="form-control "*/}
                          {/*    // placeholder={t("ProductName")}*/}
                          {/*    {...formik.getFieldProps("name")}*/}
                          {/*    invalid={formik.errors.brand ? true : false}*/}
                          {/*  />*/}
                          {/*</div>*/}
                          {/*{formik.errors.name && (*/}
                          {/*  <div className="invalid-feedback">*/}
                          {/*    {formik.errors.name}*/}
                          {/*  </div>*/}
                          {/*)}*/}
                          <div className="mt-3">
                            <button
                              disabled={!formik.isValid || formik.isSubmitting}
                              className="btn btn-info w-40"
                              type="submit"
                            >
                              {t("Search")}
                            </button>
                            <button
                              disabled={
                                !(formik.dirty && formik.isValid) ||
                                formik.isSubmitting
                              }
                              style={{ marginLeft: "5px" }}
                              className="btn btn-danger w-40"
                              type="button"
                              onClick={handleReset}
                            >
                              {t("Clear")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*</CardBody>*/}
                  {/*</div>*/}
                </CardBody>
              </Card>
            </>
          </Row>
        </div>
      </div>
    </div>
  );
};
