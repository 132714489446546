import React, { useEffect } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
// import { CardBody, Modal } from "reactstrap";
import NonAuthLayout from "src/components/NonAuthLayout";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { Layout } from "src/components/VerticalLayout";
// import { resetAppState, setUserStatus } from "src/features/App/reducer";
import {
  selectLayoutType,
  selectIsInitialized,
  selectUserStatus,
  selectUserVariables,
} from "src/features/App/selectors";
import { initializeAppTC } from "src/features/App/thunk";
import { resetCategoryTableState } from "src/features/CategoryTable/reducer";
import { selectIsLoggedIn } from "src/pages/AuthenticationInner/selectors";
import { authRoutes, userRoutes } from "src/routes/allRoutes";
import { Authmiddleware } from "src/routes/middleware/Authmiddleware";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import "../../assets/scss/theme.scss";

function getLayout(layoutType: string) {
  let layoutCls: Object = Layout;
  switch (layoutType) {
    case "horizontal":
      layoutCls = Layout;
      break;
    default:
      layoutCls = Layout;
      break;
  }
  return layoutCls;
}

export const App = () => {
  const layoutType = useAppSelector(selectLayoutType);
  const isInitialized = useAppSelector(selectIsInitialized);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  // const url = useAppSelector(selectURL);
  const location = useLocation();
  const currentUrl = location.pathname;
  // const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();
  const path = window.location.pathname;
  const savedItem = localStorage.getItem("token");
  const token = savedItem ? savedItem : "";

  useEffect(() => {
    if (currentUrl.includes("/trendyol/product/")) {
      localStorage.setItem("url", currentUrl);
    }
    if (savedItem) {
      document.cookie = `token=${token}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=restatos.com; path=/;`;
    }
    dispatch(initializeAppTC());
  }, [location.pathname]);

  const Layout: any = getLayout(layoutType);

  if (!isInitialized) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <>
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                </>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};
