import { AppRootStateType } from "src/store/types";

export const selectTableProductResearch = (state: AppRootStateType) =>
  state.productResearch.table;
export const selectDataProductResearch = (state: AppRootStateType) =>
  state.productResearch.category;
export const selectValueProductResearch = (state: AppRootStateType) =>
  state.productResearch.value;

export const selectStatusProductResearch = (state: AppRootStateType) =>
  state.productResearch.status;
export const selectStatusFetchCategoryProductResearch = (
  state: AppRootStateType,
) => state.productResearch.statusFetchCategory;

export const selectOrderNameProductResearch = (state: AppRootStateType) =>
  state.productResearch.orderName;
export const selectAscDescProductResearch = (state: AppRootStateType) =>
  state.productResearch.ascDesk;
export const selectPageProductResearch = (state: AppRootStateType) =>
  state.productResearch.page;

export const selectTotalCountPageProductResearch = (state: AppRootStateType) =>
  state.productResearch.totalCountPage;
export const selectSubscribeProductResearch = (state: AppRootStateType) =>
  state.productResearch.subscribe;
