import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "categoryTable",
  initialState:
    ({
      columnName: [] as Array<InputsType>,
      subscribe: {} as any,
      orderName: "" as string,
      ascDesc: "" as string,
      nameCategory: null as null | string,
      page: 1 as number,
      image: undefined,
      nameUrl: "" as string,
      totalCountPage: null as number | null,
      tableCategory: undefined as Array<CategoryMessage> | undefined,
      dataPriceAvg: {},
      blockBrandAndSellers: {},
      dataProducts: {},
      blockRevenue: {},
      selectBrandAndSellers: "Brand",
      statusCategoryTable: "idle" as RequestStatusType,
      statusBrandAndSellers: "idle",
      statusPriceAvg: "idle",
      statusProducts: "idle",
    } as any) || {},
  reducers: {
    onChangePhrase(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    // setSubscribe(
    //   state,
    //   action: PayloadAction<{
    //     table: any;
    //   }>,
    // ) {
    //
    // },
    setCategoryImage(state, action: PayloadAction<{ image: string }>) {
      state.image = action.payload.image;
    },
    onClickPage(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPage(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },

    clickOrderName(state, action: PayloadAction<{ orderName: string }>) {
      state.orderName = action.payload.orderName;
    },
    clickAscDesc(state, action: PayloadAction<{ ascDesc: string }>) {
      state.ascDesc = action.payload.ascDesc;
    },
    setTableCategory(
      state,
      action: PayloadAction<{ tableCategory: Array<CategoryMessage> }>,
    ) {
      const newData = action.payload.tableCategory.reduce(
        (acc: any, el: any) => {
          acc[el.id] = el.followed;
          return acc;
        },
        {},
      );
      state.subscribe = { ...state.subscribe, ...newData };
      state.tableCategory = [...action.payload.tableCategory];
    },
    setSubscribe(state, action: PayloadAction<{ id: any }>) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: true,
      };
      state.subscribe = newSubscribe;
      // (state.subscribe = { ...state.subscribe, result });
    },
    deleteSubscribe(state, action: PayloadAction<{ id: any }>) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: false,
      };
      state.subscribe = newSubscribe;
    },
    //
    setDataBrandAndSellers(state, action: PayloadAction<{ data: Array<any> }>) {
      state.blockBrandAndSellers = action.payload.data;
    },
    setDataRevenue(state, action: PayloadAction<{ data: Array<any> }>) {
      state.blockRevenue = action.payload.data;
    },
    setDataProducts(state, action: PayloadAction<{ data: Array<any> }>) {
      state.dataProducts = action.payload.data;
    },
    setDataPriceAvg(state, action: PayloadAction<{ data: Array<any> }>) {
      state.dataPriceAvg = action.payload.data;
    },
    setDataCompetitors(state, action: PayloadAction<{ data: Array<any> }>) {
      state.dataCompetitors = action.payload.data;
    },
    setSelectBrandAndSellers(state, action: PayloadAction<{ select: string }>) {
      state.selectBrandAndSellers = action.payload.select;
    },

    //
    setStatusDataRevenue(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDataRevenue = action.payload.status;
    },
    setStatusBrandAndSellers(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusBrandAndSellers = action.payload.status;
    },
    setStatusPriceAvg(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusPriceAvg = action.payload.status;
    },
    setStatusProducts(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusProducts = action.payload.status;
    },
    //
    setStatusCategoryTable(
      state,
      action: PayloadAction<{ statusCategoryTable: RequestStatusType }>,
    ) {
      state.statusCategoryTable = action.payload.statusCategoryTable;
    },
    resetCategoryTableState(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        subscribe: {} as any,
        orderName: "" as string,
        ascDesc: "" as string,
        nameCategory: null as null | string,
        page: 1 as number,
        image: undefined,
        nameUrl: "" as string,
        totalCountPage: null as number | null,
        tableCategory: undefined as Array<CategoryMessage> | undefined,
        dataPriceAvg: {},
        blockBrandAndSellers: {},
        dataProducts: {},
        blockRevenue: {},
        selectBrandAndSellers: "Brand",
        statusCategoryTable: "idle" as RequestStatusType,
        statusBrandAndSellers: "idle",
        statusPriceAvg: "idle",
        statusProducts: "idle",
      };
    },
  },
});

export const categoryTableReducer = slice.reducer;
export const {
  setSubscribe,
  deleteSubscribe,
  onChangePhrase,
  onClickPage,
  setTotalCountPage,
  setTableCategory,
  clickAscDesc,
  clickOrderName,
  setCategoryImage,
  setStatusCategoryTable,
  resetCategoryTableState,

  setDataRevenue,
  setDataBrandAndSellers,
  setSelectBrandAndSellers,
  setDataProducts,
  setDataPriceAvg,

  setStatusDataRevenue,
  setStatusBrandAndSellers,
  setStatusPriceAvg,
  setStatusProducts,
} = slice.actions;
