import { AppRootStateType } from "src/store/types";

export const selectDataMerchantTableCategory = (state: AppRootStateType) =>
  state.merchantTableCategory.table;

export const selectOrderNameMerchantTableCategory = (state: AppRootStateType) =>
  state.merchantTableCategory.orderName;
export const selectAscDescMerchantTableCategory = (state: AppRootStateType) =>
  state.merchantTableCategory.ascDesc;
export const selectPageMerchantTableCategory = (state: AppRootStateType) =>
  state.merchantTableCategory.page;
export const selectTotalCountPageMerchantTableCategory = (
  state: AppRootStateType,
) => state.merchantTableCategory.totalCountPage;
export const selectStatusMerchantTableCategory = (state: AppRootStateType) =>
  state.merchantTableCategory.status;
