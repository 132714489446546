import React from "react";
import { Navigate } from "react-router-dom";
import { BrandsTable } from "src/features/BrandsTable/BrandsTable";
import { BrandsTableCategory } from "src/features/BrandsTableCategory/BrandsTableCategory";
import { BrandsTableSellers } from "src/features/BrandsTableSellers/BrandsTableSellers";
import { CategoryList } from "src/features/CategoryList/CategoryList";
import { CategoryTableBrands } from "src/features/CategoryTableBrands/CategoryTableBrands";
import { CategoryTableSellers } from "src/features/CategoryTableSellers/CategoryTableSellers";
import { ListBrandTable } from "src/features/ListBrandTable/ListBrandTable";
import { ListSellersTable } from "src/features/ListSellersTable/ListSellersTable";
import { MerchantTable } from "src/features/MerchantTable/MerchantTable";
import { MerchantTableBrands } from "src/features/MerchantTableBrands/MerchantTableBrands";
import { MerchantTableCategory } from "src/features/MerchantTableCategory/MerchantTableCategory";
import { PriceSegmentsTable } from "src/features/Price-Segments-Table/Price-Segments-Table";
import { Product } from "src/features/product/Product";
import UiTabsAccordions from "src/features/product/WrapperProduct";
import Gallery from "src/features/product/WrapperProduct";
import { FavoriteGoods } from "src/pages/FavoriteGoods/FavoriteGoods";
import ForgotPasswordPage from "src/pages/ForgotPassword/ForgotPassword";
import { IntegrationProducts } from "src/pages/Integration-Products/IntegrationProducts";
import { ProductResearch } from "src/pages/Product-Research/Product-Research";
import { ProductSearch } from "src/pages/ProductSearch/ProductSearch";
import PageMaintenance from "src/pages/Utility/Maintenance/Maintenance";
import Logout from "../pages/Authentication/Logout";
import { Register } from "../pages/Authentication/Register";
import { Login1 } from "../pages/AuthenticationInner/Login1";
import { UserSetting } from "src/pages/Contacts/UserSetting";
import { Error1 } from "../pages/Error/Error1";
import { ErrorBasic } from "../pages/Error/ErrorBasic";
import { TrendOil } from "../features/trendOil/TrendOil";
import { Category } from "../features/CategoryTable/Category";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  { path: "/trendyol/categories", component: <TrendOil /> },
  { path: "/trendyol/product-search", component: <ProductSearch /> },
  { path: "/trendyol/category-list", component: <CategoryList /> },
  { path: "/trendyol/seller-list", component: <ListSellersTable /> },
  { path: "/trendyol/brand-list", component: <ListBrandTable /> },
  {
    path: "/trendyol/category/:category_url/products",
    component: <Category />,
  },
  {
    path: "/trendyol/category/:category_url/sellers",
    component: <CategoryTableSellers />,
  },
  {
    path: "/trendyol/category/:category_url/brands",
    component: <CategoryTableBrands />,
  },
  {
    path: "/trendyol/seller/:sellerId/products",
    component: <MerchantTable />,
  },
  {
    path: "/trendyol/seller/categories/:sellerId",
    component: <MerchantTableCategory />,
  },
  {
    path: "/trendyol/seller/brands/:sellerId",
    component: <MerchantTableBrands />,
  },
  {
    path: "/trendyol/brand/:brandId/products",
    component: <BrandsTable />,
  },
  {
    path: "/trendyol/brand/:brandId/sellers",
    component: <BrandsTableSellers />,
  },
  {
    path: "/trendyol/brand/:brandId/categories",
    component: <BrandsTableCategory />,
  },
  {
    path: "/trendyol/price/:id",
    component: <PriceSegmentsTable />,
  },
  { path: "/user-settings", component: <UserSetting /> },
  { path: "/follow/trendyol", component: <FavoriteGoods /> },
  { path: "/trendyol/product-research", component: <ProductResearch /> },
  { path: "trendyol/product/:id", component: <UiTabsAccordions /> },
  // { path: "trendyol/product/:id", component: <Product /> },
  { path: "trendyol/integration", component: <IntegrationProducts /> },
  { path: "/pages-maintenance", component: <PageMaintenance /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/trendyol/category-list" />,
  },
  { path: "*", component: <Navigate to="/trendyol/category-list" /> },
];
const authRoutes: Array<RouteProps> = [
  { path: "/signin", component: <Login1 /> },
  { path: "/logout", component: <Logout /> },
  { path: "/signup", component: <Register /> },
  { path: "/forgot-password", component: <ForgotPasswordPage /> },

  { path: "/error-404-basic", component: <Error1 /> },
  { path: "/error-500-basic", component: <ErrorBasic /> },
];

export { userRoutes, authRoutes };
