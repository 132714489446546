import { AppRootStateType } from "src/store/types";

export const selectStatusGraphProduct = (state: AppRootStateType) =>
  state.graphStockSales.statusGraphProduct;

export const selectGraphStockSales = (state: AppRootStateType) =>
  state.graphStockSales.graphStockSales;

// export const selectGraphSelectDate = (state: AppRootStateType) =>
//   state.product.dateSelect;
export const selectGraphSelectDate1 = (state: AppRootStateType) =>
  state.product.dateSelect1;
