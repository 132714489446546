import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { SellerDataType } from "src/features/product/miniProductComponent/seller/type";

export const slice = createSlice({
  name: "sellerProduct",
  initialState: {
    sellerData: [] as Array<SellerDataType>,
    merchantId: null as null | number,
    statusProduct: "idle" as RequestStatusType,
  },
  reducers: {
    setSellerData(
      state,
      action: PayloadAction<{ sellerData: Array<SellerDataType> }>,
    ) {
      state.sellerData = action.payload.sellerData;
    },
    setMerchantId(state, action: PayloadAction<{ merchantId: null | number }>) {
      state.merchantId = action.payload.merchantId;
    },
    setStatusSeller(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusProduct = action.payload.status;
    },
    clearSellerData(state, action) {
      return {
        ...state,
        sellerData: [] as Array<SellerDataType>,
        merchantId: null as null | number,
      };
    },
  },
});
export const sellerProductReducer = slice.reducer;
export const {
  setSellerData,
  setMerchantId,
  setStatusSeller,
  clearSellerData,
} = slice.actions;
