import React, { useEffect, useState } from "react";
import Navbar from "src/components/VerticalLayout/Navbar";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import s from "./HeaderVertical.module.css";
import { layoutTypes } from "src/constants/layout";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  changelayoutMode,
} from "../../slices/thunks";

import { useSelector, useDispatch } from "react-redux";

interface LayoutProps {
  children: any;
}

export const Layout = (props: LayoutProps) => {
  const dispatch = useDispatch();

  const {
    layoutMode,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    layoutType,
    leftSideBarTheme,
  } = useSelector((state: any) => ({
    layoutMode: state.layout.layoutMode,
    leftSideBarType: state.layout.leftSideBarType,
    layoutWidth: state.layout.layoutWidth,
    topbarTheme: state.layout.topbarTheme,
    layoutType: state.layout.layoutType,
    leftSideBarTheme: state.layout.leftSideBarTheme,
  }));
  useEffect(() => {
    dispatch(changeLayout(layoutTypes.VERTICAL));
  }, [dispatch]);
  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);
  useEffect(() => {
    if (layoutMode) {
      dispatch(changelayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);
  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);
  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);
  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={s.containerIndex}>
        <React.Fragment>
          <div id="layout-wrapper">
            <Header />
            <div className={s.sidebar}>
              <Sidebar
                theme={leftSideBarTheme}
                type={leftSideBarType}
                isMobile={isMobile}
              />
            </div>
            <div style={{ marginTop: "19px" }} className="main-content">
              <div className={s.navbar}>
                {" "}
                <Navbar menuOpen={true} />
              </div>

              {props.children}
            </div>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
