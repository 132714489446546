import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const SelectDropdown = ({
  id,
  select,
  defaultOption,
  fetchOne,
  fetchTwo,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectName = useAppSelector(select);

  console.log(selectName, "option");
  console.log(defaultOption[0], "defaultOption[0]");

  const handleSelect = async (option: any) => {
    console.log(option, "handleSelect option");

    const fetchAction = option === defaultOption[0] ? fetchOne : fetchTwo;
    const res = await dispatch(fetchAction(id));
  };

  useEffect(() => {
    handleSelect(selectName);
  }, []);

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="dropdown-toggle btn btn-light btn-sm">
        <span className="text-muted">
          {t(`${selectName}`)}
          <i className="mdi mdi-chevron-down ms-1"></i>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {defaultOption.map((el: any) => (
          <DropdownItem key={el} onClick={() => handleSelect(el)}>
            {t(el)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
