import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { SelectDropdown } from "src/components/Widgets/SelectDropdown/SelectDropdown";
import { useAppSelector } from "src/store/types";

const WidgetCard = ({
  bar,
  selector,
  dataSelector,
  selectChoiceProductInTheTops,
  fetchDataCategory,
  fetchDataSearch,
  id,
  title,
  subtitle,
  select,
}: any) => {
  const status = useAppSelector(selector);
  const data: any = useAppSelector(dataSelector);
  const { t, i18n } = useTranslation();

  const formatValue = (val: any) => {
    // if (val >= 1000000) {
    //   return (val / 1000000).toFixed(2) + "M";
    // } else if (val >= 1000) {
    //   return (val / 1000).toFixed(1) + "k";
    // }
    return val.toLocaleString("ru-RU"); // если значение меньше 1000, просто форматируем с разделителями тысяч
  };
  const barOptions = {
    chart: {
      type: "bar",
      height: 50,
      sparkline: {
        enabled: !0,
      },
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: 1, // Установите желаемую ширину линии
    },
    dataLabels: {
      enabled: !1,
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          return formatValue(val);
        },
      },
    },
    colors: ["#038edc"],
  };

  const series = [{ data: (data as { chart: Array<any> }).chart }];

  function formatRevenue(revenue: any) {
    const numericValue = Number(revenue);
    if (!numericValue) {
      return <span className="placeholder" style={{ width: "50%" }}></span>;
    }

    if (numericValue >= 1000000) {
      return (
        (numericValue / 1000000).toLocaleString("ru-RU", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "M"
      ); // Например, 3 082.62M
    } else if (numericValue >= 10000) {
      return (
        (numericValue / 1000).toLocaleString("ru-RU", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + "k"
      ); // Например, 10.8k
    }

    return numericValue.toLocaleString("ru-RU"); // Для значений меньше 10 000
  }

  const bestValue =
    (data as { best: string }).best ?? formatRevenue((data as any).revenue);

  return (
    <Col xl={3} sm={6} className="px-1">
      <Card
        style={{
          margin: "5px",
          position: "relative",
          opacity: status === "loading" ? 0.5 : "",
          pointerEvents: status === "loading" ? "none" : undefined,
        }}
      >
        <CardBody
          style={{
            minHeight: "210px", // Минимальная высота
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {status === "loading" && (
            // <div className="row mb-3" style={{ height: "100px" }}>
            <CustomSpinner absolute={true} top={"50%"} />
          )}
          <>
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="font-size-xs text-uppercase">{title}</h6>
                <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
                  {bestValue ? (
                    bestValue
                  ) : (
                    <span
                      className="placeholder"
                      style={{ width: "25%" }}
                    ></span>
                  )}
                </h4>
                <div className="text-muted">
                  {data.increment !== undefined && (
                    <div style={{ display: "inline-block", fontSize: "14px" }}>
                      <span
                        style={{
                          marginRight: "3px",
                          color: data.increment < 0 ? "#721c24" : "#39cb5b",
                        }}
                      >
                        {data.increment < 0 ? (
                          <>
                            {Math.abs(data.increment) + "%"}
                            <i
                              className="uil uil-arrow-down text-danger"
                              style={{ verticalAlign: "middle" }}
                            ></i>
                          </>
                        ) : (
                          <>
                            {data.increment + "%"}
                            <i
                              className="uil uil-arrow-up text-success"
                              style={{ verticalAlign: "middle" }}
                            ></i>
                          </>
                        )}
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        {" " + t("From previous period")}
                      </span>
                    </div>
                  )}
                  {subtitle && subtitle}
                </div>
              </div>

              <div>
                {select && (
                  <SelectDropdown
                    id={id}
                    select={selectChoiceProductInTheTops}
                    defaultOption={["Search", "categories"]}
                    fetchOne={fetchDataSearch}
                    fetchTwo={fetchDataCategory}
                  />
                )}
              </div>
            </div>

            <div className="apex-charts mt-3">
              <ReactApexChart
                options={barOptions} // Use barOptions or areaOptions depending on your requirement
                series={series}
                type={bar ? "bar" : "area"} // Change to "area" if using areaOptions
                className="apex-charts"
                dir="ltr"
                height="50"
              />
            </div>
          </>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WidgetCard;
