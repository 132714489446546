import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";
import { buildParams } from "src/utilitsFunction/api-utils";
// import { FetchResponseAxios } from "src/api/types";

export const categoryTableAPI = {
  async fetchCategoryTable(
    id: string,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/category/products/${id}`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/category/products/${id}/info`,
    );

    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });
    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }
    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  async fetchExcelCategoryTable(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      columns: columnName,
      orderName,
      ascDesk,
      startDate,
      endDate,
      page,
    });

    return await instance.get(
      `https://web-api.restatos.com/trendyol/category/products/${id}/0/1`,
      {
        params: params,
      },
    );
  },
  async fetchBrands(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/category/brands/${id}`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/category/brands/${id}/info`,
    );

    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });
    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }
    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }
    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);
    return { res, resInfo };
  },
  async fetchExcelBrands(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      columns: columnName,
      orderName,
      ascDesk,
      startDate,
      endDate,
      page,
    });

    return instance.get(
      `https://web-api.restatos.com/trendyol/category/brands/${id}/0/1`,
      {
        params: params,
      },
    );
  },
  async fetchSellers(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/category/sellers/${id}`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/category/sellers/${id}/info`,
    );

    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });
    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }
    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  async fetchExcelSellers(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      columns: columnName,
      orderName,
      ascDesk,
      startDate,
      endDate,
      page,
    });

    return instance.get(
      `https://web-api.restatos.com/trendyol/category/sellers/${id}/0/1`,
      {
        params: params,
      },
    );
  },

  //

  async fetchDataPriceAvg(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }
    return instance.get(
      `https://web-api.restatos.com/trendyol/category/data-price-avg/${id}`,
      {
        params: params,
      },
    );
  },
  async fetchDataBrands(id: string) {
    return instance.get(
      `https://web-api.restatos.com/trendyol/category/data-brands/${id}`,
    );
  },
  async fetchDataSellers(id: string) {
    return instance.get(
      `https://web-api.restatos.com/trendyol/category/data-sellers/${id}`,
    );
  },
  async fetchDataRevenue(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    // return instance.get(
    //   `https://web-api.restatos.com/trendyol/category/data-revenue/${id}`,
    // );

    return instance.get(
      `https://web-api.restatos.com/trendyol/category/data-revenue/${id}`,
      {
        params: params,
      },
    );
  },

  async fetchDataProducts(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get(
      `https://web-api.restatos.com/trendyol/category/data-products/${id}`,
      {
        params: params,
      },
    );
  },
};
