import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { TableSearchPositionType } from "src/features/product/miniProductComponent/table/tableSearchPosition/type";

export const slice = createSlice({
  name: "tableSearchProduct",
  initialState: {
    tableSearchPosition: [] as Array<TableSearchPositionType>,
    statusTableSearchPosition: "idle" as RequestStatusType,
  },

  reducers: {
    setTableSearchPosition(
      state,
      action: PayloadAction<{ table: Array<TableSearchPositionType> }>,
    ) {
      state.tableSearchPosition = [...action.payload.table];
    },
    setStatusTableSearchPosition(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusTableSearchPosition = action.payload.status;
    },
    clearCategoryPositionTableData(state, action) {
      return {
        ...state,
        tableSearchPosition: [] as Array<TableSearchPositionType>,
      };
    },
  },
});
export const tableSearchPositionProduct = slice.reducer;
export const {
  setTableSearchPosition,
  setStatusTableSearchPosition,
  clearCategoryPositionTableData,
} = slice.actions;
