import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setGraphStockSales,
  setStatusGraphStock,
} from "src/features/product/miniProductComponent/graphWrapper/reducer";
import { FetchGraphProductArgs } from "src/features/product/miniProductComponent/graphWrapper/type";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchGraphProduct = createAsyncThunk<any, FetchGraphProductArgs>(
  "graphProduct/fetchGraphProduct",
  async (
    { id, itemNumberUrl, merchantId, resultDate1, resultDate2 },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setStatusGraphStock({ status: "graph-loading" }));
    try {
      const res = await productAPI.fetchGraphProduct(
        id,
        itemNumberUrl,
        merchantId,
        resultDate1,
        resultDate2,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(
          setGraphStockSales({ graphStockSales: res.data.message }),
        );
        thunkAPI.dispatch(setStatusGraphStock({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusGraphStock({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
