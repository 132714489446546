import { AxiosError } from "axios";
import { setAppErrorAC, setAppStatusAC } from "src/features/App/reducer";
import { ResponseType } from "../api/types";

// original type:
// BaseThunkAPI<S, E, D extends Dispatch = Dispatch, RejectedValue = undefined>
type ThunkAPIType = {
  dispatch: (action: any) => any;
  rejectWithValue: Function;
};

// export const handleAsyncServerAppError = <D>(
//   // data: ResponseType<D>,
//   data: any,
//   thunkAPI: ThunkAPIType,
//   dispatch: (action: any) => any,
//   showError = true,
// ) => {
//   if (showError) {
//     // if (
//     //   typeof error === "object" &&
//     //   error !== null &&
//     //   "message" in error &&
//     //   typeof error.message === "string"
//     // ) {
//     //   dispatch(setAppErrorAC({ error: error.message }));
//     // }
//
//     thunkAPI.dispatch(
//       setAppErrorAC({
//         error:  error.response.data.error,
//
//       }),
//     );
//   }
//   thunkAPI.dispatch(setAppStatusAC({ status: "failed" }));
//   return thunkAPI.rejectWithValue({
//     errors: error.message,
//     // fieldsErrors: data.fieldsErrors,
//   });
// };

export const handleAsyncServerNetworkError = (
  error: any,
  thunkAPI: ThunkAPIType,
  showError = true,
) => {
  if (
    (showError && error.response.data.hasValidTariff) ||
    (showError && error.response.data.hasValidTariff === undefined)
  ) {
    // error.response && error.response.data && error.response.data.error;
    thunkAPI.dispatch(
      setAppErrorAC({
        error: error.response.data.error
          ? error.response.data.error
          : "Some error occurred",
      }),
    );
  }

  thunkAPI.dispatch(setAppStatusAC({ status: "failed" }));

  return thunkAPI.rejectWithValue({
    errors: error.response.data.error,
    fieldsErrors: undefined,
  });
};
