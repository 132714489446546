import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { DescriptionEditType } from "src/features/product/miniProductComponent/descriptionEdit/type";

export const slice = createSlice({
  name: "descriptionEditProduct",
  initialState: {
    descriptionEdit: [] as Array<DescriptionEditType>,
    statusDescriptionEdit: "idle" as RequestStatusType,
  },
  reducers: {
    setDescriptionEdit(
      state,
      action: PayloadAction<{ descriptionEdit: Array<DescriptionEditType> }>,
    ) {
      state.descriptionEdit = [...action.payload.descriptionEdit];
    },
    setStatusDescriptionEdit(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDescriptionEdit = action.payload.status;
    },

    clearDescriptionEdit(state, action) {
      return {
        ...state,
        descriptionEdit: [] as Array<DescriptionEditType>,
        statusDescriptionEdit: "idle" as RequestStatusType,
      };
    },
  },
});

export const descriptionEditProductReducer = slice.reducer;

export const {
  setDescriptionEdit,
  setStatusDescriptionEdit,
  clearDescriptionEdit,
} = slice.actions;
