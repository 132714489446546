import { FaYoutube } from "react-icons/fa";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

function YouTubeIcon({ actionStore, select }: any) {
  const dispatch = useAppDispatch();

  const showVideo = useAppSelector(select);

  const iconColor = showVideo ? "gray" : "red";
  return (
    <div style={{ marginTop: "22.5px" }}>
      <FaYoutube
        onClick={() => dispatch(actionStore({ showVideo: true }))}
        style={{ cursor: "pointer", fontSize: "28.3px", color: iconColor }}
      />
    </div>
  );
}

export default YouTubeIcon;
