import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetData from "src/components/Widgets/Widget";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import { resetBrandsTableState } from "src/features/BrandsTable/reducer";
import {
  selectChoiceCompetitors,
  selectChoiceProductInTheTops,
  selectDataCompetitors,
  selectDataProductInTheTops,
  selectDataRating,
  selectDataRevenue,
  selectStatusCompetitors,
  selectStatusDataRating,
  selectStatusDataRevenue,
  selectStatusProductInTheTops,
} from "src/features/BrandsTable/selectors";
import {
  fetchDataCategory,
  fetchDataCompetitorsSold,
  fetchDataRating,
  fetchDataRevenue,
  fetchDataSearch,
  fetchExcelDataBrandsTableProduct,
  fetchItemsInTheCategoryTop,
} from "src/features/BrandsTable/thunk";
import {
  onClickPageBrandsTableCategory,
  resetBrandsTableCategory,
  setFilterBrandsTableCategory,
} from "src/features/BrandsTableCategory/reducer";
import {
  selectAscDescBrandsTableCategory,
  selectDataBrandsTableCategory,
  selectFilteredDataBrandsTableCategory,
  selectOrderNameBrandsTableCategory,
  selectPageBrandsTableCategory,
  selectStatusBrandsTableCategory,
  selectTotalCountPageBrandsTableCategory,
} from "src/features/BrandsTableCategory/selectors";
import {
  fetchBrandsTableCategory,
  fetchExcelBrandsTableCategory,
} from "src/features/BrandsTableCategory/thunk";
import s from "src/features/CategoryTable/Category.module.css";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { D3BarChart } from "src/features/CategoryTable/miniCategory/graph/D3BarChart";
import {
  selectCategoryId,
  selectNameCategory,
  selectNameUrl,
} from "src/features/CategoryTable/selectors";

import { InputsType } from "src/features/CategoryTable/type";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { AppRootStateType, useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const BrandsTableCategory = () => {
  const [tooltipName, setTooltipName] = useState<any>(null);

  const { brandId } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const dispatch = useAppDispatch();
  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: "" },
  ]);
  const [image, setImage] = useState<string>("");
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const data = useAppSelector(selectDataBrandsTableCategory);
  const filtered = useAppSelector(selectFilteredDataBrandsTableCategory);
  const orderName = useAppSelector(selectOrderNameBrandsTableCategory);
  const ascDesc = useAppSelector(selectAscDescBrandsTableCategory);
  const page = useAppSelector(selectPageBrandsTableCategory);
  const totalCountPage = useAppSelector(
    selectTotalCountPageBrandsTableCategory,
  );
  const statusMerchantTableCategory = useAppSelector(
    selectStatusBrandsTableCategory,
  );
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const nameUrl = useAppSelector(selectNameUrl);
  const dateSelect = useAppSelector(selectDateSelect);
  const userStatus = useAppSelector(selectUserStatus);

  let filteredData = data.filter((el: any) =>
    el.name.toLowerCase().includes(filtered.toLowerCase()),
  );
  const param: any = {
    brandId,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };
  document.title = `Restatos | ${
    categoryId === brandId && currentLanguage === "tr" ? nameCategory : ""
  }
                    ${t("AnalyticalReportByBrand")}
                    ${
                      categoryId === brandId && currentLanguage !== "tr"
                        ? nameCategory
                        : ""
                    }`;
  async function fetchData() {
    const resName = await instance.get<
      FetchResponseTable<Array<{ message: string; path: string }>>
    >(`trendyol/brand/name/${brandId}`);
    dispatch(setCategoryId({ categoryId: brandId }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== brandId) {
    fetchData();
  }

  useEffect(() => {
    dispatch(fetchBrandsTableCategory(param));
    dispatch(fetchDataRating(param));
    dispatch(fetchDataRevenue(param));
  }, [brandId, page, dateSelect[0], dateSelect[1]]);

  useEffect(() => {
    return () => {
      dispatch(resetBrandsTableCategory({}));
      dispatch(resetBrandsTableState({}));
      dispatch(clearDate({}));

      setImage("");
    };
  }, []);

  const mouseClick = useCallback(() => {
    setMouse(false);
  }, []);

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPageBrandsTableCategory({ page: page }));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    dispatch(onClickPageBrandsTableCategory({ page: newPage }));
    setCount(newCount);
  };
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    const trimmedValue = value.replace(/\s+/g, " ").trim();
    // console.log(trimmedValue, "trimmedValue");
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    dispatch(setFilterBrandsTableCategory({ value: trimmedValue }));

    setInputs(updatedInputs);
  };

  const columns = [
    {
      name: t("name"),
      selector: "name",
      // title: "Image Column Tooltip",
      cell: (row: { id: string; name: string; category_id: number }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/category/${row.category_id}/products`}
          >
            {row.name.substr(0, 22)}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("revenue"),
      selector: "revenue",
      sortable: true,

      format: (row: { revenue: number }) =>
        (row.revenue / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("sold"),
      selector: "sold",
      sortable: true,
      format: (row: { sold: any }) =>
        row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("stock"),
      selector: "stock",
      sortable: true,
      format: (row: { stock: any }) =>
        row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("position"),
      // selector: (row) => row.position,
      selector: "position",
      format: (row: { position: any }) =>
        row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      sortable: true,
    },

    {
      name: t("countProductsWithSold"),
      selector: "countProductsWithSold",
      sortable: true,
      format: (row: { countProductsWithSold: any }) =>
        row.countProductsWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodWithSoldPercent"),
      selector: "prodWithSoldPercent",
      sortable: true,
      format: (row: { prodWithSoldPercent: any }) =>
        row.prodWithSoldPercent + "%",
    },

    {
      name: t("avgSoldPerProdTotal"),
      selector: "avgSoldPerProdTotal",
      sortable: true,
      format: (row: { avgSoldPerProdTotal: any }) =>
        row.avgSoldPerProdTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("avgSoldPerProdWithSold"),
      selector: "avgSoldPerProdWithSold",
      sortable: true,
      format: (row: { avgSoldPerProdWithSold: any }) =>
        row.avgSoldPerProdWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: <div style={{ marginLeft: "3px" }}>{t("chart")}</div>,
      cell: (row: { chart: Array<number> }) => (
        <div
          style={{
            marginLeft: `${currentLanguage === "ru" ? "-18px" : "-43px"}`,
          }}
        >
          <D3BarChart data={row.chart} />
        </div>
      ),
    },
  ] as any;

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      {mouse && <img className={s.bigImage} src={image} alt="" />}
      {statusMerchantTableCategory === "brandsTableCategory-loading" && (
        <CustomSpinner absolute={true} top={"600px"} />
      )}
      <div
      // className={
      //   statusMerchantTableCategory === "brandsTableCategory-loading"
      //     ? s.opasity
      //     : ""
      // }
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/brand-list">Brands</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === brandId && currentLanguage === "tr" ? (
                      <>{nameCategory}&nbsp;</>
                    ) : null}
                    {t("AnalyticalReportByBrand")}&nbsp;
                    {categoryId === brandId && currentLanguage !== "tr"
                      ? nameCategory
                      : null}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{ background: "white", fontSize: "20px", paddingLeft: "10px" }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === brandId && currentLanguage === "tr" ? (
                <> {nameCategory}&nbsp;</>
              ) : null}
              {t("AnalyticalReportByBrand")}&nbsp;
              {categoryId === brandId && currentLanguage !== "tr"
                ? nameCategory
                : null}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelBrandsTableCategory(param))}
              />
            </span>
            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <WidgetData
          id={brandId}
          //
          dataRevenue={selectDataRevenue}
          selectDataRevenue={selectDataRevenue}
          selectStatusDataRevenue={selectStatusDataRevenue}
          //
          dataRating={selectDataRating}
          selectStatusDataRating={selectStatusDataRating}
          //
          dataProductInTheTops={selectDataProductInTheTops}
          selectChoiceProductInTheTops={selectChoiceProductInTheTops}
          fetchDataSearch={fetchDataSearch}
          fetchDataCategory={fetchDataCategory}
          selectStatusProductInTheTops={selectStatusProductInTheTops}
          //
          nameOne={"Revenue in this category"}
          nameTwo={"Number of products in the top of category"}
          select={true}
          dataCompetitors={selectDataCompetitors}
          selectChoiceCompetitors={selectChoiceCompetitors}
          selectStatusCompetitors={selectStatusCompetitors}
          fetchDataCompetitorsSold={fetchDataCompetitorsSold}
          fetchItemsInTheCategoryTop={fetchItemsInTheCategoryTop}
        />
        <div
          className={
            statusMerchantTableCategory === "brandsTableCategory-loading"
              ? s.opasity
              : ""
          }
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <NavLink
              to={`/trendyol/brand/${brandId}/products`}
              type="button"
              className={"btn-light btn-sm btn"}
              style={{ marginTop: "10px" }}
            >
              {t("products")}
            </NavLink>
            <NavLink
              to={`/trendyol/brand/${brandId}/sellers`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("sellers")}
            </NavLink>
            <a
              href={`/trendyol/brand/${brandId}/categories`}
              className="btn-sm btn btn-primary "
              style={{
                marginTop: "10px",
              }}
            >
              {t("category")}
            </a>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {inputs.map((input, index) => (
                // TODO
                <CustomInput
                  id={input.id}
                  // disabled={loading}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  // onKeyPress={onKeyPress}
                />
              ))}
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav tableList ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"sold"}
            title={t("")}
            columns={columns}
            data={filteredData}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            // sortIcon={null}
            responsive={true}
            sortServer={false}
            // onChangePage={handlePageChange}
            // sortIcon
            defaultSortAsc={true}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          <TooltipInTable tooltipName={tooltipName} />
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
