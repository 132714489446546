import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setDescriptionEdit,
  setStatusDescriptionEdit,
} from "src/features/product/miniProductComponent/descriptionEdit/reducer";
import { setTimeCreate } from "src/features/product/miniProductComponent/photo/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchProductDescriptionEdit = createAsyncThunk<
  any,
  { id: string }
>("descriptionEdit/fetchProductDescriptionEdit", async ({ id }, thunkAPI) => {
  thunkAPI.dispatch(
    setStatusDescriptionEdit({ status: "descriptionEdit-loading" }),
  );
  try {
    const res = await productAPI.fetchProductDescriptionEdit(id);
    if (res.data.success === true) {
      thunkAPI.dispatch(
        setDescriptionEdit({ descriptionEdit: res.data.message }),
      );
      thunkAPI.dispatch(
        setTimeCreate({ timeCreate: res.data.message[0].time_create }),
      );
      thunkAPI.dispatch(setStatusDescriptionEdit({ status: "succeeded" }));
    } else {
      thunkAPI.dispatch(setStatusDescriptionEdit({ status: "succeeded" }));
      thunkAPI.dispatch(
        setAppErrorAC({
          error: "Something went wrong. We're already looking into the problem",
        }),
      );
    }
  } catch (error) {
    return handleAsyncServerNetworkError(error, thunkAPI);
  }
});
