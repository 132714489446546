import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import { useAppDispatch } from "../../../../../utilitsFunction/redux-utils";
import { fetchSearchPositionTableProduct } from "./thunk";
import { CustomSpinner } from "../../../../../components/Spinner/Spinner";
import { useAppSelector } from "../../../../../store/types";
import { selectDateSelect } from "../../variants/selectors";
import {
  selectDataSearchPosition,
  selectStatusSearchPosition,
} from "./selectors";
import { useTranslation } from "react-i18next";
import { clearCategoryPositionTableData } from "./reducer";
import { Card, CardBody, CardHeader, Table, Tooltip } from "reactstrap";

export const TableSearchPosition = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [removeClasses, setRemoveClasses] = useState(false);
  const [top, setTop] = useState(false);
  const dateSelect = useAppSelector(selectDateSelect);
  const data = useAppSelector(selectDataSearchPosition);
  const status = useAppSelector(selectStatusSearchPosition);

  const id = props.id ? props.id : "";

  const dateOne = dateSelect[0] ? `?date_from=${dateSelect[0]}` : "";
  const dateTwo = dateSelect[1] ? `&date_to=${dateSelect[1]}` : "";

  useEffect(() => {
    dispatch(
      fetchSearchPositionTableProduct({
        id,
        dateOne,
        dateTwo,
      }),
    );
    setRemoveClasses(true);
  }, [dateSelect, props.id]);

  useEffect(() => {
    return () => {
      dispatch(clearCategoryPositionTableData({}));
    };
  }, []);
  const columns = [
    {
      name: t("date"),
      selector: (row) => row.date,
      format: (row) => format(new Date(row.date), "dd.MM.yyyy"),
    },
    // {
    //    table: 'Category_url',
    //     // selector: row => row.category_url
    //     selector:'category_url'
    // },
    {
      // name: t("title"),
      name: t("phrase"),
      // table: 'Title',
      // selector: (row) => row.title,
      selector: "title",
    },
    {
      name: t("position"),
      // test: t("position"),
      selector: (row) => row.position,
      format: (row) =>
        row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
    },
  ];

  const [columnFilters, setColumnFilters] = useState({});

  const handleFilterChange = (columnSelector, filterValue) => {
    setColumnFilters((prevFilters) => ({
      ...prevFilters,
      [columnSelector]: filterValue,
    }));
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return Object.entries(columnFilters).every(
        ([columnSelector, filterValue]) => {
          if (!filterValue) return true; // No filter applied
          return (
            item[columnSelector].toString().toLowerCase() ===
            filterValue.toLowerCase().trim()
          );
          // .includes(filterValue.toLowerCase().trim());
        },
      );
    });
  }, [data, columnFilters]);

  const getUniqueFilterOptions = (columnSelector) => {
    return [...new Set(data.map((item) => item[columnSelector]))];
  };

  const getSubHeaderComponent = () => {
    return (
      <div>
        {columns.map((column) => (
          <div key={column.selector}>
            {/*<label>{column.test}</label>*/}
            {column.selector === "title" && (
              <div>
                <select
                  className={"form-select form-select"}
                  value={columnFilters[column.selector] || ""}
                  onChange={(e) =>
                    handleFilterChange(column.selector, e.target.value)
                  }
                >
                  <option value="">{t("allPhrases")}</option>
                  {getUniqueFilterOptions(column.selector).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [pointer, setPointer] = useState(false);

  const handleDivClick = () => {
    setIsScrollEnabled(true);
  };
  return (
    <div
      onClick={handleDivClick}
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
      style={{
        cursor: pointer ? "pointer" : "default",
      }}
    >
      <div
        onMouseLeave={() => setIsScrollEnabled(false)}
        onClick={handleDivClick}
        // style={{
        //   boxShadow: isScrollEnabled ? "1px 1px 6px #6f6f6f" : "",
        //   pointerEvents: isScrollEnabled ? "" : "none",
        //   cursor: "pointer",
        // }}
      >
        <Card>
          <div
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              overflowX: "hidden",
              borderRadius: "5px",
            }}
          >
            <>
              <CardHeader
                // style={{ padding: "5px" }}
                className="d-flex
              justify-content-between
               align-items-center
               "
              >
                <div
                  // style={{ marginLeft: "20px" }}
                  className="h4 card-title"
                >
                  {t("searchPositionTable")}
                  <Tooltip
                    placement="top"
                    isOpen={top}
                    target="searchPositionTable"
                    toggle={() => {
                      setTop(!top);
                    }}
                  >
                    {t("searchPositionTableTooltip")}
                  </Tooltip>
                  <i
                    className="uil-question-circle"
                    style={{ marginLeft: "3px", cursor: "pointer" }}
                    id="searchPositionTable"
                  ></i>
                </div>
                {getSubHeaderComponent()}
              </CardHeader>

              <CardBody className={"customCardBody"}>
                <div className="table-responsive">
                  <Table
                    className="table customTable
                   mb-0"
                  >
                    {status === "tableSearchPosition-loading" ? (
                      <div
                        style={{
                          background: "white",
                          width: "500px",
                          height: "500px",
                        }}
                      >
                        <CustomSpinner absolute={true} top={"200px"} />
                      </div>
                    ) : (
                      <DataTable
                        defaultSortFieldId={"sold"}
                        columns={columns}
                        data={filteredData}
                        pagination={false}
                        responsive
                        noDataComponent={<div>{t("dataTable")}</div>}
                      />
                    )}
                  </Table>
                </div>
              </CardBody>
            </>
          </div>
        </Card>
      </div>
    </div>
  );
};
