import { createAsyncThunk } from "@reduxjs/toolkit";
import { merchantTableAPI } from "src/api/api_merchantTable/api_merchantTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setMerchantTableCategory,
  setStatusMerchantTableCategory,
  setTotalCountPageMerchantTableCategory,
} from "src/features/MerchantTableCategory/reducer";

import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchMerchantTableCategory = createAsyncThunk<any, any>(
  "merchantTableCategory/fetchMerchantTableCategory",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTableCategory({
        status: "merchantTableCategory-loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchMerchantTableCategory(
        param.sellerId,
        param.startDate,
        param.endDate,
        // param.page,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setMerchantTableCategory({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusMerchantTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setTotalCountPageMerchantTableCategory({
            totalCountPage: res.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusMerchantTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelMerchantTableCategory = createAsyncThunk<any, any>(
  "excelMerchantTableCategory/fetchExcelMerchantTableCategory",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusMerchantTableCategory({
        status: "merchantTableCategory-loading",
      }),
    );
    try {
      const res = await merchantTableAPI.fetchExcelMerchantTableCategory(
        param.sellerId,
        param.startDate,
        param.endDate,
        // param.page,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusMerchantTableCategory({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusMerchantTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
