import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "ProductResearch",
  initialState: {
    table: [] as any,
    value: "" as string,
    category: [] as any,
    subscribe: {} as any,
    orderName: null as null | string,
    ascDesk: "" as string,
    page: 1 as number,
    totalCountPage: null as number | null,
    restart: false,
    status: "idle" as RequestStatusType,
    statusFetchCategory: "idle" as RequestStatusType,
  },

  reducers: {
    setTable(state, action: PayloadAction<{ data: Array<any> }>) {
      state.table = [...action.payload.data];
    },
    setCategoryList(state, action: PayloadAction<{ data: Array<any> }>) {
      state.category = [...action.payload.data];
    },
    onChangePhraseProductResearch(
      state,
      action: PayloadAction<{
        value: any;
      }>,
    ) {
      state.value = action.payload.value;
    },
    setStatusProductResearch(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    onClickPageProductResearch(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },

    setTotalCountPageProductResearch(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameProductResearch(
      state,
      action: PayloadAction<{ orderName: string }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescProductResearch(
      state,
      action: PayloadAction<{ ascDesk: string }>,
    ) {
      state.ascDesk = action.payload.ascDesk;
    },
    setSubscribeProductResearch(state, action: PayloadAction<{ id: any }>) {
      state.subscribe = {
        ...state.subscribe,
        [action.payload.id]: true,
      };
    },
    deleteSubscribeProductResearch(state, action: PayloadAction<{ id: any }>) {
      state.subscribe = {
        ...state.subscribe,
        [action.payload.id]: false,
      };
    },
    setStatusFetchCategory(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusFetchCategory = action.payload.status;
    },
    setRestartProductResearch(
      state,
      action: PayloadAction<{ restart: boolean }>,
    ) {
      state.restart = action.payload.restart;
    },
    resetProductResearch(state, action) {
      return {
        ...state,
        table: [] as any,
        value: "" as string,
        category: [] as any,
        subscribe: {} as any,
        orderName: null as null | string,
        ascDesk: "" as string,
        page: 1 as number,
        totalCountPage: null as number | null,
        restart: false,
        status: "idle" as RequestStatusType,
        statusFetchCategory: "idle" as RequestStatusType,
      };
    },
  },
});
export const ProductResearchReducer = slice.reducer;

export const {
  onClickPageProductResearch,
  setTotalCountPageProductResearch,
  clickOrderNameProductResearch,
  clickAscDescProductResearch,
  setTable,
  setCategoryList,
  onChangePhraseProductResearch,
  setStatusProductResearch,
  setSubscribeProductResearch,
  setStatusFetchCategory,
  deleteSubscribeProductResearch,
  setRestartProductResearch,
  resetProductResearch,
} = slice.actions;
