import { selectColumnName } from "src/features/CategoryTable/selectors";
import { AppRootStateType } from "src/store/types";

export const selectDataMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.table;
export const selectColumnNameMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.columnName;

export const selectOrderNameMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.orderName;
export const selectAscDescMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.ascDesc;
export const selectPageMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.page;
export const selectTotalCountPageMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.totalCountPage;
export const selectStatusMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.status;
export const selectSubscribeMerchantTable = (state: AppRootStateType) =>
  state.merchantTable.subscribe;

export const selectStatusCompetitors = (state: AppRootStateType) =>
  state.merchantTable.statusCompetitors;
export const selectStatusProductInTheTops = (state: AppRootStateType) =>
  state.merchantTable.statusProductInTheTops;
export const selectStatusDataRating = (state: AppRootStateType) =>
  state.merchantTable.statusDataRating;
export const selectStatusDataRevenue = (state: AppRootStateType) =>
  state.merchantTable.statusDataRevenue;

export const selectDataCompetitors = (state: AppRootStateType) =>
  state.merchantTable.dataCompetitors;
export const selectDataRating = (state: AppRootStateType) =>
  state.merchantTable.dataRating;
export const selectDataProductInTheTops = (state: AppRootStateType) =>
  state.merchantTable.dataProductInTheTops;
export const selectDataRevenue = (state: AppRootStateType) =>
  state.merchantTable.blockRevenue;
export const selectChoiceProductInTheTops = (state: AppRootStateType) =>
  state.merchantTable.selectProductInTheTops;
export const selectChoiceCompetitors = (state: AppRootStateType) =>
  state.merchantTable.selectCompetitors;
