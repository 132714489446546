import { setShowVideoCategoryList } from "src/features/CategoryList/reducer";
import { selectShowVideoCategoryList } from "src/features/CategoryList/selectors";
import { setShowVideoBrandList } from "src/features/ListBrandTable/reducer";
import { selectShowVideoListBrandTable } from "src/features/ListBrandTable/selectors";
import { setShowVideoSellersList } from "src/features/ListSellersTable/reducer";
import { selectShowVideoListSellersTable } from "src/features/ListSellersTable/selectors";

export const useDynamicStore = (currentUrl: any) => {
  let selectFunc, actionFunc;

  if (currentUrl.includes("/trendyol/category-list")) {
    selectFunc = selectShowVideoCategoryList;
    actionFunc = setShowVideoCategoryList;
  } else if (currentUrl.includes("/trendyol/brand-list")) {
    selectFunc = selectShowVideoListBrandTable;
    actionFunc = setShowVideoBrandList;
  } else if (currentUrl.includes("/trendyol/seller-list")) {
    selectFunc = selectShowVideoListSellersTable;
    actionFunc = setShowVideoSellersList;
  }

  return { selectFunc, actionFunc };
};
