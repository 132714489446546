import { AppRootStateType } from "src/store/types";

export const selectDataBrandsTableCategory = (state: AppRootStateType) =>
  state.brandsTableCategory.table;
export const selectFilteredDataBrandsTableCategory = (
  state: AppRootStateType,
) => state.brandsTableCategory.filteredBrandsTableCategory;

export const selectOrderNameBrandsTableCategory = (state: AppRootStateType) =>
  state.brandsTableCategory.orderName;
export const selectAscDescBrandsTableCategory = (state: AppRootStateType) =>
  state.brandsTableCategory.ascDesc;
export const selectPageBrandsTableCategory = (state: AppRootStateType) =>
  state.brandsTableCategory.page;
export const selectTotalCountPageBrandsTableCategory = (
  state: AppRootStateType,
) => state.brandsTableCategory.totalCountPage;
export const selectStatusBrandsTableCategory = (state: AppRootStateType) =>
  state.brandsTableCategory.status;
