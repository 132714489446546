import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAPI } from "src/api/api_helper";
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from "src/features/App/reducer";
import { InformationDataRequestType } from "src/pages/Contacts/UserSetting";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

interface ProfileStateType {
  error?: string | object | null | undefined | unknown;
  success?: any;
}
export const initialState: ProfileStateType = {
  error: "",
  success: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileError(state, action) {
      state.error = action.payload;
    },
  },
});

export const changeInformationTC = createAsyncThunk<
  any,
  InformationDataRequestType
>("profile/changeInformationTC", async (data, thunkAPI) => {
  // thunkAPI.dispatch(setAppStatusAC({ status: "loading" }));
  try {
    const res = await authAPI.informationChange(data);

    if (res.data.success === true) {
      thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
    } else {
      // thunkAPI.dispatch(setAppErrorAC({ error: "ERROR " }));
      thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
    }
  } catch (error: any) {
    return handleAsyncServerNetworkError(error, thunkAPI);
  }
});
export default profileSlice.reducer;
