import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryTableListAPI } from "src/api/api_categoryTableList/api_categoryTable";
import { merchantTableAPI } from "src/api/api_merchantTable/api_merchantTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setStatusTableCategoryList,
  setTableCategoryList,
  setTotalCountPageTableCategoryList,
} from "src/features/CategoryList/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchTableCategoryList = createAsyncThunk<any, any>(
  "tableCategoryList/fetchTableCategoryList",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableCategoryList({
        status: "tableCategoryList-loading",
      }),
    );
    try {
      const { res, resInfo } = await categoryTableListAPI.fetchCategoryList(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTableCategoryList({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusTableCategoryList({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setTotalCountPageTableCategoryList({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusTableCategoryList({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelTableCategoryList = createAsyncThunk<any, any>(
  "excelTableCategoryList/fetchExcelTableCategoryList",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableCategoryList({
        status: "tableCategoryList-loading",
      }),
    );
    try {
      const res = await categoryTableListAPI.fetchExcelCategoryList(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusTableCategoryList({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusTableCategoryList({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
