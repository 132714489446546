import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Simulate } from "react-dom/test-utils";
import { FaCheck } from "react-icons/fa";
import { Link, NavLink, useParams } from "react-router-dom";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { setCategoryId, setNameCategory } from "src/features/App/reducer";
import { setFilterTableCategoryList } from "src/features/CategoryList/reducer";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import {
  clickAscDesc,
  clickOrderName,
  deleteSubscribe,
  onChangePhrase,
  onClickPage,
  setCategoryImage,
  setStatusCategoryTable,
  setSubscribe,
} from "src/features/CategoryTable/reducer";
import {
  selectAscDesc,
  selectCategoryId,
  selectCategotyImage,
  selectColumnName,
  selectNameCategory,
  selectOrderName,
  selectPage,
  selectSubscribe,
  selectTotalCountPage,
} from "src/features/CategoryTable/selectors";
import { InputsType } from "src/features/CategoryTable/type";
import {
  clearFavoriteGoodsTableData,
  setFilterFavoriteGoods,
} from "src/pages/FavoriteGoods/reducer";
import {
  selectDataFavoriteGoods,
  selectFilteredTableFavoriteGoods,
  selectStatusFavoriteGoods,
} from "src/pages/FavoriteGoods/selectors";
import { fetchFavoriteGoods } from "src/pages/FavoriteGoods/thunk";
import {
  handlesMouseOut,
  handlesMouseOver,
} from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import DataTable from "react-data-table-component";
import s from "../../features/CategoryTable/Category.module.css";
import "../../features/CategoryTable/Category.module.css";
import { Tooltip } from "react-tooltip";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { useAppSelector } from "src/store/types";
import { useTranslation } from "react-i18next";

export const FavoriteGoods = () => {
  const { category_url } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: "" },
  ]);

  const [isHovered, setIsHovered] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const data: any = useAppSelector(selectDataFavoriteGoods);
  const columnName = useAppSelector(selectColumnName);
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  // const orderName = useAppSelector(selectOrderName);
  // const ascDesc = useAppSelector(selectAscDesc);
  // const page = useAppSelector(selectPage);
  // const image = useAppSelector(selectCategotyImage);
  // const totalCountPage = useAppSelector(selectTotalCountPage);
  const statusCategoryTable = useAppSelector(selectStatusFavoriteGoods);
  // const subscribe = useAppSelector(selectSubscribe);
  const filtered = useAppSelector(selectFilteredTableFavoriteGoods);
  const [tooltipData, setTooltipData] = useState<any>(null);

  // const toggle = useAppSelector(selectToggleSubscribeButton);
  const [loadStar, setLoadStar] = useState(false);
  let loading = statusCategoryTable === "tableFavoriteGoods-loading";
  let filteredData = data.filter((el: any) =>
    el.name.toLowerCase().includes(filtered.toLowerCase()),
  );
  document.title = `Restatos | ${
    categoryId === category_url && currentLanguage === "tr" ? nameCategory : ""
  }
      ${t("AnalyticalReportByCategory")}
                    ${
                      categoryId === category_url && currentLanguage !== "tr"
                        ? nameCategory
                        : ""
                    }`;

  // async function fetchData() {
  //   const resName = await instance.get<
  //     FetchResponseTable<Array<{ message: string; path: string }>>
  //   >(`trendyol/category/name/${category_url}`);
  //   dispatch(setCategoryId({ categoryId: category_url }));
  //   dispatch(setNameCategory({ categoryName: resName.data.message.name }));
  // }

  // if (categoryId !== category_url) {
  //   fetchData();
  // }
  useEffect(() => {
    dispatch(fetchFavoriteGoods(null));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearFavoriteGoodsTableData({}));
      setId(null);
      setIsHovered(false);
    };
  }, []);
  const mouseClick = useCallback(() => {
    setMouse(false);
  }, []);

  const handleInputChange = (index: number, value: string) => {
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    dispatch(setFilterFavoriteGoods({ value: value }));
    setInputs(updatedInputs);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhrase({ columnName: inputs }));
    }
  };

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPage({ page: page }));
  }, []);

  const handleSort = useCallback((column, sortDirection) => {
    dispatch(clickOrderName({ orderName: column.selector }));
    dispatch(clickAscDesc({ ascDesc: sortDirection }));
  }, []);

  const handleMouseEnter = (id: any) => {
    setIsHovered(true);
    setId(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setId(null);
  };

  const columns = [
    {
      name: t("image"),
      selector: "image",
      cell: (row: any) => (
        <div
        // style={{ display: "block", flexDirection: "row" }}
        >
          {
            <a
              target="_blank"
              href={`https://www.trendyol.com/p/p-p-${row.id}`}
              rel="noreferrer"
            >
              <img
                onMouseOver={(event: any) => {
                  handlesMouseOver(event, row.image, setTooltipData);
                }}
                onMouseOut={() => setTooltipData(null)}
                style={{ borderRadius: "15%", cursor: "pointer" }}
                // height={"35px"}
                height={"55px"}
                src={row.image}
                data-tip="Image"
              />
            </a>
          }
        </div>
      ),
    },
    {
      name: <div>{t("name")}</div>,
      // selector: (row) => row.name,
      selector: "name",
      title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseEnter={() => handleMouseEnter(row.id)}
            onMouseLeave={handleMouseLeave}
            to={`/trendyol/product/${row.id}`}
            target="_blank"
          >
            {row.name}
            {/*{row.name.substr(0, 22)}*/}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: <div style={{ marginLeft: "70px" }}> {t("brand")}</div>,
      // selector: (row) => row.brand,
      selector: "brandName",
      cell: (row: { brand_id: string; brandName: string; id: any }) => (
        <>
          <NavLink
            style={{ marginLeft: "70px" }}
            onMouseEnter={() => handleMouseEnter(row.id)}
            onMouseLeave={handleMouseLeave}
            to={`/trendyol/brand/${row.brand_id}/products`}
            // target="_blank"
          >
            {row.brandName}
            {/*{row.brandName.substr(0, 22)}*/}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("price"),
      // selector: (row) => row.barcode,
      selector: "priceIndicator",
      sortable: true,
      sortFunction: (a: any, b: any) => a.price - b.price,
      cell: (row: { id: number; price: any; priceIndicator: any }) => (
        <div
          style={{
            display: "flex",
            // marginLeft: "30px",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
          }}
        >
          {(row.price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
            " TL"}
          {row.priceIndicator === "up" ? (
            <span style={{ color: "red" }}>{`↑`}</span>
          ) : row.priceIndicator === "neutral" ? (
            " "
          ) : (
            <span style={{ color: "green" }}>{`↓`}</span>
          )}
        </div>
      ),
    },
    {
      name: t("BestPosition"),
      // selector: (row) => row.position,
      id: "positionIndicator",
      sortable: true,
      selector: "positionIndicator",
      sortFunction: (a: any, b: any) => a.position - b.position,
      format: (row: { positionIndicator: any }) =>
        row.positionIndicator.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      cell: (row: { id: number; positionIndicator: any; position: any }) => (
        <div
          style={{
            display: "flex",
            // marginLeft: "30px",
            justifyContent: "center",
            alignItems: "center",
            // fontSize: "24px",
            fontSize: "16px",
          }}
        >
          {row.position}
          {row.positionIndicator === "up" ? (
            <span style={{ color: "red" }}>{"↑"}</span>
          ) : row.positionIndicator === "neutral" ? (
            " "
          ) : (
            <span style={{ color: "green" }}>{"↓"}</span>
          )}
        </div>
      ),
    },
    {
      name: t("UpdatedDescription"),
      selector: "newDescription",
      // format: (row: { newImage: any }) =>
      //   row.newImage,
      cell: (row: { id: number; newDescription: any }) => (
        <div>
          {row.newDescription ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaCheck style={{ color: "green", marginRight: "5px" }} />
            </div>
          ) : (
            row.newDescription
          )}
        </div>
      ),
    },
    {
      name: t("UpdatedImage"),
      selector: "newImage",
      // format: (row: { newImage: any }) =>
      //   row.newImage,
      cell: (row: { id: number; newImage: any }) => (
        <div>
          {row.newImage ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaCheck style={{ color: "green", marginRight: "5px" }} />
            </div>
          ) : (
            row.newImage
          )}
        </div>
      ),
    },
  ];
  const onChangePagination = (newPage: number, newCount: number) => {
    dispatch(onClickPage({ page: newPage }));
    setCount(newCount);
  };

  return (
    <>
      {/*{mouse && <img className={s.bigImage} src={image} alt="" />}*/}
      {(loading && <CustomSpinner absolute={true} top={"300px"} />) ||
        (loadStar && <CustomSpinner absolute={true} top={"300px"} />)}
      <div
        className={loading || loadStar ? s.opasity : ""}
        style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div
              className="
               d-flex align-items-center justify-content-between"
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to="/user-settings">{t("Account")}</Link>
                  </BreadcrumbItem>{" "}
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    <Link target="_blank" to="">
                      {t("TrackedItems")}
                    </Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
          }}
        >
          <CardHeader>
            {/*{categoryId === category_url && currentLanguage === "tr" ? (*/}
            {/*  <> {nameCategory}&nbsp;</>*/}
            {/*) : null}*/}
            {/*{t("AnalyticalReportByCategory")}&nbsp;*/}
            {/*{categoryId === category_url && currentLanguage !== "tr"*/}
            {/*  ? nameCategory*/}
            {/*  : null}*/}
            {t("FavoriteGoods")}
            {/*{nameCategory}*/}
          </CardHeader>
        </div>
        <div
          style={{ height: "100%", background: "white", paddingLeft: "10px" }}
          className="d-flex flex-wrap gap-2 align-items-center"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            {inputs.map((input, index) => (
              // TODO
              <CustomInput
                id={input.id}
                disabled={loading}
                key={index}
                value={input.value}
                label={input.label}
                onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleInputChange(index, e.currentTarget.value)
                }
                onKeyPress={onKeyPress}
              />
            ))}
          </div>
        </div>
        <DataTable
          className={"customTableYaroslav"}
          defaultSortFieldId={"position"}
          striped
          defaultSortAsc={true}
          columns={columns as any}
          data={filteredData}
          subHeader
          fixedHeaderScrollHeight={newHeight}
          fixedHeader
          // sortServer={true}
          // sortIcon={null}
          // onChangePage={handlePageChange}
          // onSort={handleSort}
          responsive={true}
          // noDataComponent={<div>{t("dataTable")}</div>}
          noDataComponent={<div>{""}</div>}
        />
        {tooltipData && (
          <img
            style={{
              position: "absolute",
              top: tooltipData.y - 27,
              left: tooltipData.x + 30,
              background: "white",
              padding: "4px",
              height: "250px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            src={tooltipData.value}
            alt=""
          />
        )}

        {/*<SuperPagination*/}
        {/*  page={page}*/}
        {/*  itemsCountForPage={count}*/}
        {/*  totalCount={totalCountPage}*/}
        {/*  onChange={onChangePagination}*/}
        {/*/>*/}
      </div>
    </>
  );
};
