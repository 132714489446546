import { AppRootStateType } from "src/store/types";

export const selectColumnNameBrands = (state: AppRootStateType) =>
  state.categoryBrandsTable.columnName;
export const selectNameCategoryBrandsTable = (state: AppRootStateType) =>
  state.app.categoryName;
export const selectDataBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.table;

export const selectOrderNameBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.orderName;
export const selectAscDescBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.ascDesc;
export const selectPageBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.page;
export const selectTotalCountPageBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.totalCountPage;
export const selectStatusCategoryTableBrandsTable = (state: AppRootStateType) =>
  state.categoryBrandsTable.status;
