import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "IntegrationProducts",
  initialState: {
    table: [] as any,
    columnName: [] as Array<any>,
    dataInfoProduct: {} as any,
    priceUpdater: {} as any,
    page: 1 as number,
    orderName: "" as string,
    ascDesc: "" as string,
    totalCountPage: null as number | null,
    text: "" as string,
    update: false,
    status: "idle" as RequestStatusType,
    statusPriceUpdater: "idle" as RequestStatusType,
  },
  reducers: {
    setPriceUpdater(state, action: PayloadAction<{ priceUpdater: any }>) {
      state.priceUpdater = action.payload.priceUpdater;
    },
    setDataInfoProduct(state, action: PayloadAction<{ data: any }>) {
      state.dataInfoProduct = action.payload.data;
    },
    onChangePhrase(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setTable(state, action: PayloadAction<{ data: Array<any> }>) {
      state.table = [...action.payload.data];
    },
    setStatus(state, action: PayloadAction<{ status: RequestStatusType }>) {
      state.status = action.payload.status;
    },
    setStatusPriceUpdater(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusPriceUpdater = action.payload.status;
    },
    onClickPage(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    clickOrderName(state, action: PayloadAction<{ orderName: string }>) {
      state.orderName = action.payload.orderName;
    },
    clickAscDesc(state, action: PayloadAction<{ ascDesc: string }>) {
      state.ascDesc = action.payload.ascDesc;
    },
    setTotalCountPage(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    setUpdateTable(state, action: PayloadAction<{ update: boolean }>) {
      state.update = action.payload.update;
    },
    setWarning(state, action: PayloadAction<{ text: string }>) {
      state.text = action.payload.text;
    },
    resetInfoProduct(state, action) {
      state.dataInfoProduct = {};
    },
    resetIntegrationProducts(state, action) {
      return {
        ...state,
        table: [] as any,
        columnName: [] as Array<any>,
        dataInfoProduct: {},
        priceUpdater: {},
        page: 1 as number,
        orderName: "" as string,
        ascDesc: "" as string,
        totalCountPage: null as number | null,
        text: "" as string,
        status: "idle" as RequestStatusType,
      };
    },
  },
});

export const IntegrationProductsReducer = slice.reducer;
export const {
  setDataInfoProduct,
  setPriceUpdater,
  onChangePhrase,
  onClickPage,
  setTotalCountPage,
  setTable,
  setStatus,
  setStatusPriceUpdater,
  clickAscDesc,
  clickOrderName,
  setWarning,
  setUpdateTable,
  resetInfoProduct,
  resetIntegrationProducts,
} = slice.actions;
