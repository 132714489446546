import { AppRootStateType } from "src/store/types";

export const selectData = (state: AppRootStateType) =>
  state.categoryTable.tableCategory;
export const selectCategoryId = (state: AppRootStateType) =>
  state.app.categoryId;
export const selectColumnName = (state: AppRootStateType) =>
  state.categoryTable.columnName;
export const selectNameCategory = (state: AppRootStateType) =>
  state.app.categoryName;
export const selectOrderName = (state: AppRootStateType) =>
  state.categoryTable.orderName;
export const selectAscDesc = (state: AppRootStateType) =>
  state.categoryTable.ascDesc;
export const selectPage = (state: AppRootStateType) => state.categoryTable.page;
export const selectCategotyImage = (state: AppRootStateType) =>
  state.categoryTable.image;
export const selectTotalCountPage = (state: AppRootStateType) =>
  state.categoryTable.totalCountPage;
export const selectStatusCategoryTable = (state: AppRootStateType) =>
  state.categoryTable.statusCategoryTable;
export const selectSubscribe = (state: AppRootStateType) =>
  state.categoryTable.subscribe;
export const selectNameUrl = (state: AppRootStateType) => state.app.nameUrl;

//

export const selectStatusProducts = (state: AppRootStateType) =>
  state.categoryTable.statusProducts;
export const selectStatusBrandAndSellers = (state: AppRootStateType) =>
  state.categoryTable.statusBrandAndSellers;
export const selectStatusPriceAvg = (state: AppRootStateType) =>
  state.categoryTable.statusPriceAvg;
export const selectStatusDataRevenue = (state: AppRootStateType) =>
  state.categoryTable.statusDataRevenue;

export const selectDataBrandAndSellers = (state: AppRootStateType) =>
  state.categoryTable.blockBrandAndSellers;
export const selectDataPriceAvg = (state: AppRootStateType) =>
  state.categoryTable.dataPriceAvg;
export const selectDataProducts = (state: AppRootStateType) =>
  state.categoryTable.dataProducts;
export const selectDataRevenue = (state: AppRootStateType) =>
  state.categoryTable.blockRevenue;
export const selectChoiceProductInTheTops = (state: AppRootStateType) =>
  state.categoryTable.selectProductInTheTops;
export const selectChoiceBrandAndSellers = (state: AppRootStateType) =>
  state.categoryTable.selectBrandAndSellers;
