import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "listSellersTable",
  initialState: {
    table: [] as any,
    filteredTable: "" as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    columnName: [] as Array<InputsType>,
    totalCountPage: null as number | null,
    showVideo: true,
    restart: false,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setListSellersTable(state, action: PayloadAction<{ table: Array<any> }>) {
      state.table = [...action.payload.table];
    },
    setFilterListSellersTable(state, action: PayloadAction<{ value: string }>) {
      state.filteredTable = action.payload.value;
    },
    onClickPageListSellersTable(
      state,
      action: PayloadAction<{ page: number }>,
    ) {
      state.page = action.payload.page;
    },
    setTotalCountPageListSellersTable(
      state,
      action: PayloadAction<{
        totalCountPage: number | null;
      }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameListSellersTable(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescListSellersTable(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    onChangePhraseListSellersTable(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setShowVideoSellersList(
      state,
      action: PayloadAction<{ showVideo: boolean }>,
    ) {
      state.showVideo = action.payload.showVideo;
    },
    setStatusListSellersTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    setRestartListSellersTable(
      state,
      action: PayloadAction<{ restart: boolean }>,
    ) {
      state.restart = action.payload.restart;
    },
    resetListSellersTable(state, action) {
      return {
        ...state,
        table: [] as any,
        filteredTable: "" as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        columnName: [] as Array<InputsType>,
        page: 1 as number,
        restart: false,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const listSellersTableReducer = slice.reducer;

export const {
  setListSellersTable,
  setFilterListSellersTable,
  onClickPageListSellersTable,
  setTotalCountPageListSellersTable,
  clickOrderNameListSellersTable,
  clickAscDescListSellersTable,
  onChangePhraseListSellersTable,
  setShowVideoSellersList,
  resetListSellersTable,
  setRestartListSellersTable,
  setStatusListSellersTable,
} = slice.actions;
