import React from "react";
import { useTranslation } from "react-i18next";
import { FormFeedback, Input } from "reactstrap";

export const InputFormik = ({ formik, fieldName, type }: any) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor="gen-info-name-input">
        {t(fieldName)}
      </label>
      <Input
        type={type ? type : ""}
        disabled={formik.isSubmitting}
        className="form-control"
        id="gen-info-name-input"
        {...formik.getFieldProps(fieldName)}
        invalid={!!formik.errors[fieldName]}
      />
      {formik.errors[fieldName] ? (
        <FormFeedback type="invalid">{formik.errors[fieldName]}</FormFeedback>
      ) : null}
    </div>
  );
};
