import { createAsyncThunk } from "@reduxjs/toolkit";
import { listBrandTableAPI } from "src/api/api_listBrandTable/api_listBrandTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setListBrandTable,
  setStatusListBrandTable,
  setTotalCountPageListBrandTable,
} from "src/features/ListBrandTable/reducer";

import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchListBrandTable = createAsyncThunk<any, any>(
  "listBrandTable/fetchListBrandTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusListBrandTable({
        status: "listBrandTable-loading",
      }),
    );
    try {
      const { res, resInfo } = await listBrandTableAPI.fetchListBrandTable(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setListBrandTable({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusListBrandTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setTotalCountPageListBrandTable({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusListBrandTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelListBrandTable = createAsyncThunk<any, any>(
  "excelListBrandTable/fetchExcelListBrandTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusListBrandTable({
        status: "listBrandTable-loading",
      }),
    );
    try {
      const res = await listBrandTableAPI.fetchExcelListBrandTable(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;

        thunkAPI.dispatch(
          setStatusListBrandTable({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusListBrandTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
