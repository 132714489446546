import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { DescriptionEditType } from "src/features/product/miniProductComponent/descriptionEdit/type";

export const slice = createSlice({
  name: "feedback",
  initialState: {
    feedback: {} as any,
    page: 1 as number,
    totalCountPage: null as number | null,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setFeedback(state, action: PayloadAction<{ data: Record<string, any> }>) {
      // const newFeedback = { ...state.feedback };
      // for (const stateKey in action.payload.data) {
      //   if (action.payload.data.hasOwnProperty(stateKey)) {
      //     newFeedback[stateKey] = action.payload.data[stateKey];
      //   }
      // }
      //

      // state.feedback = newFeedback;

      state.feedback = action.payload.data;
    },
    setPage(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPage(
      state,
      action: PayloadAction<{ totalCountPage: number }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    setStatus(state, action: PayloadAction<{ status: RequestStatusType }>) {
      state.status = action.payload.status;
    },
    clearFeedback(state, action) {
      return {
        ...state,
        feedback: [] as Array<any>,
        page: 1 as number,
        totalCountPage: null as number | null,
        status: "idle" as RequestStatusType,
      };
    },
  },
});

export const feedbackReducer = slice.reducer;

export const {
  setFeedback,
  setPage,
  setTotalCountPage,
  setStatus,
  clearFeedback,
} = slice.actions;
