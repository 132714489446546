import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns";
import { useAppDispatch } from "../../../../../utilitsFunction/redux-utils";
import { fetchCategoryPositionTableProduct } from "./thunk";
import { CustomSpinner } from "../../../../../components/Spinner/Spinner";
import { selectDateSelect } from "../../variants/selectors";
import { useAppSelector } from "../../../../../store/types";
import trLocale from "date-fns/locale/tr";

import {
  selectDataTableCatPosition,
  selectStatusTableCatPosition,
} from "./selectors";
import { useTranslation } from "react-i18next";
import { clearCategoryPositionTableData } from "./reducer";
import { Card, CardBody, CardHeader, Nav, Table, Tooltip } from "reactstrap";

export const TableCategoryPosition = (props) => {
  const { t } = useTranslation();
  const dateSelect = useAppSelector(selectDateSelect);
  const data = useAppSelector(selectDataTableCatPosition);
  const status = useAppSelector(selectStatusTableCatPosition);
  const dispatch = useAppDispatch();
  const [top, setTop] = useState(false);

  const dateOne = dateSelect[0] ? `?date_from=${dateSelect[0]}` : "";
  const dateTwo = dateSelect[1] ? `&date_to=${dateSelect[1]}` : "";
  const id = props.id ? props.id : "";
  const [columnFilters, setColumnFilters] = useState({});
  useEffect(() => {
    dispatch(
      fetchCategoryPositionTableProduct({
        id,
        dateOne,
        dateTwo,
      }),
    );
  }, [dateSelect, props.id]);

  useEffect(() => {
    return () => {
      dispatch(clearCategoryPositionTableData({}));
    };
  }, []);

  const columns = [
    // {
    //   name: t("date"),
    //   selector: (row) => row.date,
    //   // format: (row) =>
    //   // format(new Date(row.date), "dd.MM.yyyy"),
    //   // format(row.date, "dd.MM.yyyy"),
    // },
    {
      name: t("date"),
      // selector: (row) => row.date,
      selector: (row) => {
        if (!row.date) {
          return "N/A"; // или любое другое значение по умолчанию
        }

        const parsedDate = parseISO(row.date);

        if (!isValid(parsedDate)) {
          return "Invalid Date"; // или любое другое значение для обработки некорректной даты
        }

        return format(parsedDate, "dd.MM.yyyy", { locale: trLocale });
      },
      // format: (row) => format(new Date(row.date), "dd.MM.yyyy"),
    },
    {
      test: t("category"),
      name: t("category"),
      selector: "category_name",
      cell: (row) => (
        <NavLink
          // target="_blank"
          to={`/trendyol/category/${row.category_id}/products`}
        >
          {row.category_name}
        </NavLink>
      ),
    },
    {
      name: t("position"),
      selector: (row) => row.position,
      format: (row) => row.position,
      // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
    },
  ];

  const [categoryFilter, setCategoryFilter] = useState("");

  const handleFilterChange = (columnSelector, filterValue) => {
    if (columnSelector === "category_name") {
      setCategoryFilter(filterValue);
    } else {
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [columnSelector]: filterValue,
      }));
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return (
        (categoryFilter === "" || item.category_name === categoryFilter) &&
        // Включаем другие фильтры по столбцам
        Object.entries(columnFilters).every(([columnSelector, filterValue]) => {
          if (!filterValue) return true;
          return (
            item[columnSelector].toString().toLowerCase() ===
            filterValue.toLowerCase().trim()
          );
          // .includes(filterValue.toLowerCase().trim());
        })
      );
    });
  }, [data, columnFilters, categoryFilter]);

  const getUniqueFilterOptions = (columnSelector) => {
    return [...new Set(data.map((item) => item[columnSelector]))];
  };

  const getSubHeaderComponent = () => {
    return (
      <div>
        {columns.map((column) => (
          <div
            key={column.selector}
            style={{
              // marginBottom: "7px",
              // paddingBottom: "17px",

              // marginTop: "5px",
              textAlign: "center",
            }}
          >
            {/*<label>{column.test}</label>*/}
            {column.selector === "category_name" && (
              <div>
                <select
                  className={"form-select form-select"}
                  value={categoryFilter}
                  onChange={(e) =>
                    handleFilterChange(column.selector, e.target.value)
                  }
                >
                  <option value="">{t("allCategories")}</option>
                  {getUniqueFilterOptions(column.selector).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [pointer, setPointer] = useState(false);

  const handleDivClick = () => {
    setIsScrollEnabled(true);
  };

  return (
    <>
      <div
        onClick={handleDivClick}
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        style={{
          cursor: pointer ? "pointer" : "default",
        }}
      >
        <div
          onMouseLeave={() => setIsScrollEnabled(false)}
          onClick={handleDivClick}
          // style={{
          //   boxShadow: isScrollEnabled ? "1px 1px 6px #6f6f6f" : "",
          //
          //   pointerEvents: isScrollEnabled ? "" : "none",
          //   cursor: "pointer",
          // }}
        >
          <Card>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                overflowX: "hidden",
                borderRadius: "5px",
              }}
            >
              <>
                <CardHeader
                  className="d-flex justify-content-between
               align-items-center
               "
                >
                  <div className="h4 card-title">
                    {t("categoryPosition")}
                    <Tooltip
                      placement="top"
                      isOpen={top}
                      target="categoryPosition"
                      toggle={() => {
                        setTop(!top);
                      }}
                    >
                      {t("categoryPositionTooltip")}
                    </Tooltip>
                    <i
                      className="uil-question-circle"
                      style={{ marginLeft: "3px", cursor: "pointer" }}
                      id={"categoryPosition"}
                    ></i>
                  </div>
                  {getSubHeaderComponent()}
                </CardHeader>
                <CardBody className={"customCardBody"}>
                  <div className="table-responsive">
                    <Table
                      className="table customTable
                   mb-0"
                    >
                      {status === "tableCategoryPosition-loading" ? (
                        <div
                          style={{
                            background: "white",
                            width: "500px",
                            height: "500px",
                          }}
                        >
                          <CustomSpinner absolute={true} top={"200px"} />
                        </div>
                      ) : (
                        <DataTable
                          defaultSortFieldId={"sold"}
                          columns={columns}
                          data={filteredData}
                          pagination={false}
                          responsive
                          // subHeaderComponent={
                          // }
                          noDataComponent={<div>{t("dataTable")}</div>}
                        />
                      )}
                    </Table>
                  </div>
                </CardBody>
              </>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
