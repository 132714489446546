import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt, FaShoppingCart, FaTimes } from "react-icons/fa";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Tooltip,
} from "reactstrap";
import { setAppErrorAC, setUserStatus } from "src/features/App/reducer";
import { setDateSelect1 } from "src/features/product/product-reducer";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { useAppSelector } from "../../store/types";
import { selectDateSelect } from "../../features/product/miniProductComponent/variants/selectors";
import { selectUserStatus } from "../../features/App/selectors";

export const MyDatePickerComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(
    moment().subtract("days", 30).toDate(),
  );
  const [endDate2, setEndDate2] = useState(
    moment().subtract("days", 1).toDate(),
  );
  const [selectedDates, setSelectedDates] = useState([endDate, endDate2]);

  const flatpickrRef = React.useRef(null); // Создаем ссылку на компонент Flatpickr

  const userStatus = useAppSelector(selectUserStatus);
  const dateSelect = useAppSelector(selectDateSelect);

  const minDate = new Date("2024/01/18");
  const maxDate = moment().subtract(1, "days").toDate();

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(selectedDates);
    }
  }, [selectedDates]);
  // const handleClear = () => {
  //   if (flatpickrRef.current) {
  //     flatpickrRef.current.flatpickr.clear(); // Вызываем метод clear() для сброса значения DatePicker, если ссылка существует
  //   }
  // };

  // const handleChange = (selected, dateStr, instance) => {
  //   // if (userStatus === "trial") {
  //   //   dispatch(setUserStatus({ userStatus: "unpaid" }));
  //   //   return;
  //   // }
  //   if (!selected[0]) {
  //     selected[0] = endDate;
  //   }
  //   if (!selected[1]) {
  //     selected[1] = endDate2;
  //   }
  //   let date1 = moment(selected[0]).toDate();
  //   let date2 = moment(selected[1]).toDate();
  //
  //   if (
  //     date1.getTime() === new Date(dateSelect[0]).getTime() &&
  //     date2.getTime() === new Date(dateSelect[1]).getTime()
  //   ) {
  //     return;
  //   }
  //   setSelectedDates([date1, date2]);
  //   let resultDate1 = format(date1, "yyyy-MM-dd");
  //
  //   // const resultDate2 = selected[1];
  //   const resultDate2 = moment(selected[1]).subtract(1, "day").toDate();
  //
  //   let endDateFinish = format(resultDate2, "yyyy-MM-dd");
  //
  //   console.log(date1.getTime(), "date1.getTime()");
  //   console.log(
  //     new Date(selectedDates[0]).getTime(),
  //     "new Date(selectedDates[0]).getTime()",
  //   );
  //   console.log(date2.getTime(), "date2.getTime()");
  //   console.log(
  //     new Date(selectedDates[1]).getTime(),
  //     "new Date(selectedDates[1]).getTime()",
  //   );
  //
  //   if (
  //     date1.getTime() !== new Date(selectedDates[0]).getTime() ||
  //     date2.getTime() !== new Date(selectedDates[1]).getTime()
  //   ) {
  //     dispatch(setDateSelect1({ dateSelect: [resultDate1, endDateFinish] }));
  //   }
  //
  //   // if (selected[0] !== dateSelect[0]) {
  //   //   dispatch(setDateSelect1({ dateSelect: [resultDate1, endDateFinish] }));
  //   // }
  // };

  const handleChange = (newSelectedDates, dateStr, instance) => {
    // Проверка статуса пользователя (закомментировано, но сохранено)
    // if (userStatus === "trial") {
    //   dispatch(setUserStatus({ userStatus: "unpaid" }));
    //   return;
    // }

    // Если первая дата не выбрана, используем `endDate`
    if (!newSelectedDates[0]) {
      newSelectedDates[0] = endDate;
    }

    // Если вторая дата не выбрана, используем `endDate2`
    if (!newSelectedDates[1]) {
      newSelectedDates[1] = endDate2;
    }

    // Преобразуем выбранные даты в объекты `Date` и приводим их к началу дня
    const date1 = moment(newSelectedDates[0]).startOf("day").toDate();
    const date2 = moment(newSelectedDates[1]).startOf("day").toDate();

    // Приводим текущие даты в состоянии `selectedDates` к началу дня
    const selectedDate1 = moment(selectedDates[0]).startOf("day").toDate();
    const selectedDate2 = moment(selectedDates[1]).startOf("day").toDate();

    // Сравнение дат по дням
    if (
      date1.getTime() === selectedDate1.getTime() &&
      date2.getTime() === selectedDate2.getTime()
    ) {
      return; // Даты не изменились, выходим
    }

    // Обновляем состояние выбранных дат
    setSelectedDates([date1, date2]);

    // Форматируем даты для обновления в Redux
    const resultDate1 = format(date1, "yyyy-MM-dd");
    const resultDate2 = moment(newSelectedDates[1])
      .subtract(1, "day")
      .startOf("day")
      .toDate();
    const endDateFinish = format(resultDate2, "yyyy-MM-dd");

    // Проверка, изменились ли выбранные даты, и обновление состояния в Redux
    if (
      date1.getTime() !== new Date(selectedDates[0]).getTime() ||
      date2.getTime() !== new Date(selectedDates[1]).getTime()
    ) {
      dispatch(setDateSelect1({ dateSelect: [resultDate1, endDateFinish] }));
    }

    // Закомментированная часть для дополнительной проверки (сохранено)
    // if (newSelectedDates[0] !== dateSelect[0]) {
    //   dispatch(setDateSelect1({ dateSelect: [resultDate1, endDateFinish] }));
    // }
  };

  return (
    <>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <Tooltip
          placement="top"
          isOpen={open}
          target={`preview`}
          toggle={() => setOpen(true)}
        >
          {t("You need to buy to access full features.")}
        </Tooltip>
      ) : null}
      <InputGroup
        id={`preview`}
        onMouseLeave={
          userStatus === "trial" || userStatus === "unpaid"
            ? () => setOpen(false)
            : null
        }
        style={{
          width: "218px",
          height: "40px",
          marginTop: "5px",
          marginRight: "3px",
        }}
      >
        <Flatpickr
          className="form-control"
          placeholder="yyyy-mm-dd"
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            clickOpens: true,
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: selectedDates, // Устанавливаем выбранные даты по умолчанию
            rangeSeparator: " - ",
          }}
          onClose={handleChange}
          ref={flatpickrRef}
          disabled={userStatus === "trial" || userStatus === "unpaid"}
        />
      </InputGroup>
    </>
  );
};
