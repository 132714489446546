import { createAsyncThunk } from "@reduxjs/toolkit";
import { brandsTableAPI } from "src/api/api_brandsTable/api_brandsTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setStatusTableBrandsSellers,
  setTableBrandsSellers,
  setTotalCountPageBrandsSellers,
} from "src/features/BrandsTableSellers/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchDataBrandsSellersTable = createAsyncThunk<any, any>(
  "brandsTableSellers/fetchDataBrandsSellersTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableBrandsSellers({
        status: "brandsTableSellers-loading",
      }),
    );
    try {
      const { res, resInfo } = await brandsTableAPI.fetchBrandsTableSellers(
        param.brandId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );

      if (res.data.success === true) {
        thunkAPI.dispatch(setTableBrandsSellers({ table: res.data.data }));
        thunkAPI.dispatch(
          setTotalCountPageBrandsSellers({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
        thunkAPI.dispatch(setStatusTableBrandsSellers({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableBrandsSellers({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelDataBrandsSellersTable = createAsyncThunk<any, any>(
  "excelDataBrandsSellersTable/fetchExcelDataBrandsSellersTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableBrandsSellers({
        status: "brandsTableSellers-loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchExcelBrandsTableSellers(
        param.brandId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );

      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(setStatusTableBrandsSellers({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableBrandsSellers({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
