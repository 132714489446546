import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch } from "../../../../../utilitsFunction/redux-utils";
import { fetchTablesSoldProduct } from "./thunk";
import { CustomSpinner } from "../../../../../components/Spinner/Spinner";
import { useAppSelector } from "../../../../../store/types";
import { selectDateSelect } from "../../variants/selectors";
import { selectDataTableSold, selectStatusTableSold } from "./selectors";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { clearTableSoldData } from "./reducer";
import { selectUserStatus } from "../../../../App/selectors";
import { setUserStatus } from "../../../../App/reducer";

export const TableSold = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const dateSelect = useAppSelector(selectDateSelect);
  const data = useAppSelector(selectDataTableSold);
  const status = useAppSelector(selectStatusTableSold);
  const userStatus = useAppSelector(selectUserStatus);

  const id = props.id ? props.id : "";

  const dateOne = dateSelect[0] ? `?date_from=${dateSelect[0]}` : "";
  const dateTwo = dateSelect[1] ? `&date_to=${dateSelect[1]}` : "";

  useEffect(() => {
    dispatch(
      fetchTablesSoldProduct({
        id,
        itemNumberUrl: props.itemNumberUrl,
        merchantId: props.merchantId,
        dateOne,
        dateTwo,
      }),
    );
  }, [dateSelect, props.id, props.itemNumberUrl, props.merchantId]);

  useEffect(() => {
    return () => {
      dispatch(clearTableSoldData({}));
    };
  }, []);

  const columns = [
    // {
    //   name: t("total"),
    //   format: (row) => {
    //     const total = (row.revenue || 0) + (row.sold || 0);
    //     total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    //   },
    // },

    {
      name: t("date"),
      selector: "date",
      sortable: false,
      // format: (row) => format(new Date(row.date), "dd.MM.yyyy"),
      format: (row) => row.date,
    },
    {
      name: t("FavoritesSalesTable"),
      selector: "favorite_count",
      // sortable: false,
      format: (row) =>
        row.favorite_count === null
          ? ""
          : row.favorite_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("ratingScore"),
      selector: "rating_score",
      // sortable: false,
      format: (row) =>
        row.rating_score === null
          ? ""
          : row.rating_score
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },

    {
      name: t("ratingCount"),
      selector: "rating_count",
      format: (row) =>
        row.rating_count === null
          ? ""
          : row.rating_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),

      // sortable: false,
    },

    {
      name: t("price"),
      selector: "price",
      sortable: false,
      format: (row) =>
        row.price === null
          ? ""
          : (row.price / 100)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, "") + " TL",
    },
    {
      name: t("sold"),
      selector: "sold",
      sortable: false,
      // format: (row) => (row.sold === null ? "" : row.sold),
      format: (row, index) => {
        if (row.sold === null) {
          return "";
        } else if (userStatus === "trial" || userStatus === "unpaid") {
          if (index === 0) {
            return (
              <i
                key={index}
                onClick={() =>
                  dispatch(setUserStatus({ userStatus: "unpaid" }))
                }
                className="uil uil-question-circle"
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
            );
          } else {
            return "*****"; // Заглушка для других элементов
          }
        } else {
          return row.sold; // Остальные случаи возвращают значение из данных
        }
      },
    },
    {
      name: t("revenue"),
      selector: "revenue",
      // sortable: false,
      // format: (row) =>
      //   row.revenue === null
      //     ? "" :   userStatus === "trial" || userStatus === "unpaid"
      //     : (row.revenue / 100)
      //         .toFixed(2)
      //         .toString()
      //         .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      format: (row, index) => {
        if (row.revenue === null) {
          return "";
        } else if (userStatus === "trial" || userStatus === "unpaid") {
          if (index === 0) {
            return (
              <i
                onClick={() =>
                  dispatch(setUserStatus({ userStatus: "unpaid" }))
                }
                className="uil uil-question-circle"
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
            );
          }
          return "*****";
        } else {
          return (
            (row.revenue / 100)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL"
          );
        }
      },
    },
  ];
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [pointer, setPointer] = useState(false);

  const handleDivClick = () => {
    setIsScrollEnabled(true);
  };

  return (
    <>
      <div
        onClick={handleDivClick}
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        // style={{
        //   cursor: pointer ? "pointer" : "default",
        // }}
      >
        <div
          onMouseLeave={() => setIsScrollEnabled(false)}
          onClick={handleDivClick}
          // style={{
          //   boxShadow: isScrollEnabled ? "1px 1px 6px #6f6f6f" : "",
          //   pointerEvents: isScrollEnabled ? "" : "none",
          // }}
        >
          <Card>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                borderRadius: "5px",
              }}
            >
              <>
                <CardHeader
                  className="d-flex
              justify-content-between
               align-items-center
               "
                >
                  <div className="h4 card-title">{t("sales")}</div>
                </CardHeader>

                <CardBody className={"customCardBody"}>
                  <div className="table-responsive">
                    <Table
                      className="table customTable
                   mb-0"
                    >
                      {status === "tableSold-loading" ? (
                        <div
                          style={{
                            background: "white",
                            width: "500px",
                            height: "500px",
                          }}
                        >
                          <CustomSpinner absolute={true} top={"200px"} />
                        </div>
                      ) : (
                        <DataTable
                          defaultSortFieldId={"sold"}
                          columns={columns}
                          data={data}
                          pagination={false}
                          responsive
                          noDataComponent={<div>{t("dataTable")}</div>}
                        />
                      )}
                    </Table>
                  </div>
                </CardBody>
              </>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
