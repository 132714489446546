import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import { ClearButton } from "src/components/InputClearButton/InputClearButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { TooltipInTable } from "src/components/tooltipInTable";
import WidgetCard from "src/components/Widgets/WidgetCard/WidgetCard";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
  setUserStatus,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
import WidgetBlockCategory from "src/features/CategoryTable/miniCategory/WidgetBlockCategory/WidgetBlockCategory";
import WidgetBlockCategoryTwo from "src/features/CategoryTable/miniCategory/WidgetBlockCategoryTwo/WidgetBlockCategoryTwo";
import TableDescription from "src/features/CategoryTable/table-description";
import {
  CategoryArg,
  CategoryMessage,
  InputsType,
} from "src/features/CategoryTable/type";
import {
  selectChoiceBrandAndSellers,
  selectDataBrandAndSellers,
  selectDataPriceAvg,
  selectDataProducts,
  selectDataRevenue,
  selectStatusBrandAndSellers,
  selectStatusPriceAvg,
  selectStatusProducts,
} from "src/features/CategoryTable/selectors";
import {
  fetchDataBrands,
  fetchDataPriceAvg,
  fetchDataProducts,
  fetchDataRevenue,
  fetchDataSellers,
} from "src/features/CategoryTable/thunk";
import {
  fetchFollow,
  fetchUnFollow,
} from "src/features/product/miniProductComponent/subscribeButton/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { clearDate } from "src/features/product/product-reducer";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import DataTable, { TableColumn } from "react-data-table-component";
import s from "./Category.module.css";
import "./Category.module.css";
import {
  clickAscDesc,
  clickOrderName,
  deleteSubscribe,
  onChangePhrase,
  onClickPage,
  resetCategoryTableState,
  setStatusCategoryTable,
  setSubscribe,
} from "./reducer";
import { CustomInput } from "./miniCategory/CustomInput";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { useAppSelector } from "src/store/types";
import {
  selectAscDesc,
  selectCategoryId,
  selectCategotyImage,
  selectColumnName,
  selectData,
  selectNameCategory,
  selectNameUrl,
  selectOrderName,
  selectPage,
  selectStatusCategoryTable,
  selectStatusDataRevenue,
  selectSubscribe,
  selectTotalCountPage,
} from "./selectors";
import { SuperPagination } from "./miniCategory/CustomPagination";
import { D3BarChart } from "./miniCategory/graph/D3BarChart";
import { fetchDataCategoryTable, fetchExcelDataCategoryTable } from "./thunk";
import { useTranslation } from "react-i18next";

export const Category = () => {
  const { price_from, price_to, name, brand, barcode } = useSearchParams();

  const { category_url } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [inputs, setInputs] = useState<InputsType[]>([
    {
      id: 0,
      label: "Price, from",
      name: "price_from",
      value: price_from ?? "",
    },
    {
      id: 1,
      label: "Price, up to",
      name: "price_to",
      value: price_to ?? "",
    },
    {
      id: 2,
      label: "Name",
      name: "name",
      value: name ?? "",
    },
    {
      id: 3,
      label: "Brand",

      name: "brand",
      value: brand ?? "",
    },
    { id: 4, label: "Barcode", name: "barcode", value: barcode ?? "" },
  ]);

  const [tooltipData, setTooltipData] = useState<any>(null);
  const [tooltipName, setTooltipName] = useState<any>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [mouse, setMouse] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const data: any = useAppSelector(selectData);
  const columnName = useAppSelector(selectColumnName);
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const orderName = useAppSelector(selectOrderName);
  const ascDesc = useAppSelector(selectAscDesc);
  const page = useAppSelector(selectPage);
  const image = useAppSelector(selectCategotyImage);
  const totalCountPage = useAppSelector(selectTotalCountPage);
  const statusCategoryTable = useAppSelector(selectStatusCategoryTable);
  const subscribe = useAppSelector(selectSubscribe);
  const nameUrl = useAppSelector(selectNameUrl);
  const userStatus = useAppSelector(selectUserStatus);

  // const toggle = useAppSelector(selectToggleSubscribeButton);
  const [loadStar, setLoadStar] = useState(false);

  let loading = statusCategoryTable === "categoryTable-loading";
  document.title = `Restatos | ${
    categoryId === category_url && currentLanguage === "tr" ? nameCategory : ""
  }
      ${t("AnalyticalReportByCategory")}
                    ${
                      categoryId === category_url && currentLanguage !== "tr"
                        ? nameCategory
                        : ""
                    }`;

  async function fetchData() {
    const resName = await instance.get<FetchResponseTable>(
      `trendyol/category/name/${category_url}`,
    );
    dispatch(setCategoryId({ categoryId: category_url }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== category_url) {
    fetchData();
  }

  useEffect(() => {
    // if (price_from || price_to || name || brand || barcode) {
    //   const newUrl = window.location.origin + window.location.pathname;
    //   window.history.pushState({}, document.title, newUrl);
    // }
    return () => {
      dispatch(resetCategoryTableState({}));
      dispatch(clearDate({}));
      setId(null);
      setIsHovered(false);
    };
  }, []);
  const dateSelect = useAppSelector(selectDateSelect);
  const param: any = {
    category_url,
    columnName: inputs,
    orderName,
    ascDesc,
    page,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };

  useEffect(() => {
    dispatch(fetchDataCategoryTable(param));

    //
    // dispatch(fetchDataRevenue(category_url));
    // dispatch(fetchDataPriceAvg(category_url));
    // dispatch(fetchDataProducts(category_url));
    //

    dispatch(fetchDataRevenue(param));
    dispatch(fetchDataPriceAvg(param));
    dispatch(fetchDataProducts(param));
  }, [
    category_url,
    columnName,
    ascDesc,
    orderName,
    page,
    dateSelect[0],
    dateSelect[1],
  ]);

  useEffect(() => {
    dispatch(onClickPage({ page: 1 }));
  }, [ascDesc, orderName, columnName]);

  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    const filteredValue =
      inputs[index].name === "price_from" || inputs[index].name === "price_to"
        ? value.replace(/\D/g, "")
        : value;

    if (value === "") {
      dispatch(
        setStatusCategoryTable({
          statusCategoryTable: "categoryTable-loading",
        }),
      );
      dispatch(onChangePhrase({ columnName: [] }));
    }

    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? filteredValue : input.value,
    }));

    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);

    const params = new URLSearchParams();
    updatedInputs.forEach((input) => {
      if (input.value !== "") {
        params.append(input.name, input.value);
      }
    });

    let url = window.location.pathname;
    if (params.toString() !== "") {
      url += `?${params.toString()}`;
    }
    window.history.pushState({}, "", url);

    const id = +setTimeout(() => {
      dispatch(onChangePhrase({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhrase({ columnName: inputs }));
    }
  };

  const handlePageChange = useCallback((page) => {
    dispatch(onClickPage({ page: page }));
  }, []);

  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderName({ orderName: column.selector }));
    dispatch(clickAscDesc({ ascDesc: sortDirection }));
  }, []);

  const handleMouseEnter = (id: any) => {
    setIsHovered(true);
    setId(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setId(null);
  };

  const follow = async (id: number) => {
    setLoadStar(true);
    try {
      const res = await dispatch(fetchFollow(id));

      if (res.payload.data.success) {
        setLoadStar(false);
        setId(id);
        dispatch(setSubscribe({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };
  const unFollow = async (id: number) => {
    setLoadStar(true);
    try {
      const res = await dispatch(fetchUnFollow(id));

      if (res.payload.data.success) {
        setLoadStar(false);
        setId(id);
        dispatch(deleteSubscribe({ id: id }));
      }
    } catch (error) {
      setLoadStar(false);
    }
  };
  const columns =
    ([
      {
        name: "",

        selector: "followed",
        width: "20px",
        cell: (row: any) => (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={
              subscribe[row.id] ? () => unFollow(row.id) : () => follow(row.id)
            }
          >
            {subscribe[row.id] ? (
              <i className="mdi mdi-star"></i>
            ) : (
              <i className="mdi mdi-star-outline"></i>
            )}
          </div>
        ),
      },
      {
        name: "",
        selector: "image",
        width: "55px",

        cell: (row) => (
          <div
          // style={{ display: "block", flexDirection: "row" }}
          >
            {
              <a
                target="_blank"
                href={`https://www.trendyol.com/p/p-p-${row.id}`}
                rel="noreferrer"
              >
                <img
                  onMouseOver={(event: any) => {
                    handlesMouseOver(event, row.image, setTooltipData);
                  }}
                  onMouseOut={() => setTooltipData(null)}
                  style={{ borderRadius: "15%", cursor: "pointer" }}
                  height={"35px"}
                  src={row.image}
                  data-tip="Image"
                />
              </a>
            }
          </div>
        ),
      },
      {
        // name: t("name"),
        name: <div>{t("name")}</div>,
        // selector: (row) => row.name,
        selector: "name",
        title: "Image Column Tooltip",
        cell: (row: { id: string; name: string }) => (
          <>
            <NavLink
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.name, setTooltipName);
              }}
              onMouseOut={() => setTooltipName(null)}
              // onMouseEnter={() => handleMouseEnter(row.id)}
              // onMouseLeave={handleMouseLeave}
              to={`/trendyol/product/${row.id}`}
              // target="_blank"
              id={`nameCategory${row.id}`}
            >
              {/*{row.name.substr(0, 22)}*/}
              {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
            </NavLink>
          </>
        ),
      },
      {
        name: t("brand"),
        // selector: (row) => row.brand,
        selector: "brand",
        sortable: true,
        cell: (row: { brand_id: string; brand: string; id: any }) => (
          <>
            <NavLink
              onMouseOver={(event: any) => {
                handlesMouseOver(event, row.brand, setTooltipName);
              }}
              onMouseOut={() => setTooltipName(null)}
              // onMouseEnter={() => handleMouseEnter(row.id)}
              // onMouseLeave={handleMouseLeave}
              to={`/trendyol/brand/${row.brand_id}/products`}
              target="_blank"
            >
              {row.brand.length > 22
                ? row.brand.substr(0, 22) + "..."
                : row.brand}
            </NavLink>
          </>
        ),
      },
      {
        name: t("barcode"),
        // selector: (row) => row.barcode,
        selector: "barcode",
        // sortable: true,
        cell: (row: { id: number; barcode: any }) => (
          <div
            onMouseEnter={() => handleMouseEnter(row.id)}
            onMouseLeave={handleMouseLeave}
          >
            {row.barcode}
          </div>
        ),
      },
      {
        name: t("position"),
        // selector: (row) => row.position,
        // id: "position",
        selector: "position",
        format: (row: { position: any }) =>
          row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        cell: (row: { id: number; position: any }) => (
          <div
            onMouseEnter={() => handleMouseEnter(row.id)}
            onMouseLeave={handleMouseLeave}
          >
            {row.position}
          </div>
        ),
        sortable: true,
      },
      {
        name: t("revenue"),
        selector: "revenue",
        id: "revenue",

        format: (row: { revenue: number }) =>
          (row.revenue / 100)
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
        sortable: true,
      },
      {
        name: t("sold"),
        selector: "sold",
        format: (row: { sold: any }) =>
          row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        sortable: true,
      },
      {
        name: t("stock"),
        selector: "stock",
        format: (row: { stock: any }) =>
          row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        sortable: true,
      },
      {
        name: t("price"),
        // selector: (row) => row.price,
        selector: "price",
        format: (row: { price: any }) =>
          (row.price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
          " TL",
      },
      {
        name: t("minPrice"),
        selector: "minPrice",
        format: (row: { minPrice: any }) =>
          (row.minPrice / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      },
      {
        name: t("maxPrice"),
        selector: "maxPrice",
        format: (row: { maxPrice: any }) =>
          (row.maxPrice / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
      },
      {
        name: t("merchantCount"),
        selector: "merchantCount",
        format: (row: { merchantCount: any }) =>
          row.merchantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("merchantsWithSold"),
        selector: "merchantsWithSold",
        format: (row: { merchantsWithSold: number }) =>
          row.merchantsWithSold
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        // sortable: true,
      },

      //
      {
        name: t("shareMerchants"),
        // selector: (row) => row.catCount,
        selector: "shareMerchants",
        format: (row: { shareMerchants: number }) =>
          row.shareMerchants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
          "%",
        // sortable: true,
      },
      //
      {
        name: t("favorites"),
        // selector: (row) => row.favorites,
        selector: "favorites",
        format: (row: { favorites: any }) =>
          row.favorites.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        sortable: true,
      },
      {
        name: t("avgStock"),
        selector: "avgStock",
        format: (row: { avgStock: any }) =>
          row.avgStock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      //

      {
        name: t("Average stocks per 1 seller"),
        selector: "avgStockPerMerchant",
        format: (row: { avgStockPerMerchant: any }) =>
          row.avgStockPerMerchant
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("Average stocks per 1 seller with sold"),
        selector: "avgStockPerMerchantWithSold",
        format: (row: { avgStockPerMerchantWithSold: any }) =>
          row.avgStockPerMerchantWithSold
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },

      //
      {
        name: t("tax"),
        selector: "tax",
        format: (row: { tax: any }) =>
          row.tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
      },
      {
        name: t("minRating"),
        selector: "minRating",
        format: (row: { minRating: any }) =>
          row.minRating
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("maxRating"),
        selector: "maxRating",
        format: (row: { maxRating: any }) =>
          row.maxRating
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      {
        name: t("minRatingCount"),
        selector: "minRatingCount",
        format: (row: { minRatingCount: any }) =>
          row.minRatingCount
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },

      {
        name: t("maxRatingCount"),
        selector: "maxRatingCount",
        format: (row: { maxRatingCount: any }) =>
          row.maxRatingCount
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      },
      //
      {
        name: t("Rating increment"),
        selector: "ratingIncrement",
        format: (row: { ratingIncrement: any }) =>
          row.ratingIncrement
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
      },
      //
      // new
      {
        name: t("Rating increment, pcs"),
        selector: "ratingIncrementCount",
        cell: (row: { ratingIncrementCount: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.ratingIncrementCount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </div>
        ),
      },
      {
        name: t("Count of comments at the beginning of the period"),
        selector: "minComments",
        cell: (row: { minComments: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.minComments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </div>
        ),
      },
      {
        name: t("Count of comments at the end of the period"),
        selector: "maxComments",
        cell: (row: { maxComments: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.maxComments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </div>
        ),
      },
      {
        name: t("Comment increment"),
        selector: "incrementComments",
        cell: (row: { incrementComments: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.incrementComments
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%"}
          </div>
        ),
      },
      {
        name: t("Comment increment, pcs"),
        selector: "incrementCommentsCount",
        cell: (row: { incrementCommentsCount: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.incrementCommentsCount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </div>
        ),
      },
      {
        name: t("catCount"),
        // selector: (row) => row.catCount,
        selector: "catCount",
        cell: (row: { catCount: any }) => (
          <div
            style={{
              marginRight: "-90px",
              marginLeft: "10px",
            }}
          >
            {row.catCount}
          </div>
        ),
        // sortable: true,
      },
      // new
      {
        name: t("chart"),
        cell: (row: { chart: Array<number> }) => (
          <div style={{ marginLeft: "-61px" }}>
            <D3BarChart data={row.chart} />
          </div>
        ),
      },
      // {
      //   name: <div style={{ marginLeft: "3px" }}>{t("chart")}</div>,
      //   cell: (row: { chart: Array<number> }) => (
      //     <div
      //       // style={{ marginLeft: "-51px" }}
      //       style={{ paddingRight: "-51px" }}
      //     >
      //       <D3BarChart data={row.chart} />
      //     </div>
      //   ),
      // },
    ] as React.JSX.IntrinsicAttributes & TableColumn<CategoryMessage>[]) ||
    undefined;
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPage({ page: newPage }));
    setCount(newCount);
  };
  const [modal_standard, setmodal_standard] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />
      {/*{tableDescription && (*/}
      {/*  <TableDescription setTableDescription={setTableDescription} />*/}
      {/*)}*/}
      {mouse && <img className={s.bigImage} src={image} alt="" />}
      {(loading && <CustomSpinner absolute={true} top={"600px"} />) ||
        (loadStar && <CustomSpinner absolute={true} top={"600px"} />)}
      <div
      // className={loading || loadStar ? s.opasity : ""}
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div
              className="
               d-flex align-items-center justify-content-between"
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>{" "}
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    <Link
                      // target="_blank"
                      to="/trendyol/category-list"
                    >
                      Kategori listesi
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === category_url && currentLanguage === "tr" ? (
                      <> {nameCategory}&nbsp;</>
                    ) : null}
                    {t("AnalyticalReportByCategory")}&nbsp;
                    {categoryId === category_url && currentLanguage !== "tr"
                      ? nameCategory
                      : null}
                  </BreadcrumbItem>
                  {/*<CardHeader>*/}
                  {/*{nameCategory}*/}
                  {/*</CardHeader>*/}
                  {/*<BreadcrumbItem*/}
                  {/*  style={{*/}
                  {/*    marginTop: "10px",*/}
                  {/*    marginBottom: "10px",*/}
                  {/*  }}*/}
                  {/*  active*/}
                  {/*>*/}
                  {/*  <span>{storedCategoryName}</span>*/}
                  {/*</BreadcrumbItem>*/}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">
              {categoryId === category_url && currentLanguage === "tr" ? (
                <> {nameCategory}&nbsp;</>
              ) : null}
              {t("AnalyticalReportByCategory")}&nbsp;
              {categoryId === category_url && currentLanguage !== "tr"
                ? nameCategory
                : null}
              {/*{nameCategory}*/}
              &nbsp;
              <a href={nameUrl} target="_blank">
                <i
                  className="uil-external-link-alt "
                  style={{ color: "DeepSkyBlue" }}
                ></i>
              </a>
              <ExcelButton
                action={() => dispatch(fetchExcelDataCategoryTable(param))}
              />
            </span>
            <MyDatePickerComponent />
          </CardHeader>
        </div>
        <div className="row" style={{ marginBottom: "2px", marginTop: "5px" }}>
          <WidgetCard
            bar={true}
            selector={selectStatusDataRevenue}
            dataSelector={selectDataRevenue}
            id={category_url}
            title={t("revenue")}
            // subtitle={"Бабло"}
            select={false}
          />
          <WidgetBlockCategory
            id={category_url}
            selector={selectStatusPriceAvg}
            dataSelector={selectDataPriceAvg}
          />
          <WidgetBlockCategoryTwo
            title={t("brands and sellers")}
            id={category_url}
            selector={selectStatusBrandAndSellers}
            dataSelector={selectDataBrandAndSellers}
            selectDropdown={true}
            selectChoiceBrandAndSellers={selectChoiceBrandAndSellers}
            fetchDataBrands={fetchDataBrands}
            fetchDataSellers={fetchDataSellers}
          />
          <WidgetBlockCategoryTwo
            title={t("products")}
            id={category_url}
            selector={selectStatusProducts}
            dataSelector={selectDataProducts}
            selectDropdown={false}
          />
        </div>
        <div
          className={loading || loadStar ? s.opasity : ""}
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <a
              className={"btn-primary btn-sm btn"}
              style={{ marginTop: "10px" }}
              href={`/trendyol/category/${category_url}/products`}
            >
              {t("products")}
            </a>
            <NavLink
              to={`/trendyol/category/${category_url}/sellers`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("sellers")}
            </NavLink>
            <NavLink
              to={`/trendyol/category/${category_url}/brands`}
              type="button"
              className="btn-sm btn btn-light"
              style={{
                marginTop: "10px",
              }}
            >
              {t("brands")}
            </NavLink>
            <NavLink
              to={`/trendyol/price/${category_url}`}
              type="button"
              className="btn-sm btn btn-light"
              style={{
                marginTop: "10px",
              }}
            >
              {t("Price segments")}
            </NavLink>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <i
                onClick={() => setmodal_standard(true)}
                // onClick={() => setTableDescription(true)}
                className="uil-question-circle "
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
              {inputs.map((input, index) => (
                <CustomInput
                  id={input.id}
                  disabled={loading}
                  key={index}
                  value={input.value}
                  label={input.label}
                  onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleInputChange(index, e.currentTarget.value)
                  }
                  onKeyPress={onKeyPress}
                />
              ))}
              <ClearButton
                inputs={inputs}
                setInputs={setInputs}
                setIsCleared={() =>
                  dispatch(onChangePhrase({ columnName: inputs }))
                }
              />
            </div>
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav stickyCategory  ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"revenue"}
            striped
            defaultSortAsc={false}
            columns={columns}
            data={data}
            subHeader
            fixedHeaderScrollHeight={newHeight}
            fixedHeader
            sortServer={true}
            sortIcon={null}
            // onChangePage={handlePageChange}
            onSort={handleSort}
            responsive={true}
            // noDataComponent={<div>{t("dataTable")}</div>}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          {tooltipData && (
            <img
              style={{
                zIndex: 100,
                position: "absolute",
                top: tooltipData.y - 27,
                left: tooltipData.x + 30,
                background: "white",
                padding: "4px",
                height: "250px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              src={tooltipData.value}
              alt=""
            />
          )}
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
          <TooltipInTable tooltipName={tooltipName} />
          {/*{tooltipName && (*/}
          {/*  <text*/}
          {/*    style={{*/}
          {/*      position: "absolute",*/}
          {/*      top: tooltipName.y - 27,*/}
          {/*      left: tooltipName.x + 30,*/}
          {/*      background: "white",*/}
          {/*      padding: "4px",*/}
          {/*      borderRadius: "4px",*/}
          {/*      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {tooltipName.value}*/}
          {/*  </text>*/}
          {/*)}*/}
          <SuperPagination
            page={page}
            itemsCountForPage={count}
            totalCount={totalCountPage}
            onChange={onChangePagination}
          />
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
