import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Col, Modal, Table } from "reactstrap";

const TableDescription = ({
  tog_standard,
  modal_standard,
  setmodal_standard,
}: any) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("All-data-are-for-the-last-30-days")}</p>
          <Col className="col-xxl-12" xl={12}>
            {/*<Card>*/}
            <CardHeader className="justify-content-between d-flex align-items-center">
              <CardTitle style={{ marginBottom: "7px" }} className="h4">
                {t("Description-of-columns-of-the-report-table")}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>{t("Column")}</th>
                      <th>{t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("NameCategoryTableBrands")}</td>
                      <td>{t("Brand name")}</td>
                    </tr>
                    <tr>
                      <td>{t("Brand's share of total revenue")}</td>
                      <td>
                        {t(
                          "Share of the brand's revenue to the revenue of all brands in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Revenue in this category")}</td>
                      <td>{t("Brand revenue in this category")}</td>
                    </tr>
                    <tr>
                      <td>{t("Total revenue")}</td>
                      <td>{t("Brand revenue in all categories")}</td>
                    </tr>
                    <tr>
                      <td>{t("Sold in category, pcs")}</td>
                      <td>
                        {t(
                          "Number of sold goods of the brand in this category, pcs",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total sold, pcs")}</td>
                      <td>
                        {t(
                          "Number of sold goods of the brand in all categories, pcs",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of category sales in total")}</td>
                      <td>
                        {t(
                          "Share of the sold quantity of a brand's product in a given category to its sales in all categories",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods in a category, pcs")}</td>
                      <td>
                        {t("Number of brand products in a given category")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods in the category with sales, pcs")}</td>
                      <td>
                        {t(
                          "Number of brand products in a given category with at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of goods with sales")}</td>
                      <td>
                        {t(
                          "Share of the number of a brand's products in a given category to the number of its products in that category with sales",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total goods, pcs")}</td>
                      <td>{t("Number of brand products in all categories")}</td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Share of quantity of goods in cat. of total quantity",
                        )}
                      </td>
                      <td>
                        {t(
                          "Share of the number of a brand's products in a given category to the number of its products in all categories",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Average number of sales per product")}</td>
                      <td>
                        {t(
                          "Number of brand sales divided by the number of brand products in a given category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("Average number of sales per 1 goods with sales")}
                      </td>
                      <td>
                        {t(
                          "Number of brand sales divided by the number of items with brand sales in a given category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Median price")}</td>
                      <td>
                        {t(
                          "The median price of the brand (roughly like the average, but slightly more correct in this context)",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Number of categories")}</td>
                      <td>
                        {t(
                          "Number of categories in which the brand's product is found",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sales chart")}</td>
                      <td>
                        {t(
                          "Displays the number of sales by day. One column is 1 day. Total 30 bars",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </CardBody>
  );
};

export default TableDescription;
