import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAPI } from "src/api/api_helper";
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from "src/features/App/reducer";
import {
  setForgotPasswordAc,
  setStatusForgotPassword,
} from "src/pages/ForgotPassword/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const forgotPasswordTC = createAsyncThunk<any, { email: string }>(
  "forgotPassword/forgotPasswordTC",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusForgotPassword({ status: "forgot-password-loading" }),
    );
    try {
      const res = await authAPI.forgotPassword(data);
      if (res.data.success === true) {
        thunkAPI.dispatch(setForgotPasswordAc({ value: res.data.message }));
        thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
        // thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
        thunkAPI.dispatch(setStatusForgotPassword({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusForgotPassword({ status: "succeeded" }));
        return handleAsyncServerNetworkError(res.data.error, thunkAPI);
        // thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
      }
    } catch (error: any) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
