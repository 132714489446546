import React, { useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { Button, Spinner } from "reactstrap";
import { clearSubscribeButton } from "src/features/product/miniProductComponent/subscribeButton/reducer";
import {
  selectIsSubscribe,
  selectStatusSubscribeButton,
  selectToggleSubscribeButton,
} from "src/features/product/miniProductComponent/subscribeButton/selectors";
import {
  fetchFollow,
  fetchIsSubscribe,
  fetchUnFollow,
} from "src/features/product/miniProductComponent/subscribeButton/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

type PropsType = {
  id: string;
  merchantId: string;
  itemNumberUrl: string;
};

export const SubscribeButton = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const isSubscribe = useAppSelector(selectIsSubscribe);
  const status = useAppSelector(selectStatusSubscribeButton);
  const toggle = useAppSelector(selectToggleSubscribeButton);

  useEffect(() => {
    dispatch(fetchIsSubscribe(props.id));
  }, [toggle]);

  useEffect(() => {
    return () => {
      dispatch(clearSubscribeButton);
    };
  }, []);

  const customColor = {
    backgroundColor: isSubscribe ? "green" : "#fccf16",
    borderColor: isSubscribe ? "green" : "#e7d06f",
  };
  const param = {
    id: props.id,
    itemNumberUrl: props.itemNumberUrl,
    merchantId: props.merchantId,
  };

  const toggleSubscription = () => {
    if (isSubscribe) {
      dispatch(fetchUnFollow(props.id));
    } else {
      dispatch(fetchFollow(props.id));
    }
  };

  return (
    <>
      {status === "subscribe-loading" ? (
        <Spinner
          // style={{ color: "skyblue" }}
          color={"info"}
        />
      ) : (
        <Button
          style={{
            marginLeft: "auto",
            marginTop: "-5px",
            marginBottom: "-5px",
          }}
          color="info"
          size="sm"
          active={isSubscribe as any}
          onClick={toggleSubscription}
        >
          <FaStar style={{ marginBottom: "3px" }} />
          &nbsp; {isSubscribe ? "Following" : "Follow"}
        </Button>
        // <Button size={"sm"} onClick={toggleSubscription} style={customColor}>
        //   <FaStar style={{ marginBottom: "3px" }} />
        //   &nbsp;
        //   {isSubscribe ? "UnFollow" : "Follow"}
        // </Button>
      )}
    </>
  );
};
