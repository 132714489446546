export const findHeight = (ref: any) => {
  const isMobile = window.innerWidth <= 760;
  const newHeight = isMobile ? "49vh" : ref > 700 ? ref - 295 + "px" : "auto";
  return newHeight;
};
export const findHeightListTable = (ref: any) => {
  const isMobile = window.innerWidth <= 760;
  const newHeight = isMobile ? "63vh" : ref > 700 ? ref - 275 + "px" : "auto";
  return newHeight;
};
