import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "sellersTable",
  initialState: {
    columnName: [] as Array<InputsType>,
    data: [] as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    nameCategory: null as null | string,
    totalCountPage: null as number | null,
    page: 1 as number,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    onChangePhraseSellersTable(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setDataSellersTable(state, action: PayloadAction<{ data: any }>) {
      state.data = [...action.payload.data];
    },
    setOrderNameSellersTable(
      state,
      action: PayloadAction<{ orderName: string }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    setNameCategorySellerTable(
      state,
      action: PayloadAction<{ nameCategory: string }>,
    ) {
      state.nameCategory = action.payload.nameCategory;
    },
    setAscDescSellersTable(state, action: PayloadAction<{ ascDesc: string }>) {
      state.ascDesc = action.payload.ascDesc;
    },
    setTotalCountPageSellersTable(
      state,
      action: PayloadAction<{ totalCountPage: number }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    setPageSellersTable(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setStatusSellersTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },

    clearTableSellers(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        data: [] as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        totalCountPage: null as number | null,
        page: 1 as number,
      };
      // return {
      //   ...state,
      //   columnName: [] as Array<InputsType>,
      //   table: [] as any,
      //   filteredTable: "" as any,
      //   orderName: null as null | string,
      //   ascDesc: null as null | string,
      //   page: 1 as number,
      //   totalCountPage: null as number | null,
      // };
    },
  },
});
export const categorySellersTableReducer = slice.reducer;
export const {
  onChangePhraseSellersTable,
  setDataSellersTable,
  setOrderNameSellersTable,
  setAscDescSellersTable,
  setStatusSellersTable,
  setTotalCountPageSellersTable,
  setPageSellersTable,
  setNameCategorySellerTable,
  clearTableSellers,
} = slice.actions;
