import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { selectDataBrandsSellers } from "src/features/BrandsTableSellers/selectors";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "brandsTableSellers",
  initialState:
    ({
      columnName: [] as Array<InputsType>,
      orderName: null as null | string,
      ascDesc: null as null | "asc" | "desc",
      nameCategory: null as null | string,
      page: 1 as number,
      totalCountPage: null as number | null,
      table: [] as Array<CategoryMessage>,
      status: "idle" as RequestStatusType,
    } as any) || {},
  reducers: {
    onChangePhraseBrandsSellers(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    onClickPageBrandsSellers(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPageBrandsSellers(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameBrandsSellers(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescBrandsSellers(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setTableBrandsSellers(state, action: PayloadAction<{ table: Array<any> }>) {
      state.table = [...action.payload.table];
    },
    setStatusTableBrandsSellers(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetBrandsTableStateSellers(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        orderName: null as null | string,
        ascDesc: null as null | string,
        // nameCategory: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
        table: [],
      };
    },
  },
});

export const brandsSellersTableReducer = slice.reducer;
export const {
  onChangePhraseBrandsSellers,
  onClickPageBrandsSellers,
  setTotalCountPageBrandsSellers,
  setTableBrandsSellers,
  clickAscDescBrandsSellers,
  clickOrderNameBrandsSellers,
  setStatusTableBrandsSellers,
  resetBrandsTableStateSellers,
} = slice.actions;
