import { createAsyncThunk } from "@reduxjs/toolkit";
import { integrationProductsAPI } from "src/api/api-integration-products/api-integration-products";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import { InformationDataRequestType } from "src/pages/Contacts/UserSetting";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const integrationDataTC = createAsyncThunk<
  any,
  InformationDataRequestType
>("integration/integrationDataTC", async (data, thunkAPI) => {
  try {
    const res = await integrationProductsAPI.fetchTrendyolAPICredsSave(data);

    if (res.data.success === true) {
      thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
    } else {
      thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
    }
  } catch (error: any) {
    return handleAsyncServerNetworkError(error, thunkAPI);
  }
});
