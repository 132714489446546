import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "PriceSegmentsTable",
  initialState: {
    table: [] as any,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setPriceSegmentsTable(state, action: PayloadAction<{ table: Array<any> }>) {
      state.table = [...action.payload.table];
    },
    setStatusPriceSegmentsTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetPriceSegmentsTable(state, action) {
      return {
        ...state,
        table: [] as any,
      };
    },
  },
});
export const priceSegmentsTable = slice.reducer;

export const {
  setPriceSegmentsTable,
  resetPriceSegmentsTable,
  setStatusPriceSegmentsTable,
} = slice.actions;
