import { createAsyncThunk } from "@reduxjs/toolkit";
import { brandsTableAPI } from "src/api/api_brandsTable/api_brandsTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setDataCompetitors,
  setDataProductInTheTops,
  setDataRating,
  setDataRevenue,
  setSelectCompetitors,
  setSelectProductInTheTops,
  setStatusCompetitors,
  setStatusDataRating,
  setStatusDataRevenue,
  setStatusProductInTheTops,
  setStatusTableBrands,
  setTableBrands,
  setTotalCountPageBrands,
} from "src/features/BrandsTable/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchDataBrandsTableProduct = createAsyncThunk<any, any>(
  "brandsTableProduct/fetchDataBrandsTableProduct",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableBrands({
        status: "brandsTableProduct-loading",
      }),
    );
    try {
      const { res, resInfo } = await brandsTableAPI.fetchBrandsTableProduct(
        param.brandId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTableBrands({ table: res.data.data }));
        thunkAPI.dispatch(
          setTotalCountPageBrands({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
        thunkAPI.dispatch(setStatusTableBrands({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableBrands({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelDataBrandsTableProduct = createAsyncThunk<any, any>(
  "excelDataBrandsTableProduct/fetchExcelDataBrandsTableProduct",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableBrands({
        status: "brandsTableProduct-loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchExcelBrandsTableProduct(
        param.brandId,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(setStatusTableBrands({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableBrands({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchDataCompetitorsSold = createAsyncThunk<any, any>(
  "dataCompetitorsSold/fetchDataCompetitorsSold",
  async (brandId, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCompetitors({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchDataCompetitorsSold(brandId);
      if (res.data.success === true) {
        thunkAPI.dispatch(
          setSelectCompetitors({ select: "Revenue in this category" }),
        );
        thunkAPI.dispatch(
          setDataCompetitors({ data: res.data.message.data.competitors }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
      } else {
        // alert("error");
        thunkAPI.dispatch(
          setSelectCompetitors({
            select: "Number of products in the top of category",
          }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      thunkAPI.dispatch(
        setSelectCompetitors({
          select: "Number of products in the top of category",
        }),
      );
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Competitors - Category Revenue

// export const fetchCategoryRevenue = createAsyncThunk<any, any>(
//   "categoryRevenue/fetchCategoryRevenue",
//   async (id, thunkAPI) => {
//     thunkAPI.dispatch(
//       setStatusCompetitors({
//         status: "loading",
//       }),
//     );
//     try {
//       const res = await brandsTableAPI.fetchCategoryRevenue(id);
//       if (res.data.success === true) {
//         thunkAPI.dispatch(
//           setSelectCompetitors({ select: "Выручка в категории" }),
//         );
//         thunkAPI.dispatch(
//           setDataCompetitors({ data: res.data.message.data.competitors }),
//         );
//         thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
//       } else {
//         thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
//         thunkAPI.dispatch(
//           setSelectCompetitors({ select: "Товаров в топе категории, шт" }),
//         );
//         thunkAPI.dispatch(
//           setAppErrorAC({
//             error:
//               "Something went wrong. We're already looking into the problem",
//           }),
//         );
//         // return handleAsyncServerAppError(res.data, thunkAPI);
//       }
//     } catch (error) {
//       return handleAsyncServerNetworkError(error, thunkAPI);
//     }
//   },
// );

// Получаем данные для Competitors - items in the category top
export const fetchItemsInTheCategoryTop = createAsyncThunk<any, any>(
  "itemsInTheCategoryTop/fetchItemsInTheCategoryTop",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCompetitors({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchItemsInTheCategoryTop(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(
          setSelectCompetitors({
            select: "Number of products in the top of category",
          }),
        );
        thunkAPI.dispatch(
          setDataCompetitors({ data: res.data.message.data.competitors }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(
          setSelectCompetitors({ select: "Revenue in this category" }),
        );
        thunkAPI.dispatch(setStatusCompetitors({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      thunkAPI.dispatch(
        setSelectCompetitors({ select: "Revenue in this category" }),
      );
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchDataRating = createAsyncThunk<any, any>(
  "dataRating/fetchDataRating",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusDataRating({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchDataRating(
        param.brandId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataRating({ rating: res.data }));
        thunkAPI.dispatch(setStatusDataRating({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusDataRating({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Селекта - Search
export const fetchDataSearch = createAsyncThunk<any, any>(
  "dataSearch/fetchDataSearch",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductInTheTops({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchDataSearch(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Search" }));

        thunkAPI.dispatch(setDataProductInTheTops({ data: res.data }));
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Categories" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
// Получаем данные для Селекта - Category
export const fetchDataCategory = createAsyncThunk<any, any>(
  "dataCategory/fetchDataCategory",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProductInTheTops({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchDataCategory(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Categories" }));
        thunkAPI.dispatch(setDataProductInTheTops({ data: res.data }));
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusProductInTheTops({ status: "succeeded" }));
        thunkAPI.dispatch(setSelectProductInTheTops({ select: "Search" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchDataRevenue = createAsyncThunk<any, any>(
  "dataRevenue/fetchDataRevenue",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusDataRevenue({
        status: "loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchDataRevenue(
        param.brandId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataRevenue({ revenue: res.data }));
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
