import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Col, Modal, Table } from "reactstrap";

const TableDescription = ({
  tog_standard,
  modal_standard,
  setmodal_standard,
}: any) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("All-data-are-for-the-last-30-days")}</p>
          <Col className="col-xxl-12" xl={12}>
            {/*<Card>*/}
            <CardHeader className="justify-content-between d-flex align-items-center">
              <CardTitle style={{ marginBottom: "7px" }} className="h4">
                {t("Description-of-columns-of-the-report-table")}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>{t("Column")}</th>
                      <th>{t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Image")}</td>
                      <td>
                        {t(
                          "Actual picture of the seller on Trendyol. Clickable",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Name")}</td>
                      <td>{t("Seller name")}</td>
                    </tr>
                    <tr>
                      <td>{t("Seller's share of total revenue")}</td>
                      <td>
                        {t(
                          "Share of a seller's revenue to the revenue of all sellers in the category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Revenue in this category")}</td>
                      <td>
                        {t("Revenue of the seller in the given category")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total revenue")}</td>
                      <td>{t("Seller's revenue in all categories")}</td>
                    </tr>
                    <tr>
                      <td>{t("Sold in category, pcs")}</td>
                      <td>
                        {t(
                          "Quantity of goods sold by the seller in the current category, pcs",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total sold, pcs")}</td>
                      <td>
                        {t(
                          "Quantity of goods sold by the seller in all categories, pcs",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of category sales in total")}</td>
                      <td>
                        {t(
                          "Share of the sold quantity of the seller's goods in the current category from his sales in all categories",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods in a category, pcs")}</td>
                      <td>
                        {t(
                          "Number of goods of the seller in the given category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Goods in the category with sales, pcs")}</td>
                      <td>
                        {t(
                          "The number of products of the seller in the given category that have at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of goods with sales")}</td>
                      <td>
                        {t(
                          "Share of the number of the seller's products in this category to the number of his products in this category with sales",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Total goods, pcs")}</td>
                      <td>
                        {t("Number of the seller's products in all categories")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Share of quantity of goods in cat. of total quantity",
                        )}
                      </td>
                      <td>
                        {t(
                          "Share of the number of the seller's products in this category to the number of his products in all categories",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Average number of sales per product")}</td>
                      <td>
                        {t(
                          "The number of sales of the seller divided by the number of products of the seller in the given category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("Average number of sales per 1 goods with sales")}
                      </td>
                      <td>
                        {t(
                          "Number of sales of the seller divided by the number of products with sales of the seller in the given category",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Median price")}</td>
                      <td>
                        {t(
                          "Median price at the seller (roughly like the average, but slightly more accurate)",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Stock, pcs.")}</td>
                      <td>
                        {t(
                          "Stocks of the item at the time of the last receipt of data",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Rating")}</td>
                      <td>{t("Current seller rating")}</td>
                    </tr>
                    <tr>
                      <td>{t("Number of categories")}</td>
                      <td>
                        {t(
                          "Number of categories in which the product is found",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sales chart")}</td>
                      <td>
                        {t(
                          "Displays the number of sales by day. One column is 1 day. Total 30 bars",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </CardBody>
  );
};

export default TableDescription;
