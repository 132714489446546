import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Table,
} from "reactstrap";

const TableDescription = ({
  tog_standard,
  modal_standard,
  setmodal_standard,
}: any) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t("All-data-are-for-the-last-30-days")}</p>
          <Col className="col-xxl-12" xl={12}>
            {/*<Card>*/}
            <CardHeader className="justify-content-between d-flex align-items-center">
              <CardTitle style={{ marginBottom: "7px" }} className="h4">
                {t("Description-of-columns-of-the-report-table")}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>{t("Column")}</th>
                      <th>{t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Follow")}</td>
                      <td>
                        {t(
                          'Make the product a favorite. It will be on the ”Favorites goods" page',
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Image")}</td>
                      <td>
                        {t(
                          "Actual image of the product on Trendyol. Clickable",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Name")}</td>
                      <td>{t("Product brand name")}</td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td>{t("BrandCategoryTable")}</td>*/}
                    {/*  <td>{t("Product brand name")}</td>*/}
                    {/*</tr>*/}
                    <tr>
                      <td>{t("Barcode, pcs")}</td>
                      <td>{t("Number of barcodes (variants)")}</td>
                    </tr>
                    <tr>
                      <td>{t("Best position in a category")}</td>
                      <td>{t("Best product position in any category")}</td>
                    </tr>
                    <tr>
                      <td>{t("Revenue")}</td>
                      <td>
                        {t(
                          "Number of items sold multiplied by the price on the day of sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sold, pcs")}</td>
                      <td>{t("Number of units sold in the last 30 days")}</td>
                    </tr>
                    <tr>
                      <td>{t("Stock, pcs.")}</td>
                      <td>
                        {t(
                          "Stocks of the item at the time of the last receipt of data",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Price")}</td>
                      <td>
                        {t(
                          "Price of goods at the time of last receipt of data",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Min. price")}</td>
                      <td>{t("Minimum price in the last 30 days")}</td>
                    </tr>
                    <tr>
                      <td>{t("Max price")}</td>
                      <td>{t("Maximum price in the last 30 days")}</td>
                    </tr>
                    <tr>
                      <td>{t("Number of merchants")}</td>
                      <td>{t("Number of sellers selling this item")}</td>
                    </tr>
                    <tr>
                      <td>{t("Number of merchants with sales")}</td>
                      <td>
                        {t(
                          "Number of sellers selling this product and having at least 1 sale",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Share of merchants with sales")}</td>
                      <td>
                        {t(
                          "The ratio of the number of sellers with sales to the total number of sellers of the product",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Favorites")}</td>
                      <td>{t("Number of Favorite marks")}</td>
                    </tr>
                    <tr>
                      <td>{t("Average stock, pcs.")}</td>
                      <td>{t("Average stocks for the last 30 days")}</td>
                    </tr>
                    <tr>
                      <td>{t("Average stocks per 1 seller")}</td>
                      <td>
                        {t(
                          "Average stocks for the last 30 days divided by the number of sellers",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Average stocks per 1 seller with sold")}</td>
                      <td>
                        {t(
                          "Average stocks for the last 30 days divided by the number of salespeople with sales",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("TAX")}</td>
                      <td>{t("TAXDescription")}</td>
                    </tr>
                    <tr>
                      <td>{t("Min. rating")}</td>
                      <td>{t("Minimum rating in the last 30 days")}</td>
                    </tr>
                    <tr>
                      <td>{t("Max. rating")}</td>
                      <td>{t("Maximum rating for the last 30 days")}</td>
                    </tr>

                    <tr>
                      <td>
                        {t("Count of rating at the beginning of the period")}
                      </td>
                      <td>
                        {t("Number of ratings in the rating 30 days ago")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Count of rating at the end of the period")}</td>
                      <td>
                        {t(
                          "Number of ratings at the time of the last data retrieval",
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Rating increment")}</td>
                      <td>
                        {t(
                          "Share by which the number of ratings in the rating has increased since the beginning of the reporting period",
                        )}
                      </td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td>{t("Number of categories")}</td>*/}
                    {/*  <td>*/}
                    {/*    {t(*/}
                    {/*      "Number of categories in which the product is found",*/}
                    {/*    )}*/}
                    {/*  </td>*/}
                    {/*</tr>*/}

                    <tr>
                      <td>{t("Sales chart")}</td>
                      <td>
                        {t(
                          "Displays the number of sales by day. One column is 1 day. Total 30 bars",
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </CardBody>
  );
};

export default TableDescription;
