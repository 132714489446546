import { AppRootStateType } from "src/store/types";
export const selectDateSelect = (state: AppRootStateType) =>
  state.product.dateSelect1;

export const selectVariant = (state: AppRootStateType) =>
  state.variant.variants;
export const selectStatusVariant = (state: AppRootStateType) =>
  state.variant.statusVariant;
export const selectMerchantId = (state: AppRootStateType) =>
  state.seller.merchantId;
// TODO
// export const selectUse = (state: AppRootStateType) => state.product.itemNumber;
