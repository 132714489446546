import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { SelectDropdown } from "src/components/Widgets/SelectDropdown/SelectDropdown";
// import { SelectDropdown } from "src/components/Widgets/SelectDropdown/SelectDropdown";

import { useAppSelector } from "src/store/types";

const TrafficSource = ({
  nameOne,
  nameTwo,
  brand,
  id,
  select,
  dataCompetitors,
  selectChoiceCompetitors,
  selectStatusCompetitors,
  fetchDataCompetitorsSold,
  fetchItemsInTheCategoryTop,
}: any) => {
  const { t, i18n } = useTranslation();
  const status = useAppSelector(selectStatusCompetitors);
  const option = useAppSelector(selectChoiceCompetitors);
  const data = useAppSelector(dataCompetitors);

  function formatRevenue(revenue: any) {
    const numericValue = Number(revenue);
    if (typeof numericValue === "undefined") {
      return <span className="placeholder" style={{ width: "25%" }}></span>;
    }
    if (option === "Товаров в топе категории, шт") {
      return numericValue.toLocaleString("ru-RU") + " ШТ";
    }
    if (numericValue >= 1000000) {
      return (
        (numericValue / 1000000).toLocaleString("ru-RU", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "M"
      ); // Например, 3 082.62M
    } else if (numericValue >= 10000) {
      return (
        (numericValue / 1000).toLocaleString("ru-RU", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + "k"
      ); // Например, 10.8k
    }

    return numericValue.toLocaleString("ru-RU"); // Для значений меньше 10 000
  }

  return (
    <Card
      style={{
        height: "213px",
        opacity: status === "loading" ? 0.5 : "",
        pointerEvents: status === "loading" ? "none" : undefined,
      }}
      className="mt-1 mb-2 w-100"
    >
      <CardHeader className="border-bottom-0">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h6
              className="font-size-xs text-uppercase"
              style={{ marginTop: "5px" }}
            >
              {t("competitors")}
            </h6>
          </div>
          {select && (
            <SelectDropdown
              id={id}
              select={selectChoiceCompetitors}
              defaultOption={[nameOne, nameTwo]}
              fetchOne={fetchDataCompetitorsSold}
              fetchTwo={fetchItemsInTheCategoryTop}
            />
          )}
        </div>
      </CardHeader>

      <SimpleBar style={{ height: "100px", width: "100%" }}>
        {status === "loading" && <CustomSpinner absolute={true} top={"30px"} />}
        <ul className="list-unstyled mb-0">
          {((data as any) || []).map((el: any, key: number) => (
            <li className="px-4 py-3" key={key}>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-circle">
                      {"#" + el.position}
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-muted mb-1 text-truncate">
                    {el.name}{" "}
                    <a
                      href={
                        brand
                          ? `/trendyol/brand/${el.id}/products`
                          : `/trendyol/seller/${el.id}/products`
                      }
                      target="_blank"
                    >
                      <i
                        className="uil-external-link-alt "
                        style={{ color: "DeepSkyBlue" }}
                      ></i>
                    </a>
                  </p>
                  <h5 className="font-size-16 mb-0 text-truncate">
                    {formatRevenue(el.value)}
                  </h5>
                </div>
                <div className="flex-shrink-0 align-self-start">
                  <div
                    className="badge ms-2"
                    style={{
                      backgroundColor: el.increase < 0 ? "#f8d7da" : "#d4edda",
                      color: el.increase < 0 ? "#721c24" : "#155724",
                    }} // Замените цвета по своему усмотрению
                  >
                    {el.increase < 0 ? (
                      <>
                        {Math.abs(el.increase)}
                        {""}
                        {(option as any) ===
                        "Number of products in the top of category"
                          ? t("pcs")
                          : "%"}
                        {/*{t("pcs") + "%"}*/}
                        <i className="uil uil-arrow-down text-danger ms-1"></i>
                      </>
                    ) : (
                      <>
                        {el.increase}{" "}
                        {(option as any) ===
                        "Number of products in the top of category"
                          ? t("pcs")
                          : "%"}
                        {/*{el.increase} {t("pcs") + "%"}*/}
                        <i className="uil uil-arrow-up-right text-success ms-1"></i>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </SimpleBar>
    </Card>
  );
};

export default TrafficSource;
