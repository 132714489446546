import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";
import { buildParams } from "src/utilitsFunction/api-utils";

export const merchantTableAPI = {
  async fetchMerchantTable(
    id: string,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/seller/products/${id}`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/seller/products/${id}/info`,
    );

    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });
    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }
    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }
    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  async fetchExcelMerchantTable(
    id: number,
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      columns: columnName,
      orderName,
      ascDesk,
      startDate,
      endDate,
      page,
    });
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/products/${id}/0/1`,
      {
        params: params,
      },
    );
  },
  fetchMerchantTableCategory(sellerId: string, startDate: any, endDate: any) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/seller/categories/${sellerId}`,
    );
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get(`${url}`, {
      params: params,
    });
  },
  async fetchExcelMerchantTableCategory(
    id: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      startDate,
      endDate,
    });
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/categories/${id}/0/1`,
      {
        params: params,
      },
    );
  },
  fetchMerchantTableBrands(sellerId: string, startDate: any, endDate: any) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/seller/brands/${sellerId}`,
    );
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }
    return instance.get(`${url}`, {
      params: params,
    });
  },
  async fetchExcelMerchantTableBrands(
    id: number,
    startDate: any,
    endDate: any,
  ) {
    const params = buildParams({
      startDate,
      endDate,
    });
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/brands/${id}/0/1`,
      {
        params: params,
      },
    );
  },

  //
  async fetchDataCompetitorsSold(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-competitors-sold/${id}`,
      {
        params: params,
      },
    );
  },
  async fetchDataRating(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-rating/${id}`,
      {
        params: params,
      },
    );
  },
  async fetchDataSearch(brandId: string) {
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-top-search/${brandId}`,
    );
  },
  async fetchDataCategory(brandId: string) {
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-top-category/${brandId}`,
    );
  },
  async fetchDataRevenue(id: string, startDate: any, endDate: any) {
    const params: { [key: string]: string } = {};

    if (startDate) {
      params["dateFrom"] = startDate;
    }

    if (endDate) {
      params["dateTo"] = endDate;
    }

    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-revenue/${id}`,
      {
        params: params,
      },
    );
  },
  // async fetchCategoryRevenue(brandId: string) {
  //   return instance.get(
  //     `https://web-api.restatos.com/trendyol/brand/data-revenue/${brandId}`,
  //   );
  // },data-competitors-top
  async fetchItemsInTheCategoryTop(brandId: string) {
    return instance.get(
      `https://web-api.restatos.com/trendyol/seller/data-competitors-top/${brandId}`,
    );
  },
};
