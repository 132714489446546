import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "merchantTableBrands",
  initialState: {
    table: [] as any,
    filteredTable: "" as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setMerchantTableBrands(
      state,
      action: PayloadAction<{ table: Array<any> }>,
    ) {
      state.table = [...action.payload.table];
    },
    setFilterMerchantTableBrands(state, action: PayloadAction<{ value: any }>) {
      state.filteredTable = action.payload.value;
    },
    onClickPageMerchantTableBrands(
      state,
      action: PayloadAction<{ page: number }>,
    ) {
      state.page = action.payload.page;
    },
    setTotalCountPageMerchantTableBrands(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameMerchantTableBrands(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescMerchantTableBrands(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setStatusMerchantTableBrands(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetMerchantTableBrands(state, action) {
      return {
        ...state,
        table: [] as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const merchantTableBrandsReducer = slice.reducer;
export const {
  setMerchantTableBrands,
  setFilterMerchantTableBrands,
  onClickPageMerchantTableBrands,
  setTotalCountPageMerchantTableBrands,
  clickOrderNameMerchantTableBrands,
  clickAscDescMerchantTableBrands,
  resetMerchantTableBrands,
  setStatusMerchantTableBrands,
} = slice.actions;
