import { AppRootStateType } from "src/store/types";

export const selectDataTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.table;
export const selectFilteredDataTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.filteredTable;

export const selectOrderNameTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.orderName;
export const selectAscDescTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.ascDesc;
export const selectPageTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.page;
export const selectTotalCountPageTableCategoryList = (
  state: AppRootStateType,
) => state.categoryList.totalCountPage;
export const selectStatusTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.status;
export const selectColumnNameTableCategoryList = (state: AppRootStateType) =>
  state.categoryList.columnName;

export const selectShowVideoCategoryList = (state: AppRootStateType) =>
  state.categoryList.showVideo;
export const selectRestart = (state: AppRootStateType) =>
  state.categoryList.restart;
