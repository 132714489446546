import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";
import { buildParams } from "src/utilitsFunction/api-utils";

export const listSellersTableAPI = {
  async fetchListSellersTable(
    columnName: any,
    orderName: any,
    ascDesk: any,
    page: number,
  ) {
    const url = new URL(`https://web-api.restatos.com/trendyol/sellers`);
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/sellers/info`,
    );

    const params: { [key: string]: string } = {};

    columnName.forEach((column: InputsType) => {
      if (
        column.value !== undefined &&
        column.value !== null &&
        column.value !== ""
      ) {
        params[`search[${column.name}]`] = column.value;
      }
    });
    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }
    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  //
  async fetchExcelListSellersTable(
    columnName: any,
    orderName: string,
    ascDesk: string,
    page: number,
  ) {
    const params = buildParams({
      columns: columnName,
      orderName,
      ascDesk,
      page,
    });
    return await instance.get(
      `https://web-api.restatos.com/trendyol/sellers/0/1`,
      {
        params: params,
      },
    );
  },
};
