import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { TrendOilType } from "src/features/trendOil/type";

const initialState = {
  data: {} as TrendOilType,
  key: null as null | string,
  keys: [] as string[],
  rowData: [],
  statusCategory: "idle" as RequestStatusType,
  idCategoryTable: null as any,
  categoryName: null as any,
};

export const slice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    setCategoryData(
      state,
      action: PayloadAction<{ categories: TrendOilType }>,
    ) {
      let mainCategory;
      for (mainCategory in action.payload.categories) {
        state.data[mainCategory] = action.payload.categories[mainCategory].map(
          (el: any) => el,
        );
      }
    },
    setKey(state, action: PayloadAction<{ key: string | null }>) {
      state.key = action.payload.key;
    },
    setKeys(state, action: PayloadAction<{ keys: string }>) {
      state.keys.push(action.payload.keys);
    },
    deleteKeys(state, action) {
      state.keys.pop();
    },
    setStatusCategory(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusCategory = action.payload.status;
    },
    clearData(state, action) {
      return { ...state, data: {}, key: null, keys: [], rowData: [] };
    },
    setPersonalKey(state, action: any) {
      state.idCategoryTable = action.payload.idCategoryTable;
    },
    setCategoryName(state, action: any) {
      state.categoryName = action.payload.categoryName;
    },
  },
});

export const categoriesReducer = slice.reducer;
export const {
  setCategoryData,
  // setMiniCategory,
  setKey,
  setKeys,
  deleteKeys,
  setStatusCategory,
  clearData,
  setPersonalKey,
  setCategoryName,
} = slice.actions;
