import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateSelect.module.css";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { Button, CardText, Col, Tooltip } from "reactstrap";
import { setAppErrorAC } from "src/features/App/reducer";
import { setDateSelect1 } from "src/features/product/product-reducer";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

registerLocale("en", en);

export const DateSelect = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [top, setTop] = useState(false);

  const minDate = new Date("2024/01/18");

  const [endDate, setEndDate] = useState<Date>(
    moment().subtract("days", 30).toDate(),
  );
  const [endDate2, setEndDate2] = useState<Date>(
    moment().subtract("days", 1).toDate(),
  );

  const handlerSelectDate = (endDate: Date, endDate2: Date) => {
    if (endDate.getTime() > endDate2.getTime()) {
      dispatch(
        setAppErrorAC({
          error: t("The start date must be before the end date"),
        }),
      );
      return;
    }
    let date1 = moment(endDate).toDate();
    let date2 = moment(endDate2).toDate();
    let resultDate1 = format(date1, "yyyy-MM-dd");
    let resultDate2 = format(date2, "yyyy-MM-dd");

    if (endDate && false) {
      dispatch(
        setAppErrorAC({
          error: t("Start and end dates are required"),
        }),
      );
    } else if (endDate) {
      dispatch(setDateSelect1({ dateSelect: [resultDate1, resultDate2] }));
    }
  };
  return (
    <>
      <div
        className="h4 card-title "
        style={{ marginLeft: "1.5px", marginBottom: "10px", cursor: "pointer" }}
      >
        {t("filter")}{" "}
        <Tooltip
          placement="top"
          isOpen={top}
          target="filter"
          toggle={() => {
            setTop(!top);
          }}
        >
          {t("filterTooltip")}
        </Tooltip>
        <i className="uil-question-circle" id={"filter"}></i>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          // marginTop: "30px",
        }}
      >
        {/*<div className="card-h-90 card">*/}

        {/*  <div className="d-flex card-header justify-content-between">*/}

        <Col>
          <DatePicker
            style={{ zIndex: "1000" }}
            onTouche
            className="form-control d-block"
            // isClearable
            showIcon
            dateFormat="dd.MM.yyyy"
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
            startDate={endDate}
            minDate={minDate}
            maxDate={new Date()}
          />
        </Col>
        <Col>
          <DatePicker
            style={{ zIndex: "1000" }}
            className="form-control d-block"
            dateFormat="dd.MM.yyyy"
            showIcon
            selected={endDate2}
            onChange={(date: any) => setEndDate2(date)}
            maxDate={new Date()}
          />
        </Col>
        <Button
          type="submit"
          onClick={() => handlerSelectDate(endDate, endDate2)}
          color="primary"
          outline
          style={{
            width: "auto",
            marginRight: "10px",
          }}
        >
          {t("sent")}
        </Button>
      </div>
    </>
  );
};
