import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/u;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
export const productSearchSchema = yup.object().shape({
  productId: yup
    .mixed()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .test("is-valid-input", "Please enter a valid number or URL", (value) => {
      if (value === null || value === undefined || value === "") {
        return true;
      }
      const isNumber = /^\d+$/.test(value);
      const isUrl = /-(\d+)/.test(value);

      return isNumber || isUrl;
    }),
  // .required("Required"),
});

// export const loginSchema = yup.object().shape({
//   email: yup.string().email("Please enter a valid email").required("Required"),
//   password: yup
//     .string()
//     // .min(8)
//     // .matches(passwordRules, { message: "Please create a stronger password" })
//     .required("Resquired"),
// });
export const dataSchema = yup.object().shape({
  date1: yup.date().required("Required").nullable(),
});
export const emailSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
});
export const basicSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),
  // age: yup.number().positive().integer().required("Required"),
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
export const changePasswordYpu = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
export const advancedSchema = yup.object().shape({
  jobType: yup
    .string()
    .oneOf(["designer", "developer", "manager", "other"], "Invalid Job Type")
    .required("Required"),
  acceptedTos: yup
    .boolean()
    .oneOf([true], "Please accept the terms of service"),
});

export const optionalInformation = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[а-яА-ЯёЁa-zA-Z\s]+$/,
      "Only alphabetic characters and spaces are allowed",
    )
    .required("Name is required"),
  tax: yup
    .number()
    .nullable()
    .typeError(({ label }) => `${label} must be a number.`)
    .label('"TAX"')
    .positive(({ label }) => `${label} must be a positive number.`),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .matches(
  //     /^[0-9+]+$/,
  //     "The phone number must consist of numbers and may include the symbol '+'.",
  //   ),
  phone: yup
    .string()
    .nullable()
    .matches(
      /^\+[0-9]+$/,
      "The phone number must start with '+' and contain only digits after it.",
    ),
});

export const productResearchYup = yup.object().shape({
  minPrice: yup.number(),
  maxPrice: yup.number(),
  merchCountFrom: yup.number(),
  merchCountTo: yup.number(),
  soldFrom: yup.number(),
  soldTo: yup.number(),
  revenueFrom: yup.number(),
  revenueTo: yup.number(),
  ratingFrom: yup.number(),
  ratingTo: yup.number(),
  favoritesFrom: yup.number(),
  favoritesTo: yup.number(),
  minRatingCount: yup.number(),
  maxRatingCount: yup.number(),
  shareMerchantsFrom: yup.number(),
  shareMerchantsTo: yup.number(),
  // name: yup.number(),
});
export const messageYup = yup.object().shape({
  message: yup
    .string()
    .min(10)
    // .matches( { message: "Please create a message" })
    .required("Required"),
});

export const integrationYup = yup.object().shape({
  api: yup
    .string()
    .matches(/^[а-яА-ЯёЁa-zA-Z]+$/, "Only alphabetic characters allowed"),
  secret: yup
    .number()
    .nullable()
    .typeError(({ label }) => `${label} must be a number.`)
    .label('"TAX"')
    .positive(({ label }) => `${label} must be a positive number.`),
  supplierId: yup
    .string()
    .nullable()
    .matches(
      /^\+[0-9]+$/,
      "The phone number must start with '+' and contain only digits after it.",
    ),
  token: yup
    .string()
    .nullable()
    .matches(
      /^\+[0-9]+$/,
      "The phone number must start with '+' and contain only digits after it.",
    ),
});
export const priceUpdaterYup = yup.object().shape({
  supplierId: yup.mixed(),
  barcode: yup.mixed(),
  minPrice: yup.mixed(),
  enabled: yup.mixed(),
  price_step: yup.number().max(100, "Error"),
  time_from: yup.string(),
  time_to: yup.string(),
  stop_price: yup.number(),
});
