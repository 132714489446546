import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { CategoryMessage } from "src/features/CategoryTable/type";

export const priceSegmentsTableAPI = {
  async priceSegmentsTable(id: string) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/category/price/${id}`,
    );

    return instance.get<FetchResponseTable<Array<CategoryMessage>>>(`${url}`);
  },
};
