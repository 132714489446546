import { da } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BreadcrumbItem, Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import {
  clearData,
  setCategoryName,
  setKey,
  setPersonalKey,
} from "src/features/trendOil/reducer";
import { getCategoriesVadim } from "src/features/trendOil/thunk";
import {
  OptionArray,
  TrendArray,
  TrendOilType,
} from "src/features/trendOil/type";
import { AppRootStateType } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import s from "./TrendOil.module.css";

export const TrendOil = () => {
  document.title = "Restatos | Trendyol";
  const { category_url } = useParams();
  const [mapOn, setMapOn] = useState<OptionArray[]>([]);
  const cat: TrendOilType = useSelector(
    (state: AppRootStateType) => state.category.data,
  );
  const status = useSelector(
    (state: AppRootStateType) => state.category.statusCategory,
  );

  const navigate = useNavigate();
  let nameCategory = Object.keys(cat || {});

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCategoriesVadim());

    return () => {
      dispatch(setKey({ key: null }));
      dispatch(clearData({}));
      setMapOn([]);
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(setKey({ key: null }));
      dispatch(clearData({}));
      setMapOn([]);
    };
  }, [category_url]);

  if (status === "category-loading") {
    return <CustomSpinner />;
  }

  const styles = {
    menuList: (base: any) => ({
      ...base,
      marginBottom: "15px",
      maxHeight: "900px",
      overflowY: "auto",
      cursor: "pointer",
      paddingTop: "-3px",
      paddingBottom: "-3px ",
    }),
  };

  const clickOn = (el: any) => {
    dispatch(setKey({ key: el }));
    const options: OptionArray[] =
      cat[el].map((item: TrendArray) => ({
        value: item.name.toLowerCase(),
        label: item.name,
        id: item.id,
        url: item.url,
      })) || [];
    setMapOn(options);
  };

  const clickSelect = (e: any) => {
    // @ts-ignore
    // dispatch(setPersonalKey({ idCategoryTable: e.id }));
    localStorage.setItem("categoryId", e.id);

    // @ts-ignore
    console.log(e, "e");
    // @ts-ignore
    // dispatch(setCategoryName({ categoryName: e.label }));
    // localStorage.setItem("categoryName", e.label);
    localStorage.setItem("categoryId", 0);

    navigate(`/trendyol/category/${e.id}/products`);
  };
  return (
    <>
      {/*<Container fluid>*/}
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            {/*<h4 className="mb-0">{breadcrumbItem}</h4>*/}
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                  active
                >
                  {/*<Link to="#">Trendyol</Link>*/}
                  <span>Trendyol</span>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
      <div className={s.mainContainer}>
        <div className={s.mainCategoryContainer}>
          {nameCategory.map((el) => {
            return (
              <div
                className={s.select}
                style={{ margin: "0px 30px 30px 30px" }}
                key={el}
                onClick={() => clickOn(el)}
              >
                <Select
                  onBlur={() => setMapOn([])}
                  isClearable={false}
                  isSearchable={false}
                  blurInputOnSelect={false}
                  menuShouldScrollIntoView={false}
                  onChange={(e: any) => clickSelect(e)}
                  styles={styles}
                  options={mapOn}
                  placeholder={el}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/*</Container>*/}
    </>
  );
};
