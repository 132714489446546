import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { GraphsMessageType } from "src/features/product/miniProductComponent/graphWrapper/type";

export const slice = createSlice({
  name: "graphStockSalesProduct",
  initialState: {
    graphStockSales: [] as Array<GraphsMessageType>,
    statusGraphProduct: "idle" as RequestStatusType,
  },
  reducers: {
    setGraphStockSales(
      state,
      action: PayloadAction<{ graphStockSales: Array<GraphsMessageType> }>,
    ) {
      state.graphStockSales = [...action.payload.graphStockSales];
    },
    setStatusGraphStock(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusGraphProduct = action.payload.status;
    },
    clearGraphStock(state, action) {
      return {
        ...state,
        graphStockSales: [] as Array<GraphsMessageType>,
      };
    },
  },
});
export const graphStockSalesProductReducer = slice.reducer;
export const { setGraphStockSales, setStatusGraphStock, clearGraphStock } =
  slice.actions;
