import { ListBrandTable } from "src/features/ListBrandTable/ListBrandTable";
import { AppRootStateType } from "src/store/types";

export const selectDataListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.table;
export const selectFilteredDataListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.filteredTable;
export const selectOrderNameListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.orderName;
export const selectAscDescListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.ascDesc;
export const selectPageListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.page;
export const selectTotalCountPageListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.totalCountPage;
export const selectStatusListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.status;
export const selectColumnNameListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.columnName;
export const selectShowVideoListBrandTable = (state: AppRootStateType) =>
  state.listBrandTable.showVideo;
export const selectRestart = (state: AppRootStateType) =>
  state.listBrandTable.restart;
