import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import { CategoryMessage } from "src/features/CategoryTable/type";

export const accountUnSubscribeAPI = {
  async accountUnSubscribe(value: { message: string }) {
    const url = new URL(`https://web-api.restatos.com/account/unsubscribe`);
    return instance.post(`${url}`, value);
  },
};
