import React from "react";
import SidebarContent from "./SidebarContent";

export const Sidebar = (props: any) => {
  function tToggle() {
    alert("test");
    document.body.setAttribute("data-sidebar-size", "sm");
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <button
          onClick={() => {
            tToggle();
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars" />
        </button>

        <div className="h-100">
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  );
};
