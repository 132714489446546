import { AppRootStateType } from "src/store/types";

export const selectTableIntegrationProducts = (state: AppRootStateType) =>
  state.integrationProducts.table;
export const selectText = (state: AppRootStateType) =>
  state.integrationProducts.text;

export const selectColumnName = (state: AppRootStateType) =>
  state.integrationProducts.columnName;
export const selectStatus = (state: AppRootStateType) =>
  state.integrationProducts.status;
export const selectStatusPriceUpdater = (state: AppRootStateType) =>
  state.integrationProducts.statusPriceUpdater;

export const selectPage = (state: AppRootStateType) =>
  state.integrationProducts.page;
export const selectOrderName = (state: AppRootStateType) =>
  state.integrationProducts.orderName;
export const selectAscDesc = (state: AppRootStateType) =>
  state.integrationProducts.ascDesc;

export const selectTotalCountPage = (state: AppRootStateType) =>
  state.integrationProducts.totalCountPage;
export const selectPriceUpdater = (state: AppRootStateType) =>
  state.integrationProducts.priceUpdater;
export const selectUpdate = (state: AppRootStateType) =>
  state.integrationProducts.update;
export const selectDataInfoProduct = (state: AppRootStateType) =>
  state.integrationProducts.dataInfoProduct;
