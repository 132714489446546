import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { ImageDataType } from "src/features/product/miniProductComponent/photo/type";

export const slice = createSlice({
  name: "photoProduct",
  initialState: {
    timeCreatePhoto: null as null | string,
    timeCreate: null as null | string,
    photo: [] as Array<ImageDataType>,
    statusPhoto: "idle" as RequestStatusType,
  },
  reducers: {
    setTimeCreatePhoto(
      state,
      action: PayloadAction<{ timeCreatePhoto: string }>,
    ) {
      state.timeCreatePhoto = action.payload.timeCreatePhoto;
    },
    setTimeCreate(state, action: PayloadAction<{ timeCreate: string }>) {
      state.timeCreate = action.payload.timeCreate;
    },
    setPhotoData(
      state,
      action: PayloadAction<{
        photo: Array<ImageDataType>;
      }>,
    ) {
      state.photo = [...action.payload.photo];
    },
    setStatusPhoto(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusPhoto = action.payload.status;
    },
    clearPhotoState(state, action) {
      return {
        ...state,
        timeCreatePhoto: null as null | string,
        timeCreate: null as null | string,
        photo: [] as Array<ImageDataType>,
        statusPhoto: "idle" as RequestStatusType,
      };
    },
  },
});

export const photoProductReducer = slice.reducer;

export const {
  setTimeCreatePhoto,
  setTimeCreate,
  setPhotoData,
  setStatusPhoto,
  clearPhotoState,
} = slice.actions;
