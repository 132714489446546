import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryTableAPI } from "src/api/api_categoryTable/api_categoryTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setDataCategoryBrandsTable,
  setStatusCategoryBrandsTable,
  setTotalCountPageCategoryBrandsTable,
} from "src/features/CategoryTableBrands/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchCategoryBrandsTable = createAsyncThunk<any, any>(
  "categoryBrandsTable/fetchCategoryBrandsTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCategoryBrandsTable({ status: "brands-loading" }),
    );
    try {
      const { res, resInfo } = await categoryTableAPI.fetchBrands(
        param.category_url,
        param.columnNameBrands,
        param.orderColumnName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );

      if (res.data.success === true) {
        thunkAPI.dispatch(setDataCategoryBrandsTable({ table: res.data.data }));
        thunkAPI.dispatch(
          setTotalCountPageCategoryBrandsTable({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );

        thunkAPI.dispatch(
          setStatusCategoryBrandsTable({ status: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusCategoryBrandsTable({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelCategoryBrandsTable = createAsyncThunk<any, any>(
  "excelCategoryBrandsTable/fetchExcelCategoryBrandsTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCategoryBrandsTable({ status: "brands-loading" }),
    );
    try {
      const res = await categoryTableAPI.fetchExcelBrands(
        param.category_url,
        param.columnNameBrands,
        param.orderColumnName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusCategoryBrandsTable({ status: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusCategoryBrandsTable({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
