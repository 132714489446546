import React from "react";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import { CardBody, Modal } from "reactstrap";
import { setUserStatus } from "src/features/App/reducer";
import {
  selectUserStatus,
  selectUserVariables,
} from "src/features/App/selectors";
import { selectIsLoggedIn } from "src/pages/AuthenticationInner/selectors";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const Authmiddleware = (props: any) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const userStatus = useAppSelector(selectUserStatus);
  const variables = useAppSelector(selectUserVariables);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();

  // const dispatch = useAppDispatch();

  if (!isLoggedIn) {
    return <Navigate to={"/signin"} />;
  }
  return (
    <React.Fragment>
      {props.children}
      {userStatus === "unpaid" && !currentUrl.includes("/user-settings") && (
        <CardBody>
          <Modal
            isOpen={userStatus}
            toggle={() => {
              // tog_backdrop();
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {variables.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  dispatch(setUserStatus({ userStatus: "trial" }));
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>{variables.body}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  dispatch(setUserStatus({ userStatus: "trial" }));
                }}
              >
                Close
              </button>
              {/*<button type="button" className="btn btn-primary">*/}
              <NavLink className="btn btn-primary" to={variables.url}>
                {variables.button}
              </NavLink>
              {/*</button>*/}
            </div>
          </Modal>
        </CardBody>
      )}
    </React.Fragment>
  );
};
