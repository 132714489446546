import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { ProductAdvert } from "src/features/product/miniProductComponent/advertisingCompany/type";

export const slice = createSlice({
  name: "productAdvert",
  initialState: {
    data: [] as Array<ProductAdvert>,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setProductAdvert(
      state,
      action: PayloadAction<{ data: Array<ProductAdvert> }>,
    ) {
      state.data = [...action.payload.data];
    },
    setStatusProductAdvert(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    clearProductAdvert(state, action) {
      return {
        ...state,
        data: [] as Array<ProductAdvert>,
      };
    },
  },
});
export const productAdvertReducer = slice.reducer;
export const { setProductAdvert, setStatusProductAdvert, clearProductAdvert } =
  slice.actions;
