import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setFeedback,
  setStatus,
  setTotalCountPage,
} from "src/features/product/miniProductComponent/feedback/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchFeedback = createAsyncThunk<any, any>(
  "feedback/fetchFeedback",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(setStatus({ status: "loading" }));
    try {
      const res = await productAPI.fetchFeedback(
        param.id,
        param.merchantId,
        param.page,
        // param.startDate,
        // param.endDate,
      );
      if (res.data.success === true) {
        console.log(res.data.message, "res.data.message.data");
        thunkAPI.dispatch(setFeedback({ data: res.data.message.data }));
        thunkAPI.dispatch(
          setTotalCountPage({
            totalCountPage: res.data.message.pageCount,
          }),
        );
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const fetchExcelFeedback = createAsyncThunk<any, any>(
  "excelFeedback/fetchExcelFeedback",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(setStatus({ status: "loading" }));

    try {
      const res = await productAPI.fetchExcelFeedback(
        param.id,
        param.merchantId,
        param.page,
      );
      if (res.data.success === true) {
        if ((res.data as any).to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
