import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setPhotoData,
  setStatusPhoto,
  setTimeCreatePhoto,
} from "src/features/product/miniProductComponent/photo/reducer";
import { setStatusVariant } from "src/features/product/miniProductComponent/variants/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchProductImage = createAsyncThunk<any, any>(
  "imageTable/fetchProductImage",
  async ({ id, itemNumberUrl }, thunkAPI) => {
    thunkAPI.dispatch(setStatusPhoto({ status: "image-loading" }));
    try {
      const res = await productAPI.fetchProductImage(id, itemNumberUrl);
      if (res.data.success === true) {
        thunkAPI.dispatch(setPhotoData({ photo: res.data.message }));
        thunkAPI.dispatch(
          setTimeCreatePhoto({
            timeCreatePhoto: res.data.message[0].time_create,
          }),
        );
        thunkAPI.dispatch(setStatusPhoto({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusVariant({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
