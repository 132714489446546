import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "listBrandTable",
  initialState: {
    table: [] as any,
    filteredTable: "" as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    columnName: [] as Array<InputsType>,
    showVideo: true,
    restart: false,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setListBrandTable(state, action: PayloadAction<{ table: Array<any> }>) {
      state.table = [...action.payload.table];
    },
    setFilterListBrandTable(state, action: PayloadAction<{ value: string }>) {
      state.filteredTable = action.payload.value;
    },
    onClickPageListBrandTable(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPageListBrandTable(
      state,
      action: PayloadAction<{
        totalCountPage: number | null;
      }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameListBrandTable(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescListBrandTable(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    onChangePhraseListBrandTable(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setShowVideoBrandList(
      state,
      action: PayloadAction<{ showVideo: boolean }>,
    ) {
      state.showVideo = action.payload.showVideo;
    },
    setStatusListBrandTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    setRestartListBrandTable(
      state,
      action: PayloadAction<{ restart: boolean }>,
    ) {
      state.restart = action.payload.restart;
    },
    resetListBrandTable(state, action) {
      return {
        ...state,
        table: [] as any,
        filteredTable: "" as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        columnName: [] as Array<InputsType>,
        page: 1 as number,
        restart: false,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const listBrandTableReducer = slice.reducer;

export const {
  setListBrandTable,
  setFilterListBrandTable,
  onClickPageListBrandTable,
  setTotalCountPageListBrandTable,
  clickOrderNameListBrandTable,
  clickAscDescListBrandTable,
  onChangePhraseListBrandTable,
  setShowVideoBrandList,
  resetListBrandTable,
  setRestartListBrandTable,
  setStatusListBrandTable,
} = slice.actions;
