// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap";
// import { get, map } from "lodash";
// import { withTranslation } from "react-i18next";
// import { changeToggleAC } from "src/features/App/reducer";
// import i18n from "../../../i18n";
// import languages from "../../../common/languages";
//
// const LanguageDropdown = (props: any) => {
//   const [selectedLang, setSelectedLang] = useState<string>("");
//   const [menu, setMenu] = useState<boolean>(false);
//   const dispatch = useDispatch();
//   useEffect(() => {
//     const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");
//     setSelectedLang(currentLanguage);
//   }, []);
//
//   const changeLanguageAction = (lang: string) => {
//     i18n.changeLanguage(lang);
//     dispatch(changeToggleAC({ toggle: true }));
//     localStorage.setItem("I18N_LANGUAGE", lang);
//     setSelectedLang(lang);
//     window.location.reload();
//   };
//
//   const toggle = () => {
//     setMenu(!menu);
//   };
//
//   return (
//     <React.Fragment>
//       <Dropdown
//         isOpen={menu}
//         toggle={toggle}
//         className="d-inline-block language-switch"
//       >
//         <DropdownToggle className="btn header-item " tag="button">
//           <img
//             src={get(languages, `${selectedLang}.flag`)}
//             alt="dashonic"
//             height="16"
//           />
//         </DropdownToggle>
//         <DropdownMenu className="dropdown-menu-end">
//           {map(Object.keys(languages), (key) => (
//             <DropdownItem
//               key={key}
//               onClick={() => changeLanguageAction(key)}
//               className={`notify-item ${
//                 selectedLang === key ? "active" : "none"
//               }`}
//             >
//               <img
//                 src={get(languages, `${key}.flag`)}
//                 alt="dashonic"
//                 className="me-1"
//                 height="12"
//               />
//               <span className="align-middle">
//                 {get(languages, `${key}.label`)}
//               </span>
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   );
// };
//
// export default withTranslation()(LanguageDropdown);
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation, WithTranslation } from "react-i18next";
import { changeToggleAC } from "src/features/App/reducer";
import i18n from "../../../i18n";
import languages from "../../../common/languages";

// interface LanguageDropdownProps extends WithTranslation {}

const LanguageDropdown: React.FC<any> = ({ t }) => {
  const [selectedLang, setSelectedLang] = useState<string | null>(
    localStorage.getItem("I18N_LANGUAGE"),
  );
  const [menu, setMenu] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedLang) {
      const defaultLanguage = "tr";
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem("I18N_LANGUAGE", defaultLanguage);
      setSelectedLang(defaultLanguage);
    }
  }, []);

  const changeLanguageAction = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(changeToggleAC({ toggle: true }));
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    // window.location.reload(); Пробую закоментировать лишнюю перезагрузку при смене языка
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block language-switch"
      >
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="dashonic"
            height="16"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : ""}`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="dashonic"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(LanguageDropdown);
