import React, { useState } from "react";

export const ExcelButton = ({ action }: any) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await action();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      type="button"
      className="btn-sm btn btn-green"
      style={{
        marginLeft: "3px",
        backgroundColor: "green",
        color: "white",
        border: "none",
      }}
      onClick={handleClick}
      disabled={loading}
    >
      <i
        style={{
          marginRight: "3px",
        }}
        className="uil-cloud-download"
      ></i>
      {"Excel"}
    </button>
  );
};
