import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { selectStatusProductInTheTops } from "src/features/BrandsTable/selectors";
import { CategoryMessage, InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "brandsTable",
  initialState:
    ({
      columnName: [] as Array<InputsType>,
      subscribe: {} as any,
      orderName: null as null | string,
      ascDesc: null as null | "asc" | "desc",
      nameCategory: null as null | string,
      page: 1 as number,
      totalCountPage: null as number | null,
      table: [] as Array<CategoryMessage>,
      dataCompetitors: [],
      dataRating: {},
      dataProductInTheTops: {},
      blockRevenue: {},
      selectProductInTheTops: "Search",
      selectCompetitors: "Revenue in this category",
      status: "idle" as RequestStatusType,
      statusCompetitors: "idle",
      statusProductInTheTops: "idle",
      statusDataRating: "idle",
      statusDataRevenue: "idle",
    } as any) || {},
  reducers: {
    onChangePhraseBrands(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    onClickPageBrands(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPageBrands(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameBrands(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescBrands(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setSubscribeCategoryTableBrands(state, action: PayloadAction<{ id: any }>) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: true,
      };
      state.subscribe = newSubscribe;
    },
    deleteSubscribeCategoryTableBrands(
      state,
      action: PayloadAction<{ id: any }>,
    ) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: false,
      };
      state.subscribe = newSubscribe;
    },
    setTableBrands(state, action: PayloadAction<{ table: Array<any> }>) {
      const newData = action.payload.table.reduce((acc: any, el: any) => {
        acc[el.id] = el.followed;
        return acc;
      }, {});
      state.subscribe = { ...state.subscribe, ...newData };
      state.table = [...action.payload.table];
    },
    setDataRevenue(state, action: PayloadAction<{ revenue: Array<any> }>) {
      state.blockRevenue = action.payload.revenue;
    },
    setDataProductInTheTops(
      state,
      action: PayloadAction<{ data: Array<any> }>,
    ) {
      state.dataProductInTheTops = action.payload.data;
    },
    setSelectProductInTheTops(
      state,
      action: PayloadAction<{ select: string }>,
    ) {
      state.selectProductInTheTops = action.payload.select;
    },
    setDataRating(state, action: PayloadAction<{ rating: Array<any> }>) {
      state.dataRating = action.payload.rating;
    },
    setDataCompetitors(state, action: PayloadAction<{ data: Array<any> }>) {
      state.dataCompetitors = action.payload.data;
    },
    setSelectCompetitors(state, action: PayloadAction<{ select: string }>) {
      state.selectCompetitors = action.payload.select;
    },

    setStatusTableBrands(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    //
    setStatusDataRevenue(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDataRevenue = action.payload.status;
    },
    setStatusProductInTheTops(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusProductInTheTops = action.payload.status;
    },
    setStatusDataRating(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDataRating = action.payload.status;
    },
    setStatusCompetitors(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusCompetitors = action.payload.status;
    },

    resetBrandsTableState(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        subscribe: {} as any,
        orderName: null as null | string,
        ascDesc: null as null | "asc" | "desc",
        nameCategory: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
        table: [] as Array<CategoryMessage>,
        dataCompetitors: [],
        dataRating: {},
        dataProductInTheTops: {},
        blockRevenue: {},
        selectProductInTheTops: "Search",
        selectCompetitors: "revenueInCategory",
        status: "idle" as RequestStatusType,
        statusCompetitors: "idle",
        statusProductInTheTops: "idle",
        statusDataRating: "idle",
        statusDataRevenue: "idle",
      };
    },
  },
});

export const brandsTableReducer = slice.reducer;
export const {
  setSubscribeCategoryTableBrands,
  deleteSubscribeCategoryTableBrands,
  onChangePhraseBrands,
  onClickPageBrands,
  setTotalCountPageBrands,
  setTableBrands,
  clickAscDescBrands,
  clickOrderNameBrands,

  setDataRevenue,
  setDataProductInTheTops,
  setSelectProductInTheTops,
  setDataRating,
  setDataCompetitors,
  setSelectCompetitors,

  setStatusDataRevenue,
  setStatusProductInTheTops,
  setStatusDataRating,
  setStatusCompetitors,

  setStatusTableBrands,

  resetBrandsTableState,
} = slice.actions;
