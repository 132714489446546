import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbItem, CardHeader, Col, Row, Tooltip } from "reactstrap";
import { ClearButton } from "src/components/InputClearButton/InputClearButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import { setUserStatus } from "src/features/App/reducer";
import { selectAppError, selectUserStatus } from "src/features/App/selectors";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";

import { InputsType } from "src/features/CategoryTable/type";
import { ModalView } from "src/pages/Integration-Products/components/Modal-View/Modal-View";
import {
  selectAscDesc,
  selectColumnName,
  selectDataInfoProduct,
  selectOrderName,
  selectPage,
  selectPriceUpdater,
  selectStatus,
  selectText,
  selectTotalCountPage,
  selectUpdate,
} from "src/pages/Integration-Products/selectors";
import {
  clickAscDesc,
  clickOrderName,
  onChangePhrase,
  onClickPage,
  resetIntegrationProducts,
  setPriceUpdater,
  setStatus,
} from "src/pages/Integration-Products/reducer";
import { selectTableIntegrationProducts } from "src/pages/Integration-Products/selectors";
import { fetchDataIntegrationProducts } from "src/pages/Integration-Products/thunk";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeight } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import DataTable from "react-data-table-component";
import s from "../../features/CategoryTable/Category.module.css";
import "../../features/CategoryTable/Category.module.css";
import { useAppSelector } from "src/store/types";
import { useTranslation } from "react-i18next";

export const IntegrationProducts = () => {
  const [columnFilters, setColumnFilters] = useState({});
  const [advertSlotFilter, setAdvertSlotFilter] = useState("");
  document.title = "Restatos | Integration Products";
  const { name, barcode } = useSearchParams();
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const [modal_standard, setmodal_standard] = useState(false);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState<number>(100);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const [tooltipData, setTooltipData] = useState<any>(null);
  const [tooltipName, setTooltipName] = useState<any>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const userStatus = useAppSelector(selectUserStatus);
  const data = useAppSelector(selectTableIntegrationProducts);
  const status = useAppSelector(selectStatus);
  const columnName = useAppSelector(selectColumnName);
  const orderName = useAppSelector(selectOrderName);
  const ascDesc = useAppSelector(selectAscDesc);
  const page = useAppSelector(selectPage);
  const totalCountPage = useAppSelector(selectTotalCountPage);
  const text = useAppSelector(selectText);
  const dataInfoProduct = useAppSelector(selectDataInfoProduct);
  const update = useAppSelector(selectUpdate);

  const [inputs, setInputs] = useState<InputsType[]>([
    {
      id: 0,
      label: "Name",
      name: "name",
      value: name ?? "",
    },

    { id: 1, label: "Barcode", name: "barcode", value: barcode ?? "" },
  ]);

  useEffect(() => {
    return () => {
      // dispatch(resetCategoryTableState({}));
      dispatch(resetIntegrationProducts({}));
      setId(null);
      setIsHovered(false);
    };
  }, []);

  const param: any = {
    columnName: inputs,
    orderName,
    ascDesc,
    page,
  };

  useEffect(() => {
    if (update) {
      dispatch(fetchDataIntegrationProducts(param));
    }
  }, [update]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchDataIntegrationProducts(param));
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [ascDesc, columnName, orderName, page]);

  useEffect(() => {
    dispatch(onClickPage({ page: 1 }));
  }, [ascDesc, orderName, columnName]);

  const handleMouseEnter = (id: any) => {
    setIsHovered(true);
    setId(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setId(null);
  };

  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPage({ page: newPage }));
    setCount(newCount);
  };
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }

    if (value === "") {
      dispatch(
        setStatus({
          status: "loading",
        }),
      );
      dispatch(onChangePhrase({ columnName: [] }));
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));

    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);

    const params = new URLSearchParams();
    updatedInputs.forEach((input) => {
      if (input.value !== "") {
        params.append(input.name, input.value);
      }
    });

    let url = window.location.pathname;
    if (params.toString() !== "") {
      url += `?${params.toString()}`;
    }
    window.history.pushState({}, "", url);

    const id = +setTimeout(() => {
      dispatch(onChangePhrase({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  const columns = [
    {
      name: "",
      width: "20px",
      cell: (row: any) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            fontSize: "15px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(true);
            dispatch(
              setPriceUpdater({
                priceUpdater: {
                  supplierId: row.supplier_id,
                  barcode: row.barcode,
                },
              }),
            );
          }}
        >
          <i
            onMouseOver={(event: any) => {
              handlesMouseOver(
                event,
                row.price_updater
                  ? t("Auto price included")
                  : t("Auto price off"),
                setTooltipName,
              );
            }}
            onMouseOut={() => setTooltipName(null)}
            className="uil-edit"
            style={{ color: row.price_updater ? "green" : "deepskyblue" }}
          ></i>
        </div>
      ),
    },
    // {
    //   name: "",
    //   width: "10px",
    //   cell: (row: any) => (
    //     <div
    //       style={{
    //         width: "100%",
    //         height: "100%",
    //         display: "flex",
    //         fontSize: "15px",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         marginLeft: "5px",
    //       }}
    //     >
    //       {row.price_updater ? (
    //         <i
    //           className="mdi mdi-cash-multiple"
    //           style={{ color: "#ffa14f" }}
    //         ></i>
    //       ) : (
    //         ""
    //       )}
    //     </div>
    //   ),
    // },
    {
      // name: t("image"),
      selector: "image",
      width: "55px",
      cell: (row: any) => (
        <div
        // style={{ display: "block", flexDirection: "row" }}
        >
          {
            <a target="_blank" href={`${row.image}`} rel="noreferrer">
              <img
                onMouseOver={(event: any) => {
                  handlesMouseOver(event, row.image, setTooltipData);
                }}
                onMouseOut={() => setTooltipData(null)}
                style={{ borderRadius: "15%", cursor: "pointer" }}
                height={"35px"}
                src={row.image}
                data-tip="Image"
              />
            </a>
          }
        </div>
      ),
    },
    {
      name: <div>{t("name")}</div>,
      selector: "name",
      sortable: true,

      title: "Image Column Tooltip",
      cell: (row: { id: string; name: string }) => (
        <>
          <div
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // to={`/trendyol/product/${row.id}`}
            // id={`nameCategory${row.id}`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </div>
        </>
      ),
    },
    {
      name: t("barcode"),
      selector: "barcode",
      sortable: true,
      cell: (row: { id: number; barcode: any }) => (
        <div
          onMouseEnter={() => handleMouseEnter(row.id)}
          onMouseLeave={handleMouseLeave}
        >
          {row.barcode}
        </div>
      ),
    },
    {
      name: t("supplierId"),
      selector: "supplier_id",
      sortable: true,

      cell: (row: { id: number; supplier_id: any }) => (
        <div>{row.supplier_id}</div>
      ),
    },
    {
      name: t("list_price"),
      selector: "list_price",
      sortable: true,

      format: (row: { list_price: any }) =>
        row.list_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("sale_price"),
      selector: "sale_price",
      sortable: true,

      format: (row: { sale_price: any }) =>
        row.sale_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("competitors_number"),
      selector: "competitors_number",
      sortable: true,

      cell: (row: { competitors_number: number }) => (
        <div>{row.competitors_number}</div>
      ),
    },
    {
      name: t("competitors_min_price"),
      selector: "competitor_min_price",
      sortable: true,

      format: (row: { competitor_min_price: any }) =>
        row.competitor_min_price
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("brand"),
      selector: "brand",
      sortable: true,
      cell: (row: { brand_id: string; brand: string; id: any }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.brand, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            to={`/trendyol/brand/${row.brand_id}/products`}
            target="_blank"
          >
            {row.brand.length > 22
              ? row.brand.substr(0, 22) + "..."
              : row.brand}
          </NavLink>
        </>
      ),
    },
    {
      name: t("stock"),
      selector: "quantity",
      format: (row: { quantity: any }) =>
        row.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      sortable: true,
    },
    {
      name: t("Date of change"),
      selector: "last_update_date",
      width: "120px",
      cell: (row: { last_update_date: any }) => (
        <div>{row.last_update_date}</div>
      ),
      sortable: true,
    },
    {
      name: t("Date of creation"),
      selector: "create_date_time",
      width: "120px",
      id: "create_date_time",
      cell: (row: { create_date_time: any }) => (
        <div>{row.create_date_time}</div>
      ),
      sortable: true,
    },
  ] as any;
  // const onChangePagination = (newPage: number, newCount: number) => {
  //   if (userStatus === "trial") {
  //     dispatch(setUserStatus({ userStatus: "unpaid" }));
  //     return;
  //   }
  //   dispatch(onClickPage({ page: newPage }));
  //   setCount(newCount);
  // };

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );
  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhrase({ columnName: inputs }));
    }
  };
  const handleFilterChange = (columnSelector: any, filterValue: any) => {
    if (columnSelector === "type") {
      setAdvertSlotFilter(filterValue);
    } else {
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [columnSelector]: filterValue,
      }));
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((item: any) => {
      return (
        (advertSlotFilter === "" || item.type === advertSlotFilter) &&
        Object.entries(columnFilters).every(([columnSelector, filterValue]) => {
          if (!filterValue) return true;
          return (
            item[columnSelector].toString().toLowerCase() ===
            // @ts-ignore
            filterValue.toLowerCase().trim()
          );
        })
      );
    });
  }, [data, columnFilters, advertSlotFilter]);
  const getUniqueFilterOptions = (columnSelector: any) => {
    return [...new Set(data.map((item: any) => item[columnSelector]))];
  };

  const getSubHeaderComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        {columns.map((column: any) => (
          <div
            key={column.selector}
            style={{
              textAlign: "center",
            }}
          >
            {column.selector === "type" && (
              <div>
                <select
                  className={"form-select form-select"}
                  value={advertSlotFilter}
                  onChange={(e) => {
                    handleFilterChange(column.selector, e.target.value);
                  }}
                >
                  <option value="">{t("Type")}</option>
                  {getUniqueFilterOptions(column.selector).map((option) => (
                    <option key={option as any} value={option as any}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  function tog_standard() {
    setOpen(!open);
  }

  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderName({ orderName: column.selector }));
    dispatch(clickAscDesc({ ascDesc: sortDirection }));
  }, []);

  return (
    <>
      <ModalView
        setOpen={setOpen}
        open={open}
        data={dataInfoProduct}
        tog_standard={tog_standard}
      />
      {status === "loading" && <CustomSpinner absolute={true} top={"300px"} />}
      <div
        className={status === "loading" ? s.opasity : ""}
        style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div
              className="
               d-flex align-items-center justify-content-between"
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>{" "}
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    <Link
                      // target="_blank"
                      to="/trendyol/category-list"
                    >
                      Integration Products
                    </Link>
                  </BreadcrumbItem>
                  {/*<BreadcrumbItem*/}
                  {/*  style={{*/}
                  {/*    marginTop: "10px",*/}
                  {/*    marginBottom: "10px",*/}
                  {/*    // marginLeft: "15px",*/}
                  {/*  }}*/}
                  {/*  active*/}
                  {/*>*/}
                  {/*  {categoryId === category_url && currentLanguage === "tr" ? (*/}
                  {/*    <> {nameCategory}&nbsp;</>*/}
                  {/*  ) : null}*/}
                  {/*  {t("AnalyticalReportByCategory")}&nbsp;*/}
                  {/*  {categoryId === category_url && currentLanguage !== "tr"*/}
                  {/*    ? nameCategory*/}
                  {/*    : null}*/}
                  {/*</BreadcrumbItem>*/}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <CardHeader className="d-flex">
            <span className="me-auto">{t("Trendyol integration by API")}</span>
          </CardHeader>
        </div>
        {text !== "" ? (
          <div
            style={{
              height: "100%",
              background: "white",
              paddingLeft: "10px",
            }}
            className="gap-2 align-items-center"
          >
            <div
              style={{
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {" "}
              <h1>
                {/*{text}*/}
                {t(
                  "To work with this section, fill in the data for API access",
                )}
              </h1>
            </div>
            <div
              style={{
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <NavLink to="/user-settings" className="styled-link">
                {t("Go to user settings")}
              </NavLink>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                height: "100%",
                background: "white",
                paddingLeft: "10px",
              }}
              className="d-flex flex-wrap gap-2 align-items-center"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                {/*<i*/}
                {/*  onClick={() => setmodal_standard(true)}*/}
                {/*  // onClick={() => setTableDescription(true)}*/}
                {/*  className="uil-question-circle "*/}
                {/*  style={{ fontSize: "24px", cursor: "pointer" }}*/}
                {/*></i>*/}
                {getSubHeaderComponent()}
                {inputs.map((input, index) => (
                  <CustomInput
                    id={input.id}
                    disabled={status === "loading"}
                    key={index}
                    value={input.value}
                    label={input.label}
                    onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                      handleInputChange(index, e.currentTarget.value)
                    }
                    onKeyPress={onKeyPress}
                  />
                ))}
                <ClearButton
                  inputs={inputs}
                  setInputs={setInputs}
                  setIsCleared={() =>
                    dispatch(onChangePhrase({ columnName: inputs }))
                  }
                />
              </div>
            </div>
            <DataTable
              conditionalRowStyles={
                userStatus === "trial" || userStatus === "unpaid"
                  ? conditionalRowStyles
                  : ""
              }
              className={`customTableYaroslav stickyCategory ${
                userStatus === "trial" || userStatus === "unpaid"
                  ? "no-scroll"
                  : ""
              }`}
              defaultSortFieldId={"create_date_time"}
              striped
              defaultSortAsc={false}
              columns={columns}
              // data={data}
              data={filteredData}
              subHeader
              fixedHeaderScrollHeight={newHeight}
              fixedHeader
              sortServer={true}
              // sortIcon={null}
              onSort={handleSort}
              responsive={true}
              // noDataComponent={<div>{t("dataTable")}</div>}
              noDataComponent={<div>{""}</div>}
            />
          </>
        )}

        {userStatus === "trial" || userStatus === "unpaid" ? (
          <div className={s.blur}></div>
        ) : null}
        {tooltipData && (
          <img
            style={{
              zIndex: "100",
              position: "absolute",
              top: tooltipData.y - 27,
              left: tooltipData.x + 30,
              background: "white",
              padding: "4px",
              height: "250px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            src={tooltipData.value}
            alt=""
          />
        )}
        {userStatus === "trial" || userStatus === "unpaid" ? (
          <div className={s.blur}></div>
        ) : null}
        <TooltipInTable tooltipName={tooltipName} />
        <SuperPagination
          page={page}
          itemsCountForPage={count}
          totalCount={totalCountPage}
          onChange={onChangePagination}
        />
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
