import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import { resetAppState } from "src/features/App/reducer";
import { selectUserName } from "src/pages/Contacts/selectors";
import { logoutTC } from "src/slices/Auth/Login/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import withRouter from "../../Common/withRouter";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import s from "./Profile.module.css";
const ProfileMenu = (props: any) => {
  const { t } = useTranslation();

  const [menu, setMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userName = useAppSelector(selectUserName);

  const logoutHandler = useCallback(() => {
    dispatch(logoutTC());
    // dispatch(resetAppState({}));
  }, []);
  const navigateClick = () => {
    navigate("/user-settings");
  };
  const navigateFavoriteGoods = () => {
    navigate("/follow/trendyol");
  };
  const navigateChromewebstore = () => {
    window.location.href =
      "https://chromewebstore.google.com/detail/restatos-trendyol-analiz/dmkoohpcbkdibfinebjiopmnnmgpffli";
  };
  const navigateTrendyolAPIProducts = () => {
    navigate("/trendyol/integration");
  };
  const isMobile = window.innerWidth < 581;
  return (
    <div style={{ marginLeft: "-15px" }}>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/*<img*/}
          {/*  className="rounded-circle header-profile-user"*/}
          {/*  src={user1}*/}
          {/*  alt="Header Avatar"*/}
          {/*/>*/}
          {/*<Icon className="ml-4" name="user" size="24px" />*/}

          <span className="  d-sm-block user-item-desc">
            <span className="user-name">{userName ? userName : "Account"}</span>
            {/*<span className="user-sub-title">Administrator</span>*/}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{userName}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">
              {/*{email}*/}
            </p>
          </div>
          <DropdownItem divider />

          {isMobile && (
            <DropdownItem
              onClick={navigateChromewebstore}
              className="d-flex align-items-center "
            >
              <i className="mdi mdi-puzzle"></i>{" "}
              <span style={{ marginLeft: "5px" }} className="align-middle">
                <Link
                  target={"_blank"}
                  to={
                    "https://chromewebstore.google.com/detail/restatos-trendyol-analiz/dmkoohpcbkdibfinebjiopmnnmgpffli"
                  }
                >
                  {t("Use the Google extension.")}
                </Link>
              </span>
            </DropdownItem>
          )}
          <DropdownItem
            onClick={navigateFavoriteGoods}
            className="d-flex align-items-center "
          >
            <i className="mdi mdi-star"></i>
            {/*<i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>*/}{" "}
            <span style={{ marginLeft: "5px" }} className="align-middle">
              <Link to="/follow/trendyol">{props.t("FavoriteGoods")}</Link>
            </span>
            {/*<span className="badge badge-soft-success ms-auto">New</span>*/}
          </DropdownItem>

          <DropdownItem
            onClick={navigateTrendyolAPIProducts}
            className="d-flex align-items-center "
          >
            <i className="mdi mdi-link"></i>
            <span style={{ marginLeft: "5px" }} className="align-middle">
              <Link to="/trendyol/integration">{props.t("Integration")}</Link>
            </span>
          </DropdownItem>

          <DropdownItem
            onClick={navigateClick}
            className="d-flex align-items-center "
          >
            <i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/user-settings"> {props.t("setting")}</Link>
            </span>
            {/*<span className="badge badge-soft-success ms-auto">New</span>*/}
          </DropdownItem>
          <DropdownItem onClick={logoutHandler}>
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <span className={s.span}>{props.t("logout")}</span>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
