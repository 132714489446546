import { createAsyncThunk } from "@reduxjs/toolkit";
import { productSearchAPI } from "src/api/api_productSearch/api_productSearch";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setSearchProduct,
  setStatusSearchProduct,
} from "src/pages/ProductSearch/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchSearchProduct = createAsyncThunk<any, any>(
  "searchProduct/fetchSearchProduct",
  async (productId, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusSearchProduct({
        status: "searchProduct-loading",
      }),
    );
    try {
      const res = await productSearchAPI.fetchProductSearch(productId);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSearchProduct({ productId: res.data.message.id }));

        thunkAPI.dispatch(setStatusSearchProduct({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusSearchProduct({ status: "succeeded" }));

        thunkAPI.dispatch(setAppErrorAC({ error: res.data.error }));
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
