import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

const initialState = {
  isLoggedIn: false,
  tariffs: [],
  canUseTrial: false,
  checkPromo: false,
  status: "idle" as RequestStatusType,
};

export const slice = createSlice({
  name: "signUp",
  initialState: initialState,
  reducers: {
    setSignUpData(state, action: PayloadAction<{ tariffs: any }>) {
      state.tariffs = action.payload.tariffs;
    },
    setCanUseTrial(state, action: PayloadAction<{ canUseTrial: any }>) {
      state.canUseTrial = action.payload.canUseTrial;
    },
    setCheckPromo(state, action: PayloadAction<{ checkPromo: any }>) {
      state.checkPromo = action.payload.checkPromo;
    },
    setStatusSignUp(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
  },
});

export const registerReducer = slice.reducer;
export const { setSignUpData, setCanUseTrial, setCheckPromo, setStatusSignUp } =
  slice.actions;
type ActionsType = ReturnType<typeof setStatusSignUp>;
