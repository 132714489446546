import { AppRootStateType, useAppSelector } from "src/store/types";

export const selectLayoutType = (state: AppRootStateType) =>
  state.layout.layoutType;

export const selectIsInitialized = (state: AppRootStateType) =>
  state.app.isInitialized;
export const selectStatus = (state: AppRootStateType) =>
  state.photo.statusPhoto;
export const selectStatusApp = (state: AppRootStateType) => state.app.status;
export const selectTariffName = (state: AppRootStateType) =>
  state.app.tariffName;
export const selectUserVariables = (state: AppRootStateType) =>
  state.app.variables;
export const selectUserStatus = (state: AppRootStateType) =>
  state.app.userStatus;
export const selectPaidTo = (state: AppRootStateType) => state.app.paidTo;
export const selectURL = (state: AppRootStateType) => state.app.url;

export const selectAPIKey = (state: AppRootStateType) => state.app.APIKey;
export const selectAPISecret = (state: AppRootStateType) => state.app.APISecret;
export const selectSatıcıID = (state: AppRootStateType) => state.app.SatıcıID;
export const selectToken = (state: AppRootStateType) =>
  state.app.tokenIntegration;
export const selectAppError = (state: AppRootStateType) => state.app.error;
