import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Tooltip } from "reactstrap";

type PropsType = {
  id: any;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value: string;
  label: string;
  onChange: any;
  key: any;
};

export const CustomInput = (props: PropsType) => {
  const { t, i18n } = useTranslation();
  const [translatedLabel, setTranslatedLabel] = useState<any>();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  // props.label

  useEffect(() => {
    setTranslatedLabel(t(props.label));
  }, [i18n.language, props.label]);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ margin: "5px 5px", textAlign: "center" }}>
        <Input
          id={`inputField-${props.id}`}
          // onKeyUp={props.onKeyUp}
          onKeyPress={props.onKeyPress}
          // onKeyDown={(e:number)=>void}
          disabled={props.disabled}
          type="text"
          value={props.value}
          // placeholder={props.label}
          placeholder={translatedLabel}
          onChange={props.onChange}
        />
        <Tooltip
          isOpen={tooltipOpen}
          target={`inputField-${props.id}`}
          toggle={toggleTooltip}
        >
          {translatedLabel}
        </Tooltip>
      </div>
    </div>
  );
  // }, [props]);
};
