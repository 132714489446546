import { createAsyncThunk } from "@reduxjs/toolkit";
import { integrationProductsAPI } from "src/api/api-integration-products/api-integration-products";
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from "src/features/App/reducer";
import {
  setDataInfoProduct,
  setStatus,
  setStatusPriceUpdater,
  setTable,
  setTotalCountPage,
  setUpdateTable,
  setWarning,
} from "src/pages/Integration-Products/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchDataIntegrationProducts = createAsyncThunk<any, any>(
  "integrationProducts/fetchDataIntegrationProducts",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(setStatus({ status: "loading" }));
    try {
      const { res, resInfo } =
        await integrationProductsAPI.fetchIntegrationProducts(
          param.columnName,
          param.orderName,
          param.ascDesc,
          param.page,
        );
      if (res.data.success === true) {
        if (
          res.data.error === "no_access_creds" ||
          res.data.error === "access_error"
        ) {
          thunkAPI.dispatch(setWarning({ text: res.data.message }));
          thunkAPI.dispatch(setStatus({ status: "succeeded" }));

          return;
        }
        thunkAPI.dispatch(setTable({ data: res.data.message }));
        thunkAPI.dispatch(
          setTotalCountPage({
            totalCountPage: resInfo.data.message.recordsFiltered,
          }),
        );
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchAPIPriceUpdater = createAsyncThunk<any, any>(
  "APIPriceUpdater/fetchAPIPriceUpdater",
  async (param, thunkAPI) => {
    // thunkAPI.dispatch(setStatus({ status: "loading" }));
    try {
      const res = await integrationProductsAPI.fetchTrendyolAPIPriceUpdater(
        param,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataInfoProduct({ data: res.data.message }));
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatus({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
export const autoPriceSetTC = createAsyncThunk<any, any>(
  "autoPriceSetTC/fetchAutoPriceSetTC",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(setUpdateTable({ update: false }));
    thunkAPI.dispatch(setStatusPriceUpdater({ status: "loading" }));
    try {
      const res = await integrationProductsAPI.fetchAutoPriceSet(param);
      if (res.data.success === true) {
        //
        thunkAPI.dispatch(setUpdateTable({ update: true }));
        //
        thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
        thunkAPI.dispatch(setStatusPriceUpdater({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusPriceUpdater({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error: res.data.error,
          }),
        );

        // thunkAPI.dispatch(
        //   setAppErrorAC({
        //     error:
        //       "Something went wrong. We're already looking into the problem",
        //   }),
        // );
      }
    } catch (error) {
      thunkAPI.dispatch(setStatus({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
