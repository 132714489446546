import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "merchantTableCategory",
  initialState: {
    table: [] as any,
    // filteredTableCategory: [] as any,
    filteredTable: "" as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setMerchantTableCategory(
      state,
      action: PayloadAction<{ table: Array<any> }>,
    ) {
      state.table = [...action.payload.table];
    },
    setFilterMerchantTableCategory(
      state,
      action: PayloadAction<{ value: string }>,
    ) {
      state.filteredTable = action.payload.value;
    },

    onClickPageMerchantTableCategory(
      state,
      action: PayloadAction<{ page: number }>,
    ) {
      state.page = action.payload.page;
    },
    setTotalCountPageMerchantTableCategory(
      state,
      action: PayloadAction<{
        totalCountPage: number | null;
      }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameMerchantTableCategory(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescMerchantTableCategory(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setStatusMerchantTableCategory(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetMerchantTableCategory(state, action) {
      return {
        ...state,
        table: [] as any,
        filteredTable: "" as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const merchantTableCategoryReducer = slice.reducer;
export const {
  setMerchantTableCategory,
  setFilterMerchantTableCategory,
  onClickPageMerchantTableCategory,
  setTotalCountPageMerchantTableCategory,
  clickOrderNameMerchantTableCategory,
  clickAscDescMerchantTableCategory,
  resetMerchantTableCategory,
  setStatusMerchantTableCategory,
} = slice.actions;
