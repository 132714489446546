import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useParams } from "react-router-dom";
import { BreadcrumbItem, CardHeader, Col, Row } from "reactstrap";
import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import WidgetCard from "src/components/Widgets/WidgetCard/WidgetCard";
import {
  setCategoryId,
  setNameCategory,
  setNameUrl,
} from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import TableDescription from "src/features/CategoryList/table-description";
import s from "src/features/CategoryTable/Category.module.css";
import WidgetBlockCategory from "src/features/CategoryTable/miniCategory/WidgetBlockCategory/WidgetBlockCategory";
import WidgetBlockCategoryTwo from "src/features/CategoryTable/miniCategory/WidgetBlockCategoryTwo/WidgetBlockCategoryTwo";
import { resetCategoryTableState } from "src/features/CategoryTable/reducer";
import {
  selectCategoryId,
  selectChoiceBrandAndSellers,
  selectDataBrandAndSellers,
  selectDataPriceAvg,
  selectDataProducts,
  selectDataRevenue,
  selectNameCategory,
  selectNameUrl,
  selectStatusBrandAndSellers,
  selectStatusDataRevenue,
  selectStatusPriceAvg,
  selectStatusProducts,
} from "src/features/CategoryTable/selectors";
import {
  fetchDataBrands,
  fetchDataPriceAvg,
  fetchDataProducts,
  fetchDataRevenue,
  fetchDataSellers,
} from "src/features/CategoryTable/thunk";
import { resetPriceSegmentsTable } from "src/features/Price-Segments-Table/reducer";
import {
  selectDataPriceSegmentsTable,
  selectStatusPriceSegmentsTable,
} from "src/features/Price-Segments-Table/selectors";
import { fetchPriceSegmentsTable } from "src/features/Price-Segments-Table/thunk";
import { selectDateSelect } from "src/features/product/miniProductComponent/variants/selectors";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { findHeight, findHeightListTable } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const PriceSegmentsTable = () => {
  const { id: category_url } = useParams();

  document.title = "Restatos | Price segments";
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const dispatch = useAppDispatch();
  const ref = useRef(window.innerHeight).current;
  const newHeight = findHeight(ref);
  const h = findHeightListTable(ref);
  const data = useAppSelector(selectDataPriceSegmentsTable);
  const status = useAppSelector(selectStatusPriceSegmentsTable);
  const categoryId = useAppSelector(selectCategoryId);
  const nameCategory = useAppSelector(selectNameCategory);
  const nameUrl = useAppSelector(selectNameUrl);
  const userStatus = useAppSelector(selectUserStatus);
  const dateSelect = useAppSelector(selectDateSelect);

  async function fetchData() {
    const resName = await instance.get<FetchResponseTable>(
      `trendyol/category/name/${category_url}`,
    );
    dispatch(setCategoryId({ categoryId: category_url }));
    dispatch(setNameCategory({ categoryName: resName.data.message.name }));
    dispatch(setNameUrl({ nameUrl: resName.data.message.url }));
  }

  if (categoryId !== category_url) {
    fetchData();
  }

  const param: any = {
    category_url,
    startDate: dateSelect[0],
    endDate: dateSelect[1],
  };

  useEffect(() => {
    dispatch(fetchPriceSegmentsTable(category_url));
    dispatch(fetchDataRevenue(param));
    dispatch(fetchDataPriceAvg(param));
    dispatch(fetchDataProducts(param));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCategoryTableState({}));
      dispatch(resetPriceSegmentsTable({}));
    };
  }, []);

  const columns = [
    {
      name: t("priceRange"),
      selector: "range",
      sortable: true,
      sortFunction: (
        rowA: { min_range_price: string },
        rowB: { min_range_price: string },
      ) => {
        const rowAPrice = parseInt(rowA.min_range_price);
        const rowBPrice = parseInt(rowB.min_range_price);

        if (rowAPrice > rowBPrice) {
          return 1;
        }

        if (rowAPrice < rowBPrice) {
          return -1;
        }

        return 0;
      },
      cell: (row: {
        id: string;
        range: string;
        min_range_price: string;
        max_range_price: string;
      }) => (
        <>
          <NavLink
            style={{
              display: "inline-block",
              width: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            to={`/trendyol/category/${category_url}/products?price_from=${
              (row.min_range_price as any) / 100
            }&price_to=${(row.max_range_price as any) / 100}`}
          >
            {/*{row.min_range_price + "-" + row.max_range_price}*/}
            {(+row.min_range_price / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL"}{" "}
            -{" "}
            {(+row.max_range_price / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL"}
          </NavLink>
        </>
      ),
    },

    {
      name: t("sold"),
      selector: "sold",
      sortable: true,
      format: (row: { sold: any }) =>
        row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("revenue"),
      selector: "revenue",
      sortable: true,
      id: "revenue",
      format: (row: { revenue: number }) =>
        (row.revenue / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },

    {
      name: t("prodCount"),
      selector: "products",
      sortable: true,
      format: (row: { products: any }) =>
        row.products.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodCountWithSold"),
      selector: "products_with_sold",
      sortable: true,
      format: (row: { products_with_sold: any }) =>
        row.products_with_sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 1
    {
      name: t("Share of goods with sales"),
      selector: "share_prod_with_sales",
      sortable: true,
      format: (row: { share_prod_with_sales: any }) =>
        row.share_prod_with_sales
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    // 1
    {
      name: t("brandCount"),
      selector: "brands",
      sortable: true,
      format: (row: { brands: any }) =>
        row.brands.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("brandCountWithSold"),
      selector: "brands_with_sells",
      sortable: true,
      format: (row: { brands_with_sells: any }) =>
        row.brands_with_sells.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 2
    {
      name: t("Share of brands with sales"),
      selector: "share_brand_with_sales",
      sortable: true,
      format: (row: { share_brand_with_sales: any }) =>
        row.share_brand_with_sales
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },

    // 2
    {
      name: t("merchCount"),
      selector: "merchants",
      sortable: true,
      format: (row: { merchants: any }) =>
        row.merchants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("merchCountWithSold"),
      selector: "merchants_with_sells",
      sortable: true,
      format: (row: { merchants_with_sells: any }) =>
        row.merchants_with_sells
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // 3
    {
      name: t("Share of salespeople with sales"),
      selector: "share_merch_with_sales",
      sortable: true,
      format: (row: { share_merch_with_sales: any }) =>
        row.share_merch_with_sales
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },

    // 3
    // RU: ; EN: Average revenue per product; TR:
    //
    // RU: Средняя выручка на 1 товар с продажами; EN: Average revenue per product with sales; TR: Satışlarla birlikte ürün başına ortalama gelir
    {
      name: t("Average revenue per product"),
      selector: "product_revenue_avg",
      format: (row: { product_revenue_avg: any }) =>
        Math.round(row.product_revenue_avg / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("Average revenue per product with sales"),
      selector: "product_with_sold_revenue_avg",
      format: (row: { product_with_sold_revenue_avg: any }) =>
        Math.round(row.product_with_sold_revenue_avg / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
  ] as any;

  const [modal_standard, setmodal_standard] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />
      {/*{tableDescription && (*/}
      {/*  <TableDescription setTableDescription={setTableDescription} />*/}
      {/*)}*/}
      {status === "priceSegmentsTable-loading" && (
        <CustomSpinner absolute={true} top={"600px"} />
      )}

      <div
      // className={status === "priceSegmentsTable-loading" ? s.opasity : ""}
      // style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div
              className="
               d-flex align-items-center justify-content-between"
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>{" "}
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    <Link
                      // target="_blank"
                      to="/trendyol/category-list"
                    >
                      Categories
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      // marginLeft: "15px",
                    }}
                    active
                  >
                    {categoryId === category_url && currentLanguage === "tr" ? (
                      <> {nameCategory}&nbsp;</>
                    ) : null}
                    {t("AnalyticalReportByCategory")}&nbsp;
                    {categoryId === category_url && currentLanguage !== "tr"
                      ? nameCategory
                      : null}
                  </BreadcrumbItem>
                  {/*<CardHeader>*/}
                  {/*{nameCategory}*/}
                  {/*</CardHeader>*/}
                  {/*<BreadcrumbItem*/}
                  {/*  style={{*/}
                  {/*    marginTop: "10px",*/}
                  {/*    marginBottom: "10px",*/}
                  {/*  }}*/}
                  {/*  active*/}
                  {/*>*/}
                  {/*  <span>{storedCategoryName}</span>*/}
                  {/*</BreadcrumbItem>*/}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "10px",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <CardHeader>
            {categoryId === category_url && currentLanguage === "tr" ? (
              <> {nameCategory}&nbsp;</>
            ) : null}
            {t("AnalyticalReportByCategory")}&nbsp;
            {categoryId === category_url && currentLanguage !== "tr"
              ? nameCategory
              : null}
            {/*{nameCategory}*/}
            &nbsp;
            <a href={nameUrl} target="_blank">
              <i
                className="uil-external-link-alt "
                style={{ color: "DeepSkyBlue" }}
              ></i>
            </a>
          </CardHeader>
        </div>
        <div className="row" style={{ marginBottom: "2px", marginTop: "5px" }}>
          <WidgetCard
            bar={true}
            selector={selectStatusDataRevenue}
            dataSelector={selectDataRevenue}
            id={category_url}
            title={t("revenue")}
            // subtitle={"Бабло"}
            select={false}
          />
          <WidgetBlockCategory
            id={category_url}
            selector={selectStatusPriceAvg}
            dataSelector={selectDataPriceAvg}
          />
          <WidgetBlockCategoryTwo
            title={t("brands and sellers")}
            id={category_url}
            selector={selectStatusBrandAndSellers}
            dataSelector={selectDataBrandAndSellers}
            selectDropdown={true}
            selectChoiceBrandAndSellers={selectChoiceBrandAndSellers}
            fetchDataBrands={fetchDataBrands}
            fetchDataSellers={fetchDataSellers}
          />
          <WidgetBlockCategoryTwo
            title={t("products")}
            id={category_url}
            selector={selectStatusProducts}
            dataSelector={selectDataProducts}
            selectDropdown={false}
          />
        </div>
        <div
          className={status === "priceSegmentsTable-loading" ? s.opasity : ""}
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%", background: "white", paddingLeft: "10px" }}
            className="d-flex flex-wrap gap-2 align-items-center"
          >
            <NavLink
              className={"btn-sm btn btn-light"}
              style={{ marginTop: "10px" }}
              to={`/trendyol/category/${category_url}/products`}
            >
              {t("products")}
            </NavLink>
            <NavLink
              to={`/trendyol/category/${category_url}/sellers`}
              type="button"
              className="btn-sm btn btn-light"
              style={{ marginTop: "10px" }}
            >
              {t("sellers")}
            </NavLink>
            <NavLink
              to={`/trendyol/category/${category_url}/brands`}
              type="button"
              className="btn-sm btn btn-light"
              style={{
                marginTop: "10px",
              }}
            >
              {t("brands")}
            </NavLink>
            <NavLink
              to={`/trendyol/price/${category_url}`}
              type="button"
              className="btn-sm btn btn-primary"
              style={{
                marginTop: "10px",
              }}
            >
              {t("Price segments")}
            </NavLink>
            {/*<div*/}
            {/*  style={{*/}
            {/*    display: "flex",*/}
            {/*    alignItems: "center",*/}
            {/*    marginLeft: "auto",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <i*/}
            {/*    onClick={() => setmodal_standard(true)}*/}
            {/*    // onClick={() => setTableDescription(true)}*/}
            {/*    className="uil-question-circle "*/}
            {/*    style={{ fontSize: "24px", cursor: "pointer" }}*/}
            {/*  ></i>*/}
            {/*</div>*/}
          </div>
          <DataTable
            conditionalRowStyles={
              userStatus === "trial" || userStatus === "unpaid"
                ? conditionalRowStyles
                : ""
            }
            className={`customTableYaroslav tableList ${
              userStatus === "trial" || userStatus === "unpaid"
                ? "no-scroll"
                : ""
            }`}
            // className={"customTableYaroslav"}
            defaultSortFieldId={"range"}
            striped
            defaultSortAsc={true}
            columns={columns}
            data={data}
            subHeader
            fixedHeader
            sortServer={false}
            fixedHeaderScrollHeight={newHeight}
            sortIcon={null}
            // onChangePage={handlePageChange}
            responsive={true}
            // noDataComponent={<div>{t("dataTable")}</div>}
            noDataComponent={<div>{""}</div>}
          />
          {userStatus === "trial" || userStatus === "unpaid" ? (
            <div className={s.blur}></div>
          ) : null}
        </div>
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
