import React, { ReactNode } from "react";

interface SimpleBarWrapperProps {
  children: ReactNode;
}

export function ScrollWrapper({ children }: SimpleBarWrapperProps) {
  return (
    <div data-simplebar="init" className="chat-message-list widget-chat-list">
      <div className="simplebar-wrapper" style={{ margin: "0px" }}>
        <div className="simplebar-mask">
          <div
            className="simplebar-offset"
            style={{ right: "0px", bottom: "0px", top: "0px" }}
          >
            <div
              className="simplebar-content-wrapper"
              role="region"
              aria-label="scrollable content"
              style={{ overflow: "hidden scroll", top: "0px" }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
