import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "subscribeButton",
  initialState: {
    isFollowing: null as boolean | null,
    // subscribe: {} as any,
    toggle: null as boolean | null,
    status: "subscribe-loading" as RequestStatusType,
  },
  reducers: {
    setIsSubscribe(state, action: PayloadAction<{ isFollowing: boolean }>) {
      state.isFollowing = action.payload.isFollowing;
    },
    // setSubscribe(state, action: PayloadAction<{ subscribe: any }>) {
    //   state.subscribe = action.payload.subscribe;
    // },
    setToggleSubscription(
      state,
      action: PayloadAction<{ toggle: boolean | null }>,
    ) {
      state.toggle = action.payload.toggle;
    },
    setStatusSubscribeButton(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    clearSubscribeButton(state, action) {
      return {
        ...state,
        isFollowing: null as boolean | null,
        toggle: null as boolean | null,
      };
    },
  },
});
export const subscribeButtonReducer = slice.reducer;
export const {
  setIsSubscribe,
  setStatusSubscribeButton,
  setToggleSubscription,
  clearSubscribeButton,
} = slice.actions;
