import { AppRootStateType } from "src/store/types";

export const selectData = (state: AppRootStateType) =>
  state.product.dataProduct;

export const selectItemNumber = (state: AppRootStateType) =>
  state.product.itemNumber;

export const selectStatusProduct = (state: AppRootStateType) =>
  state.product.statusProduct;
