// // import React, { useEffect, useState } from "react";
// // import { Link, useParams } from "react-router-dom";
// // import { Row, Container } from "reactstrap";
// // import { GalleryData } from "src/features/product/gallery";
// // import { selectMerchantId } from "src/features/product/miniProductComponent/seller/selectors";
// // import { clearProductData } from "src/features/product/product-reducer";
// // import { selectData, selectItemNumber } from "src/features/product/selectors";
// // import { fetchProduct } from "src/features/product/thunk";
// // import { useAppSelector } from "src/store/types";
// // import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// //
// // //Import Images
// // import img1 from "../../assets/images/small/img-1.jpg";
// // import img3 from "../../assets/images/small/img-3.jpg";
// // import img4 from "../../assets/images/small/img-4.jpg";
// // import img5 from "../../assets/images/small/img-5.jpg";
// // import img6 from "../../assets/images/small/img-6.jpg";
// // import img7 from "../../assets/images/small/img-7.jpg";
// //
// // //Import Light box
// // import Lightbox from "react-image-lightbox";
// // import "react-image-lightbox/style.css";
// //
// // const Gallery = () => {
// //   document.title = "Gallery | Dashonic - React Admin & Dashboard Template";
// //   const [displayCategory, setCategory] = useState<string>("All");
// //   const [Modal, setModal] = useState<boolean>(false);
// //   const [photoIndex, setphotoIndex] = useState<number>(0);
// //
// //   const images = [img1, img3, img4, img5, img6, img7];
// //   const { id } = useParams();
// //   const dispatch = useAppDispatch();
// //   const data = useAppSelector(selectData);
// //   const itemNumber = useAppSelector(selectItemNumber);
// //   const merchantIdUrl = useAppSelector(selectMerchantId);
// //   const merchantId = merchantIdUrl ? "/" + merchantIdUrl : "";
// //   const itemNumberUrl = itemNumber !== null ? "/" + itemNumber : "";
// //   document.title = `Restatos | ${data.name}`;
// //
// //   useEffect(() => {
// //     dispatch(
// //       fetchProduct({
// //         id,
// //         itemNumberUrl,
// //         merchantId,
// //       }),
// //     );
// //   }, [id, merchantId, itemNumber]);
// //
// //   useEffect(() => {
// //     return () => {
// //       dispatch(clearProductData({}));
// //       localStorage.removeItem("url");
// //       // localStorage.clear();
// //     };
// //   }, []);
// //
// //   return (
// //     <React.Fragment>
// //       <Container fluid>
// //         <Row>
// //           {/*<Breadcrumbs*/}
// //           {/*  title={"Trendyol"}*/}
// //           {/*  id={data.category?.id}*/}
// //           {/*  categoryName={data.category?.name}*/}
// //           {/*  breadcrumbItem={data.name}*/}
// //           {/*/>*/}
// //           {/*<div className="page-content">*/}
// //           {/*  <Container fluid>*/}
// //           {/*    <Row>*/}
// //           {/*<Col xs="12">*/}
// //           {/*  <Card>*/}
// //           {/*    <CardHeader className="justify-content-between d-flex align-items-center">*/}
// //           {/*      <h4 className="card-title">Gallery Photos</h4>*/}
// //           {/*      <p className="m-0 badge badge-soft-primary py-2">*/}
// //           {/*        Dashonic Only*/}
// //           {/*      </p>*/}
// //           {/*    </CardHeader>*/}
// //           {/*    <CardBody>*/}
// //           {/*<Row>*/}
// //           {/*  <Col lg={12}>*/}
// //           <div className="text-center">
// //             <ul
// //               className="list-inline categories-filter button-group"
// //               id="filter"
// //             >
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Продажи")}
// //                   className={
// //                     displayCategory === "Продажи"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Продажи
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Поиск")}
// //                   className={
// //                     displayCategory === "Поиск"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Поиск
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Категории")}
// //                   className={
// //                     displayCategory === "Категории"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Категории
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Реклама")}
// //                   className={
// //                     displayCategory === "Реклама"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Реклама
// //                 </Link>{" "}
// //               </li>
// //               {/*<li className="list-inline-item">*/}
// //               {/*  <Link*/}
// //               {/*    to="#"*/}
// //               {/*    onClick={() => setCategory("development")}*/}
// //               {/*    className={*/}
// //               {/*      displayCategory === "development"*/}
// //               {/*        ? "categories active"*/}
// //               {/*        : "categories"*/}
// //               {/*    }*/}
// //               {/*  >*/}
// //               {/*    Development*/}
// //               {/*  </Link>*/}
// //               {/*</li>*/}
// //             </ul>
// //           </div>
// //           {/*</Col>*/}
// //         </Row>
// //         <Row className="gallery-wrapper">
// //           {GalleryData.filter(
// //             ({ category }: any) =>
// //               category.includes(displayCategory) ||
// //               displayCategory === "All",
// //           ).map(({ title, image, author }, key) => (
// //             // <Col key={key} xl={4} sm={6} className="element-item">
// //             //   <Card className="gallery-box">
// //             //     <div className="gallery-container">
// //             //       <Link
// //             //         className="image-popup"
// //             //         to={image}
// //             //         title={title}
// //             //         onClick={(event) => {
// //             //           event.preventDefault();
// //             //           setModal(!Modal);
// //             //           setphotoIndex(key);
// //             //         }}
// //             //       >
// //             //         <img
// //             //           className="gallery-img img-fluid mx-auto"
// //             //           src={image}
// //             //           alt={title}
// //             //         />
// //             //         <div className="gallery-overlay"></div>
// //             //       </Link>
// //             //     </div>
// //             //
// //             //     <div className="box-content p-3">
// //             //       <h5 className="title">{title}</h5>
// //             //       <p className="post">
// //             //         by{" "}
// //             //         <Link to="#" className="text-body">
// //             //           {author}
// //             //         </Link>
// //             //       </p>
// //             //     </div>
// //             //   </Card>
// //             // </Col>
// //           ))}
// //           </Row>
// //           {/*</CardBody>*/}
// //           {/*</Card>*/}
// //           {/*</Col>*/}
// //         {/*</Row>*/}
// //       {/*</Container>*/}
// //       {/*</div>*/}
// //     </React.Fragment>
// //   );
// // };
// //
// // export default Gallery;
// //
// // import React, { useEffect, useState } from "react";
// // import { Link, useParams } from "react-router-dom";
// // import { Row, Container, Col, CardBody, Card } from "reactstrap";
// // import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs";
// // import { GalleryData } from "src/features/product/gallery";
// // import { DateSelect } from "src/features/product/miniProductComponent/dateSelect/DateSelect";
// // import { Description } from "src/features/product/miniProductComponent/description/Description";
// // import { Photo } from "src/features/product/miniProductComponent/photo/Photo";
// // import { selectMerchantId } from "src/features/product/miniProductComponent/seller/selectors";
// // import { Seller } from "src/features/product/miniProductComponent/seller/Seller";
// // import { Variants } from "src/features/product/miniProductComponent/variants/Variants";
// // import { clearProductData } from "src/features/product/product-reducer";
// // import { selectData, selectItemNumber } from "src/features/product/selectors";
// // import { fetchProduct } from "src/features/product/thunk";
// // import { useAppSelector } from "src/store/types";
// // import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// //
// // const Gallery = () => {
// //   document.title = "Gallery | Dashonic - React Admin & Dashboard Template";
// //   const [displayCategory, setCategory] = useState<string>("Продажи");
// //
// //   const { id } = useParams();
// //   const dispatch = useAppDispatch();
// //   const data = useAppSelector(selectData);
// //   const itemNumber = useAppSelector(selectItemNumber);
// //   const merchantIdUrl = useAppSelector(selectMerchantId);
// //   const merchantId = merchantIdUrl ? "/" + merchantIdUrl : "";
// //   const itemNumberUrl = itemNumber !== null ? "/" + itemNumber : "";
// //   document.title = `Restatos | ${data.name}`;
// //
// //   useEffect(() => {
// //     dispatch(
// //       fetchProduct({
// //         id,
// //         itemNumberUrl,
// //         merchantId,
// //       }),
// //     );
// //   }, [id, merchantId, itemNumber]);
// //
// //   useEffect(() => {
// //     return () => {
// //       dispatch(clearProductData({}));
// //       localStorage.removeItem("url");
// //     };
// //   }, []);
// //   const components = GalleryData({ data, id, itemNumberUrl, merchantId });
// //   return (
// //     <React.Fragment>
// //       <Container fluid>
// //         <Row>
// //           <Breadcrumbs
// //             title={"Trendyol"}
// //             id={data.category?.id}
// //             categoryName={data.category?.name}
// //             breadcrumbItem={data.name}
// //           />
// //           <Col xl={3}>
// //             <Photo itemNumberUrl={itemNumberUrl} id={id} photo={data} />
// //           </Col>
// //           <Col xl={3}>
// //             <Description
// //               data={data}
// //               id={id}
// //               merchantId={merchantId}
// //               itemNumberUrl={itemNumberUrl}
// //             />
// //           </Col>
// //           <Col xl={3}>
// //             <Variants data={data} id={id} merchantId={merchantId} />
// //           </Col>
// //           <Col xl={3}>
// //             <Seller itemNumberUrl={itemNumberUrl} id={id} />
// //           </Col>
// //           <Card style={{ height: "100px", marginTop: "10px" }}>
// //             <CardBody>
// //               <Row>
// //                 <DateSelect />
// //               </Row>
// //             </CardBody>
// //           </Card>
// //           <div className="text-center">
// //             <ul
// //               className="list-inline categories-filter button-group"
// //               // id="filter"
// //             >
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Продажи")}
// //                   className={
// //                     displayCategory === "Продажи"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Продажи
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Поиск")}
// //                   className={
// //                     displayCategory === "Поиск"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Поиск
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Категории")}
// //                   className={
// //                     displayCategory === "Категории"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Категории
// //                 </Link>{" "}
// //               </li>
// //               <li className="list-inline-item">
// //                 <Link
// //                   to="#"
// //                   onClick={() => setCategory("Реклама")}
// //                   className={
// //                     displayCategory === "Реклама"
// //                       ? "categories active"
// //                       : "categories"
// //                   }
// //                 >
// //                   Реклама
// //                 </Link>{" "}
// //               </li>
// //             </ul>
// //           </div>
// //         </Row>
// //         <Row className="gallery-wrapper">
// //           {/*{GalleryData.filter(*/}
// //           {/*  ({ category }: any) =>*/}
// //           {/*    category.includes(displayCategory) || displayCategory === "All",*/}
// //           {/*).map(({ id, component }, key) => (*/}
// //           {/*  <div key={key} className="col-12 mb-4">*/}
// //           {/*    {component({ itemNumberUrl, id, merchantId })}*/}
// //           {/*  </div>*/}
// //           {/*))}*/}
// //
// //           {components.map((item) => (
// //             <div key={item.id}>
// //               {item.component.map((Component, index) => (
// //                 <div key={index}>
// //                   {/* Conditionally render the first component with additional Card */}
// //                   {/*{index === 0 && (*/}
// //                   {/*  <Card style={{ height: "100px", marginTop: "10px" }}>*/}
// //                   {/*    <CardBody>*/}
// //                   {/*      <Row>{Component}</Row>*/}
// //                   {/*    </CardBody>*/}
// //                   {/*  </Card>*/}
// //                   {/*)}*/}
// //                   {/* Render other components based on displayCategory */}
// //                   {displayCategory === item.name && Component}
// //                 </div>
// //               ))}
// //             </div>
// //           ))}
// //         </Row>
// //       </Container>
// //     </React.Fragment>
// //   );
// // };
// //
// // export default Gallery;
// //
// // import React, { useEffect, useState } from "react";
// // import { Link, useParams } from "react-router-dom";
// // import { Row, Container, Col, CardBody, Card } from "reactstrap";
// // import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs";
// // import { GalleryData } from "src/features/product/gallery";
// // import { DateSelect } from "src/features/product/miniProductComponent/dateSelect/DateSelect";
// // import { Description } from "src/features/product/miniProductComponent/description/Description";
// // import { Photo } from "src/features/product/miniProductComponent/photo/Photo";
// // import { selectMerchantId } from "src/features/product/miniProductComponent/seller/selectors";
// // import { Seller } from "src/features/product/miniProductComponent/seller/Seller";
// // import { Variants } from "src/features/product/miniProductComponent/variants/Variants";
// // import { clearProductData } from "src/features/product/product-reducer";
// // import { selectData, selectItemNumber } from "src/features/product/selectors";
// // import { fetchProduct } from "src/features/product/thunk";
// // import { useAppSelector } from "src/store/types";
// // import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// // import "./style.module.css";
// //
// // const Gallery = () => {
// //   const [displayCategory, setCategory] = useState<string>("Продажи");
// //
// //   const { id } = useParams();
// //   const dispatch = useAppDispatch();
// //   const data = useAppSelector(selectData);
// //   const itemNumber = useAppSelector(selectItemNumber);
// //   const merchantIdUrl = useAppSelector(selectMerchantId);
// //   const merchantId = merchantIdUrl ? "/" + merchantIdUrl : "";
// //   const itemNumberUrl = itemNumber !== null ? "/" + itemNumber : "";
// //
// //   useEffect(() => {
// //     dispatch(fetchProduct({ id, itemNumberUrl, merchantId }));
// //   }, [id, merchantId, itemNumber]);
// //
// //   useEffect(() => {
// //     return () => {
// //       dispatch(clearProductData({}));
// //       localStorage.removeItem("url");
// //     };
// //   }, []);
// //
// //   const components = GalleryData({ data, id, itemNumberUrl, merchantId });
// //
// //   return (
// //     <Container fluid>
// //       <Row>
// //         <Breadcrumbs
// //           title={"Trendyol"}
// //           id={data.category?.id}
// //           categoryName={data.category?.name}
// //           breadcrumbItem={data.name}
// //         />
// //         <Col xl={3}>
// //           <Photo itemNumberUrl={itemNumberUrl} id={id} photo={data} />
// //         </Col>
// //         <Col xl={3}>
// //           <Description
// //             data={data}
// //             id={id}
// //             merchantId={merchantId}
// //             itemNumberUrl={itemNumberUrl}
// //           />
// //         </Col>
// //         <Col xl={3}>
// //           <Variants data={data} id={id} merchantId={merchantId} />
// //         </Col>
// //         <Col xl={3}>
// //           <Seller itemNumberUrl={itemNumberUrl} id={id} />
// //         </Col>
// //       </Row>
// //
// //       <div className="text-center">
// //         <ul className="list-inline categories-filter button-group">
// //           <li className="list-inline-item">
// //             <Link
// //               to="#"
// //               onClick={() => setCategory("Продажи")}
// //               className={
// //                 displayCategory === "Продажи"
// //                   ? "categories active"
// //                   : "categories"
// //               }
// //             >
// //               Продажи
// //             </Link>
// //           </li>
// //           <li className="list-inline-item">
// //             <Link
// //               to="#"
// //               onClick={() => setCategory("Поиск")}
// //               className={
// //                 displayCategory === "Поиск" ? "categories active" : "categories"
// //               }
// //             >
// //               Поиск
// //             </Link>
// //           </li>
// //           <li className="list-inline-item">
// //             <Link
// //               to="#"
// //               onClick={() => setCategory("Категории")}
// //               className={
// //                 displayCategory === "Категории"
// //                   ? "categories active"
// //                   : "categories"
// //               }
// //             >
// //               Категории
// //             </Link>
// //           </li>
// //           <li className="list-inline-item">
// //             <Link
// //               to="#"
// //               onClick={() => setCategory("Реклама")}
// //               className={
// //                 displayCategory === "Реклама"
// //                   ? "categories active"
// //                   : "categories"
// //               }
// //             >
// //               Реклама
// //             </Link>
// //           </li>
// //         </ul>
// //       </div>
// //
// //       <Row className="gallery-wrapper">
// //         {components
// //           .filter(
// //             ({ name }) =>
// //               name.includes(displayCategory) || displayCategory === "All",
// //           )
// //           .map((item) => (
// //             <div key={item.id}>
// //               {item.component.map((Component, index) => (
// //                 <div key={index}>
// //                   {displayCategory === item.name && Component}
// //                 </div>
// //               ))}
// //             </div>
// //           ))}
// //       </Row>
// //     </Container>
// //   );
// // };
// //
// // export default Gallery;
//
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Row, Container, Col, CardBody, Card } from "reactstrap";
// import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs";
// import { GalleryData } from "src/features/product/gallery";
// import { DateSelect } from "src/features/product/miniProductComponent/dateSelect/DateSelect";
// import { Description } from "src/features/product/miniProductComponent/description/Description";
// import { Photo } from "src/features/product/miniProductComponent/photo/Photo";
// import { selectMerchantId } from "src/features/product/miniProductComponent/seller/selectors";
// import { Seller } from "src/features/product/miniProductComponent/seller/Seller";
// import { Variants } from "src/features/product/miniProductComponent/variants/Variants";
// import { clearProductData } from "src/features/product/product-reducer";
// import { selectData, selectItemNumber } from "src/features/product/selectors";
// import { fetchProduct } from "src/features/product/thunk";
// import { useAppSelector } from "src/store/types";
// import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// import "./style.module.css";
//
// const Gallery = () => {
//   const [displayCategory, setCategory] = useState<string>("Продажи");
//
//   const { id } = useParams();
//   const dispatch = useAppDispatch();
//   const data = useAppSelector(selectData);
//   const itemNumber = useAppSelector(selectItemNumber);
//   const merchantIdUrl = useAppSelector(selectMerchantId);
//   const merchantId = merchantIdUrl ? "/" + merchantIdUrl : "";
//   const itemNumberUrl = itemNumber !== null ? "/" + itemNumber : "";
//
//   useEffect(() => {
//     dispatch(fetchProduct({ id, itemNumberUrl, merchantId }));
//   }, [id, merchantId, itemNumber]);
//
//   useEffect(() => {
//     return () => {
//       dispatch(clearProductData({}));
//       localStorage.removeItem("url");
//     };
//   }, []);
//
//   const components = GalleryData({ data, id, itemNumberUrl, merchantId });
//
//   const handleCategoryChange = (category: any) => {
//     const currentScrollPosition = window.scrollY;
//     console.log(currentScrollPosition, "currentScrollPosition");
//     setCategory(category);
//     // setTimeout(() => {
//     console.log(
//       currentScrollPosition,
//       " window.scrollTo(currentScrollPosition",
//     );
//
//     window.scrollTo(0, currentScrollPosition);
//     // }, 0);
//   };
//
//   return (
//     <Container fluid>
//       <Row>
//         <Breadcrumbs
//           title={"Trendyol"}
//           id={data.category?.id}
//           categoryName={data.category?.name}
//           breadcrumbItem={data.name}
//         />
//         <Col xl={3}>
//           <Photo itemNumberUrl={itemNumberUrl} id={id} photo={data} />
//         </Col>
//         <Col xl={3}>
//           <Description
//             data={data}
//             id={id}
//             merchantId={merchantId}
//             itemNumberUrl={itemNumberUrl}
//           />
//         </Col>
//         <Col xl={3}>
//           <Variants data={data} id={id} merchantId={merchantId} />
//         </Col>
//         <Col xl={3}>{/*<Seller itemNumberUrl={itemNumberUrl} id={id} />*/}</Col>
//       </Row>
//
//       <div className="text-center">
//         <ul className="list-inline categories-filter button-group">
//           <li className="list-inline-item">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleCategoryChange("Продажи");
//               }}
//               className={
//                 displayCategory === "Продажи"
//                   ? "categories active"
//                   : "categories"
//               }
//             >
//               Продажи
//             </button>
//           </li>
//           <li className="list-inline-item">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleCategoryChange("Поиск");
//               }}
//               className={
//                 displayCategory === "Поиск" ? "categories active" : "categories"
//               }
//             >
//               Поиск
//             </button>
//           </li>
//           <li className="list-inline-item">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleCategoryChange("Категории");
//               }}
//               className={
//                 displayCategory === "Категории"
//                   ? "categories active"
//                   : "categories"
//               }
//             >
//               Категории
//             </button>
//           </li>
//           <li className="list-inline-item">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 handleCategoryChange("Реклама");
//               }}
//               className={
//                 displayCategory === "Реклама"
//                   ? "categories active"
//                   : "categories"
//               }
//             >
//               Реклама
//             </button>
//           </li>
//         </ul>
//       </div>
//
//       <Row style={{ height: "80%" }} className="gallery-wrapper">
//         {components
//           .filter(
//             ({ name }) =>
//               name.includes(displayCategory) || displayCategory === "All",
//           )
//           .map((item) => (
//             <div key={item.id}>
//               {item.component.map((Component, index) => (
//                 <div key={index}>
//                   {displayCategory === item.name && Component}
//                 </div>
//               ))}
//             </div>
//           ))}
//       </Row>
//     </Container>
//   );
// };
//
// export default Gallery;

import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import { Link, useParams } from "react-router-dom";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs";
import { fetchExcelDataBrandsTableProduct } from "src/features/BrandsTable/thunk";
import { AdvertisingCompany } from "src/features/product/miniProductComponent/advertisingCompany/AdvertisingCompany";
import { DateSelect } from "src/features/product/miniProductComponent/dateSelect/DateSelect";
import { Description } from "src/features/product/miniProductComponent/description/Description";
import { DescriptionEdit } from "src/features/product/miniProductComponent/descriptionEdit/DescriptionEdit";
import { Feedback } from "src/features/product/miniProductComponent/feedback/Feedback";
import { GraphWrapper } from "src/features/product/miniProductComponent/graphWrapper/GraphWrapper";
import { Photo } from "src/features/product/miniProductComponent/photo/Photo";
import { selectMerchantId } from "src/features/product/miniProductComponent/seller/selectors";
import { Seller } from "src/features/product/miniProductComponent/seller/Seller";
import { TableCategoryPosition } from "src/features/product/miniProductComponent/table/tableCategoryPosition/TableCategoryPosition";
import { TableSearchPosition } from "src/features/product/miniProductComponent/table/tableSearchPosition/TableSearchPosition";
import { TableSold } from "src/features/product/miniProductComponent/table/tableSold/TableSold";
import { Variants } from "src/features/product/miniProductComponent/variants/Variants";
import { clearProductData } from "src/features/product/product-reducer";
import { selectData, selectItemNumber } from "src/features/product/selectors";
import { fetchProduct } from "src/features/product/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

//Import Breadcrumb

const UiTabsAccordions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectData);
  const itemNumber = useAppSelector(selectItemNumber);
  const merchantIdUrl = useAppSelector(selectMerchantId);
  const merchantId = merchantIdUrl ? "/" + merchantIdUrl : "";
  const itemNumberUrl = itemNumber !== null ? "/" + itemNumber : "";
  document.title = `Restatos | ${data.name}`;

  useEffect(() => {
    dispatch(
      fetchProduct({
        id,
        itemNumberUrl,
        merchantId,
      }),
    );
  }, [id, merchantId, itemNumber]);

  useEffect(() => {
    return () => {
      dispatch(clearProductData({}));
      localStorage.removeItem("url");
      // localStorage.clear();
    };
  }, []);
  const [navPillTab, setnavPillTab] = useState("1");

  const navPilltoggle = (tab: any) => {
    if (navPillTab !== tab) setnavPillTab(tab);
  };

  return (
    <React.Fragment>
      {/*<div className="page-content">*/}
      <Container fluid={true}>
        <Row>
          <Breadcrumbs
            title={"Trendyol"}
            id={data.category?.id}
            categoryName={data.category?.name}
            breadcrumbItem={data.name}
          />
          <Col xl={3}>
            <Photo itemNumberUrl={itemNumberUrl} id={id} photo={data} />
          </Col>
          <Col xl={3}>
            <Description
              data={data}
              id={id}
              merchantId={merchantId}
              itemNumberUrl={itemNumberUrl}
            />
          </Col>
          <Col xl={3}>
            <Variants data={data} id={id} merchantId={merchantId} />
          </Col>
          <Col xl={3}>
            <Seller itemNumberUrl={itemNumberUrl} id={id} />
          </Col>
          <Card style={{ height: "100px", marginTop: "10px" }}>
            <CardBody>
              <Row>
                <DateSelect />
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Col xl={6} className="col-xxl-12">
            <Card>
              <CardBody>
                <Nav pills justified className="bg-light">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "1" })}
                      onClick={() => {
                        navPilltoggle("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">{t("sales")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "2" })}
                      onClick={() => {
                        navPilltoggle("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">{t("Search")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "3" })}
                      onClick={() => {
                        navPilltoggle("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {t("categories")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "4" })}
                      onClick={() => {
                        navPilltoggle("4");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">{t("Adverts")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "5" })}
                      onClick={() => {
                        navPilltoggle("5");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">{t("Feedback")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: navPillTab === "6" })}
                      onClick={() => {
                        navPilltoggle("6");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {t("Description")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={navPillTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <GraphWrapper
                      data={data}
                      id={id}
                      itemNumberUrl={itemNumberUrl}
                      merchantId={merchantId}
                    />
                    <TableSold
                      itemNumberUrl={itemNumberUrl}
                      id={id}
                      merchantId={merchantId}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <TableSearchPosition id={id} />
                  </TabPane>
                  <TabPane tabId="3">
                    <TableCategoryPosition id={id} />
                  </TabPane>
                  <TabPane tabId="4">
                    <AdvertisingCompany
                      id={id}
                      merchantId={merchantId}
                      itemNumberUrl={itemNumberUrl}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    {/*<AdvertisingCompany*/}
                    {/*  id={id}*/}
                    {/*  merchantId={merchantId}*/}
                    {/*  itemNumberUrl={itemNumberUrl}*/}
                    {/*/>*/}
                    <Feedback id={id} merchantId={merchantId} />
                  </TabPane>
                  <TabPane tabId="6">
                    {/*<Col xl={6}>*/}
                    <DescriptionEdit id={id} />
                    {/*</Col>*/}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/*</div>*/}
    </React.Fragment>
  );
};

export default UiTabsAccordions;
