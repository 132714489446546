import React from "react";
import { layoutPositions } from "src/constants/layout";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { changeLayoutPosition } from "../../slices/thunks";
//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

export const RightSidebar = ({ onClosed }: any) => {
  const dispatch = useAppDispatch();

  const { layoutPosition } = useSelector((state: any) => ({
    layoutPosition: state.layout.layoutPosition,
  }));

  return (
    <React.Fragment>
      <div>
        <SimpleBar className="vh-100">
          <div className="rightbar-title d-flex align-items-center bg-dark p-3">
            <h5 className="m-0 me-2 text-white">Theme Customizer</h5>
            <Link
              to="#"
              onClick={onClosed}
              className="right-bar-toggle ms-auto"
            >
              <i className="mdi mdi-close noti-icon"></i>
            </Link>
          </div>
          <hr className="m-0" />
          <div className="p-4">
            <h6 className="mb-3">Layouts</h6>
            <h6 className="mt-4 mb-3">Layout Position</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="layout-position-fixed"
                name="layout-position"
                value={layoutPositions.SCROLLABLE_FALSE}
                className="form-check-input"
                checked={layoutPosition === layoutPositions.SCROLLABLE_FALSE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutPosition(e.target.value));
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="layout-position-fixed"
              >
                Fixed
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="layout-position-scrollable"
                name="layout-position"
                value={layoutPositions.SCROLLABLE_TRUE}
                className="form-check-input"
                checked={layoutPosition === layoutPositions.SCROLLABLE_TRUE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutPosition(e.target.value));
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="layout-position-scrollable"
              >
                Scrollable
              </label>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};
