import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spinner, Tooltip } from "reactstrap";
import { ScrollWrapper } from "src/components/ScrollWrapper/ScrollWrapper";
import {
  selectStatusVariant,
  selectVariant,
} from "src/features/product/miniProductComponent/variants/selectors";
import { fetchVariantProduct } from "src/features/product/miniProductComponent/variants/thunk";
import {
  clearProductData,
  setItemNumber,
} from "src/features/product/product-reducer";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

type PropsType = {
  data?: any;
  id: string;
  merchantId: string;
};

export const Variants = (props: PropsType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [top, setTop] = useState(false);
  const [number, setNumber] = useState<number | null>(null);
  const variants = useAppSelector(selectVariant);
  const statusVariant = useAppSelector(selectStatusVariant);

  useEffect(() => {
    dispatch(
      fetchVariantProduct({ id: props.id, merchantId: props.merchantId }),
    );
  }, [props.id, props.data, props.merchantId]);

  useEffect(() => {
    return () => {
      dispatch(clearProductData({}));
    };
  }, []);
  return (
    <>
      {statusVariant === "variant-loading" ? (
        <div
          className="card-h-90 card"
          style={{
            backgroundColor: "white",
            alignItems: "center",
            padding: "50px",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      ) : (
        <div className="card-h-90 card">
          <div className="d-flex card-header justify-content-between">
            <div className="h4 card-title" style={{ cursor: "pointer" }}>
              {t("variants")}
              <Tooltip
                placement="top"
                isOpen={top}
                target="varints"
                toggle={() => {
                  setTop(!top);
                }}
              >
                {t("variantsTooltip")}
              </Tooltip>
              <i
                className="uil-question-circle"
                style={{ marginLeft: "3px" }}
                id={"varints"}
              ></i>
            </div>
            {variants.length > 1 && (
              <Button
                style={{
                  marginLeft: "auto",
                  marginTop: "-5px",
                  marginBottom: "-5px",
                }}
                color="info"
                size="sm"
                active={number === null}
                onClick={() => {
                  dispatch(setItemNumber({ itemNumber: null }));
                  setNumber(null);
                }}
              >
                {t("all")}
              </Button>
            )}
          </div>
          <ScrollWrapper>
            <div
              className="card-body"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {variants.length > 2 &&
                variants.map((variant) => (
                  <Button
                    size={"sm"}
                    key={variant.item_number}
                    disabled={variants.length < 2 || variant.enable === false}
                    active={
                      number === variant.item_number && variant.enable !== false
                    }
                    style={{
                      margin: "5px",
                      fontSize: "10px",
                      marginRight: "9px",
                      width: "auto",
                      cursor:
                        variant.enable === false || variants.length < 2
                          ? "not-allowed"
                          : "pointer",

                      backgroundColor:
                        variant.enable === false || variants.length < 2
                          ? "rgba(151,155,155,0.58)"
                          : "",
                      border:
                        variant.enable === false || variants.length < 2
                          ? "rgba(55,63,59,0.58)"
                          : "",
                    }}
                    color="info"
                    onClick={() => {
                      dispatch(
                        setItemNumber({ itemNumber: variant.item_number }),
                      );
                      setNumber(variant.item_number);
                    }}
                  >
                    {variant.name}
                  </Button>
                ))}
            </div>
          </ScrollWrapper>
        </div>
      )}
    </>
  );
};
