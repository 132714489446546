import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "categoryBrandsTable",
  initialState: {
    columnName: [] as Array<InputsType>,
    table: [] as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    nameCategory: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    onChangePhraseCategoryBrandsTable(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setDataCategoryBrandsTable(state, action: PayloadAction<{ table: any }>) {
      state.table = action.payload.table;
    },
    setOrderNameCategoryBrandsTable(
      state,
      action: PayloadAction<{ orderName: any }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    setAscDescCategoryBrandsTable(
      state,
      action: PayloadAction<{ ascDesc: any }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setNameCategoryBrandsTable(
      state,
      action: PayloadAction<{ nameCategory: string }>,
    ) {
      state.nameCategory = action.payload.nameCategory;
    },
    setPageCategoryBrandsTable(state, action: PayloadAction<{ page: any }>) {
      state.page = action.payload.page;
    },
    setTotalCountPageCategoryBrandsTable(
      state,
      action: PayloadAction<{ totalCountPage: any }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    setStatusCategoryBrandsTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    clearTableCategoryBrands(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        table: [] as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        // nameCategory: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const categoryBrandsTableReducer = slice.reducer;
export const {
  onChangePhraseCategoryBrandsTable,
  setDataCategoryBrandsTable,
  setOrderNameCategoryBrandsTable,
  setAscDescCategoryBrandsTable,
  setPageCategoryBrandsTable,
  setTotalCountPageCategoryBrandsTable,
  setStatusCategoryBrandsTable,
  setNameCategoryBrandsTable,
  clearTableCategoryBrands,
} = slice.actions;
