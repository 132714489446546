import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { appReducer } from "src/features/App/reducer";
import { brandsTableReducer } from "src/features/BrandsTable/reducer";
import { brandsTableCategoryReducer } from "src/features/BrandsTableCategory/reducer";
import { brandsSellersTableReducer } from "src/features/BrandsTableSellers/reducer";
import { tableCategoryListReducer } from "src/features/CategoryList/reducer";
import { categoryTableReducer } from "src/features/CategoryTable/reducer";
import { categoryBrandsTableReducer } from "src/features/CategoryTableBrands/reducer";
import { listBrandTableReducer } from "src/features/ListBrandTable/reducer";
import { listSellersTableReducer } from "src/features/ListSellersTable/reducer";
import { merchantTableReducer } from "src/features/MerchantTable/reducer";
import { merchantTableBrandsReducer } from "src/features/MerchantTableBrands/reducer";
import { merchantTableCategoryReducer } from "src/features/MerchantTableCategory/reducer";
import { priceSegmentsTable } from "src/features/Price-Segments-Table/reducer";
import { productAdvertReducer } from "src/features/product/miniProductComponent/advertisingCompany/reducer";
import { descriptionEditProductReducer } from "src/features/product/miniProductComponent/descriptionEdit/reducer";
import { feedbackReducer } from "src/features/product/miniProductComponent/feedback/reducer";
import { graphStockSalesProductReducer } from "src/features/product/miniProductComponent/graphWrapper/reducer";
import { photoProductReducer } from "src/features/product/miniProductComponent/photo/reducer";
import { sellerProductReducer } from "src/features/product/miniProductComponent/seller/reducer";
import { subscribeButtonReducer } from "src/features/product/miniProductComponent/subscribeButton/reducer";
import { tableCategoryPositionProduct } from "src/features/product/miniProductComponent/table/tableCategoryPosition/reducer";
import { tableSearchPositionProduct } from "src/features/product/miniProductComponent/table/tableSearchPosition/reducer";
import { tableSoldProduct } from "src/features/product/miniProductComponent/table/tableSold/reducer";
import { variantProductReducer } from "src/features/product/miniProductComponent/variants/reducer";
import { productReducer } from "src/features/product/product-reducer";
import { categorySellersTableReducer } from "src/features/CategoryTableSellers/reducer";
import { categoriesReducer } from "src/features/trendOil/reducer";
import { tableFavoriteGoodsReducer } from "src/pages/FavoriteGoods/reducer";
import { forgotPasswordReducer } from "src/pages/ForgotPassword/reducer";
import { IntegrationProductsReducer } from "src/pages/Integration-Products/reducer";
import { ProductResearchReducer } from "src/pages/Product-Research/reducer";
import { productSearchReducer } from "src/pages/ProductSearch/reducer";
import { loginReducer } from "src/slices/Auth/Login/reducer";
import { registerReducer } from "src/slices/Auth/Register/reducer";
import profileReducer from "./Auth/Profile/reducer";
import layoutReducer from "./Layout/reducer";
import thunkMiddleware from "redux-thunk";

export const rootReducer = combineReducers({
  categoryTable: categoryTableReducer,
  app: appReducer,
  login: loginReducer,
  register: registerReducer,
  forgotPassword: forgotPasswordReducer,
  categoryList: tableCategoryListReducer,
  category: categoriesReducer,
  categorySellersTable: categorySellersTableReducer,
  categoryBrandsTable: categoryBrandsTableReducer,
  seller: sellerProductReducer,
  merchantTable: merchantTableReducer,
  merchantTableCategory: merchantTableCategoryReducer,
  merchantTableBrands: merchantTableBrandsReducer,
  brandsTable: brandsTableReducer,
  brandsTableSellers: brandsSellersTableReducer,
  brandsTableCategory: brandsTableCategoryReducer,
  listSellersTable: listSellersTableReducer,
  listBrandTable: listBrandTableReducer,
  priceSegmentsTable: priceSegmentsTable,
  product: productReducer,
  productSearch: productSearchReducer,
  integrationProducts: IntegrationProductsReducer,
  productAdvert: productAdvertReducer,
  productResearch: ProductResearchReducer,
  subscribeButton: subscribeButtonReducer,
  photo: photoProductReducer,
  variant: variantProductReducer,
  graphStockSales: graphStockSalesProductReducer,
  tableCategory: tableCategoryPositionProduct,
  tableSearchPosition: tableSearchPositionProduct,
  tableSold: tableSoldProduct,
  favoriteGoods: tableFavoriteGoodsReducer,
  descriptionEdit: descriptionEditProductReducer,
  feedback: feedbackReducer,
  layout: layoutReducer,
  profile: profileReducer,
});

// непосредственно создаём store
//export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export let store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(thunkMiddleware),
});

// а это, чтобы можно было в консоли браузера обращаться к store в любой момент
// @ts-ignore
window.store = store;
