import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";

interface BreadcrumbProps {
  breadcrumbItem?: string;
  id?: any;
  categoryName?: any;
  title?: string;
}

export const Breadcrumbs = ({
  breadcrumbItem,
  id,
  categoryName,
  title,
}: BreadcrumbProps) => {
  return (
    <Row>
      <Col xs="12">
        <div
          className="
         d-flex align-items-center justify-content-between"
        >
          {/*<h4 className="mb-0">{breadcrumbItem}</h4>*/}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              >
                <Link to={CATEGORY_LIST_ROUTE}>{title}</Link>
              </BreadcrumbItem>
              {categoryName && (
                <BreadcrumbItem
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Link to={`/trendyol/category/${id}/products`}>
                    {categoryName}
                  </Link>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                active
              >
                {/*<Link to="#">{breadcrumbItem}</Link>*/}
                <span>{breadcrumbItem}</span>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};
