import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BreadcrumbItem, Col, Row } from "reactstrap";
import { ExcelButton } from "src/components/ FetchAndRedirectButtonExcel/ExcelButton";
import LimitedAccessComponent from "src/components/LimitedAccessComponent/LimitedAccessComponent";
import { MyDatePickerComponent } from "src/components/MyDatePickerComponent/MyDatePickerComponent";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { TooltipInTable } from "src/components/tooltipInTable";
import VideoModal from "src/components/VideoModal/VideoModal";
import { setUserStatus } from "src/features/App/reducer";
import { selectUserStatus } from "src/features/App/selectors";
import useSearchParams from "src/features/CategoryTable/hooks/useSearchParams";
import TableDescription from "src/features/ListBrandTable/table-description";
import s from "src/features/CategoryTable/Category.module.css";
import { CustomInput } from "src/features/CategoryTable/miniCategory/CustomInput";
import { SuperPagination } from "src/features/CategoryTable/miniCategory/CustomPagination";
import { InputsType } from "src/features/CategoryTable/type";
import {
  clickAscDescListBrandTable,
  clickOrderNameListBrandTable,
  onChangePhraseListBrandTable,
  onClickPageListBrandTable,
  resetListBrandTable,
  setRestartListBrandTable,
  setShowVideoBrandList,
  setStatusListBrandTable,
} from "src/features/ListBrandTable/reducer";
import {
  selectAscDescListBrandTable,
  selectColumnNameListBrandTable,
  selectDataListBrandTable,
  selectFilteredDataListBrandTable,
  selectOrderNameListBrandTable,
  selectPageListBrandTable,
  selectRestart,
  selectShowVideoListBrandTable,
  selectStatusListBrandTable,
  selectTotalCountPageListBrandTable,
} from "src/features/ListBrandTable/selectors";
import {
  fetchExcelListBrandTable,
  fetchListBrandTable,
} from "src/features/ListBrandTable/thunk";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { handlesMouseOver } from "src/utilitsFunction/handlesMouseOverAndOut";
import { findHeightListTable } from "src/utilitsFunction/height";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const ListBrandTable = () => {
  const { name } = useSearchParams();

  const [timerId, setTimerId] = useState<number | undefined>(undefined);

  document.title = "Restatos | List Brand";
  const { t } = useTranslation();
  const ref = useRef(window.innerHeight).current;
  const dispatch = useAppDispatch();
  const h = findHeightListTable(ref);
  const [count, setCount] = useState<number>(100);

  const [inputs, setInputs] = useState<InputsType[]>([
    { id: 0, label: "Name", name: "name", value: name ?? "" },
    // { id: 1, label: t("Brand"), name: "brand", value: "" },
    // { id: 2, label: "Barcode", name: "barcode", value: "" },
  ]);

  const [tooltipName, setTooltipName] = useState<any>(null);

  const data = useAppSelector(selectDataListBrandTable);
  const filtered = useAppSelector(selectFilteredDataListBrandTable);
  const orderName = useAppSelector(selectOrderNameListBrandTable);
  const page = useAppSelector(selectPageListBrandTable);
  const ascDesc = useAppSelector(selectAscDescListBrandTable);
  const totalCountPage = useAppSelector(selectTotalCountPageListBrandTable);
  const status = useAppSelector(selectStatusListBrandTable);
  const columnName = useAppSelector(selectColumnNameListBrandTable);
  const restart = useAppSelector(selectRestart);

  //
  // let filteredData = data.filter((el: any) =>
  //   el.name.toLowerCase().includes(filtered.toLowerCase()),
  // );

  const userStatus = useAppSelector(selectUserStatus);

  const param = {
    // category_url,
    columnName: inputs,
    orderName,
    ascDesc,
    page,
  };
  useEffect(() => {
    dispatch(fetchListBrandTable(param));
  }, [ascDesc, orderName, page, columnName]);

  useEffect(() => {
    dispatch(onClickPageListBrandTable({ page: 1 }));
  }, [ascDesc, orderName, columnName]);

  useEffect(() => {
    return () => {
      dispatch(resetListBrandTable({}));
    };
  }, []);
  const handleSort = useCallback((column, sortDirection) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(clickOrderNameListBrandTable({ orderName: column.selector }));
    dispatch(clickAscDescListBrandTable({ ascDesc: sortDirection }));
  }, []);
  const onChangePagination = (newPage: number, newCount: number) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    dispatch(onClickPageListBrandTable({ page: newPage }));
    setCount(newCount);
  };

  // const handleInputChange = (index: number, value: string) => {
  //   const updatedInputs = inputs.map((input, i) => ({
  //     ...input,
  //     value: i === index ? value : input.value,
  //   }));
  //   dispatch(setFilterListBrandTable({ value: value }));
  //   setInputs(updatedInputs);
  // };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(timerId);
      setTimerId(undefined);
      dispatch(onChangePhraseListBrandTable({ columnName: inputs }));
    }
  };
  const handleInputChange = (index: number, value: string) => {
    if (userStatus === "trial") {
      dispatch(setUserStatus({ userStatus: "unpaid" }));
      return;
    }
    if (value === "") {
      dispatch(
        setStatusListBrandTable({
          status: "listBrandTable-loading",
        }),
      );
      dispatch(onChangePhraseListBrandTable({ columnName: [] }));
    }
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      value: i === index ? value : input.value,
    }));
    setInputs(updatedInputs);
    timerId && clearTimeout(timerId);
    const params = new URLSearchParams();
    updatedInputs.forEach((input) => {
      if (input.value !== "") {
        params.append(input.name, input.value);
      }
    });

    let url = window.location.pathname;
    if (params.toString() !== "") {
      url += `?${params.toString()}`;
    }
    window.history.pushState({}, "", url);
    const id = +setTimeout(() => {
      // dispatch(onClickPageListBrandTable({ page: 1 }));
      dispatch(onChangePhraseListBrandTable({ columnName: updatedInputs }));
      setTimerId(undefined);
    }, 600000);
    setTimerId(id);
  };

  if (restart) {
    const hasNonEmptyValue = inputs.some((input: any) => input.value !== "");

    if (hasNonEmptyValue) {
      const newInputs = inputs.map((input: any) => ({ ...input, value: "" }));

      const params = new URLSearchParams();
      newInputs.forEach((input: any) => {
        if (input.value === "") {
          params.append(input.name, input.value);
        }
      });
      setInputs(newInputs);

      const newUrl = window.location.origin + window.location.pathname;

      window.history.pushState({}, document.title, newUrl);
      dispatch(onChangePhraseListBrandTable({ columnName: [] }));
    }
    dispatch(setRestartListBrandTable({ restart: false }));
  }

  const columns = [
    {
      name: t("name"),
      selector: "name",
      sortable: true,
      cell: (row: { id: string; name: string }) => (
        <>
          <NavLink
            onMouseOver={(event: any) => {
              handlesMouseOver(event, row.name, setTooltipName);
            }}
            onMouseOut={() => setTooltipName(null)}
            // target="_blank"
            to={`/trendyol/brand/${row.id}/products`}
          >
            {row.name.length > 22 ? row.name.substr(0, 22) + "..." : row.name}
          </NavLink>
          <Tooltip />
        </>
      ),
    },
    {
      name: t("sold"),
      selector: "sold",
      sortable: true,
      format: (row: { sold: any }) =>
        row.sold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("revenue"),
      selector: "revenue",
      sortable: true,
      id: "revenue",
      format: (row: { revenue: number }) =>
        (row.revenue / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    // {
    //   name: t("stock"),
    //   selector: "stock",
    //   sortable: true,
    //   format: (row: { stock: any }) =>
    //     row.stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    // },
    {
      name: t("prodCount"),
      selector: "prodCount",
      sortable: true,
      format: (row: { prodCount: any }) =>
        row.prodCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("prodCountWithSold"),
      selector: "prodCountWithSold",
      sortable: true,
      format: (row: { prodCountWithSold: any }) =>
        row.prodCountWithSold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareProdWithSold"),
      selector: "shareProdWithSold",
      sortable: true,
      format: (row: { shareProdWithSold: any }) =>
        row.shareProdWithSold
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("prodCountWithStock"),
      // selector: (row) => row.position,
      selector: "prodCountWithStock",
      format: (row: { prodCountWithStock: any }) =>
        row.prodCountWithStock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      sortable: true,
    },
    //ВАДИМ ПОПРОСИЛ СКРЫТЬ товаров с остатками и доля торова с остатками

    // {
    //   name: t("shareProdWithStock"),
    //   selector: "shareProdWithStock",
    //   sortable: true,
    //   format: (row: { shareProdWithStock: any }) =>
    //     row.shareProdWithStock
    //       .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    // },
    {
      name: t("merchantCount"),
      selector: "merchantCount",
      sortable: true,
      format: (row: { merchantCount: any }) =>
        row.merchantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("merchantCountWithSold"),
      selector: "merchantCountWithSold",
      sortable: true,
      format: (row: { merchantCountWithSold: any }) =>
        row.merchantCountWithSold
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      name: t("shareMerchantWithSold"),
      selector: "shareMerchantWithSold",
      sortable: true,
      format: (row: { shareMerchantWithSold: any }) =>
        row.shareMerchantWithSold
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    },
    {
      name: t("merchantCountWithStock"),
      selector: "merchantCountWithStock",
      sortable: true,
      format: (row: { merchantCountWithStock: any }) =>
        row.merchantCountWithStock
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    // {
    //   name: t("shareMerchantWithStock"),
    //   selector: "shareMerchantWithStock",
    //   sortable: true,
    //   format: (row: { shareMerchantWithStock: any }) =>
    //     row.shareMerchantWithStock
    //       .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "%",
    // },
    {
      name: t("minPriceWithSold"),
      selector: "minPriceWithSold",
      format: (row: { minPriceWithSold: any }) =>
        (row.minPriceWithSold / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("maxPriceWithSold"),
      selector: "maxPriceWithSold",
      format: (row: { maxPriceWithSold: any }) =>
        (row.maxPriceWithSold / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("avgPriceWithSold"),
      // selector: (row) => row.price,
      selector: "avgPriceWithSold",
      format: (row: { avgPriceWithSold: any }) =>
        (row.avgPriceWithSold / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("minPriceWithStock"),
      // selector: (row) => row.price,
      selector: "minPriceWithStock",
      format: (row: { minPriceWithStock: any }) =>
        (row.minPriceWithStock / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("maxPriceWithStock"),
      // selector: (row) => row.price,
      selector: "maxPriceWithStock",
      format: (row: { maxPriceWithStock: any }) =>
        (row.maxPriceWithStock / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("avgPriceWithStock"),
      // selector: (row) => row.price,
      selector: "avgPriceWithStock",
      format: (row: { avgPriceWithStock: any }) =>
        (row.avgPriceWithStock / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
    {
      name: t("minPrice"),
      selector: "minPrice",
      format: (row: { minPrice: any }) =>
        (row.minPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("maxPrice"),
      selector: "maxPrice",
      format: (row: { maxPrice: any }) =>
        (row.maxPrice / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        " TL",
    },
    {
      name: t("avgPrice"),
      // selector: (row) => row.price,
      selector: "avgPrice",
      format: (row: { avgPrice: any }) =>
        (row.avgPrice / 100)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " TL",
    },
  ] as any;

  const [modal_standard, setmodal_standard] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const conditionalRowStyles: any = useMemo(
    () => [
      {
        when: (row: any, index: any) => {
          return row.index >= 3;
        },
        style: {
          filter: "blur(10px)",
          userSelect: "none",
        },
      },
    ],
    [],
  );

  return (
    <>
      <TableDescription
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
      />
      {status === "listBrandTable-loading" && (
        <CustomSpinner absolute={true} top={"300px"} />
      )}
      <div
        className={status === "listBrandTable-loading" ? s.opasity : ""}
        style={{ height: "100%" }}
      >
        <Row>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "15px",
                    }}
                    active
                  >
                    <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    active
                  >
                    <Link to="/trendyol/brand-list">{t("brandList")}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>

        <div
          style={{ height: "100%", background: "white", paddingLeft: "10px" }}
          className="d-flex flex-wrap gap-2 align-items-center"
        >
          <div style={{ pointerEvents: "none", opacity: 0.6 }}>
            <MyDatePickerComponent />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <ExcelButton
              action={() => dispatch(fetchExcelListBrandTable(param))}
            />
            <i
              onClick={() => setmodal_standard(true)}
              className="uil-question-circle "
              style={{ fontSize: "24px", cursor: "pointer" }}
            ></i>
            {inputs.map((input, index) => (
              // TODO
              <CustomInput
                id={input.id}
                disabled={status === "listBrandTable-loading"}
                key={index}
                value={input.value}
                label={input.label}
                onChange={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleInputChange(index, e.currentTarget.value)
                }
                onKeyPress={onKeyPress}
              />
            ))}
          </div>
        </div>
        <VideoModal
          turkishURL={"https://www.youtube.com/embed/xiToZCsxiLQ"}
          englishURL={"https://www.youtube.com/embed/t4z0Wk8APpY"}
          actionStore={setShowVideoBrandList}
          selectFunc={selectShowVideoListBrandTable}
        />
        <DataTable
          conditionalRowStyles={
            userStatus === "trial" || userStatus === "unpaid"
              ? conditionalRowStyles
              : ""
          }
          className={`customTableYaroslav tableList ${
            userStatus === "trial" || userStatus === "unpaid" ? "no-scroll" : ""
          }`}
          // className={"customTableYaroslav"}
          defaultSortFieldId={"revenue"}
          title={t("")}
          columns={columns}
          data={data}
          subHeader
          fixedHeaderScrollHeight={h}
          fixedHeader
          // sortIcon={null}
          responsive={true}
          // sortServer={false}
          sortServer={true}
          defaultSortAsc={false}
          onSort={handleSort}
          // onChangePage={handlePageChange}
          // sortIcon
          // defaultSortAsc={true}
          noDataComponent={<div>{""}</div>}
        />
        {userStatus === "trial" || userStatus === "unpaid" ? (
          <div className={s.blurLists}></div>
        ) : null}
        <TooltipInTable tooltipName={tooltipName} />

        <SuperPagination
          page={page}
          itemsCountForPage={count}
          totalCount={totalCountPage}
          onChange={onChangePagination}
        />
      </div>
      {userStatus === "trial" || userStatus === "unpaid" ? (
        <LimitedAccessComponent />
      ) : null}
    </>
  );
};
