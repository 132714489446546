import React from "react";

export function ClearButton({ inputs, setInputs, setIsCleared }: any) {
  const clearInputValues = () => {
    const hasNonEmptyValue = inputs.some((input: any) => input.value !== "");

    if (hasNonEmptyValue) {
      const newInputs = inputs.map((input: any) => ({ ...input, value: "" }));
      const params = new URLSearchParams();
      newInputs.forEach((input: any) => {
        if (input.value === "") {
          params.append(input.name, input.value);
        }
      });
      setInputs(newInputs);

      const newUrl = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, newUrl);
      setIsCleared();
    }
  };

  return (
    <button
      type="button"
      className="btn-danger btn-sm btn"
      onClick={clearInputValues}
      style={{
        marginRight: "3px",
        width: "30px",
        height: "30px",
        lineHeight: "0",
        padding: "0",
        textAlign: "center",
        borderRadius: "5px",
      }}
    >
      X
    </button>
  );
}
