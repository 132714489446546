import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { VariantsResponseType } from "src/features/product/miniProductComponent/variants/type";

export const slice = createSlice({
  name: "variantProduct",
  initialState: {
    variants: [] as VariantsResponseType[],
    statusVariant: "idle" as RequestStatusType,
  },
  reducers: {
    setVariants(
      state,
      action: PayloadAction<{ variants: Array<VariantsResponseType> }>,
    ) {
      state.variants = [...action.payload.variants];
    },
    setStatusVariant(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusVariant = action.payload.status;
    },
    clearProductData(state, action) {
      return {
        ...state,
        variants: [] as VariantsResponseType[],
      };
    },
  },
});

export const variantProductReducer = slice.reducer;
export const { setVariants, setStatusVariant, clearProductData } =
  slice.actions;
