import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryTableAPI } from "src/api/api_categoryTable/api_categoryTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setDataBrandAndSellers,
  setDataPriceAvg,
  setDataProducts,
  setDataRevenue,
  setSelectBrandAndSellers,
  setStatusBrandAndSellers,
  setStatusCategoryTable,
  setStatusPriceAvg,
  setStatusProducts,
  setTableCategory,
  setTotalCountPage,
} from "src/features/CategoryTable/reducer";
import { setStatusDataRevenue } from "src/features/CategoryTable/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchDataCategoryTable = createAsyncThunk<any, any>(
  "categoryTable/fetchDataCategoryTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCategoryTable({ statusCategoryTable: "categoryTable-loading" }),
    );

    try {
      const { res, resInfo } = await categoryTableAPI.fetchCategoryTable(
        param.category_url,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setTableCategory({ tableCategory: res.data.data }));
        // thunkAPI.dispatch(setSubscribe({ table: res.data.data }));

        thunkAPI.dispatch(
          setTotalCountPage({ totalCountPage: resInfo.data.recordsFiltered }),
        );
        // thunkAPI.dispatch(setToggleSubscription({ toggle: null }));

        thunkAPI.dispatch(
          setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      thunkAPI.dispatch(
        setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
      );
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelDataCategoryTable = createAsyncThunk<any, any>(
  "excelDataCategoryTable/fetchExcelDataCategoryTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusCategoryTable({ statusCategoryTable: "categoryTable-loading" }),
    );
    try {
      const res = await categoryTableAPI.fetchExcelCategoryTable(
        param.category_url,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        // if (res.data.url) {
        //   console.log(res, "res.data.url");
        // }
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      thunkAPI.dispatch(
        setStatusCategoryTable({ statusCategoryTable: "succeeded" }),
      );
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

//

export const fetchDataPriceAvg = createAsyncThunk<any, any>(
  "dataPriceAvg/fetchDataPriceAvg",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusPriceAvg({
        status: "loading",
      }),
    );
    try {
      const res = await categoryTableAPI.fetchDataPriceAvg(
        param.category_url,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataPriceAvg({ data: res.data.message }));
        thunkAPI.dispatch(setStatusPriceAvg({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusPriceAvg({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Селекта - Brand
export const fetchDataBrands = createAsyncThunk<any, any>(
  "dataBrands/fetchDataBrands",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusBrandAndSellers({
        status: "loading",
      }),
    );
    try {
      const res = await categoryTableAPI.fetchDataBrands(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectBrandAndSellers({ select: "Brand" }));
        thunkAPI.dispatch(setDataBrandAndSellers({ data: res.data.message }));
        thunkAPI.dispatch(setStatusBrandAndSellers({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusBrandAndSellers({ status: "succeeded" }));
        thunkAPI.dispatch(setSelectBrandAndSellers({ select: "Sellers" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

// Получаем данные для Селекта -    Продавцы
export const fetchDataSellers = createAsyncThunk<any, any>(
  "dataSellers/fetchDataSellers",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusBrandAndSellers({
        status: "loading",
      }),
    );
    try {
      const res = await categoryTableAPI.fetchDataSellers(id);
      if (res.data.success === true) {
        thunkAPI.dispatch(setSelectBrandAndSellers({ select: "Sellers" }));

        thunkAPI.dispatch(setDataBrandAndSellers({ data: res.data.message }));
        thunkAPI.dispatch(setStatusBrandAndSellers({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusBrandAndSellers({ status: "succeeded" }));
        thunkAPI.dispatch(setSelectBrandAndSellers({ select: "Brand" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchDataRevenue = createAsyncThunk<any, any>(
  "dataRevenue/fetchDataRevenue",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusDataRevenue({
        status: "loading",
      }),
    );
    try {
      const res = await categoryTableAPI.fetchDataRevenue(
        param.category_url,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataRevenue({ data: res.data }));
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusDataRevenue({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchDataProducts = createAsyncThunk<any, any>(
  "dataProducts/fetchDataProducts",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusProducts({
        status: "loading",
      }),
    );
    try {
      const res = await categoryTableAPI.fetchDataProducts(
        param.category_url,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataProducts({ data: res.data.message }));
        thunkAPI.dispatch(setStatusProducts({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusProducts({ status: "succeeded" }));

        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
        // return handleAsyncServerAppError(res.data, thunkAPI);
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
