import { AppRootStateType } from "src/store/types";

export const selectColumnNameSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.columnName;
export const selectNameCategorySellersTable = (state: AppRootStateType) =>
  state.app.categoryName;
export const selectDataSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.data;

export const selectOrderNameSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.orderName;
export const selectAscDescSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.ascDesc;
export const selectPageSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.page;
export const selectTotalCountPageSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.totalCountPage;
export const selectStatusSellersTable = (state: AppRootStateType) =>
  state.categorySellersTable.status;
