import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";

export const slice = createSlice({
  name: "brandsTableCategory",
  initialState: {
    table: [] as any,
    // filteredTableCategory: [] as any,
    filteredBrandsTableCategory: "" as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    status: "idle" as RequestStatusType,
  },
  reducers: {
    setBrandsTableCategory(
      state,
      action: PayloadAction<{ table: Array<any> }>,
    ) {
      state.table = [...action.payload.table];
    },
    setFilterBrandsTableCategory(
      state,
      action: PayloadAction<{ value: string }>,
    ) {
      state.filteredBrandsTableCategory = action.payload.value;
    },

    onClickPageBrandsTableCategory(
      state,
      action: PayloadAction<{ page: number }>,
    ) {
      state.page = action.payload.page;
    },
    setTotalCountPageBrandsTableCategory(
      state,
      action: PayloadAction<{
        totalCountPage: number | null;
      }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameBrandsTableCategory(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescBrandsTableCategory(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setStatusBrandsTableCategory(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    resetBrandsTableCategory(state, action) {
      return {
        ...state,
        table: [] as any,
        filteredBrandsTableCategory: "" as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
      };
    },
  },
});
export const brandsTableCategoryReducer = slice.reducer;
export const {
  setBrandsTableCategory,
  setFilterBrandsTableCategory,
  onClickPageBrandsTableCategory,
  setTotalCountPageBrandsTableCategory,
  clickOrderNameBrandsTableCategory,
  clickAscDescBrandsTableCategory,
  setStatusBrandsTableCategory,
  resetBrandsTableCategory,
} = slice.actions;
