import { createAsyncThunk } from "@reduxjs/toolkit";
import { productAPI } from "src/api/api_helper";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setStatusTableCategoryPosition,
  setTableCategoryPosition,
} from "src/features/product/miniProductComponent/table/tableCategoryPosition/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchCategoryPositionTableProduct = createAsyncThunk<
  any,
  { id: string; dateOne: string; dateTwo: string }
>(
  "categoryPositionTableProduct/fetchCategoryPositionTableProduct",
  async ({ id, dateOne, dateTwo }, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableCategoryPosition({
        status: "tableCategoryPosition-loading",
      }),
    );
    try {
      const res = await productAPI.fetchCategoryPositionTableProduct(
        id,
        dateOne,
        dateTwo,
      );

      if (res.data.success === true) {
        thunkAPI.dispatch(setTableCategoryPosition({ table: res.data.data }));

        thunkAPI.dispatch(
          setStatusTableCategoryPosition({ status: "succeeded" }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusTableCategoryPosition({ status: "succeeded" }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
