import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { TableCategoryPositionType } from "src/features/product/miniProductComponent/table/tableCategoryPosition/type";

export const slice = createSlice({
  name: "tableFavoriteGoods",
  initialState: {
    table: [] as Array<any>,
    filteredTable: "" as any,

    status: "idle" as RequestStatusType,
  },
  reducers: {
    setTableFavoriteGoods(
      state,
      action: PayloadAction<{
        table: Array<any>;
      }>,
    ) {
      state.table = [...action.payload.table];
    },
    setFilterFavoriteGoods(state, action: PayloadAction<{ value: string }>) {
      state.filteredTable = action.payload.value;
    },
    setStatusTableFavoriteGoods(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },
    clearFavoriteGoodsTableData(state, action) {
      return {
        ...state,
        table: [] as Array<any>,
      };
    },
  },
});
export const tableFavoriteGoodsReducer = slice.reducer;
export const {
  setFilterFavoriteGoods,
  setTableFavoriteGoods,
  setStatusTableFavoriteGoods,
  clearFavoriteGoodsTableData,
} = slice.actions;
