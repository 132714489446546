import { AppRootStateType } from "src/store/types";

export const selectDataBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.table;

export const selectColumnNameBrandsTableSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.columnName;

export const selectOrderNameBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.orderName;
export const selectAscDescBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.ascDesc;
export const selectPageBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.page;
export const selectTotalCountPageBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.totalCountPage;
export const selectStatusTableBrandsSellers = (state: AppRootStateType) =>
  state.brandsTableSellers.status;
