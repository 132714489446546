import React from "react";
import { Spinner } from "reactstrap";
type PropsType = {
  absolute?: boolean;
  top?: string | undefined;
};
export const CustomSpinner = ({ absolute, top }: PropsType) => {
  return (
    <div>
      {absolute ? (
        <div
          style={{
            position: "absolute",
            top: top,
            textAlign: "center",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "30%",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Spinner color="info">Loading...</Spinner>
        </div>
      )}
    </div>
  );
};
