// export const cookiesFind = (localStorageToken: string | null) => {
//   if (localStorageToken) return null;
//   let cookies = document.cookie
//     .split(";")
//     .map((cookie) => cookie.trim().split("="));
//
//   for (const cookie of cookies) {
//     const [cookieName, cookieValue] = cookie;
//     if (cookieName === "token" && cookieValue !== null) {
//       // if (localStorageToken === null) {
//       localStorage.setItem("token", cookieValue);
//       return localStorage.getItem("token");
//       // }
//     }
//   }
// };
export const cookiesFind = (localStorageToken: string | null, name: string) => {
  // Если токен уже есть в localStorage, возвращаем его
  if (localStorageToken) {
    return localStorageToken;
  }

  let cookies = document.cookie
    .split(";")
    .map((cookie) => cookie.trim().split("="));

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie;
    if (
      cookieName === name && // Поменял 08.04.24
      // "token"

      cookieValue !== null
    ) {
      // Если нашли токен в cookies, сохраняем его в localStorage и возвращаем
      localStorage.setItem(
        // "token" // Поменял 08.04.24
        name,
        cookieValue,
      );
      return cookieValue;
    }
  }

  // Если токен не найден ни в localStorage, ни в cookies, возвращаем null
  return null;
};
