import { createAsyncThunk } from "@reduxjs/toolkit";
import { brandsTableAPI } from "src/api/api_brandsTable/api_brandsTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setBrandsTableCategory,
  setStatusBrandsTableCategory,
  setTotalCountPageBrandsTableCategory,
} from "src/features/BrandsTableCategory/reducer";

import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchBrandsTableCategory = createAsyncThunk<any, any>(
  "brandsTableCategory/fetchBrandsTableCategory",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusBrandsTableCategory({
        status: "brandsTableCategory-loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchBrandsTableCategory(
        param.brandId,
        param.startDate,
        param.endDate,
        // param.page,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setBrandsTableCategory({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusBrandsTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setTotalCountPageBrandsTableCategory({
            totalCountPage: res.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusBrandsTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      // dispatch(setStatusSeller({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelBrandsTableCategory = createAsyncThunk<any, any>(
  "excelBrandsTableCategory/fetchExcelBrandsTableCategory",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusBrandsTableCategory({
        status: "brandsTableCategory-loading",
      }),
    );
    try {
      const res = await brandsTableAPI.fetchExcelBrandsTableCategory(
        param.brandId,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusBrandsTableCategory({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusBrandsTableCategory({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
