import { useEffect, useState } from "react";

// import { useLocation } from "react-router-dom";

function useSearchParams() {
  const searchParams = new URLSearchParams(window.location.search);

  const [price_from, setPriceFrom] = useState<string | null>(
    searchParams.get("price_from") ?? null,
  );
  const [price_to, setPriceTo] = useState<string | null>(
    searchParams.get("price_to") ?? null,
  );
  const [name, setName] = useState<string | null>(
    searchParams.get("name") ?? null,
  );
  const [brand, setBrand] = useState<string | null>(
    searchParams.get("brand") ?? null,
  );
  const [barcode, setBarcode] = useState<string | null>(
    searchParams.get("barcode") ?? null,
  );

  const [share_goods_with_sold_from, setShareGoodsWithSoldFrom] = useState<
    string | null
  >(searchParams.get("share_goods_with_sold_from") ?? null);
  const [share_goods_with_sold_to, setShareGoodsWithSoldTo] = useState<
    string | null
  >(searchParams.get("share_goods_with_sold_to") ?? null);
  const [share_merch_with_sold_from, setShareMerchWithSoldFrom] = useState<
    string | null
  >(searchParams.get("share_merch_with_sold_from") ?? null);
  const [share_merch_with_sold_to, setShareMerchWithSoldTo] = useState<
    string | null
  >(searchParams.get("share_merch_with_sold_to") ?? null);
  const [minPrice, setMinPrice] = useState<string | null>(
    searchParams.get("minPrice") ?? null,
  );
  const [maxPrice, setMaxPrice] = useState<string | null>(
    searchParams.get("maxPrice") ?? null,
  );
  const [merchCountFrom, setMerchCountFrom] = useState<string | null>(
    searchParams.get("merchCountFrom") ?? null,
  );
  const [merchCountTo, setMerchCountTo] = useState<string | null>(
    searchParams.get("merchCountTo") ?? null,
  );
  const [soldFrom, setSoldFrom] = useState<string | null>(
    searchParams.get("soldFrom") ?? null,
  );
  const [soldTo, setSoldTo] = useState<string | null>(
    searchParams.get("soldTo") ?? null,
  );
  const [revenueFrom, setRevenueFrom] = useState<string | null>(
    searchParams.get("revenueFrom") ?? null,
  );
  const [revenueTo, setRevenueTo] = useState<string | null>(
    searchParams.get("revenueTo") ?? null,
  );
  const [ratingFrom, setRatingFrom] = useState<string | null>(
    searchParams.get("ratingFrom") ?? null,
  );
  const [ratingTo, setRatingTo] = useState<string | null>(
    searchParams.get("ratingTo") ?? null,
  );
  const [favoritesFrom, setFavoritesFrom] = useState<string | null>(
    searchParams.get("favoritesFrom") ?? null,
  );
  const [favoritesTo, setFavoritesTo] = useState<string | null>(
    searchParams.get("favoritesTo") ?? null,
  );
  const [minRatingCount, setMinRatingCount] = useState<string | null>(
    searchParams.get("minRatingCount") ?? null,
  );
  const [maxRatingCount, setMaxRatingCount] = useState<string | null>(
    searchParams.get("maxRatingCount") ?? null,
  );
  const [shareMerchantsFrom, setShareMerchantsFrom] = useState<string | null>(
    searchParams.get("shareMerchantsFrom") ?? null,
  );
  const [shareMerchantsTo, setShareMerchantsTo] = useState<string | null>(
    searchParams.get("shareMerchantsTo") ?? null,
  );
  const [cats, setCats] = useState<string | null>(
    searchParams.get("cats") ?? null,
  );
  const [urlParamNameCategory, setUrlParamNameCategory] = useState<
    string | null
  >(searchParams.get("urlParamNameCategory") ?? null);

  ///
  const [taxFrom, setTaxFrom] = useState<string | null>(
    searchParams.get("taxFrom") ?? null,
  );
  const [taxTo, setTaxTo] = useState<string | null>(
    searchParams.get("taxTo") ?? null,
  );
  ///
  useEffect(() => {
    setPriceFrom(searchParams.get("price_from") ?? "");
    setPriceTo(searchParams.get("price_to") ?? "");
    setName(searchParams.get("name") ?? "");
    setBrand(searchParams.get("brand") ?? "");
    setBarcode(searchParams.get("barcode") ?? "");

    setShareGoodsWithSoldFrom(
      searchParams.get("share_goods_with_sold_from") ?? "",
    );
    setShareGoodsWithSoldTo(searchParams.get("share_goods_with_sold_to") ?? "");
    setShareMerchWithSoldFrom(
      searchParams.get("share_merch_with_sold_from") ?? "",
    );
    setShareMerchWithSoldTo(searchParams.get("share_merch_with_sold_to") ?? "");

    setShareMerchWithSoldTo(searchParams.get("minPrice") ?? "");
    setShareMerchWithSoldTo(searchParams.get("maxPrice") ?? "");
    setShareMerchWithSoldTo(searchParams.get("merchCountFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("merchCountTo") ?? "");
    setShareMerchWithSoldTo(searchParams.get("soldFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("soldTo") ?? "");
    setShareMerchWithSoldTo(searchParams.get("revenueFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("revenueTo") ?? "");
    setShareMerchWithSoldTo(searchParams.get("ratingFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("ratingTo") ?? "");
    setShareMerchWithSoldTo(searchParams.get("favoritesFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("favoritesTo") ?? "");
    setShareMerchWithSoldTo(searchParams.get("minRatingCount") ?? "");
    setShareMerchWithSoldTo(searchParams.get("maxRatingCount") ?? "");
    setShareMerchWithSoldTo(searchParams.get("shareMerchantsFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("shareMerchantsTo") ?? "");
    //
    setShareMerchWithSoldTo(searchParams.get("taxFrom") ?? "");
    setShareMerchWithSoldTo(searchParams.get("taxTo") ?? "");
    //
    setShareMerchWithSoldTo(searchParams.get("cats") ?? "");
    setShareMerchWithSoldTo(searchParams.get("urlParamNameCategory") ?? "");
    //
  }, [searchParams]);

  return {
    price_from,
    price_to,
    name,
    brand,
    barcode,
    share_goods_with_sold_from,
    share_goods_with_sold_to,
    share_merch_with_sold_from,
    share_merch_with_sold_to,
    //
    minPrice,
    maxPrice,
    merchCountFrom,
    merchCountTo,
    soldFrom,
    soldTo,
    revenueFrom,
    revenueTo,
    ratingFrom,
    ratingTo,
    favoritesFrom,
    favoritesTo,
    minRatingCount,
    maxRatingCount,
    shareMerchantsFrom,
    shareMerchantsTo,
    taxFrom,
    taxTo,
    cats,
    urlParamNameCategory,
    //
  };
}

export default useSearchParams;
