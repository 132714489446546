import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAPI, tokenAPI } from "src/api/api_helper";
import {
  changeEmailAC,
  changeNameAC,
  changePhoneAC,
  changeTexAC,
  fetchTokenAC,
  setAppErrorAC,
  setAppInitializedAC,
  setAppStatusAC,
  setPaidToAC,
  setTariffNameAC,
  setUserStatus,
  setUserVariables,
} from "src/features/App/reducer";
import { setIsLoggedInAc } from "src/slices/Auth/Login/reducer";

export const initializeAppTC = createAsyncThunk<any, void>(
  "app/initializeAppTC",
  async (_, thunkAPI) => {
    try {
      const res = await authAPI.me();
      if (res.data.success === true) {
        thunkAPI.dispatch(
          fetchTokenAC({ tokenGoogle: res.data.message.token }),
        );
        thunkAPI.dispatch(changeNameAC({ name: res.data.message.name }));
        // 08.04.24
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 5);
        const expiresUTCString = expirationDate.toUTCString();
        if (res.data.message.cookies) {
          let cookObjKeys = Object.keys(res.data.message.cookies);
          for (let key of cookObjKeys) {
            let value =
              res.data.message.cookies[
                <keyof typeof res.data.message.cookies>key
              ];

            if (value != "undefined" && value) {
              document.cookie = `${key}=${value}; expires=${expiresUTCString}; domain=app.restatos.com; path=/;`;
              localStorage.setItem(key, value);
              // 13.04.24 Vadim
            }
          }
        }

        thunkAPI.dispatch(changeTexAC({ tax: res.data.message.tex }));
        thunkAPI.dispatch(changePhoneAC({ phone: res.data.message.phone }));
        thunkAPI.dispatch(changeEmailAC({ email: res.data.message.email }));
        // paid_to и tariff_name
        thunkAPI.dispatch(setPaidToAC({ paidTo: res.data.message.paid_to }));
        thunkAPI.dispatch(
          setTariffNameAC({ tariffName: res.data.message.tariff_name }),
        );

        thunkAPI.dispatch(
          setUserStatus({ userStatus: res.data.message.status }), //Убираю оповещение Access denided 13.03.24
        );

        thunkAPI.dispatch(
          setUserVariables({ variables: res.data.message.variables }),
        );
        thunkAPI.dispatch(setIsLoggedInAc({ value: true }));
        thunkAPI.dispatch(setAppInitializedAC({ isInitialized: true }));
        thunkAPI.dispatch(setAppStatusAC({ status: "succeeded" }));
      } else {
        localStorage.removeItem("token");
        document.cookie = `token=1; expires=Fri, 31 Dec 2000 23:59:59 GMT; domain=.restatos.com; path=/;`;
        thunkAPI.dispatch(setIsLoggedInAc({ value: false }));
        thunkAPI.dispatch(setAppInitializedAC({ isInitialized: true }));
        // thunkAPI.dispatch(setAppErrorAC({ error: "Initialized Error" }));
      }
    } catch (error) {
      // alert("catch");

      // localStorage.removeItem("token"); Сделали удаление токена закомееннтированным в связи с тем чтобы не вылетало из приложения
      // thunkAPI.dispatch(setIsLoggedInAc({ value: false })); закомееннтировал в связи с тем чтобы не вылетало из приложения 17.03.24
      thunkAPI.dispatch(setAppInitializedAC({ isInitialized: true }));
      // return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchTokenTC = createAsyncThunk<any, void>(
  "app/fetchTokenTC",
  async (_, thunkAPI) => {
    try {
      const res = await tokenAPI.fetchToken();
      if (res.data.success === true) {
        thunkAPI.dispatch(
          fetchTokenAC({ tokenGoogle: res.data.message.token }),
        );
      } else {
        thunkAPI.dispatch(setAppErrorAC({ error: "Token Error" }));
      }
    } catch (error) {
      // return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
