import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  BreadcrumbItem,
  Card,
  CardText,
  Col,
  FormFeedback,
  Input,
  Spinner,
} from "reactstrap";
import s from "src/features/CategoryTable/Category.module.css";
import { GetProductSearchDescription } from "src/pages/ProductSearch/getProductSearchDescription";
import { resetSearchProduct } from "src/pages/ProductSearch/reducer";
import {
  selectProductId,
  selectStatusProductSearch,
} from "src/pages/ProductSearch/selectors";
import { fetchSearchProduct } from "src/pages/ProductSearch/thunk";
import { CATEGORY_LIST_ROUTE } from "src/routes/routeVariables";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { productSearchSchema } from "src/utilitsFunction/schemas";

export const ProductSearch = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status = useAppSelector(selectStatusProductSearch);
  const productId = useAppSelector(selectProductId);
  const validation = useFormik({
    validationSchema: productSearchSchema,
    initialValues: {
      productId: "",
    },
    onSubmit: (values: { productId: string }) => {
      const match = values.productId.match(/-p-(\d*)/);
      if (match) {
        const extractedValue = match[1];
        dispatch(fetchSearchProduct(extractedValue));
      }
    },
  });
  if (productId) {
    navigate(`/trendyol/product/${productId}`);
  }
  useEffect(() => {
    return () => {
      dispatch(resetSearchProduct({}));
    };
  }, []);

  return (
    <>
      {" "}
      {status === "searchProduct-loading" && (
        <Spinner
          color="info"
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
          }}
        />
      )}
      <div
        className={status === "searchProduct-loading" ? s.opasity : ""}
        style={{ height: "100%" }}
      >
        <form onSubmit={validation.handleSubmit}>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
                active
              >
                <Link to={CATEGORY_LIST_ROUTE}>Trendyol</Link>
              </BreadcrumbItem>{" "}
              <BreadcrumbItem
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  // marginLeft: "15px",
                }}
                active
              >
                <Link
                  // target="_blank"
                  to="#"
                >
                  {t("ProductSearch")}
                </Link>
              </BreadcrumbItem>
            </ol>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Col md={6}>
              <Card className="card-body">
                <h3 className="card-title mb-1">{t("searchProduct")}</h3>
                <CardText>
                  <GetProductSearchDescription />
                </CardText>
                <Input
                  className="form-control"
                  id="gen-info-designation-input"
                  placeholder="SKU"
                  {...validation.getFieldProps("productId")}
                  invalid={
                    // formikInformation.touched.phone &&
                    validation.errors.productId ? true : false
                  }
                />
                {
                  // formikInformation.touched.phone &&
                  validation.errors.productId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.productId}
                    </FormFeedback>
                  ) : null
                }
                <button
                  className="btn btn-info "
                  style={{ marginTop: "10px" }}
                  type="submit"
                >
                  {t("Search")}
                </button>
              </Card>
            </Col>
          </div>
        </form>
      </div>
    </>
  );
};
