import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

export const D3BarChart = ({ data }) => {
  const [tooltipData, setTooltipData] = useState(null);

  const ref = useRef(window.innerWidth).current;
  const padding =
    ref > 4200
      ? 0.8
      : ref > 3700
      ? 0.7
      : ref > 3200
      ? 0.6
      : ref > 2700
      ? 0.5
      : ref > 2300
      ? 0.4
      : ref > 1024
      ? 0.3
      : 0.2;

  // const svgRef = useRef();
  const svgRef = useRef(null);

  const chartHeight = 20;
  const xScale = d3
    .scaleBand()
    .domain(data.map((_, i) => i))
    .range([0, 150])
    .padding(0.1)
    .padding(padding)
    .paddingOuter(0.3);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data)])
    .nice()
    .range([chartHeight, 0]);

  // useEffect(() => {
  //   if (!data || !svgRef.current) return;
  //
  //   const svg = d3.select(svgRef.current);
  //
  //   // Очищаем предыдущие элементы с классом ".bar"
  //   const existingElements = svg.selectAll(".bar");
  //   if (!existingElements.empty()) {
  //     existingElements.remove();
  //   }
  //   // Очистка предыдущего содержимого SVG
  //   svg.selectAll("*").remove();
  //
  //   const chartWidth = 300;
  //   const chartHeight = 200;
  //
  //   const xScale = d3
  //     .scaleBand()
  //     .domain(data.map((_, i) => i))
  //     .range([0, chartWidth])
  //     .padding(0.1);
  //
  //   const yScale = d3
  //     .scaleLinear()
  //     .domain([0, d3.max(data)])
  //     .nice()
  //     .range([chartHeight, 0]);
  //
  //   svg
  //     .selectAll(".bar")
  //     .data(data)
  //     .enter()
  //     .append("rect")
  //     .attr("class", "bar")
  //     .attr("x", (_, i) => xScale(i))
  //     .attr("y", (d) => yScale(d))
  //     .attr("width", xScale.bandwidth())
  //     .attr("height", (d) => chartHeight - yScale(d))
  //     .on("mouseover", (event, d) => {
  //       setTooltipData({ value: d });
  //     })
  //     .on("mouseout", () => {
  //       setTooltipData(null);
  //     });
  // }, [data]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const chartGroup = svg
      .append("g")
      .attr("transform", `translate(20, ${chartHeight - 0})`);

    if (data.every((value) => value === 0)) {
      chartGroup
        .append("line")
        .attr("x1", 10)
        .attr("x2", 140)
        .attr("y1", 5) // Сделайте линию чуть выше
        .attr("y2", 5) // Сделайте линию чуть выше
        .attr("stroke", "gray")
        .attr("stroke-opacity", 0.3)
        .attr("stroke-width", 2) // Уменьшите ширину линии
        .attr("stroke-linecap", "round");
    } else {
      const bars = chartGroup
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (_, i) => xScale(i))
        .attr("y", (d) => yScale(d))
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => chartHeight - yScale(d))
        .on("mouseover", (event, d, i) => handleMouseOver(event, d, i))
        .on("mouseout", () => handleMouseOut());
    }
  }, [data]);

  const handleMouseOver = (event, d, i) => {
    const svgElement = svgRef.current;
    const svgRect = svgElement.getBoundingClientRect();

    const xPosition = event.clientX - svgRect.left;
    const yPosition = event.clientY - svgRect.top;

    setTooltipData({ x: xPosition, y: yPosition, value: d });
  };

  const handleMouseOut = () => {
    setTooltipData(null);
  };

  return (
    <div
      style={{
        position: "relative",
        // marginRight: "40px",
      }}
    >
      <svg
        ref={svgRef}
        width={xScale.range()[1] + 30} // Установите ширину svg на основе максимальной ширины xScale плюс дополнительные 30 пикселей для отступов
        height={chartHeight + 20} // Установите высоту svg на основе высоты графика плюс дополнительные 20 пикселей для отступов
        style={{ border: "none" }}
      >
        {/*<g transform="translate(10, 10)">*/}
        {/*  {data.map((d, i) => (*/}
        {/*    <rect*/}
        {/*      key={i}*/}
        {/*      x={xScale(i)}*/}
        {/*      y={yScale(d)}*/}
        {/*      width={xScale.bandwidth()}*/}
        {/*      height={chartHeight - yScale(d)}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</g>*/}
      </svg>
      {tooltipData && (
        <div
          style={{
            position: "absolute",
            top: tooltipData.y + 15,
            left: tooltipData.x - 20,
            background: "white",
            padding: "4px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {tooltipData.value}
        </div>
      )}
    </div>
  );
};

// import React, { useRef, useEffect, useState } from "react";
// import * as d3 from "d3";
//
// export const D3BarChart = ({ data }) => {
//   const [tooltipData, setTooltipData] = useState(null);
//   const svgRef = useRef(null);
//
//   const chartHeight = 200;
//
//   useEffect(() => {
//     if (!data || !svgRef.current) return;
//
//     const svg = d3.select(svgRef.current);
//
//     // Очищаем предыдущие элементы с классом ".bar"
//     const existingElements = svg.selectAll(".bar");
//     if (!existingElements.empty()) {
//       existingElements.remove();
//     }
//
//     // Создаем или обновляем прямоугольники для каждого элемента данных
//     svg
//       .selectAll(".bar")
//       .data(data)
//       .join(
//         // Добавляем новые прямоугольники при необходимости
//         (enter) =>
//           enter
//             .append("rect")
//             .attr("class", "bar")
//             .attr("x", (_, i) => i * 30) // Горизонтальная позиция
//             .attr("y", (d) => chartHeight - d) // Вертикальная позиция
//             .attr("width", 20) // Ширина прямоугольника
//             .attr("height", (d) => d) // Высота прямоугольника
//             .attr("fill", "steelblue"), // Цвет прямоугольника
//
//         // Обновляем существующие прямоугольники при необходимости
//         (update) =>
//           update
//             .attr("x", (_, i) => i * 30)
//             .attr("y", (d) => chartHeight - d)
//             .attr("height", (d) => d),
//
//         // Удаляем лишние прямоугольники при необходимости
//         (exit) => exit.remove(),
//       )
//       .on("mouseover", (_, d) => {
//         const svgRect = svgRef.current.getBoundingClientRect();
//         const xPosition = _.clientX - svgRect.left;
//         const yPosition = _.clientY - svgRect.top;
//
//         setTooltipData({ x: xPosition, y: yPosition, value: d });
//       })
//       .on("mouseout", () => {
//         setTooltipData(null);
//       });
//   }, [data]);
//
//   return (
//     <div style={{ position: "relative" }}>
//       <svg
//         ref={svgRef}
//         width={300} // Ширина SVG
//         height={chartHeight} // Высота SVG
//         style={{ border: "1px solid gray" }}
//       ></svg>
//       {tooltipData && (
//         <div
//           style={{
//             position: "absolute",
//             top: tooltipData.y + 10,
//             left: tooltipData.x + 10,
//             background: "white",
//             padding: "8px",
//             borderRadius: "4px",
//             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
//           }}
//         >
//           {tooltipData.value}
//         </div>
//       )}
//     </div>
//   );
// };
