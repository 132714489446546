import { createAsyncThunk } from "@reduxjs/toolkit";
import { listSellersTableAPI } from "src/api/api_listSellersTable/api_listSellersTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import {
  setListSellersTable,
  setStatusListSellersTable,
  setTotalCountPageListSellersTable,
} from "src/features/ListSellersTable/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchListSellersTable = createAsyncThunk<any, any>(
  "listSellersTable/fetchListSellersTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusListSellersTable({
        status: "listSellersTable-loading",
      }),
    );
    try {
      const { res, resInfo } = await listSellersTableAPI.fetchListSellersTable(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setListSellersTable({ table: res.data.data }));
        thunkAPI.dispatch(
          setStatusListSellersTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setTotalCountPageListSellersTable({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusListSellersTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelListSellersTable = createAsyncThunk<any, any>(
  "excelListSellersTable/fetchExcelListSellersTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusListSellersTable({
        status: "listSellersTable-loading",
      }),
    );
    try {
      const res = await listSellersTableAPI.fetchExcelListSellersTable(
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
      );
      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(
          setStatusListSellersTable({
            status: "succeeded",
          }),
        );
      } else {
        thunkAPI.dispatch(
          setStatusListSellersTable({
            status: "succeeded",
          }),
        );
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
