import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  selectError,
  selectSuccess,
} from "src/components/ErrorSnackbar/selectors";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";

export const ErrorSnackbar = () => {
  const error = useAppSelector(selectError);
  const success = useAppSelector(selectSuccess);
  const dispatch = useAppDispatch();

  if (success !== null) {
    toast.success(success);
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAppSuccessAC({ success: null }));
    }, 2000);
  }, [success]);

  if (error !== null) {
    toast.error(error);
  }
  useEffect(() => {
    setTimeout(() => {
      dispatch(setAppErrorAC({ error: null }));
    }, 2000);
  }, [error]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={15000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};
