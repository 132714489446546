import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, Input, Label, Modal } from "reactstrap";
import { accountUnSubscribeTC } from "src/pages/Contacts/thunk";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { messageYup } from "src/utilitsFunction/schemas";

export const ModalWindow = ({
  open,
  setOpen,
  placeholder,
  title,
  body,
  button,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: messageYup,
    initialValues: {
      message: "",
    },
    onSubmit: async (values: { message: string }, { setSubmitting }) => {
      try {
        await dispatch(accountUnSubscribeTC(values));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {/*{open &&  (*/}
      <CardBody>
        <Modal
          isOpen={open}
          toggle={() => {
            // tog_backdrop();
          }}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setOpen(false);
                  // dispatch(setUserStatus({ userStatus: "false" }));
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Label>{body}</Label>
              <Input
                rows={6}
                // type="text"
                type="textarea"
                placeholder={placeholder}
                {...formik.getFieldProps("message")}
                invalid={!!formik.errors.message}
              />
              {formik.errors.message && (
                <div className="invalid-feedback">{formik.errors.message}</div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setOpen(false);
                  // dispatch(setUserStatus({ userStatus: "false" }));
                }}
              >
                {t("Close")}
              </button>

              {/*<button type="button" className="btn btn-primary">*/}
              <button
                type="submit"
                className={`btn ${
                  !formik.isValid ||
                  !formik.touched.message ||
                  formik.isSubmitting
                    ? "btn-disabled"
                    : "btn-primary"
                }`}
                // to={"#"}
              >
                {button}
              </button>
              {/*</button>*/}
            </div>
          </form>
        </Modal>
      </CardBody>
      {/*)}*/}
    </>
  );
};
