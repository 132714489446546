import { AppRootStateType } from "src/store/types";

export const selectDataBrands = (state: AppRootStateType) =>
  state.brandsTable.table;
// export const selectBrandsId = (state: AppRootStateType) =>
//   state.brandsTable.;
export const selectColumnNameBrandsTable = (state: AppRootStateType) =>
  state.brandsTable.columnName;
export const selectNameCategoryBrands = (state: AppRootStateType) =>
  state.brandsTable.categoryNameBrands;
export const selectOrderNameBrands = (state: AppRootStateType) =>
  state.brandsTable.orderName;
export const selectAscDescBrands = (state: AppRootStateType) =>
  state.brandsTable.ascDesc;
export const selectPageBrands = (state: AppRootStateType) =>
  state.brandsTable.page;
export const selectTotalCountPageBrands = (state: AppRootStateType) =>
  state.brandsTable.totalCountPage;
export const selectStatusCategoryTableBrands = (state: AppRootStateType) =>
  state.brandsTable.status;

export const selectStatusCompetitors = (state: AppRootStateType) =>
  state.brandsTable.statusCompetitors;
export const selectStatusProductInTheTops = (state: AppRootStateType) =>
  state.brandsTable.statusProductInTheTops;
export const selectStatusDataRating = (state: AppRootStateType) =>
  state.brandsTable.statusDataRating;
export const selectStatusDataRevenue = (state: AppRootStateType) =>
  state.brandsTable.statusDataRevenue;

export const selectSubscribeCategoryTableBrands = (state: AppRootStateType) =>
  state.brandsTable.subscribe;

export const selectDataCompetitors = (state: AppRootStateType) =>
  state.brandsTable.dataCompetitors;
export const selectDataRating = (state: AppRootStateType) =>
  state.brandsTable.dataRating;
export const selectDataProductInTheTops = (state: AppRootStateType) =>
  state.brandsTable.dataProductInTheTops;
export const selectDataRevenue = (state: AppRootStateType) =>
  state.brandsTable.blockRevenue;
export const selectChoiceProductInTheTops = (state: AppRootStateType) =>
  state.brandsTable.selectProductInTheTops;
export const selectChoiceCompetitors = (state: AppRootStateType) =>
  state.brandsTable.selectCompetitors;
