// import { format } from "date-fns";
// import React, { useEffect, useMemo, useState } from "react";
// import DataTable from "react-data-table-component";
// import { useTranslation } from "react-i18next";
// import { NavLink } from "react-router-dom";
// import { Card, CardBody, CardHeader, Table, Tooltip } from "reactstrap";
// import { CustomSpinner } from "src/components/Spinner/Spinner";
// import { fetchProductAdvert } from "src/features/product/miniProductComponent/advertisingCompany/thunk";
// import { selectGraphSelectDate1 } from "src/features/product/miniProductComponent/graphWrapper/selectors";
// import { useAppSelector } from "src/store/types";
// import { useAppDispatch } from "src/utilitsFunction/redux-utils";
// import {
//   selectDataProductAdvert,
//   selectStatusProductAdvert,
// } from "./selectors";
// import { clearProductAdvert } from "./reducer";
//
// export const AdvertisingCompany = ({ id, merchantId, itemNumberUrl }) => {
//   const { t } = useTranslation();
//   const dispatch = useAppDispatch();
//   const [top, setTop] = useState(false);
//   const [columnFilters, setColumnFilters] = useState({});
//   const [categoryFilter, setCategoryFilter] = useState("");
//   const status = useAppSelector(selectStatusProductAdvert);
//   const data = useAppSelector(selectDataProductAdvert);
//   const graphSelectDate1 = useAppSelector(selectGraphSelectDate1);
//
//   const resultDate1 = graphSelectDate1[0]
//     ? "?date_from=" + graphSelectDate1[0]
//     : "";
//   const resultDate2 = graphSelectDate1[1]
//     ? "&date_to=" + graphSelectDate1[1]
//     : "";
//   let param = {
//     id,
//     merchantId,
//     itemNumberUrl,
//     resultDate1,
//     resultDate2,
//   };
//   useEffect(() => {
//     dispatch(fetchProductAdvert(param));
//   }, [id, merchantId, itemNumberUrl, resultDate1, resultDate2]);
//   useEffect(() => {
//     return () => {
//       dispatch(clearProductAdvert({}));
//     };
//   }, []);
//   const columns = [
//     {
//       name: t("date"),
//       selector: "date",
//       format: (row) => format(new Date(row.date), "dd.MM.yyyy"),
//     },
//     {
//       name: t("position"),
//       selector: "advert_slot",
//       format: (row) =>
//         row.advert_slot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
//     },
//     {
//       name: "CPC",
//       selector: "cpc",
//       // format: (row) =>
//       //   row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
//     },
//     {
//       test: t("category"),
//       name: t("category"),
//       selector: "category_title",
//       cell: (row) => (
//         <NavLink
//           target="_blank"
//           to={`/trendyol/category/${row.category_id}/products`}
//         >
//           {row.category_title}
//         </NavLink>
//       ),
//     },
//   ];
//   const handleFilterChange = (columnSelector, filterValue) => {
//     if (columnSelector === "category_title") {
//       setCategoryFilter(filterValue);
//     } else {
//       setColumnFilters((prevFilters) => ({
//         ...prevFilters,
//         [columnSelector]: filterValue,
//       }));
//     }
//   };
//
//   const filteredData = useMemo(() => {
//     return data.filter((item) => {
//       return (
//         (categoryFilter === "" || item.category_title === categoryFilter) &&
//         Object.entries(columnFilters).every(([columnSelector, filterValue]) => {
//           if (!filterValue) return true;
//           return item[columnSelector]
//             .toString()
//             .toLowerCase()
//             .includes(filterValue.toLowerCase().trim());
//         })
//       );
//     });
//   }, [data, columnFilters, categoryFilter]);
//
//   const getUniqueFilterOptions = (columnSelector) => {
//     return [...new Set(data.map((item) => item[columnSelector]))];
//   };
//
//   const getSubHeaderComponent = () => {
//     return (
//       <div>
//         {columns.map((column) => (
//           <div
//             key={column.selector}
//             style={{
//               // marginBottom: "7px",
//               // paddingBottom: "17px",
//               // marginTop: "5px",
//               textAlign: "center",
//             }}
//           >
//             {/*<label>{column.test}</label>*/}
//             {column.selector === "category_title" && (
//               <div>
//                 <select
//                   className={"form-select form-select"}
//                   value={categoryFilter}
//                   onChange={(e) =>
//                     handleFilterChange(column.selector, e.target.value)
//                   }
//                 >
//                   <option value="">{t("Type")}</option>
//                   {getUniqueFilterOptions(column.selector).map((option) => (
//                     <option key={option} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   };
//   return (
//     <>
//       <Card>
//         <div
//           style={{
//             maxHeight: "500px",
//             overflowY: "auto",
//             overflowX: "hidden",
//             borderRadius: "5px",
//           }}
//         >
//           {status === "productAdvert-loading" ? (
//             <CustomSpinner absolute={true} top={"200px"} />
//           ) : (
//             <>
//               <CardHeader
//                 className="d-flex justify-content-between
//                align-items-center
//                "
//               >
//                 <div className="h4 card-title">
//                   {t("Adverts")}
//                   <Tooltip
//                     placement="top"
//                     isOpen={top}
//                     target="Adverts"
//                     toggle={() => {
//                       setTop(!top);
//                     }}
//                   >
//                     {t("AdvertsTooltip")}
//                   </Tooltip>
//                   <i
//                     className="uil-question-circle"
//                     style={{ marginLeft: "3px", cursor: "pointer" }}
//                     id={"Adverts"}
//                   ></i>
//                 </div>
//                 {getSubHeaderComponent()}
//               </CardHeader>
//               <CardBody className={"customCardBody"}>
//                 <div className="table-responsive">
//                   <Table className="table customTable  mb-0">
//                     <DataTable
//                       defaultSortFieldId={"sold"}
//                       columns={columns}
//                       data={filteredData}
//                       pagination={false}
//                       responsive
//                       // subHeaderComponent={
//                       // }
//                       noDataComponent={<div>{t("dataTable")}</div>}
//                     />
//                   </Table>
//                 </div>
//               </CardBody>
//             </>
//           )}
//         </div>
//       </Card>
//     </>
//   );
// };
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Table, Tooltip } from "reactstrap";
import { CustomSpinner } from "src/components/Spinner/Spinner";
import { fetchProductAdvert } from "src/features/product/miniProductComponent/advertisingCompany/thunk";
import { selectGraphSelectDate1 } from "src/features/product/miniProductComponent/graphWrapper/selectors";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import {
  selectDataProductAdvert,
  selectStatusProductAdvert,
} from "./selectors";
import { clearProductAdvert } from "./reducer";

export const AdvertisingCompany = ({ id, merchantId, itemNumberUrl }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [top, setTop] = useState(false);
  const [columnFilters, setColumnFilters] = useState({});
  const [categoryFilter, setCategoryFilter] = useState("");
  const [advertSlotFilter, setAdvertSlotFilter] = useState("");
  const [navLink, setNavLink] = useState(false);
  const status = useAppSelector(selectStatusProductAdvert);
  const data = useAppSelector(selectDataProductAdvert);
  const graphSelectDate1 = useAppSelector(selectGraphSelectDate1);

  const resultDate1 = graphSelectDate1[0]
    ? "?date_from=" + graphSelectDate1[0]
    : "";
  const resultDate2 = graphSelectDate1[1]
    ? "&date_to=" + graphSelectDate1[1]
    : "";

  let param = {
    id,
    merchantId,
    itemNumberUrl,
    resultDate1,
    resultDate2,
  };

  useEffect(() => {
    dispatch(fetchProductAdvert(param));
  }, [id, merchantId, itemNumberUrl, resultDate1, resultDate2]);
  useEffect(() => {
    return () => {
      dispatch(clearProductAdvert({}));
    };
  }, []);

  const columns = [
    {
      name: t("date"),
      selector: "date",
      format: (row) => format(new Date(row.date), "dd.MM.yyyy"),
    },
    {
      name: t("position"),
      selector: "advert_slot",
      format: (row) =>
        row.advert_slot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
    },
    {
      name: "CPC",
      selector: "cpc",
      // format: (row) =>
      //   row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
    },
    {
      test: t("title"),
      name: t("title"),
      selector: "category_title",
      cell: (row) =>
        row.type !== "category" &&
        row.type !== "similar" &&
        row.type !== "buy_with" ? (
          row.category_title
        ) : (
          <>
            <NavLink
              target="_blank"
              to={
                row.type !== "similar" || row.type !== "buy_with"
                  ? `/trendyol/product/${row.category_id}`
                  : `/trendyol/category/${row.category_id}/products`
              }
            >
              {row.category_title}
            </NavLink>
          </>
        ),
    },
    {
      // name: "type",
      selector: "type",
      // format: (row) =>
      //   row.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
      cell: (row) => (
        <div
        // style={{ display: "none" }}
        >
          {t(`${row.type}`)}
        </div>
      ),
      // cell: (row) => (row.type ? null : <div>{row.type}</div>),
    },
  ];

  const handleFilterChange = (columnSelector, filterValue) => {
    if (columnSelector === "category_title") {
      setCategoryFilter(filterValue);
    } else if (columnSelector === "type") {
      setAdvertSlotFilter(filterValue);
    } else {
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [columnSelector]: filterValue,
      }));
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return (
        (categoryFilter === "" || item.category_title === categoryFilter) &&
        (advertSlotFilter === "" || item.type === advertSlotFilter) &&
        Object.entries(columnFilters).every(([columnSelector, filterValue]) => {
          if (!filterValue) return true;
          return (
            item[columnSelector].toString().toLowerCase() ===
            filterValue.toLowerCase().trim()
          );
        })
      );
    });
  }, [data, columnFilters, categoryFilter, advertSlotFilter]);
  const getUniqueFilterOptions = (columnSelector) => {
    return [...new Set(data.map((item) => item[columnSelector]))];
  };

  console.log(data, "data");

  const getSubHeaderComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        {columns.map((column) => (
          <div
            key={column.selector}
            style={{
              textAlign: "center",
            }}
          >
            {column.selector === "category_title" && (
              <div>
                <select
                  className={"form-select form-select"}
                  value={categoryFilter}
                  onChange={(e) => {
                    console.log(
                      column.selector,
                      e.target.value,
                      "column.selector, e.target.value",
                    );
                    handleFilterChange(column.selector, e.target.value);
                  }}
                >
                  <option value="">{t("Name")}</option>
                  {getUniqueFilterOptions(column.selector).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/*{column.selector === "type" && (*/}
            {column.selector === "type" && (
              <div>
                <select
                  className={"form-select form-select"}
                  value={advertSlotFilter}
                  onChange={(e) => {
                    //
                    //
                    //
                    // setNavLink(!navLink);
                    //
                    //
                    //
                    handleFilterChange(column.selector, e.target.value);
                  }}
                >
                  <option value="">{t("Type")}</option>
                  {getUniqueFilterOptions(column.selector).map((option) => (
                    <option key={option} value={option}>
                      {/*{option}*/}
                      {t(`${option}`)}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [pointer, setPointer] = useState(false);

  const handleDivClick = () => {
    setIsScrollEnabled(true);
  };
  return (
    <>
      <div
        onClick={handleDivClick}
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        style={{
          cursor: pointer ? "pointer" : "default",
        }}
      >
        <div
          onMouseLeave={() => setIsScrollEnabled(false)}
          onClick={handleDivClick}
          // style={{
          //   boxShadow: isScrollEnabled ? "1px 1px 6px #6f6f6f" : "",
          //   pointerEvents: isScrollEnabled ? "" : "none",
          // }}
        >
          <Card>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                overflowX: "hidden",
                borderRadius: "5px",
              }}
            >
              <>
                <CardHeader className="d-flex justify-content-between align-items-center  ">
                  <div className="h4 card-title">
                    {t("Adverts")}
                    <Tooltip
                      placement="top"
                      isOpen={top}
                      target="Adverts"
                      toggle={() => {
                        setTop(!top);
                      }}
                    >
                      {t("AdvertsTooltip")}
                    </Tooltip>
                    <i
                      className="uil-question-circle"
                      style={{ marginLeft: "3px", cursor: "pointer" }}
                      id={"Adverts"}
                    ></i>
                  </div>
                  {getSubHeaderComponent()}
                </CardHeader>
                <CardBody className={"customCardBody"}>
                  <div className="table-responsive">
                    <Table className="table customTable mb-0">
                      {status === "productAdvert-loading" ? (
                        <div
                          style={{
                            background: "white",
                            width: "500px",
                            height: "500px",
                          }}
                        >
                          <CustomSpinner absolute={true} top={"200px"} />
                        </div>
                      ) : (
                        <DataTable
                          defaultSortFieldId={"sold"}
                          columns={columns}
                          data={filteredData}
                          pagination={false}
                          responsive
                          noDataComponent={<div>{t("dataTable")}</div>}
                        />
                      )}
                    </Table>
                  </div>
                </CardBody>
              </>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
