import { instance } from "src/api/api_helper";
import { FetchResponseAxios } from "src/api/types";
import { ProductAdvert } from "src/features/product/miniProductComponent/advertisingCompany/type";
export const productAdvertAPI = {
  fetchProductAdvert(
    id: string,
    itemNumberUrl: string,
    merchantId: string,
    resultDate1: string,
    resultDate2: string,
  ) {
    const itemNumberAll = itemNumberUrl ? itemNumberUrl : "/all";
    return instance.get<FetchResponseAxios<Array<ProductAdvert>>>(
      `trendyol/product/advert-table/${id}${itemNumberAll}${merchantId}${resultDate1}${resultDate2}`,
    );
  },
};
