import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatusType } from "src/features/App/reducer";
import { InputsType } from "src/features/CategoryTable/type";

export const slice = createSlice({
  name: "merchantTable",
  initialState: {
    columnName: [] as Array<InputsType>,
    subscribe: {} as any,
    table: [] as any,
    orderName: null as null | string,
    ascDesc: null as null | string,
    page: 1 as number,
    totalCountPage: null as number | null,
    dataCompetitors: [] as any,
    dataRating: {},
    dataProductInTheTops: {},
    blockRevenue: {},
    selectProductInTheTops: "Search",
    selectCompetitors: "Revenue in this category",
    status: "idle" as RequestStatusType,
    statusCompetitors: "idle",
    statusProductInTheTops: "idle",
    statusDataRating: "idle",
    statusDataRevenue: "idle",
  },
  reducers: {
    onChangePhraseMerchantTable(
      state,
      action: PayloadAction<{ columnName: Array<InputsType> }>,
    ) {
      state.columnName = [...action.payload.columnName];
    },
    setMerchantTable(state, action: PayloadAction<{ table: Array<any> }>) {
      const newData = action.payload.table.reduce((acc: any, el: any) => {
        acc[el.id] = el.followed;
        return acc;
      }, {});
      state.subscribe = { ...state.subscribe, ...newData };
      state.table = [...action.payload.table];
    },
    onClickPageMerchantTable(state, action: PayloadAction<{ page: number }>) {
      state.page = action.payload.page;
    },
    setTotalCountPageMerchantTable(
      state,
      action: PayloadAction<{ totalCountPage: number | null }>,
    ) {
      state.totalCountPage = action.payload.totalCountPage;
    },
    clickOrderNameMerchantTable(
      state,
      action: PayloadAction<{ orderName: string | null }>,
    ) {
      state.orderName = action.payload.orderName;
    },
    clickAscDescMerchantTable(
      state,
      action: PayloadAction<{ ascDesc: string | null }>,
    ) {
      state.ascDesc = action.payload.ascDesc;
    },
    setSubscribeMerchantTable(state, action: PayloadAction<{ id: any }>) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: true,
      };
      state.subscribe = newSubscribe;
    },
    deleteSubscribeMerchantTable(state, action: PayloadAction<{ id: any }>) {
      const newSubscribe = {
        ...state.subscribe,
        [action.payload.id]: false,
      };
      state.subscribe = newSubscribe;
    },
    setStatusMerchantTable(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.status = action.payload.status;
    },

    //

    setDataRevenue(state, action: PayloadAction<{ revenue: Array<any> }>) {
      state.blockRevenue = action.payload.revenue;
    },
    setDataProductInTheTops(
      state,
      action: PayloadAction<{ data: Array<any> }>,
    ) {
      state.dataProductInTheTops = action.payload.data;
    },
    setSelectProductInTheTops(
      state,
      action: PayloadAction<{ select: string }>,
    ) {
      state.selectProductInTheTops = action.payload.select;
    },
    setDataRating(state, action: PayloadAction<{ rating: Array<any> }>) {
      state.dataRating = action.payload.rating;
    },
    setDataCompetitors(state, action: PayloadAction<{ data: Array<any> }>) {
      state.dataCompetitors = action.payload.data;
    },
    setSelectCompetitors(state, action: PayloadAction<{ select: string }>) {
      state.selectCompetitors = action.payload.select;
    },

    //
    setStatusDataRevenue(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDataRevenue = action.payload.status;
    },
    setStatusProductInTheTops(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusProductInTheTops = action.payload.status;
    },
    setStatusDataRating(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusDataRating = action.payload.status;
    },
    setStatusCompetitors(
      state,
      action: PayloadAction<{ status: RequestStatusType }>,
    ) {
      state.statusCompetitors = action.payload.status;
    },
    //
    resetMerchantTable(state, action) {
      return {
        ...state,
        columnName: [] as Array<InputsType>,
        subscribe: {} as any,
        table: [] as any,
        orderName: null as null | string,
        ascDesc: null as null | string,
        page: 1 as number,
        totalCountPage: null as number | null,
        dataCompetitors: [] as any,
        dataRating: {},
        dataProductInTheTops: {},
        blockRevenue: {},
        selectProductInTheTops: "Search",
        selectCompetitors: "Revenue in this category",
        status: "idle" as RequestStatusType,
        statusCompetitors: "idle",
        statusProductInTheTops: "idle",
        statusDataRating: "idle",
        statusDataRevenue: "idle",
      };
    },
  },
});
export const merchantTableReducer = slice.reducer;
export const {
  setSubscribeMerchantTable,
  deleteSubscribeMerchantTable,
  onChangePhraseMerchantTable,
  setMerchantTable,
  onClickPageMerchantTable,
  setTotalCountPageMerchantTable,
  clickOrderNameMerchantTable,
  clickAscDescMerchantTable,

  setDataRevenue,
  setDataProductInTheTops,
  setSelectProductInTheTops,
  setDataRating,
  setDataCompetitors,
  setSelectCompetitors,

  setStatusDataRevenue,
  setStatusProductInTheTops,
  setStatusDataRating,
  setStatusCompetitors,

  resetMerchantTable,
  setStatusMerchantTable,
} = slice.actions;
