import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";

export const productResearchAPI = {
  async fetchProductResearchAPI(
    value: any,
    orderName: any,
    ascDesk: any,
    page: any,
  ) {
    console.log();
    console.log();
    const url = new URL(
      `https://web-api.restatos.com/trendyol/researchProducts`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/researchProducts/info`,
    );

    const params: { [key: string]: string } = {};

    if (value) {
      // const categoryIdString = value.selectedCategory
      //   .map((column: InputsType) => column.value)
      //   .filter(
      //     (value: string | number | null | undefined) =>
      //       value !== undefined && value !== null && value !== "",
      //   )
      //   .join(",");
      // if (categoryIdString !== "") {
      //   params["search[cats]"] = categoryIdString;
      // }
      for (const key in value) {
        if (
          // key !== "selectedCategory" &&
          key !== "urlParamNameCategory" &&
          value[key] !== ""
        ) {
          params[`search[${key}]`] = `${value[key]}`;
        }
      }
    }

    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }

    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get(`${url}`, {
        params: params,
      }),
      instance.get(`${infoUrl}`, {
        params: params,
      }),
    ]);
    return { res, resInfo };
  },
  async fetchTableProductResearchAPI(
    id: string,
    orderName: string,
    ascDesk: string,
    page: number,
  ) {
    const url = new URL(
      `https://web-api.restatos.com/trendyol/category/products/${id}`,
    );
    const infoUrl = new URL(
      `https://web-api.restatos.com/trendyol/category/products/${id}/info`,
    );

    const params: { [key: string]: string } = {};

    if (orderName !== "") {
      params["orderColumn"] = orderName;
    }
    if (ascDesk !== "") {
      params["orderType"] = ascDesk;
    }
    params["page"] = `${page}`;

    const [res, resInfo] = await Promise.all([
      instance.get<FetchResponseTable<Array<any>>>(`${url}`, {
        params: params,
      }),
      instance.get<FetchResponseTable<Array<any>>>(`${infoUrl}`, {
        params: params,
      }),
    ]);

    return { res, resInfo };
  },
  async fetchCategoryAPI(value: any) {
    return instance.post<any>("trendyol/categoryAutocomplete", {
      value,
    });
  },
};
