import { instance } from "src/api/api_helper";
import { FetchResponseTable } from "src/api/types";

export const productSearchAPI = {
  fetchProductSearch(productId: string) {
    const trendyol = "trendyol/productSearch";
    return instance.get<FetchResponseTable<{ id: number }>>(
      `${trendyol}/${productId}`,
    );
  },
};
