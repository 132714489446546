import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CardBody,
  FormFeedback,
  Input,
  Label,
  Modal,
  Spinner,
} from "reactstrap";
import {
  resetInfoProduct,
  setPriceUpdater,
} from "src/pages/Integration-Products/reducer";
import {
  selectDataInfoProduct,
  selectPriceUpdater,
  selectStatus,
  selectStatusPriceUpdater,
} from "src/pages/Integration-Products/selectors";
import {
  autoPriceSetTC,
  fetchAPIPriceUpdater,
} from "src/pages/Integration-Products/thunk";
import { useAppSelector } from "src/store/types";
import { useAppDispatch } from "src/utilitsFunction/redux-utils";
import { priceUpdaterYup } from "src/utilitsFunction/schemas";

export const ModalView = ({ open, setOpen, data, tog_standard }: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const priceUpdater = useAppSelector(selectPriceUpdater);
  const status = useAppSelector(selectStatusPriceUpdater);

  useEffect(() => {
    if (open && Object.keys(priceUpdater).length > 0) {
      dispatch(fetchAPIPriceUpdater(priceUpdater));
      // const intervalId = setInterval(() => {
      //   dispatch(fetchAPIPriceUpdater(priceUpdater));
      // }, 60000); // 60000 milliseconds = 1 minute

      // Clear interval on componнent unmount or when open becomes false
      // return () => {
      //   dispatch(resetInfoProduct({}));
      //   clearInterval(intervalId);
      // };
    }
  }, [open, priceUpdater, dispatch]);

  const formik = useFormik({
    validationSchema: priceUpdaterYup,
    initialValues: {
      supplierId: data.supplier_id,
      barcode: data.barcode,
      min_price: data.min_price,
      enabled: true,
      price_step: data.price_step,
      time_from: data.time_from,
      time_to: data.time_to,
      all_day: data.all_day !== undefined ? data.all_day : true,
      enabled_stop_price: data.enabled_stop_price,
      stop_price: data.stop_price,
      agreement: false,
      all_variants: false,
      id: null,
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        await dispatch(autoPriceSetTC(values));
      } catch (error) {
        // Handle error
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (data) {
      formik.setValues({
        supplierId: data.supplier_id,
        barcode: data.barcode,
        min_price: data.min_price,
        enabled: data.enabled,
        price_step: 1,
        time_from: data.time_from,
        time_to: data.time_to,
        all_day: data.all_day !== undefined ? data.all_day : true,
        enabled_stop_price: data.enabled_stop_price,
        stop_price: data.stop_price,
        agreement: false,
        all_variants: data.all_variants,
        id: data.id,
      });
    }
  }, [data]);

  const isBelowThreshold = (value: any) => {
    const threshold = data.price * 0.7;
    return value < threshold;
  };

  return (
    <CardBody>
      <Modal
        isOpen={open}
        toggle={() => tog_standard()}
        // backdrop={"static"}
        // id="staticBackdrop"
      >
        <form onSubmit={formik.handleSubmit}>
          <Input
            type="hidden"
            checked={formik.values.id}
            id="id"
            {...formik.getFieldProps("id")}
          />
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {t("Automatic price")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setOpen(false);
                dispatch(setPriceUpdater({ priceUpdater: {} }));
                dispatch(resetInfoProduct({}));
              }}
              aria-label="Close"
            ></button>
          </div>
          {status === "loading" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "5px",
              }}
            >
              <Spinner style={{ color: "skyblue" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <img
                src={data.image}
                style={{
                  maxWidth: "50%",
                  maxHeight: "100px",
                  borderRadius: "3%",
                  margin: "5px 0 5px 20px",
                }}
                alt="Product"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                <div>
                  {data.name}{" "}
                  {data.product_url ? (
                    <a
                      href={data.product_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="uil-external-link-alt"
                        style={{ color: "DeepSkyBlue" }}
                      ></i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div>Barcode: {data.barcode}</div>
                <div>
                  {t("supplierId")}: {data.supplier_id}
                </div>
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <p>
                      {t("Current your price")}
                      {" " + data.price} TL
                    </p>
                  </div>

                  <div className="row">
                    {data._merchants_number > 1 ? (
                      <p>
                        {t("Current minimum competitor's price")}{" "}
                        {data._min_price_competitor} TL{" "}
                      </p>
                    ) : (
                      <p>{t("without competitors")}</p>
                    )}
                  </div>
                  <div className="mb-3 mt-3 mt-xl-0 row">
                    <div>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={formik.values.enabled}
                          id="enableInput"
                          {...formik.getFieldProps("enabled")}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="enableInput"
                        >
                          {t("Enable")}
                        </Label>
                      </div>
                    </div>
                  </div>

                  {formik.values.enabled && (
                    <>
                      <div className="mb-3 mt-3 mt-xl-0 row">
                        <label className="col-form-label form-label">
                          {t("Min price, TL")}
                        </label>
                        <div className="">
                          <Input
                            className={`form-control ${
                              isBelowThreshold(formik.values.min_price)
                                ? "is-invalid"
                                : ""
                            }`}
                            style={{
                              borderColor: isBelowThreshold(
                                formik.values.min_price,
                              )
                                ? "yellow"
                                : "",
                            }}
                            type="number"
                            placeholder={
                              data.min_price > 0 ? data.min_price : 999
                            }
                            min={10}
                            required
                            {...formik.getFieldProps("min_price")}
                          />
                          {isBelowThreshold(formik.values.min_price) && (
                            <div className="invalid-feedback">
                              {t(
                                "The minimum price is 30% lower than the current price",
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mb-3 mt-3 mt-xl-0 row">
                        <label className="col-form-label form-label">
                          {t("Price step, TL")}
                        </label>
                        <div className="">
                          <Input
                            className={`form-control ${
                              formik.touched.price_step &&
                              formik.errors.price_step
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              data.price_step > 0 ? data.price_step : 1
                            }
                            type="number"
                            required
                            {...formik.getFieldProps("price_step")}
                          />
                          {formik.touched.price_step &&
                          formik.errors.price_step ? (
                            <FormFeedback type="invalid">
                              {t("The price step cannot exceed 100 lira")}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3 mt-3 mt-xl-0 row">
                        <div>
                          <div className="form-check form-switch form-switch-md mb-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              checked={formik.values.all_day}
                              id="allDayInput"
                              {...formik.getFieldProps("all_day")}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="allDayInput"
                            >
                              {t("All-day")}
                            </Label>
                          </div>
                        </div>
                      </div>
                      {!formik.values.all_day && (
                        <>
                          <div className="mb-3 mt-3 mt-xl-0 row">
                            <div>
                              <label className="col-form-label form-label">
                                {t("From")}
                              </label>
                              <Input
                                className="form-control"
                                type="time"
                                {...formik.getFieldProps("time_from")}
                                required={!formik.values.all_day}
                              />
                              <label className="col-form-label form-label">
                                {t("To")}
                              </label>
                              <Input
                                className="form-control"
                                type="time"
                                {...formik.getFieldProps("time_to")}
                                required={!formik.values.all_day}
                              />
                            </div>
                          </div>

                          <div className="mb-3 mt-3 mt-xl-0 row">
                            <div>
                              <div className="form-check form-switch form-switch-md mb-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={formik.values.enabled_stop_price}
                                  id="stopPriceCheckbox"
                                  {...formik.getFieldProps(
                                    "enabled_stop_price",
                                  )}
                                />
                                <div className="mb-1 mt-1 mt-xl-0 row">
                                  <Label
                                    className="form-check-label"
                                    htmlFor="stopPriceCheckbox"
                                  >
                                    {t("After stop set price to...")}
                                  </Label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {formik.values.enabled_stop_price && (
                            <div className="mb-3 mt-3 mt-xl-0 row">
                              <div>
                                <label className="col-form-label form-label">
                                  {t("Inactive price")}
                                </label>
                                <Input
                                  className={`form-control ${
                                    isBelowThreshold(formik.values.stop_price)
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  style={{
                                    borderColor: isBelowThreshold(
                                      formik.values.stop_price,
                                    )
                                      ? "yellow"
                                      : "",
                                  }}
                                  type="number"
                                  id="stop-price-input"
                                  placeholder={
                                    data.price > 0 ? data.price : 1199.9
                                  }
                                  required={formik.values.enabled_stop_price}
                                  {...formik.getFieldProps("stop_price")}
                                />

                                {isBelowThreshold(formik.values.stop_price) && (
                                  <FormFeedback type="invalid">
                                    {t(
                                      'please make sure you set the "price in inactive time" correctly',
                                    )}
                                  </FormFeedback>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {data.variants > 1 ? (
                    <div className="mb-3 mt-3 mt-xl-0 row">
                      <div>
                        <div className="form-check form-switch form-switch-md mb-2">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            checked={formik.values.all_variants}
                            id="allVariants"
                            {...formik.getFieldProps("all_variants")}
                          />
                          <div className="mb-1 mt-1 mt-xl-0 row">
                            <Label
                              className="form-check-label"
                              htmlFor="allVariants"
                            >
                              {t("For all variants")} {" ("}
                              {data.variants} {t("pcs") + ")"}
                            </Label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mb-3 mt-3 mt-xl-0 row">
                    <div>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={formik.values.agreement}
                          id="agreement"
                          {...formik.getFieldProps("agreement")}
                        />
                        <div className="mb-1 mt-1 mt-xl-0 row">
                          <Label
                            className="form-check-label"
                            htmlFor="agreement"
                          >
                            {t("I understand auto price")}:{" "}
                            <a
                              target="_blank"
                              href="https://restatos.com/integration-agreement"
                            >
                              https://restatos.com/integration-agreement
                            </a>
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 mt-3 mt-xl-0 row">
                    <div>
                      <button
                        disabled={
                          !formik.isValid ||
                          formik.isSubmitting ||
                          !formik.values.agreement
                        }
                        type="submit"
                        className="w-md btn btn-primary"
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </CardBody>
  );
};
