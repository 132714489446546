import React from "react";

export const TooltipInTable = ({ tooltipName }: any) => {
  if (!tooltipName) {
    return null;
  }

  return (
    <text
      style={{
        zIndex: 100,
        position: "absolute",
        top: tooltipName.y - 7,
        left: tooltipName.x + 30,
        background: "white",
        padding: "4px",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {tooltipName.value}
    </text>
  );
};
