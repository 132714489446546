import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Button } from "reactstrap";
import s from "./VideoComponent.module.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../utilitsFunction/redux-utils";
import { useAppSelector } from "../../store/types";

function VideoModal({ turkishURL, englishURL, actionStore, selectFunc }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const showVideo = useAppSelector(selectFunc);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const storedVideoClosedAt = localStorage.getItem("videoClosedAt");
    if (storedVideoClosedAt) {
      const videoClosedAt = new Date(storedVideoClosedAt);
      const now = new Date();
      const daysDiff = (now - videoClosedAt) / (1000 * 60 * 60 * 24); // Milliseconds to days
      if (daysDiff < 3) {
        dispatch(actionStore({ showVideo: false }));
      } else {
        dispatch(actionStore({ showVideo: true }));
      }
    } else {
      dispatch(actionStore({ showVideo: true }));
    }
  }, [dispatch]);
  const handleCloseVideo = () => {
    localStorage.setItem("videoClosedAt", new Date().toISOString());
    dispatch(actionStore({ showVideo: false }));
  };

  const calculateDimensions = () => {
    let width = screenWidth > 768 ? 560 : screenWidth * 0.8;
    let height = (width * 9) / 16; // 16:9 aspect ratio

    return { width, height };
  };

  const { width, height } = calculateDimensions();

  return (
    <>
      {showVideo && (
        <>
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              zIndex: 9999,
            }}
          >
            {videoLoaded && (
              <Button
                onClick={handleCloseVideo}
                className={s.closeButton}
                color="link"
                size="lg"
              >
                <FaTimes size={30} color="grey" />
              </Button>
            )}
            <iframe
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                display: videoLoaded ? "block" : "none",
              }}
              width={width} // ш
              height={height}
              src={currentLanguage === "tr" ? turkishURL : englishURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={handleVideoLoad}
            ></iframe>
          </div>
        </>
      )}
    </>
  );
}

export default VideoModal;
