import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryTableAPI } from "src/api/api_categoryTable/api_categoryTable";
import { setAppErrorAC, setAppSuccessAC } from "src/features/App/reducer";
import { setStatusCategoryTable } from "src/features/CategoryTable/reducer";
import {
  setDataSellersTable,
  setStatusSellersTable,
  setTotalCountPageSellersTable,
} from "src/features/CategoryTableSellers/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchSellerTable = createAsyncThunk<any, any>(
  "sellerTable/fetchSellerTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(setStatusSellersTable({ status: "sellers-loading" }));
    try {
      const { res, resInfo } = await categoryTableAPI.fetchSellers(
        param.category_url,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );
      if (res.data.success === true) {
        thunkAPI.dispatch(setDataSellersTable({ data: res.data.data }));
        thunkAPI.dispatch(
          setTotalCountPageSellersTable({
            totalCountPage: resInfo.data.recordsFiltered,
          }),
        );

        thunkAPI.dispatch(setStatusSellersTable({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusSellersTable({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);

export const fetchExcelSellerTable = createAsyncThunk<any, any>(
  "excelSellerTable/fetchExcelSellerTable",
  async (param, thunkAPI) => {
    thunkAPI.dispatch(
      thunkAPI.dispatch(setStatusSellersTable({ status: "sellers-loading" })),
    );

    try {
      const res = await categoryTableAPI.fetchExcelSellers(
        param.category_url,
        param.columnName,
        param.orderName,
        param.ascDesc,
        param.page,
        param.startDate,
        param.endDate,
      );

      if (res.data.success === true) {
        if (res.data.to_email) {
          thunkAPI.dispatch(setAppSuccessAC({ success: res.data.message }));
          return;
        }
        window.location.href = `${res.data.url}`;
        thunkAPI.dispatch(setStatusSellersTable({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusSellersTable({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      thunkAPI.dispatch(setStatusSellersTable({ status: "succeeded" }));
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
