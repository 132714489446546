import { AppRootStateType } from "src/store/types";

export const selectDataMerchantTableBrands = (state: AppRootStateType) =>
  state.merchantTableBrands.table;

export const selectOrderNameMerchantTableBrands = (state: AppRootStateType) =>
  state.merchantTableBrands.orderName;
export const selectAscDescMerchantTableBrands = (state: AppRootStateType) =>
  state.merchantTableBrands.ascDesc;
export const selectPageMerchantTableBrands = (state: AppRootStateType) =>
  state.merchantTableBrands.page;
export const selectTotalCountPageMerchantTableBrands = (
  state: AppRootStateType,
) => state.merchantTableBrands.totalCountPage;
export const selectStatusMerchantTableBrands = (state: AppRootStateType) =>
  state.merchantTableBrands.status;
