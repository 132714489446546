import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import WidgetCard from "src/components/Widgets/WidgetCard/WidgetCard";
import TrafficSource from "src/components/Widgets/Сompetitors";

const WidgetData = ({
  nameOne,
  nameTwo,
  brand,
  id,
  dataRevenue,
  selectStatusDataRevenue,
  select,
  dataRating,
  selectStatusDataRating,
  dataProductInTheTops,
  selectStatusProductInTheTops,
  selectChoiceProductInTheTops,
  dataCompetitors,
  fetchDataSearch,
  fetchDataCategory,
  selectChoiceCompetitors,
  selectStatusCompetitors,
  fetchDataCompetitorsSold,
  fetchItemsInTheCategoryTop,
}: any) => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <div
        className="row"
        style={{
          // marginBottom: "2px",
          marginTop: "5px",
        }}
      >
        <WidgetCard
          bar={true}
          selector={selectStatusDataRevenue}
          dataSelector={dataRevenue}
          id={id}
          title={t("revenue")}
          subtitle={t("revenue for this period")}
          select={false}
        />{" "}
        <WidgetCard
          bar={false}
          selector={selectStatusProductInTheTops}
          dataSelector={dataProductInTheTops}
          selectChoiceProductInTheTops={selectChoiceProductInTheTops}
          id={id}
          title={t("Number of products in tops")}
          fetchDataSearch={fetchDataSearch}
          fetchDataCategory={fetchDataCategory}
          subtitle={t("Best position")}
          select={true}
        />
        <WidgetCard
          bar={false}
          selector={selectStatusDataRating}
          dataSelector={dataRating}
          title={t("Average product rating")}
          subtitle={t("Best product rating")}
        />
        <Col xl={3} sm={6} className="px-1">
          <TrafficSource
            nameOne={nameOne}
            nameTwo={nameTwo}
            brand={brand}
            id={id}
            select={select}
            dataCompetitors={dataCompetitors}
            selectChoiceCompetitors={selectChoiceCompetitors}
            selectStatusCompetitors={selectStatusCompetitors}
            fetchDataCompetitorsSold={fetchDataCompetitorsSold}
            fetchItemsInTheCategoryTop={fetchItemsInTheCategoryTop}
          />
        </Col>
      </div>
    </React.Fragment>
  );
};

export default WidgetData;
