import { createAsyncThunk } from "@reduxjs/toolkit";
import { favoritesGoodsTableAPI } from "src/api/api_favoriteGoodsTable/api_favoriteGoodsTable";
import { setAppErrorAC } from "src/features/App/reducer";
import {
  setStatusTableFavoriteGoods,
  setTableFavoriteGoods,
} from "src/pages/FavoriteGoods/reducer";
import { handleAsyncServerNetworkError } from "src/utilitsFunction/error-utils";

export const fetchFavoriteGoods = createAsyncThunk<any, any>(
  "favoriteGoods/fetchFavoriteGoods",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(
      setStatusTableFavoriteGoods({
        status: "tableFavoriteGoods-loading",
      }),
    );
    try {
      const res = await favoritesGoodsTableAPI.fetchFavoriteGoodsTable();

      if (res.data.success === true) {
        thunkAPI.dispatch(setTableFavoriteGoods({ table: res.data.data }));
        thunkAPI.dispatch(setStatusTableFavoriteGoods({ status: "succeeded" }));
      } else {
        thunkAPI.dispatch(setStatusTableFavoriteGoods({ status: "succeeded" }));
        thunkAPI.dispatch(
          setAppErrorAC({
            error:
              "Something went wrong. We're already looking into the problem",
          }),
        );
      }
    } catch (error) {
      return handleAsyncServerNetworkError(error, thunkAPI);
    }
  },
);
