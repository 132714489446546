import React, { useRef } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { selectCat, selectKeys } from "src/components/VerticalLayout/selectors";
import { deleteKeys, setKeys } from "src/features/trendOil/reducer";
// import { photos } from "src/components/VerticalLayout/utils/photosUtil";
import { useAppSelector } from "src/store/types";
import withRouter from "../Common/withRouter";
import Left from "./../../assets/images/arrow_arrows_back_direction_left_navigation_right_icon_123237.svg";
// import category from "../../assets/images/my/category.svg";
import category from "../../assets/images/category.png";
import list from "../../assets/images/list.png";
import seller from "../../assets/images/seller.png";
import brand from "../../assets/images/brand-image.png";
import search from "../../assets/images/search-interface-symbol.png";
import s from "./SidebarContent.module.css";

const MenuItem = ({ item, photo, props }: any) => {
  return (
    <li>
      {photo && (
        <img
          src={photo}
          style={{ width: "22px", height: "22px" }}
          className={" icon nav-icon"}
        />
      )}
      <span className="menu-item">{props.t(item["label"])}</span>
      {item !== null && (
        <Link to={"#!"} className={"badge  menu-item "}>
          {item.substr(0, 17)}
        </Link>
      )}
    </li>
  );
};

const SidebarContent = (props: any) => {
  const { t } = useTranslation();
  const categoriesData = [
    // {
    //   key: "Trendyol.Categories",
    //   name: "Categories",
    //   to: "/trendyol/categories",
    //   image: category,
    // },
    {
      name: t("categories"),
      to: "/trendyol/category-list",
      image: list,
    },
    {
      name: t("sellers"),
      to: "/trendyol/seller-list",
      image: seller,
    },
    {
      name: t("brands"),
      to: "/trendyol/brand-list",
      image: brand,
    },
    {
      name: t("searchProduct"),
      to: "/trendyol/product-search",
      image: search,
    },
    {
      name: t("Market research"),
      to: "/trendyol/product-research",
      image: search,
    },
    {
      name: t("Integration"),
      to: "/trendyol/integration",
      image: category,
    },
  ];
  const ref = useRef<any>();
  const keys = useAppSelector(selectKeys);
  const cat = useAppSelector(selectCat);
  const dispatch = useDispatch();
  let nameCategory = Object.keys(cat);

  const sentNull = (e: any) => {
    dispatch(deleteKeys(null));
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="sidebar-menu-scroll" id="nav-scroll">
        <div id="sidebar-menu">
          {keys.length !== 0 && (
            <img
              src={Left}
              onClick={sentNull}
              className={"badge "}
              style={{
                display: "inline-block",
                position: "absolute",
                height: "40px",
                width: "40px",
                // padding:'10px',
                marginLeft: "10px",
                marginTop: "-20px",
                marginBottom: "30px",
              }}
            />
          )}
          <ul className="metismenu  list-unstyled" id="side-menu">
            {keys.length === 0 && (
              <div>
                {categoriesData.map((el) => (
                  <Link
                    key={el.name}
                    onClick={(e) => {
                      // if (el.key) {
                      //   dispatch(setKeys({ keys: "Trendyol.Categories" }));
                      // } else {
                      tToggle();
                      // }
                    }}
                    to={el.to ? el.to : "#"}
                    className={s.brandLink}
                    style={{ display: "block", width: "100%", margin: "15px" }}
                  >
                    <img
                      className={s.brandLogo}
                      src={el.image}
                      alt=""
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                        marginLeft: "5px",
                      }}
                    />
                    {el.name}
                  </Link>
                ))}
              </div>
            )}

            {/*{keys.includes("Trendyol") && keys.length === 1 && (*/}
            {/*  <Link*/}
            {/*    to={"#!"}*/}
            {/*    style={{ marginLeft: "8px", marginTop: "17px" }}*/}
            {/*    onClick={() =>*/}
            {/*      dispatch(setKeys({ keys: "Trendyol.Categories" }))*/}
            {/*    }*/}
            {/*    className={"badge  menu-item "}*/}
            {/*  >*/}
            {/*    {" "}*/}
            {/*    <img style={{ paddingBottom: "3px" }} src={category} alt="" />*/}
            {/*    {"Categories"}*/}
            {/*  </Link>*/}
            {/*)}*/}
            <div style={{ marginTop: "17px" }}>
              {keys.includes("Trendyol.Categories") &&
                keys.length === 1 &&
                nameCategory.map((element: any) => {
                  return (
                    <>
                      <div onClick={() => dispatch(setKeys({ keys: element }))}>
                        {/*{photos[element].map((el: any, index: any) =>*/}
                        {/*  <div key={index}>*/}
                        <MenuItem
                          // photo={el.image}
                          item={element}
                          props={props}
                        />
                        {/*</div>*/}
                        {/*)}*/}
                      </div>
                    </>
                  );
                })}
              {typeof cat[keys[1]] !== "undefined" &&
                cat[keys[1]].map((el: any) => {
                  return (
                    <div key={el.id}>
                      <Link
                        className={s.brandLink}
                        onClick={() => tToggle()}
                        to={`/trendyol/category/${el.id}/products`}
                      >
                        {el.name.substr(0, 17)}
                      </Link>
                    </div>
                  );
                })}
            </div>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(SidebarContent));
