import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export function GetProductSearchDescription() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  if (currentLanguage === "tr") {
    return (
      <>
        Ürün
        {/*<NavLink to={"https://www.trendyol.com/"}>trendyol.com</NavLink>{" "}*/}
        <span> trendyol.com </span>
        sayfasına bağlantı verilerek veya SKU ile bulunabilir
      </>
    );
  } else {
    return (
      <>
        {t("searchProductDescriptionPartOne")}
        {/*<NavLink to={"https://www.trendyol.com/"}> trendyol.com </NavLink>*/}
        <span> trendyol.com </span>
        {t("searchProductDescriptionPartTwo")}
      </>
    );
  }
}
