interface InputsType {
  name: string;
  value: string | number | null | undefined;
}

interface BuildParamsArgs {
  columns?: InputsType[];
  orderName?: string;
  ascDesk?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
}

export const buildParams = ({
  columns = [],
  orderName = "",
  ascDesk = "",
  startDate,
  endDate,
  page = 1,
}: BuildParamsArgs): { [key: string]: string } => {
  const params: { [key: string]: string } = {};

  columns.forEach((column) => {
    if (
      column.value !== undefined &&
      column.value !== null &&
      column.value !== ""
    ) {
      params[`search[${column.name}]`] = column.value.toString();
    }
  });

  if (orderName !== "") {
    params["orderColumn"] = orderName;
  }
  if (ascDesk !== "") {
    params["orderType"] = ascDesk;
  }

  if (startDate) {
    params["dateFrom"] = startDate;
  }

  if (endDate) {
    params["dateTo"] = endDate;
  }

  params["page"] = page.toString();

  return params;
};
